import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Container, Grid, Button} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {ROUTE} from '../../../common/constant';
import {shuttleBusInfo, initialVerifyScreen, verifyOrder} from '../../../stores/shuttleBus/actions';
import {displayDateTime, convertDatetimeUTC, compareTime} from '../../../utils/date_time';

/**
 * notify register shuttle bus
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    !(Object.keys(this.props.shuttle.messageRegistry).length > 0) &&
      this.props.history.replace({pathname: ROUTE.VALIDATE_BUS});
    window.scrollTo(0, 0);
  }

  /**
   * handleNextScreen
   */
  handleNextScreen = () => {
    this.props.verifyOrder(localStorage.getItem('order_no'), localStorage.getItem('email'), this.props);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {shuttle} = this.props;

    return (
      <Container maxWidth="xs" style={{height: '100vh'}}>
        <Grid container>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-48">
            <img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Logo" style={{width: 74}} />
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <span className="font-18 mb-5px color-success font-weight-600">WILLER mobi の</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
              <span className="font-18 mb-5px color-success font-weight-600">予約リクエストを受け付けました</span>
            </Grid>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <span className="font-18 mb-5px color-danger">※まだ予約は確定していません。</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
              <span className="font-18 mb-5px color-danger">予約確定のメールをお待ちください</span>
            </Grid>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <span className="font-16 mb-5px font-weight-600">乗車地/降車地の予定到着時刻を</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
              <span className="font-16 mb-5px font-weight-600">下記、時刻にメールにて連絡いたします</span>
            </Grid>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
            <Grid style={{borderRadius: 12, backgroundColor: '#F8F8F8', padding: 24}}>
              <Grid item xs={12} justify="center" alignItems="center" container className="mb-24">
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <span className="font-12 mb-5px">メール送付先</span>
                </Grid>
                {shuttle.messageRegistry?.emails?.map((item, index) => (
                  <Grid item xs={12} justify="center" alignItems="center" container key={index}>
                    <span className="font-17 mb-5px">{item}</span>
                  </Grid>
                ))}
              </Grid>
              <hr className="mb-24"></hr>
              {shuttle.messageRegistry?.update_information_orders
                  ?.sort((e) => e.reservation_id)
                  ?.map((item, index) => (
                    <Grid container key={index} className="mb-12">
                      <Grid item xs={12} justify="center" alignItems="center" container>
                        <span className="font-12 mb-5px font-weight-600">
                          {localStorage.getItem('way_down') &&
                        Number(item?.reservation_id) === Number(localStorage.getItem('way_down')) ?
                          '【復路便】' :
                          '【往路便】'}
                          {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                          '乗車地の予定出発時刻のご連絡期間' :
                          '降車地の予定到着時刻のご連絡期間'}
                        </span>
                      </Grid>
                      <Grid item xs={12} justify="center" alignItems="center" container>
                        <span className="font-18 mb-5px">
                          &nbsp;&nbsp;{displayDateTime(convertDatetimeUTC(item.min_closed_time))}頃
                        </span>
                      </Grid>
                      {!compareTime(item.min_closed_time, item.max_closed_time) && (
                        <Grid item xs={12} justify="center" alignItems="center" container>
                          <span className="font-18 mb-5px">
                          ~{displayDateTime(convertDatetimeUTC(item.max_closed_time))}頃
                          </span>
                        </Grid>
                      )}
                    </Grid>
                  ))}
            </Grid>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-48 mb-24">
            <div className="width_100 padding-item-0">
              <Button
                variant="contained"
                className="width_100 button_color_success font-weight-600"
                onClick={this.handleNextScreen}
              >
                予約リクエスト内容を確認する
                <ArrowRightIcon style={{position: 'absolute', right: 5}} />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shuttle: state.shuttle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    shuttleBusInfo: (shuttle_bus_info) => dispatch(shuttleBusInfo(shuttle_bus_info)),
    initialVerifyScreen: () => dispatch(initialVerifyScreen()),
    verifyOrder: (order_no, email, props) => dispatch(verifyOrder(order_no, email, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
