import {api} from '../services/api';

const verifyOrderUrl = 'exbus/reservation/verify-order';
const registerShuttleBusUrl = 'exbus/reservation/update-information';
const cancelShuttleBusUrl = 'exbus/reservation/cancel';

const getJitTimeSettingUrl = 'exbus/reservation/expected-times';
const checkExistAccountUrl = 'exbus/reservation/check-email';
const checkValidTimeUrl = 'exbus/reservation/check-time';

export const verifyOrderApi = (order_no, email) => {
  return api.get(verifyOrderUrl, {order_no: order_no, email: email});
};

export const registerShuttleBusApi = (payload) => {
  return api.put(registerShuttleBusUrl, payload);
};

export const cancelShuttleBusApi = (payload) => {
  return api.post(cancelShuttleBusUrl, payload);
};

export const getJitTimeSettingApi = (express_time, time_type, zone_id) => {
  return api.get(getJitTimeSettingUrl, {express_time: express_time, time_type: time_type, zone_id: zone_id});
};

export const checkExistAccountApi = (email) => {
  return api.get(checkExistAccountUrl, {email: email});
};

export const checkValidTimeApi = (expected_time, time_type) => {
  return api.get(checkValidTimeUrl, {expected_time: expected_time, time_type: time_type});
};
