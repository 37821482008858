import {getClientTimezone} from './date_time';

/**
 * convert raw data
 * @param {Object} data
 * @return {Object}
 */
export const defaultData = (data) => {
  return {
    id: data?.id ? data?.id : -1,
    pick_up_id: data?.preferred_pickup_stop_id ? data?.preferred_pickup_stop_id : '',
    pick_up_name: data?.pickup_name,
    pick_up_lat: data?.pickup_lat,
    pick_up_lon: data?.pickup_lon,
    drop_off_id: data?.preferred_dropoff_stop_id ? data?.preferred_dropoff_stop_id : '',
    drop_off_name: data?.drop_off_name,
    drop_off_lat: data?.drop_off_lat,
    drop_off_lon: data?.drop_off_lon,
    shuttle_bus_type: data?.shuttle_bus_type,
    expected_time: data?.expected_time ? data?.expected_time : '',
    passenger_name: data?.passenger_name ? data?.passenger_name : '',
    passenger_email: data ?
      data?.passenger_email :
      localStorage.getItem('user_email') ?
      localStorage.getItem('user_email') :
      '',
    phone: data?.phone ? data?.phone : '',
    number_in_use: data?.number_in_use ? data?.number_in_use : '',
    isTermConditions: false,
    isPrivacyPolicy: false,
    simulation_id: data?.simulation_id,
    geofence_id: data?.geofence_id,
    booking_id: data?.booking_id,
    estimated_drop_off_swat_time: data?.estimated_drop_off_swat_time,
    estimated_pickup_swat_time: data?.estimated_pickup_swat_time,
    shuttle_bus_stop_name: data?.shuttle_bus_stop_name,
    max_closed_time: data?.max_closed_time,
    min_closed_time: data?.min_closed_time,
    status: data?.status,
  };
};

export const convertAsahikawaPayload = (data) => {
  const wex_infos = [
    {
      id: data.id ? data.id : -1,
      reservation_id: data.reservation_id ? data.reservation_id : null,
      preferred_pickup_stop_id: data.pick_up_id,
      pickup_lat: data.pick_up_lat,
      pickup_lon: data.pick_up_lon,
      pickup_name: data.pick_up_name,
      preferred_dropoff_stop_id: data.drop_off_id,
      drop_off_lat: data.drop_off_lat,
      drop_off_lon: data.drop_off_lon,
      drop_off_name: data.drop_off_name,
      passenger_email: data.passenger_email?.trim().toLowerCase(),
      passenger_name: data.passenger_name?.trim(),
      phone: data.phone?.trim(),
      shuttle_bus_type: data.shuttle_bus_type,
      vehicle_id: null,
      expected_time: data.expected_time,
      number_in_use: data.number_in_use,
      status: data.status ? 'REGISTERED' : 'NONE',
      booking_id: data.booking_id,
      geofence_id: data.geofence_id,
      trip_booking: {
        transportation_type: 'SHUTTLE_BUS',
        preferred_pickup_stop_id: data.pick_up_id,
        pickup_location_lat: data.pick_up_lat,
        pickup_location_lon: data.pick_up_lon,
        pickup_location_name: data.pick_up_name,
        preferred_dropoff_stop_id: data.drop_off_id,
        dropoff_location_lat: data.drop_off_lat,
        dropoff_location_lon: data.drop_off_lon,
        dropoff_location_name: data.drop_off_name,
        demand: {
          normal_passengers: 0,
          passenger: data.number_in_use,
          special_category: 0,
        },
        requested_departure_time: data.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? null : data.expected_time,
        requested_destination_time:
          data.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? data.expected_time : null,
        trip_info: [{}],
        simulation_id: data.simulation_id,
        service_type: 'SHUTTLE_BUS_JIT',
      },
    },
  ];

  return {wex_infos: wex_infos, zone_id: getClientTimezone()};
};

/**
 * convert data for cancel asahikawa shuttle bus
 * @param {Object} data
 * @return {Object}
 */
export const convertDeletePayload = (data) => {
  return {
    pickup_location_lat: data.pick_up_lat,
    pickup_location_lon: data.pick_up_lon,
    pickup_location_name: data.pick_up_name,
    dropoff_location_lat: data.drop_off_lat,
    dropoff_location_lon: data.drop_off_lon,
    dropoff_location_name: data.drop_off_name,
    requested_departure_time: data.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? null : data.expected_time,
    requested_destination_time: data.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? data.expected_time : null,
    id: data.id,
    zone_id: getClientTimezone(),
  };
};
