import {ACTION_TYPES} from './reducer';
import {setMessageModal} from '../modal/actions';
import {modalObj} from '../../utils/modal.js';
import {resetPasswordApi, verifyPasswordTokenApi} from '../../services/authenServices';
import {ROUTE} from '../../common/constant';

// verify password token
const requestVerifyPasswordTokenAction = {
  type: ACTION_TYPES.VERIFY_PASSWORD_TOKEN_START,
};

export const successVerifyPasswordTokenAction = (data) => {
  return {
    type: ACTION_TYPES.VERIFY_PASSWORD_TOKEN_SUCCESS,
    message_code: data,
  };
};

export const failVerifyPasswordTokenAction = (data) => {
  return {
    type: ACTION_TYPES.VERIFY_PASSWORD_TOKEN_FAIL,
    message_code: data,
  };
};

// reset password
const requestResetPasswordAction = {
  type: ACTION_TYPES.RESET_PASSWORD_START,
};

export const successResetPasswordAction = (data) => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD_SUCCESS,
  };
};

export const failResetPasswordAction = (data) => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD_FAIL,
  };
};

/**
 * Verify Password Token
 * @param {string} token
 * @return {object}
 */
export const verifyPasswordToken = (token) => {
  return (dispatch) => {
    dispatch(requestVerifyPasswordTokenAction);
    return verifyPasswordTokenApi(token).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successVerifyPasswordTokenAction(response.message_code));
          } else {
            dispatch(failVerifyPasswordTokenAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failVerifyPasswordTokenAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Reset Password
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const resetPassword = (payload, props) => {
  return (dispatch) => {
    dispatch(requestResetPasswordAction);
    resetPasswordApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successResetPasswordAction(response.result));
            props.history.push(ROUTE.MOBILE_RE_PASSWORD_COMPLETE);
          } else {
            dispatch(failResetPasswordAction(response.message));
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        (error) => {
          dispatch(setMessageModal(modalObj(true, error.message)));
          dispatch(failResetPasswordAction('Internal Server Error'));
        },
    );
  };
};
