export const ACTION_TYPES = {
  GET_GEOFENCES_START: 'GET_GEOFENCES_START',
  GET_GEOFENCES_SUCCESS: 'GET_GEOFENCES_SUCCESS',
  GET_GEOFENCES_FAIL: 'GET_GEOFENCES_FAIL',
};

const initialState = {
  geofences: [],
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_GEOFENCES_START:
      return {
        ...state,
      };
    case ACTION_TYPES.GET_GEOFENCES_SUCCESS:
      return {
        ...state,
        geofences: action.geofences,
      };
    case ACTION_TYPES.GET_GEOFENCES_FAIL:
      return {
        ...state,
        geofences: [],
      };
    default:
      return state;
  }
}
