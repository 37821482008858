import {ACTION_TYPES} from './reducer';
import {ROUTE} from '../../common/constant';
import {
  getPlaceApi,
  getExpectedTimeApi,
  registerAsahikawaApi,
  updatedAsahikawaApi,
  cancelAsahikawaApi,
  verifyAsahikawaOrderApi,
} from '../../services/asahikawaServices';
import {defaultData} from '../../utils/asahikawa';


// initial data in landing page
export const initialLandingPageState = () => {
  return {
    type: ACTION_TYPES.INITIAL_LANDING_PAGE,
  };
};

// get asahikawa pick up places
const requestGetPickUpPlacesAction = {
  type: ACTION_TYPES.GET_ASAHIKAWA_PICK_UP_PLACE_START,
};

export const successGetPickUpPlacesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ASAHIKAWA_PICK_UP_PLACE_SUCCESS,
    pick_up_places: data,
  };
};

export const failGetPickUpPlacesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ASAHIKAWA_PICK_UP_PLACE_FAIL,
    pick_up_places: [],
  };
};

// get asahikawa drop off places
const requestGetDropOffPlacesAction = {
  type: ACTION_TYPES.GET_ASAHIKAWA_DROP_OFF_PLACE_START,
};

export const successGetDropOffPlacesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ASAHIKAWA_DROP_OFF_PLACE_SUCCESS,
    drop_off_places: data,
  };
};

export const failGetDropOffPlacesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_ASAHIKAWA_DROP_OFF_PLACE_FAIL,
    drop_off_places: [],
  };
};

// get expected times
const requestExpectedTimesAction = {
  type: ACTION_TYPES.GET_EXPECTED_TIME_START,
};

export const successExpectedTimesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_EXPECTED_TIME_SUCCESS,
    expected_times: data,
  };
};

export const failExpectedTimesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_EXPECTED_TIME_FAIL,
    expected_times: [],
  };
};

// register asahikawa bus
const requestRegisterAsahikawaBusAction = {
  type: ACTION_TYPES.REGISTER_ASAHIKAWA_BUS_START,
};

export const successRegisterAsahikawaBusAction = (data) => {
  return {
    type: ACTION_TYPES.REGISTER_ASAHIKAWA_BUS_SUCCESS,
    messageRegistry: data.result,
  };
};

export const failRegisterAsahikawaBusAction = (data) => {
  return {
    type: ACTION_TYPES.REGISTER_ASAHIKAWA_BUS_FAIL,
    errorMessage: data,
  };
};

// update asahikawa bus
const requestUpdateAsahikawaBusAction = {
  type: ACTION_TYPES.UPDATE_ASAHIKAWA_BUS_START,
};

export const successUpdateAsahikawaBusAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ASAHIKAWA_BUS_SUCCESS,
    messageRegistry: data.result,
  };
};

export const failUpdateAsahikawaBusAction = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_ASAHIKAWA_BUS_FAIL,
    errorMessage: data,
  };
};

// cancel asahikawa bus
const requestCancelAsahikawaBusAction = {
  type: ACTION_TYPES.CANCEL_ASAHIKAWA_BUS_START,
};

export const successCancelAsahikawaBusAction = (data) => {
  return {
    type: ACTION_TYPES.CANCEL_ASAHIKAWA_BUS_SUCCESS,
  };
};

export const failCancelAsahikawaBusAction = (data) => {
  return {
    type: ACTION_TYPES.CANCEL_ASAHIKAWA_BUS_FAIL,
  };
};

// asahikawa bus state
export const asahikawaBusData = (data) => {
  return {
    type: ACTION_TYPES.ASAHIKAWA_BUS,
    asahikawa_bus: data,
  };
};

// initial verify order screen
export const initialVerifyOrderScreen = () => {
  return {
    type: ACTION_TYPES.INITIAL_SCREEN,
  };
};

// verify asahikawa shuttle bus order
export const startVerifyOrderAction = {
  type: ACTION_TYPES.VERIFY_ASAHIKAWA_ORDER_START,
};

export const successVerifyOrderAction = (data) => {
  return {
    type: ACTION_TYPES.VERIFY_ASAHIKAWA_ORDER_SUCCESS,
    asahikawa_bus: data,
  };
};

export const failVerifyOrderAction = (data) => {
  return {
    type: ACTION_TYPES.VERIFY_ASAHIKAWA_ORDER_FAIL,
    errorMessage: data,
  };
};

/**
 * getPickUpPlaces
 * @param {String} transit_stop_id
 * @param {String} place_type
 * @return {*}
 */
export const getPickUpPlaces = (transit_stop_id, place_type) => {
  return (dispatch) => {
    dispatch(requestGetPickUpPlacesAction);
    return getPlaceApi(transit_stop_id, place_type).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successGetPickUpPlacesAction(response.result));
          } else {
            dispatch(failGetPickUpPlacesAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failGetPickUpPlacesAction('Internal Server Error'));
        },
    );
  };
};

/**
 * getDropOffPlaces
 * @param {String} transit_stop_id
 * @param {String} place_type
 * @return {*}
 */
export const getDropOffPlaces = (transit_stop_id, place_type) => {
  return (dispatch) => {
    dispatch(requestGetDropOffPlacesAction);
    return getPlaceApi(transit_stop_id, place_type).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successGetDropOffPlacesAction(response.result));
          } else {
            dispatch(failGetDropOffPlacesAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failGetDropOffPlacesAction('Internal Server Error'));
        },
    );
  };
};

/**
 * getExpectedTimes
 * @param {Date} date_time
 * @param {String} zone_id
 * @param {String} geofence_id
 * @param {String} time_type
 * @return {*}
 */
export const getExpectedTimes = (date_time, zone_id, geofence_id, time_type) => {
  return (dispatch) => {
    dispatch(requestExpectedTimesAction);
    return getExpectedTimeApi(date_time, zone_id, geofence_id, time_type).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successExpectedTimesAction(response.result));
          } else {
            dispatch(failExpectedTimesAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failExpectedTimesAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Register Asahikawa Bus
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const registerAsahikawaBus = (payload, props) => {
  return (dispatch) => {
    dispatch(requestRegisterAsahikawaBusAction);
    return registerAsahikawaApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successRegisterAsahikawaBusAction(response));
            props.history.push(ROUTE.ASAHIKAWA_COMPLETE);
          } else {
            dispatch(failRegisterAsahikawaBusAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failRegisterAsahikawaBusAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Update Asahikawa Bus
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const updateAsahikawaBus = (payload, props) => {
  return (dispatch) => {
    dispatch(requestUpdateAsahikawaBusAction);
    return updatedAsahikawaApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successUpdateAsahikawaBusAction(response));
            props.history.push(ROUTE.ASAHIKAWA_COMPLETE);
          } else {
            dispatch(failUpdateAsahikawaBusAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failUpdateAsahikawaBusAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Cancel Asahikawa Bus
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const cancelAsahikawaBus = (payload, props) => {
  return (dispatch) => {
    dispatch(requestCancelAsahikawaBusAction);
    return cancelAsahikawaApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successCancelAsahikawaBusAction(response));
          } else {
            dispatch(failCancelAsahikawaBusAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failCancelAsahikawaBusAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Verify Asahikawa Bus
 * @param {object} reservation_number
 * @param {object} email
 * @param {object} props
 * @return {object}
 */
export const verifyAsahikawaOrder = (reservation_number, email, props) => {
  return (dispatch) => {
    dispatch(startVerifyOrderAction);
    localStorage.removeItem('token');
    return verifyAsahikawaOrderApi(reservation_number, email).then(
        (response) => {
          if (response && response.status === 200) {
            localStorage.setItem('token', response.result.token);
            localStorage.setItem('reservation_number', response.result.reservation_number);
            localStorage.setItem('asahikawa_email', response.result.email);
            dispatch(successVerifyOrderAction(defaultData(response.result.shuttle_bus_info)));
            props.history.push(ROUTE.ASAHIKAWA_DETAIL);
          } else {
            dispatch(failVerifyOrderAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failVerifyOrderAction('Internal Server Error'));
        },
    );
  };
};


/**
 * Refresh Detail's data Asahikawa Bus
 * @param {object} reservation_number
 * @param {object} email
 * @param {object} props
 * @return {object}
 */
export const reloadAsahikawaOrderDetail = (reservation_number, email, props) => {
  return (dispatch) => {
    dispatch(startVerifyOrderAction);
    localStorage.removeItem('token');
    return verifyAsahikawaOrderApi(reservation_number, email).then(
        (response) => {
          if (response && response.status === 200) {
            localStorage.setItem('token', response.result.token);
            localStorage.setItem('reservation_number', response.result.reservation_number);
            localStorage.setItem('asahikawa_email', response.result.email);
            dispatch(successVerifyOrderAction(defaultData(response.result.shuttle_bus_info)));
          } else {
            dispatch(failVerifyOrderAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failVerifyOrderAction('Internal Server Error'));
        },
    );
  };
};
