import React, {Component} from 'react';
import './style.css';

/**
 * Loading Circle component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    const {radius, stroke} = this.props;
    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {radius, stroke, progress} = this.props;
    const strokeDashoffset = this.circumference - (progress / 100) * this.circumference;

    return (
      <svg preserveAspectRatio="xMidYMid meet" height={radius * 2} width={radius * 2}>
        <g>
          <circle
            stroke="#18C090"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={this.circumference + ' ' + this.circumference}
            style={{strokeDashoffset}}
            r={this.normalizedRadius}
            cx={radius}
            cy={radius}
          ></circle>
          <path
            id="Path_46576"
            data-name="Path 46576"
            // eslint-disable-next-line max-len
            d="M607.037,590.846l1.656,1.655a.965.965,0,0,1,0,1.361l-12.556,12.556a.976.976,0,0,1-1.37,0l-7.125-7.125a.965.965,0,0,1,0-1.361l1.656-1.656a.966.966,0,0,1,1.36,0l4.794,4.794,10.224-10.224a.964.964,0,0,1,1.36,0"
            transform="translate(-562.362 -562.566)"
            fill={progress < 100 ? '#c8c8c8' : '#18c090'}
            fillRule="evenodd"
          />
        </g>
      </svg>
    );
  }
}

export default Index;
