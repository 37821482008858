import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {Container, Grid, Button} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {ROUTE} from '../../../common/constant';
import SnowBus from '../../../views/shuttle_bus_asahikawa/landing_page/snow_bus';
import {asahikawaBusData, initialLandingPageState} from '../../../stores/asahikawa/actions';
import {getGeofences} from '../../../stores/maps/actions';
import {defaultData} from '../../../utils/asahikawa';

const useStyles = (theme) => ({
  layout: {
    marginTop: '12px !important',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: `calc(${window.innerHeight}px - 170px)`,
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: `calc(${window.innerHeight}px - 170px)`,
    },
  },
});

/**
 * Landing page
 */
class Index extends Component {
  /**
   * componentDidMount
   */
  componentDidMount() {
    localStorage.removeItem('token');
    localStorage.removeItem('reservation_number');
    localStorage.removeItem('asahikawa_email');
    this.props.initialLandingPageState();
    this.props.getGeofences();
    const urlParamStr = new URLSearchParams(window.location.search);
    if (urlParamStr.get('email')) {
      localStorage.setItem('user_email', urlParamStr.get('email'));
    }
    window.scrollTo(0, 0);
  }

  /**
   * handle redirect to create new order
   */
  handleCreateNewOrder = () => {
    this.props.asahikawaBusData(defaultData());
    this.props.history.push(ROUTE.ASAHIKAWA_INPUT);
  };

  /**
   * Render HTML
   * @return {component}
   */
  render() {
    const {asahikawa, classes} = this.props;

    return (
      <Container maxWidth="xl" style={{height: '100%'}} className="p-0">
        <LoadingOverlay
          active={asahikawa.isLoading}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          <Grid container className="pt-12">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
            </Grid>
          </Grid>
          <div className={classes.layout}>
            <SnowBus handleOnClick={() => this.handleCreateNewOrder()} />
          </div>
          <Grid container item justify="center" alignItems="center">
            <Grid container item xs={12} md={6} justify="center" alignItems="center" className="mt-24 mb-24">
              <Grid item xs={6} justify="center" alignItems="center" container className="padding-item-0">
                <Button
                  type="submit"
                  variant="contained"
                  className="width_100 button_color_success button_height font-weight-600"
                  onClick={() => this.handleCreateNewOrder()}
                >
                  新規予約
                </Button>
              </Grid>
              <Grid item xs={6} justify="center" alignItems="center" container className="padding-item-0">
                <Button
                  type="submit"
                  variant="contained"
                  className="width_100 button_color_info button_height font-weight-600"
                  onClick={() => this.props.history.push(ROUTE.ASAHIKAWA_VERIFY_ORDER)}
                >
                  予約確認
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    asahikawa: state.asahikawa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGeofences: () => dispatch(getGeofences()),
    asahikawaBusData: (data) => dispatch(asahikawaBusData(data)),
    initialLandingPageState: () => dispatch(initialLandingPageState()),
  };
};

export default withTranslation('translations')(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Index)),
);
