/* eslint-disable indent */
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Container, Grid} from '@material-ui/core';
import _ from 'lodash';
import {getPlanByGeofencesApi, getPlanByIdApi} from '../../services/subscriptionServices';
import {convertQueryStringToObject} from '../../utils/common';
import {customDisplayCurrency} from '../../utils/common';
import './style.css';

/**
 * Subscription Landing Page
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    window.scrollTo(0, 0);
    const urlParamStr = convertQueryStringToObject(window.location.search);

    const planId = urlParamStr?.planId;
    const geofence_id = urlParamStr?.geofenceId;

    if (planId) {
      await getPlanByIdApi(planId)
        .then((response) => {
          if (response && response.status === 200) {
            this.setState({plans: response.result});
          } else {
            if (geofence_id) {
              getPlanByGeofencesApi(geofence_id ? geofence_id : '', 200).then((response) => {
                if (response && response.status === 200) {
                  this.setState({plans: response.result?.filter((element) => element.is_main_plan)[0]});
                } else {
                  this.setState({plans: []});
                }
              });
            } else {
              this.setState({plans: []});
            }
          }
        });
    } else {
      await getPlanByGeofencesApi(geofence_id ? geofence_id : '', 200).then((response) => {
        if (response && response.status === 200) {
          this.setState({plans: response.result?.filter((element) => element.is_main_plan)[0]});
        } else {
          this.setState({plans: []});
        }
      });
    }
  }

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t} = this.props;
    const {plans} = this.state;

    return (
      <Container maxWidth="xs" style={{height: '100vh'}} className="landing-page">
        <Grid container>
          <Grid item xs={12} justify="center" alignItems="center" container>
            <img
              src={`${process.env.PUBLIC_URL}/images/subscription/banner_family_${localStorage.getItem(
                'i18nextLng',
              )}.png`}
              alt="img"
            />
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60 list-content">
            <span className="font-16 font-weight-600 item-content">{t('family_option.title1')}</span>
            <div className="item-content">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/logo_rotate_180_degree.png`}
                alt="img"
                className="mt-24"
              />
            </div>
            <span className="font-20 font-weight-600 mt-12 item-content">{t('family_option.description')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-24">
            <span className="font-14 font-weight-600 padding-border-item">{t('family_option.title2')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-48 list-content">
            <span className="font-14 font-weight-600 pt-6 item-content">
              {t('family_option.introduce', {
                value: customDisplayCurrency(
                  !_.isEmpty(plans) ? plans?.plan_prices[0]?.extra_fee : 0,
                  !_.isEmpty(plans) ? plans?.plan_prices[0]?.currency_code : 'JPY',
                ),
              })}
            </span>
            <span className="font-14 font-weight-600 pt-6 item-content">
              {t('family_option.introduce1', {
                value: customDisplayCurrency(
                  !_.isEmpty(plans) ? plans?.plan_prices[0]?.extra_fee : 0,
                  !_.isEmpty(plans) ? plans?.plan_prices[0]?.currency_code : 'JPY',
                ),
              })}
            </span>
            <span className="font-14 font-weight-600 pt-6 item-content">
              {t('family_option.introduce2', {
                value: customDisplayCurrency(
                  !_.isEmpty(plans) ? plans?.plan_prices[0]?.extra_fee : 0,
                  !_.isEmpty(plans) ? plans?.plan_prices[0]?.currency_code : 'JPY',
                ),
              })}
            </span>
          </Grid>
        </Grid>
        <Grid container className="mt-60 background-image">
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60">
            <span className="font-40 font-weight-600">{t('family_option.feature')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-8">
            <span className="font-17 font-weight-600">{t('family_option.feature_description')}</span>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/humanity_logo.png`}
                alt="Wallet"
                style={{width: 70}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className="font-17 font-weight-600 item-content">{t('family_option.feature1_title', {
                value: customDisplayCurrency(
                  !_.isEmpty(plans) ? plans?.plan_prices[0]?.extra_fee : 0,
                  !_.isEmpty(plans) ? plans?.plan_prices[0]?.currency_code : 'JPY',
                ),
              })}</span>
              <span className="font-17 font-weight-600 item-content">{t('family_option.feature1_title2')}</span>
              <span className="font-17 font-weight-600 item-content">
                {t('family_option.feature1_description1', {
                  value: customDisplayCurrency(
                    !_.isEmpty(plans) ? plans?.plan_prices[0]?.extra_fee : 0,
                    !_.isEmpty(plans) ? plans?.plan_prices[0]?.currency_code : 'JPY',
                  ),
                })}
              </span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14 item-content">
                {t('family_option.feature1_description2', {
                  value: plans?.limit_family_user ? plans?.limit_family_user : 0,
                })}
              </span>
              <span className="font-14 item-content">{t('family_option.feature1_description3', {value: plans?.limit_family_user ? plans?.limit_family_user : 0})}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60">
            <span className={this.props.i18n.language === 'vi' ? 'font-16 font-weight-600' : 'font-17 font-weight-600'}>{t('family_option.feature2_title1')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-8">
            <span className="font-24 font-weight-600">{t('family_option.feature2_title2')}</span>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/mobile_logo.png`}
                alt="Mobile"
                style={{width: 70}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className="font-17 font-weight-600 item-content">{t('family_option.feature2_description1')}</span>
              <span className="font-17 font-weight-600 item-content">{t('family_option.feature2_description2')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14 item-content">{t('family_option.feature2_description3')}</span>
              <span className="font-14 item-content">{t('family_option.feature2_description4')}</span>
              <span className="font-14 item-content">{t('family_option.feature2_description5')}</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/telephone_logo.png`}
                alt="Telephone"
                style={{width: 45}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className={this.props.i18n.language === 'en' ? 'font-weight-600 item-content font-16' : 'font-weight-600 item-content font-17'}>
                {t('family_option.feature3_title1')}
              </span>
              <span className={this.props.i18n.language === 'en' ? 'font-weight-600 item-content font-16' : 'font-weight-600 item-content font-17'}>
                {t('family_option.feature3_title2')}
              </span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14 item-content">{t('family_option.feature3_description1')}</span>
              <span className="font-14 item-content">{t('family_option.feature3_description2')}</span>
              <span className="font-14 item-content">({t('family_option.japan_only')})</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/position_logo.png`}
                alt="Position"
                style={{width: 70}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className="font-17 font-weight-600 item-content">{t('family_option.feature4_title1')}</span>
              <span className="font-17 font-weight-600 item-content">{t('family_option.feature4_title2')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14 item-content">{t('family_option.feature4_description1')}</span>
              <span className="font-14 item-content">{t('family_option.feature4_description2')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 mb-36">
              <a
                onClick={() => window.webkit?.messageHandlers.test.postMessage('GEOFENCE')}
                className="font-12 color-success font-weight-600"
                id="geofence"
                href="geofence"
              >
                {t('family_option.fast_feature')}
              </a>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 mb-75 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img src={`${process.env.PUBLIC_URL}/images/subscription/car_logo.png`} alt="Car" style={{width: 70}} />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36 list-content">
              <span className="font-17 font-weight-600 item-content">{t('family_option.feature5_title1')}</span>
              <span className="font-17 font-weight-600 item-content">{t('family_option.feature5_title2')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 mb-36 list-content">
              <span className="font-14 item-content">{t('family_option.feature5_description1')}</span>
              <span className="font-14 item-content">{t('family_option.feature5_description2')}</span>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation('translations')(Index);
