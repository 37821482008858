const axios = require('axios');
const LOGGER = require('../utils/logger');
const Config = require('../common/config');

const validateStatus = (status) => {
  return status === 200 || status === 400 || status === 401 || status === 500;
};

// common base instance
const BASE_URL = process.env.REACT_APP_WEX_HOST + 'api/';
const HEADERS_MULTIPLE_PART = {
  'Content-Type': 'multipart/form-data; boundary=something',
};
const HEADER_TOKEN = {
  token: localStorage.getItem('token'),
};

const instance = axios.create({
  'baseURL': BASE_URL,
  'contentType': 'application/json',
  'accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
});

// Add a request interceptor
instance.interceptors.request.use(
    function(config) {
      if (localStorage.getItem('TOKEN_KEY')) {
        config.headers['Authorization'] = localStorage.getItem('TOKEN_KEY');
        config.headers['x-auth-token'] = localStorage.getItem('TOKEN_KEY');
      }
      if (localStorage.getItem('token')) {
        config.headers['token'] = localStorage.getItem('token');
      }
      // Do something before request is sent
      Config.DEBUG && LOGGER.log('******Header=', JSON.parse(JSON.stringify(config)));
      return config;
    },
    function(error) {
    // Handle error 5xx, 4xx
    // Do something with request error
      return Promise.reject(error);
    },
);

// Add a response interceptor
instance.interceptors.response.use(
    function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
      return response;
    },
    function(error) {
      // Handle error 5xx, 4xx
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
);

const api = {
  post: (endpoint, params) => {
    Config.DEBUG && LOGGER.log('******Post=', JSON.parse(JSON.stringify(endpoint)));
    Config.DEBUG && LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
        .post(endpoint, params, {
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              Config.DEBUG && LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              Config.DEBUG && LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },

  postMultiplePart: (endpoint, params) => {
    Config.DEBUG && LOGGER.log('******postMultiplePart=', JSON.parse(JSON.stringify(endpoint)));
    Config.DEBUG && LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
        .post(endpoint, params, {
          headers: HEADERS_MULTIPLE_PART,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              Config.DEBUG && LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              Config.DEBUG && LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },

  get: (endpoint, params = {}) => {
    Config.DEBUG && LOGGER.log('******Get=', JSON.parse(JSON.stringify(endpoint)));
    Config.DEBUG && LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
        .get(endpoint, {
          params: params,
          headers: HEADER_TOKEN,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              Config.DEBUG && LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              Config.DEBUG && LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },

  put: (endpoint, params) => {
    Config.DEBUG && LOGGER.log('******Put=', JSON.parse(JSON.stringify(endpoint)));
    Config.DEBUG && LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
        .put(endpoint, params, {
          headers: HEADER_TOKEN,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              Config.DEBUG && LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              Config.DEBUG && LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },

  delete: (endpoint, params) => {
    Config.DEBUG && LOGGER.log('******Delete=', JSON.parse(JSON.stringify(endpoint)));
    return instance
        .delete(endpoint, {
          data: params,
          headers: HEADER_TOKEN,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              Config.DEBUG && LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              Config.DEBUG && LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              Config.DEBUG && LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },
};

export {api};
