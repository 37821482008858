import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import {Container, Grid, Button, Modal, Backdrop} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import {ROUTE} from '../../../common/constant';
import {updateAsahikawaBus, cancelAsahikawaBus, reloadAsahikawaOrderDetail} from '../../../stores/asahikawa/actions';
import MobileModal from '../../../components/mobileModal';
import {convertAsahikawaPayload, convertDeletePayload} from '../../../utils/asahikawa';
import {displayTejimaiTime, displayDateTime, convertDatetimeUTC, compareTime} from '../../../utils/date_time';

/**
 * Asahikawa bus detail
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      asahikawa_bus: {},

      openModal: false,
      isSubmitForm: false,
    };
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (_.isEmpty(this.props.asahikawa?.asahikawa_bus)) {
      this.props.history.push(ROUTE.ASAHIKAWA);
    } else {
      this.props.reloadAsahikawaOrderDetail(
          localStorage.getItem('reservation_number'),
          localStorage.getItem('asahikawa_email'),
          this.props,
      );
      this.setState({
        asahikawa_bus: this.props.asahikawa.asahikawa_bus,
      });
      window.scrollTo(0, 0);
    }
  }

  /**
   * handleEditShuttleBus
   */
  handleEditShuttleBus = () => {
    this.props.history.push(ROUTE.ASAHIKAWA_INPUT);
  };

  /**
   * handleButtonModalOpen
   */
  handleCancelOrder = () => {
    this.setState({
      openModal: false,
    });
    this.props
        .cancelAsahikawaBus(convertDeletePayload(this.state.asahikawa_bus))
        .then(() => this.setState({isSubmitForm: true}));
  };

  /**
   * handleButtonModalClose
   */
  handleButtonModalClose = () => {
    this.setState({
      openModal: false,
    });
  };

  handleBackToLogin = () => {
    this.props.history.push(ROUTE.ASAHIKAWA);
  };

  /**
   * handleRegistrationFailedOrder
   */
  handleRegistrationFailedOrder = () => {
    this.props.updateAsahikawaBus(convertAsahikawaPayload(this.state.asahikawa_bus), this.props);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {asahikawa} = this.props;
    const data = this.state.asahikawa_bus;

    return (
      <Container maxWidth="sm" style={{height: '100%'}} className="p-0 body_content">
        <LoadingOverlay
          active={asahikawa.isLoading}
          style={{height: '100%'}}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          <Grid container className="pt-12">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
            </Grid>
          </Grid>
          {/* 往路 WILLER mobi 搭乗者情報 */}
          {data ? (
            data.status === 'REGISTERED' || data.status === 'FAILED' ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="center"
                  container
                  className="header_color_mobile mt-36"
                >
                  <span className="padding-item-6 font-weight-600">WILLER mobi 搭乗者情報</span>
                </Grid>
                {(!data.estimated_drop_off_swat_time || !data.estimated_pickup_swat_time) &&
                  (data.status === 'REGISTERED' ? (
                    <Grid
                      item
                      xs={12}
                      justify="flex-start"
                      alignItems="center"
                      container
                      className="button_color_success"
                    >
                      <CheckIcon style={{paddingLeft: 24, paddingRight: 12, fontSize: 24, width: 'auto'}} />
                      <span className="font-16 font-weight-600">予約リクエストを受け付けました</span>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      justify="flex-start"
                      alignItems="center"
                      container
                      className="button_color_submit"
                    >
                      <ErrorIcon style={{paddingLeft: 24, paddingRight: 12, fontSize: 24, width: 'auto'}} />
                      <span className="font-16 font-weight-600">予約リクエストが完了していません</span>
                    </Grid>
                  ))}
                {/* 乗車日時 */}
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">WILLER mobi 乗車日時</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  {data.estimated_drop_off_swat_time || data.estimated_pickup_swat_time ? (
                    <>
                      <span className="padding-item-0 font-20">
                        {displayDateTime(convertDatetimeUTC(data.estimated_pickup_swat_time))}
                      </span>
                      <hr className="width_100 divider"></hr>
                    </>
                  ) : data.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? (
                    <>
                      <span className="padding-item-0 font-16 color-secondary">
                        {displayTejimaiTime(convertDatetimeUTC(data.min_closed_time))}
                        {!compareTime(data.min_closed_time, data.max_closed_time) &&
                          `時~${displayTejimaiTime(convertDatetimeUTC(data.max_closed_time))}`}
                        時頃お知らせします
                      </span>
                      <hr className="width_100 divider"></hr>
                    </>
                  ) : (
                    <>
                      <span className="padding-item-0 font-20">
                        {displayDateTime(convertDatetimeUTC(data.expected_time))}
                      </span>
                      <hr className="width_100 divider"></hr>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">WILLER mobi 乗車地</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="padding-item-0">
                  <Grid item xs={10} justify="flex-start" alignItems="center" container>
                    <span className="font-20">{data.pick_up_name}</span>
                  </Grid>
                  <Grid item xs={2} justify="flex-end" alignItems="center" container>
                    <a
                      href={`http://www.google.com/maps/place/${data.pick_up_lat + ',' + data.pick_up_lon}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={`${process.env.PUBLIC_URL}/images/map_link.svg`} alt="Logo" />
                    </a>
                  </Grid>
                  <hr className="width_100 divider_padding_inner"></hr>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">WILLER mobi 降車日時</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  {data.estimated_drop_off_swat_time || data.estimated_pickup_swat_time ? (
                    <>
                      <span className="padding-item-0 font-20">
                        {displayDateTime(convertDatetimeUTC(data.estimated_drop_off_swat_time))}
                      </span>
                      <hr className="width_100 divider"></hr>
                    </>
                  ) : data.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? (
                    <>
                      <span className="padding-item-0 font-20">
                        {displayDateTime(convertDatetimeUTC(data.expected_time))}
                      </span>
                      <hr className="width_100 divider"></hr>
                    </>
                  ) : (
                    <>
                      <span className="padding-item-0 font-16 color-secondary">
                        {displayTejimaiTime(convertDatetimeUTC(data.min_closed_time))}
                        {!compareTime(data.min_closed_time, data.max_closed_time) &&
                          `時~${displayTejimaiTime(convertDatetimeUTC(data.max_closed_time))}`}
                        時頃お知らせします
                      </span>
                      <hr className="width_100 divider"></hr>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">WILLER mobi 降車地</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="padding-item-0">
                  <Grid item xs={10} justify="flex-start" alignItems="center" container>
                    <span className="font-20">{data.drop_off_name}</span>
                  </Grid>
                  <Grid item xs={2} justify="flex-end" alignItems="center" container>
                    <a
                      href={`http://www.google.com/maps/place/${data.drop_off_lat + ',' + data.drop_off_lon}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={`${process.env.PUBLIC_URL}/images/map_link.svg`} alt="Logo" />
                    </a>
                  </Grid>

                  <hr className="width_100 divider_padding_inner"></hr>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">乗車代表者氏名</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  <span className="padding-item-0 font-20">{data.passenger_name}</span>
                  <hr className="width_100 divider"></hr>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">連絡用Eメールアドレス</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  <span className="padding-item-0 font-20">
                    {data.passenger_email ? data.passenger_email : localStorage.getItem('email')}
                  </span>
                  <hr className="width_100 divider"></hr>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">電話番号</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  <span className="padding-item-0 font-20">{data.phone}</span>
                  <hr className="width_100 divider"></hr>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">利用人数</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  <span className="padding-item-0 font-20">{data.number_in_use}名</span>
                  <hr className="width_100 divider"></hr>
                </Grid>
                {data.status === 'REGISTERED' ? (
                  data.estimated_drop_off_swat_time || data.estimated_pickup_swat_time ? (
                    <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12" />
                  ) : (
                    <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                      <div className="width_100 padding-item-0">
                        <Button
                          variant="contained"
                          className="width_100 button_color_info font-weight-600"
                          onClick={() => this.handleEditShuttleBus(data.id)}
                        >
                          予約変更
                          <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                        </Button>
                      </div>
                    </Grid>
                  )
                ) : (
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                    <div className="width_100 padding-item-0">
                      <Button
                        variant="contained"
                        className="width_100 button_color_info font-weight-600"
                        onClick={() => this.handleEditShuttleBus(data.id)}
                      >
                        予約リクエスト内容を変更する
                        <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                      </Button>
                    </div>
                  </Grid>
                )}
                {data.status === 'REGISTERED' ? (
                  data.estimated_drop_off_swat_time || data.estimated_pickup_swat_time ? (
                    <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24"></Grid>
                  ) : (
                    <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24">
                      <div className="width_100 padding-item-0">
                        <Button
                          variant="contained"
                          className="width_100 button_color_danger font-weight-600"
                          onClick={() => this.setState({openModal: true})}
                        >
                          キャンセル
                          <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                        </Button>
                      </div>
                    </Grid>
                  )
                ) : (
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24">
                    <div className="width_100 padding-item-0">
                      <Button
                        variant="contained"
                        className="width_100 button_color_success font-weight-600"
                        onClick={() => this.handleRegistrationFailedOrder()}
                      >
                        同じ内容で予約リクエストをする
                        <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                      </Button>
                    </div>
                  </Grid>
                )}
                {data.status === 'REGISTERED' && (
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24 mb-50">
                    <div className="width_100 padding-item-0">
                      <Button
                        variant="contained"
                        className="width_100 button_color_success font-weight-600"
                        onClick={() => this.props.history.push(ROUTE.ASAHIKAWA)}
                      >
                        トップに戻る
                        <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                      </Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            ) : (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    justify="flex-start"
                    alignItems="center"
                    container
                    className="header_color_mobile mt-36"
                  >
                    <span className="padding-item-6 font-weight-600">WILLER mobi 搭乗者情報</span>
                  </Grid>
                </Grid>
                <Grid container style={{height: 550}}>
                  <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-48">
                    <Grid item xs={12} justify="center" alignItems="flex-start" container>
                      <span className="padding-item-0 font-16">予約リクエストを頂いたWILLER mobi</span>
                      <span className="padding-item-0 font-16">を手配することができませんでした。</span>
                    </Grid>
                    <Grid item xs={12} justify="center" alignItems="flex-start" container>
                      <div className="width_100 padding-item-0">
                        <Button
                          variant="contained"
                          className="width_100 button_color_success font-weight-600"
                          onClick={() => this.props.history.push(ROUTE.ASAHIKAWA)}
                        >
                          トップに戻る
                          <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )
          ) : (
            <Grid container style={{height: 700}}>
              <Grid item xs={12} justify="center" alignItems="center" container className="mt-48"></Grid>
            </Grid>
          )}
          <Grid container className={data ? 'footer' : 'footer fixed_footer'}>
            <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
              <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
            </Grid>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <MobileModal
              onClickCancel={this.handleButtonModalClose.bind(this)}
              onClickOk={() => this.handleCancelOrder()}
              shuttleId={this.state.select_id}
              usage_type="DELETE"
            ></MobileModal>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.isSubmitForm}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <MobileModal onClickOk={this.handleBackToLogin} usage_type="CANCEL"></MobileModal>
          </Modal>
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    asahikawa: state.asahikawa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAsahikawaBus: (payload, props) => dispatch(updateAsahikawaBus(payload, props)),
    cancelAsahikawaBus: (payload, props) => dispatch(cancelAsahikawaBus(payload, props)),
    reloadAsahikawaOrderDetail: (reservation_number, email, props) =>
      dispatch(reloadAsahikawaOrderDetail(reservation_number, email, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
