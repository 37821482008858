import {api} from '../services/api';
import {mobiApi} from '../services/mobiApi';

const verifyAccountMobileUrl = `exbus/auth/verify-email?token=`;
const verifyPasswordTokenUrl = 'exbus/auth/verify-password-token';
const verifySubscriptionUrl = `auth/verify-subscription`;
const resetPasswordUrl = 'exbus/auth/reset-password';


export const verifyAccountMobileApi = (token) => {
  return api.get(verifyAccountMobileUrl + token);
};

export const verifyPasswordTokenApi = (token) => {
  return api.get(verifyPasswordTokenUrl, {token: token});
};

export const verifySubscriptionApi = (token) => {
  return mobiApi.get(verifySubscriptionUrl, {token: token});
};

export const resetPasswordApi = (payload) => {
  return api.post(resetPasswordUrl, payload);
};
