import {api} from '../services/api';

const getPlaceUrl = '/exbus/asahikawa/ski-place';
const getExpectedTimeUrl = '/exbus/asahikawa/expected-time';
const verifyAsahikawaOrderUrl = '/exbus/asahikawa/verify';
const registerAsahikawaUrl = '/exbus/asahikawa/create-information';
const updatedAsahikawaUrl = '/exbus/asahikawa/update-information';
const cancelAsahikawaUrl = '/exbus/asahikawa/cancel';

export const getPlaceApi = (transit_stop_id, place_type) => {
  return api.get(getPlaceUrl, {transit_stop_id: transit_stop_id, place_type: place_type});
};

export const getExpectedTimeApi = (date_time, zone_id, geofences_id, time_type) => {
  return api.get(getExpectedTimeUrl, {
    date_time: date_time,
    zone_id: zone_id,
    geofence_id: geofences_id,
    time_type: time_type,
  });
};

export const verifyAsahikawaOrderApi = (reservation_number, email) => {
  return api.get(verifyAsahikawaOrderUrl, {reservation_number: reservation_number, email: email});
};

export const registerAsahikawaApi = (payload) => {
  return api.post(registerAsahikawaUrl, payload);
};

export const updatedAsahikawaApi = (payload) => {
  return api.put(updatedAsahikawaUrl, payload);
};

export const cancelAsahikawaApi = (payload) => {
  return api.post(cancelAsahikawaUrl, payload);
};
