import {ACTION_TYPES} from './reducer';
import {getGeofencesApi} from '../../services/mapServices';

// get geofences
const requestGetGeofencesAction = {
  type: ACTION_TYPES.GET_GEOFENCES_START,
};

export const successGetGeofencesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_GEOFENCES_SUCCESS,
    geofences: data,
  };
};

export const failGetGeofencesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_GEOFENCES_FAIL,
  };
};

/**
 * get geofences
 * @return {object}
 */
export const getGeofences = () => {
  return (dispatch) => {
    dispatch(requestGetGeofencesAction);
    return getGeofencesApi().then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successGetGeofencesAction(response.result));
          } else {
            dispatch(failGetGeofencesAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failGetGeofencesAction('Internal Server Error'));
        },
    );
  };
};
