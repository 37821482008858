import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Container} from '@material-ui/core';
import {isAndroid, isIOS} from '../../utils/common';
import {APP_URL} from '../../common/constant';

/**
 * QR view
 */
class QR extends Component {
  /**
   * componentDidMount
   */
  componentDidMount() {
    // If device is iOS, open AppStore
    if (isIOS()) {
      window.location.href = APP_URL.IOS;
    } else if (isAndroid()) {
      // Else if device is Android, open CH Play
      window.location.href = APP_URL.ANDROID;
    }
  }
  /**
   * Render view
   * @return {HTMLElement}
   */
  render() {
    return <Container className="QR"></Container>;
  }
}

export default withTranslation('translations')(QR);
