/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import TermAndPrivacySection from '../../components/TermAndPrivacySection';
import TERMS_MOBI from './terms_mobi';
import './styles.css';
/**
 * QR Terms
 */
class QrTerm extends Component {
  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div className="Terms">
        {TERMS_MOBI.map((section, i) => (
          <TermAndPrivacySection section={section} i={i} key={i} />
        ))}
        <br></br>
        <p>2021年06月17日　制定 2021年06月17日　実施</p>
        <hr className="hr-dotted" />
      </div>
    );
  }
}

export default QrTerm;
