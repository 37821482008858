import {api} from '../services/api';

const getGeofencesUrl = 'exbus/reservation/geofences';
const getTransitStopNearestUrl = 'exbus/reservation/transit-stop-nearest';
const getGeofencesBusStopUrl = 'exbus/reservation/transit-stop';

export const getGeofencesApi = () => {
  return api.get(getGeofencesUrl);
};

export const getTransitStopNearestApi = (payload) => {
  return api.post(getTransitStopNearestUrl, payload);
};

export const getGeofencesBusStopApi = (payload) => {
  return api.post(getGeofencesBusStopUrl, payload);
};
