import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Container, Grid} from '@material-ui/core';
import './style.css';

/**
 * Tutorial App Page
 */
class Index extends Component {
  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t} = this.props;
    const request = [
      {
        step: 'tutorial_app.step1.title',
        title: 'tutorial_app.step1.content',
        image_url: `${process.env.PUBLIC_URL}/images/subscription/step1_${localStorage.getItem('i18nextLng')}.png`,
      },
      {
        step: 'tutorial_app.step2.title',
        title: 'tutorial_app.step2.content',
        image_url: `${process.env.PUBLIC_URL}/images/subscription/step2_${localStorage.getItem('i18nextLng')}.png`,
      },
      {
        step: 'tutorial_app.step3.title',
        title: 'tutorial_app.step3.content',
        image_url: `${process.env.PUBLIC_URL}/images/subscription/step3_${localStorage.getItem('i18nextLng')}.png`,
      },
    ];

    return (
      <Container maxWidth="xs" style={{height: '100vh'}} className="landing-page tutorial-app">
        <Grid container>
          <Grid item xs={12} justify="center" alignItems="center" container>
            <img src={`${process.env.PUBLIC_URL}/images/subscription/tutorial_app.png`} alt="img" />
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60 list-content">
            <span className="font-18 font-weight-600 item-content">{t('tutorial_app.title')}</span>
            <div className="item-content">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/logo_rotate_180_degree.png`}
                alt="img"
                className="mt-24"
              />
            </div>
            <span className="font-20 font-weight-600 mt-12 item-content">{t('tutorial_app.description')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-48 list-content">
            <span className="font-14 font-weight-600 pt-6 item-content">{t('tutorial_app.content1')}</span>
            <span className="font-14 font-weight-600 pt-6 item-content">{t('tutorial_app.content2')}</span>
            <span className="font-14 font-weight-600 pt-6 item-content" style={{padding: '0 6px'}}>{t('tutorial_app.content3')}</span>
            <span className="font-14 font-weight-600 pt-6 item-content">{t('tutorial_app.content4')}</span>
          </Grid>
        </Grid>
        <Grid container className="mt-60 mb-84 background-image">
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60">
            <span className="font-40 font-weight-600">{t('tutorial_app.feature.title')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-8">
            <span className="font-18 font-weight-600">{t('tutorial_app.feature.description')}</span>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img src={`${process.env.PUBLIC_URL}/images/subscription/car.gif`} alt="Car" style={{width: 70}} />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt- list-content">
              <span className="font-20 font-weight-600 item-content">{t('tutorial_app.car.title')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-18 mb-24 list-content">
              <span className="font-14 item-content">{t('tutorial_app.car.content1')}</span>
              <span className="font-14 item-content">{t('tutorial_app.car.content2')}</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img
                src={`${process.env.PUBLIC_URL}/images/subscription/location-pin.gif`}
                alt="Position"
                style={{width: 68}}
              />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 list-content">
              <span className="font-20 font-weight-600 item-content">{t('tutorial_app.location.title')}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-18 mb-24 list-content ">
              <span className="font-14 item-content">{t('tutorial_app.location.content1')}</span>
              <span className="font-14 item-content">{t('tutorial_app.location.content2')}</span>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            justify="center"
            alignItems="center"
            container
            className="mt-24 mb-24 margin-item-0 width_100 background-white"
          >
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <img src={`${process.env.PUBLIC_URL}/images/subscription/wallet.gif`} alt="Wallet" style={{width: 70}} />
            </Grid>
            <Grid className="width_100">
              <Grid item xs={12} justify="center" alignItems="center" container className="mt-24 list-content">
                <span className="font-20 font-weight-600 item-content">{t('tutorial_app.wallet.title')}</span>
              </Grid>
              <Grid item xs={12} justify="center" alignItems="center" container className="mt-18 mb-24 list-content ">
                <span className="font-14 item-content">{t('tutorial_app.wallet.content1')}</span>
                <span className="font-14 item-content">{t('tutorial_app.wallet.content2')}</span>
                <span className="font-14 item-content">{t('tutorial_app.wallet.content3')}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="mb-84">
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-60">
            <span className="font-40 font-weight-600">{t('tutorial_app.request.title')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-8 mb-36">
            <span className="font-18 font-weight-600">{t('tutorial_app.request.description')}</span>
          </Grid>
        </Grid>
        {request.map((element, index) => (
          <Grid key={index} item xs={12} justify="center" alignItems="center" container className="mb-72 width_100">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <span className="font-16 item-content item-step">{t(element.step)}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-18" style={{padding: '0 10px'}}>
              <span className="font-17 font-weight-600 item-content text-align">{t(element.title)}</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24">
              <img src={`${element.image_url}`} alt="step" />
            </Grid>
          </Grid>
        ))}
        <Grid container className="footer"></Grid>
      </Container>
    );
  }
}

export default withTranslation('translations')(Index);
