import {api} from '../services/api';

const getSubscriptionHistoryUrl = 'exbus/landing-page/history/';
const getMainPlanUrl = 'exbus/landing-page/main-plan';
const getPlanByGeofencesUrl = 'exbus/landing-page/plan-by-geofence';
const getPlanByIdUrl = '/exbus/landing-page/plans/';

export const getSubscriptionHistoryApi = (userId, from, to, geofence_id) => {
  return api.get(getSubscriptionHistoryUrl + userId, {from: from, to: to, geofence_id: geofence_id});
};

export const getMainPlanApi = () => {
  return api.get(getMainPlanUrl);
};

export const getPlanByGeofencesApi = (geofence_id, size) => {
  return api.get(getPlanByGeofencesUrl, {geofence_id: geofence_id, size: size});
};

export const getPlanByIdApi = (planId) => {
  return api.get(getPlanByIdUrl + planId);
};
