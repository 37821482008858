import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import SimpleReactValidator from 'simple-react-validator';
import {Container, Grid, Button, Modal, Backdrop} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import {ROUTE} from '../../../common/constant';
import {
  registerShuttleBus,
  editShuttleBus,
  cancelShuttleBus,
  verifyOrder,
  shuttleBusInfo,
  reloadDetailOrder,
} from '../../../stores/shuttleBus/actions';
import MobileModal from '../../../components/mobileModal';
import {verifyInitialShuttleBusScreen} from '../../../utils/common';
import {convertPayload, convertDeletePayload} from '../../../utils/shuttle_bus';
import {displayTejimaiTime, displayDateTime, convertDatetimeUTC, compareTime} from '../../../utils/date_time';

/**
 * shuttle bus confirm
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      shuttle_bus_info: [],
      select_id: null,

      openModal: false,
      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.props.reloadDetailOrder(localStorage.getItem('order_no'), localStorage.getItem('email'), this.props);
    this.setState({
      shuttle_bus_info: this.props.shuttle.shuttle_bus_info,
    });
    window.scrollTo(0, 0);
    !verifyInitialShuttleBusScreen(this.props.shuttle) && this.props.history.replace({pathname: ROUTE.VALIDATE_BUS});
  }

  /**
   * componentWillReceiveProps
   * @param {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this.setState({
      shuttle_bus_info: nextProps.shuttle.shuttle_bus_info,
    });
    window.scrollTo(0, 0);
    this.forceUpdate();
  }

  /**
   * handleEditShuttleBus
   * @param {int} id
   */
  handleEditShuttleBus = (id) => {
    this.props.history.push(ROUTE.DETAIL_POSITION + id);
  };

  /**
   * handleButtonModalOpen
   * @param {int} id
   */
  handleCancelOrder = (id) => {
    this.setState({
      openModal: false,
    });
    this.props.cancelShuttleBus(convertDeletePayload(this.state.shuttle_bus_info, id), this.props);
  };

  /**
   * handleButtonModalClose
   */
  handleButtonModalClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleNextScreen
   */
  handleNextScreen = () => {
    this.props.verifyOrder(localStorage.getItem('order_no'), localStorage.getItem('email'), this.props);
  };

  handleBackToLogin = () => {
    this.props.history.push(ROUTE.VALIDATE_BUS);
  };

  /**
   * handleReOrder
   * @param {object} item
   * @param {int} index
   */
  handleReOrder = (item, index) => {
    const data = this.state.shuttle_bus_info;
    data[index].drop_off_lat = 0;
    data[index].drop_off_lon = 0;
    data[index].drop_off_name = '';
    data[index].estimate_swat_time = null;
    data[index].expected_time = null;
    data[index].number_in_use = null;
    data[index].simulation_id = null;
    data[index].status = true;
    this.props.shuttleBusInfo(data);
    this.props.history.push(ROUTE.REGISTRY_POSITION);
  };

  /**
   * handleRegistrationFailedOrder
   * @param {int} id
   */
  handleRegistrationFailedOrder = (id) => {
    const payload = this.state.shuttle_bus_info.filter((e) => e.id === Number(id));
    this.props.editShuttleBus(convertPayload(payload), id, this.props);
  };

  /**
   * validate all record is canceled
   * @param {object} object
   * @return {boolean}
   */
  isAllCanceled = (object) => {
    return (
      object?.every((item) => item.status === false) ||
      !object?.some(
          (item) => item.status === 'REGISTERED' || item.status === 'FAILED' || item.status === 'OFFER_FAILED',
      )
    );
  };

  /**
   * filter data by status
   * @param {Object} data
   * @return {Object}
   */
  getOrderByStatus = (data) => {
    return data?.filter(
        (item) => item.status === 'REGISTERED' || item.status === 'FAILED' || item.status === 'OFFER_FAILED',
    );
  };

  /**
   * render view by status order
   * @param {Object} item
   * @param {Object} index
   * @return {*}
   */
  renderOrder = (item, index) => {
    switch (item.status) {
      case 'REGISTERED':
      case 'FAILED':
        return (
          item.is_shuttle && (
            <Grid container key={index}>
              <Grid
                item
                xs={12}
                justify="flex-start"
                alignItems="center"
                container
                className="header_color_mobile mt-36"
              >
                <span className="padding-item-6 font-weight-600">
                  {localStorage.getItem('way_down') &&
                  Number(item?.reservation_id) === Number(localStorage.getItem('way_down')) ?
                    '復路 WILLER mobi 搭乗者情報' :
                    '往路 WILLER mobi 搭乗者情報'}
                </span>
              </Grid>
              {!item.estimate_swat_time &&
                (item.status === 'REGISTERED' ? (
                  <Grid
                    item
                    xs={12}
                    justify="flex-start"
                    alignItems="center"
                    container
                    className="button_color_success"
                  >
                    <CheckIcon style={{paddingLeft: 24, paddingRight: 12, fontSize: 24, width: 'auto'}} />
                    <span className="font-16 font-weight-600">予約リクエストを受け付けました</span>
                  </Grid>
                ) : (
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="button_color_submit">
                    <ErrorIcon style={{paddingLeft: 24, paddingRight: 12, fontSize: 24, width: 'auto'}} />
                    <span className="font-16 font-weight-600">予約リクエストが完了していません</span>
                  </Grid>
                ))}
              {/* 乗車日時 */}
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-weight-600">WILLER mobi 乗車日時</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? (
                  item.estimate_swat_time ? (
                    <>
                      <span className="padding-item-0 font-20">
                        {displayDateTime(convertDatetimeUTC(item.estimate_swat_time))}
                      </span>
                      <hr className="width_100 divider"></hr>
                    </>
                  ) : (
                    <>
                      <span className="padding-item-0 font-16 color-secondary">
                        {displayTejimaiTime(convertDatetimeUTC(item.min_closed_time))}
                        {!compareTime(item.min_closed_time, item.max_closed_time) &&
                          `時~${displayTejimaiTime(convertDatetimeUTC(item.max_closed_time))}`}
                        時頃お知らせします
                      </span>
                      <hr className="width_100 divider"></hr>
                    </>
                  )
                ) : (
                  <>
                    <span className="padding-item-0 font-20">
                      {displayDateTime(convertDatetimeUTC(item.expected_time))}
                    </span>
                    <hr className="width_100 divider"></hr>
                  </>
                )}
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-weight-600">WILLER mobi 乗車地</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="padding-item-0">
                <Grid item xs={10} justify="flex-start" alignItems="center" container>
                  <span className="font-20">
                    {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                      item.drop_off_name :
                      item.estimate_swat_time ?
                      item.shuttle_bus_stop_name :
                      item.ex_bus_name}
                  </span>
                </Grid>
                <Grid item xs={2} justify="flex-end" alignItems="center" container>
                  <a
                    href={`http://www.google.com/maps/place/${
                      item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                        item.drop_off_lat + ',' + item.drop_off_lon :
                        item.ex_bus_lat + ',' + item.ex_bus_lon
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={`${process.env.PUBLIC_URL}/images/map_link.svg`} alt="Logo" />
                  </a>
                </Grid>
                <hr className="width_100 divider_padding_inner"></hr>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-weight-600">WILLER mobi 降車日時</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? (
                  <>
                    <span className="padding-item-0 font-20">
                      {displayDateTime(convertDatetimeUTC(item.expected_time))}
                    </span>
                    <hr className="width_100 divider"></hr>
                  </>
                ) : item.estimate_swat_time ? (
                  <>
                    <span className="padding-item-0 font-20">
                      {displayDateTime(convertDatetimeUTC(item.estimate_swat_time))}
                    </span>
                    <hr className="width_100 divider"></hr>
                  </>
                ) : (
                  <>
                    <span className="padding-item-0 font-16 color-secondary">
                      {displayTejimaiTime(convertDatetimeUTC(item.min_closed_time))}
                      {!compareTime(item.min_closed_time, item.max_closed_time) &&
                        `時~${displayTejimaiTime(convertDatetimeUTC(item.max_closed_time))}`}
                      時頃お知らせします
                    </span>
                    <hr className="width_100 divider"></hr>
                  </>
                )}
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-weight-600">WILLER mobi 降車地</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="padding-item-0">
                <Grid item xs={10} justify="flex-start" alignItems="center" container>
                  <span className="font-20">
                    {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                      item.estimate_swat_time ?
                        item.shuttle_bus_stop_name :
                        item.ex_bus_name :
                      item.drop_off_name}
                  </span>
                </Grid>
                <Grid item xs={2} justify="flex-end" alignItems="center" container>
                  <a
                    href={`http://www.google.com/maps/place/${
                      item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                      item.ex_bus_lat + ',' + item.ex_bus_lon :
                      item.drop_off_lat + ',' + item.drop_off_lon
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={`${process.env.PUBLIC_URL}/images/map_link.svg`} alt="Logo" />
                  </a>
                </Grid>
                <hr className="width_100 divider_padding_inner"></hr>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-weight-600">乗車代表者氏名</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <span className="padding-item-0 font-20">{item.passenger_name}</span>
                <hr className="width_100 divider"></hr>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-weight-600">連絡用Eメールアドレス</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <span className="padding-item-0 font-20">
                  {item.passenger_email ? item.passenger_email : localStorage.getItem('email')}
                </span>
                <hr className="width_100 divider"></hr>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-weight-600">電話番号</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <span className="padding-item-0 font-20">{item.phone}</span>
                <hr className="width_100 divider"></hr>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-weight-600">利用人数</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <span className="padding-item-0 font-20">{item.number_in_use}名</span>
                <hr className="width_100 divider"></hr>
              </Grid>
              {item.status === 'REGISTERED' ? (
                item.estimate_swat_time ? (
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12" />
                ) : (
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                    <div className="width_100 padding-item-0">
                      <Button
                        variant="contained"
                        className="width_100 button_color_info font-weight-600"
                        onClick={() => this.handleEditShuttleBus(item.id)}
                      >
                        予約変更
                        <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                      </Button>
                    </div>
                  </Grid>
                )
              ) : (
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <div className="width_100 padding-item-0">
                    <Button
                      variant="contained"
                      className="width_100 button_color_info font-weight-600"
                      onClick={() => this.handleEditShuttleBus(item.id)}
                    >
                      予約リクエスト内容を変更する
                      <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                    </Button>
                  </div>
                </Grid>
              )}
              {item.status === 'REGISTERED' ? (
                item.estimate_swat_time ? (
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24 mb-50"></Grid>
                ) : (
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24 mb-50">
                    <div className="width_100 padding-item-0">
                      <Button
                        variant="contained"
                        className="width_100 button_color_danger font-weight-600"
                        onClick={() => this.setState({openModal: true, select_id: item.id})}
                      >
                        キャンセル
                        <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                      </Button>
                    </div>
                  </Grid>
                )
              ) : (
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24 mb-50">
                  <div className="width_100 padding-item-0">
                    <Button
                      variant="contained"
                      className="width_100 button_color_success font-weight-600"
                      onClick={() => this.handleRegistrationFailedOrder(item.id)}
                    >
                      同じ内容で予約リクエストをする
                      <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>
          )
        );
      case 'OFFER_FAILED':
      case false:
        return (
          item.is_shuttle &&
          item.list_expect_time.length > 0 && (
            <Grid container key={index}>
              <Grid
                item
                xs={12}
                justify="flex-start"
                alignItems="center"
                container
                className="header_color_mobile mt-36"
              >
                <span className="padding-item-6 font-weight-600">
                  {localStorage.getItem('way_down') &&
                  Number(item?.reservation_id) === Number(localStorage.getItem('way_down')) ?
                    '復路 WILLER mobi 搭乗者情報' :
                    '往路 WILLER mobi 搭乗者情報'}
                </span>
              </Grid>
              <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
                <span className="padding-item-0 font-16 color-secondary">
                  {localStorage.getItem('way_down') &&
                  Number(item?.reservation_id) === Number(localStorage.getItem('way_down')) ?
                    '復路オンデマンドバスの' :
                    '往路オンデマンドバスの'}
                </span>
              </Grid>
              <Grid item xs={12} justify="center" alignItems="center" container>
                <span className="padding-item-0 font-16 color-secondary">{'ご予約はありません'}</span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24 mb-50">
                <div className="width_100 padding-item-0">
                  <Button
                    variant="contained"
                    className="width_100 button_color_success font-weight-600"
                    onClick={() =>
                      this.handleReOrder(
                          item,
                        this.state.shuttle_bus_info?.findIndex(
                            (e) =>
                              e.reservation_id === item.reservation_id && e.shuttle_bus_type === item.shuttle_bus_type,
                        ),
                      )
                    }
                  >
                    予約リクエストを追加する
                    <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                  </Button>
                </div>
              </Grid>
            </Grid>
          )
        );
      default:
        return;
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {shuttle} = this.props;
    const data = this.state.shuttle_bus_info;
    const filterData = this.getOrderByStatus(data);

    return (
      <Container maxWidth="sm" style={{height: '100%'}} className="p-0 body_content">
        <LoadingOverlay
          active={shuttle.isLoading}
          style={{height: '100%'}}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          <Grid container className="pt-12">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
            </Grid>
          </Grid>
          {/* 往路 WILLER mobi 搭乗者情報 */}
          {data?.length > 0 && !this.isAllCanceled(data) ? (
            filterData?.every((item) => item.status === 'OFFER_FAILED') ? (
              filterData?.every((item) => item.is_shuttle === false) ? (
                <Grid container>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
                    <span>本日ご予約頂ける便がありません。</span>
                  </Grid>
                </Grid>
              ) : filterData?.every((item) => !item.list_expect_time.length > 0) ? (
                <Grid container>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
                    <span className="font-weight-600">本日ご予約頂ける便がありません。</span>
                  </Grid>
                </Grid>
              ) : (
                data?.sort((item) => item.reservation_id).map((item, index) => this.renderOrder(item, index))
              )
            ) : filterData?.every((item) => item.is_shuttle === false) ? (
              <Grid container>
                <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
                  <span>本日ご予約頂ける便がありません。</span>
                </Grid>
              </Grid>
            ) : (
              data?.sort((item) => item.reservation_id).map((item, index) => this.renderOrder(item, index))
            )
          ) : (
            <Grid container style={{height: 700}}>
              <Grid item xs={12} justify="center" alignItems="center" container className="mt-48"></Grid>
            </Grid>
          )}
          <Grid container className={data?.length > 0 && !this.isAllCanceled(data) ? 'footer' : 'footer fixed_footer'}>
            <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
              <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
            </Grid>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <MobileModal
              onClickCancel={this.handleButtonModalClose.bind(this)}
              onClickOk={(e) => this.handleCancelOrder(e)}
              shuttleId={this.state.select_id}
              usage_type="DELETE"
            ></MobileModal>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={data?.length > 0 ? this.isAllCanceled(data) : false}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <MobileModal
              onClickCancel={this.handleBackToLogin}
              onClickOk={this.handleNextScreen}
              shuttleId={this.state.select_id}
              usage_type="NOTIFY"
            ></MobileModal>
          </Modal>
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shuttle: state.shuttle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyOrder: (order_no, email, props) => dispatch(verifyOrder(order_no, email, props)),
    registerShuttleBus: (payload, props) => dispatch(registerShuttleBus(payload, props)),
    cancelShuttleBus: (id, props) => dispatch(cancelShuttleBus(id, props)),
    shuttleBusInfo: (shuttle_bus_info) => dispatch(shuttleBusInfo(shuttle_bus_info)),
    editShuttleBus: (payload, id, props) => dispatch(editShuttleBus(payload, id, props)),
    reloadDetailOrder: (order_no, email, props) => dispatch(reloadDetailOrder(order_no, email, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
