/* eslint-disable max-len */
export default [
  {
    title: 'QR決済サービス利用規約',
    description:
      'QR決済サービス利用規約（以下、「本規約」といいます。）は、WILLER株式会社（以下、「当社」といいます。）が、主として、「mobi Community Mobility アプリ」（以下、「本アプリ」といいます。）を通じて運営・提供するQR決済サービスの利用条件等を定めるものです。',
  },
  {
    title: '（総則）',
    list1: [
      {
        title:
          '本規約は、mobi Community Mobility アプリ利用規約（以下、「原規約」といいます。）における個別規約であり、QR決済サービスに関して特段の定めがある場合を除き、本規約が優先して適用されます。',
      },
      {
        title:
          '利用者は、QR決済サービスの利用の時に、本規約及び付属する手順書、利用規則等（以下、「本規約等」といいます。）に同意したものとみなします。',
      },
      {
        title: '原規約において、当社は提供事業者となり、QR決済サービスは個別サービスとなります。',
      },
      {
        title:
          '本規約等の内容は、当社の判断において、以下の場合に利用者に対して何らの事前の通知なしに変更することがあります。この場合、変更日以降の利用については、変更後の本規約等の内容が適用されます。なお、当社は、本規約等の内容を変更する場合、合理的な期間をもって事前に、利用者に変更内容を周知するものとします。',
        list2: {
          content: [
            {
              title: '本規約等の内容変更が、利用者の一般の利益のために実施されるとき',
            },
            {
              title:
                '本規約等の内容変更が、本規約等の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。',
            },
          ],
        },
      },
      {
        title:
          '本規約等の定めの一部の効力が、法令又は判決により無効となった又は取り消された場合においても、残りの定めの有効性、適法性及び法的強制力はなお有効であるものとします。',
      },
    ],
  },
  {
    title: '（QR決済サービスの内容）',
    list1: [
      {
        title:
          'QR決済サービス（以下、「本サービス」といいます。）において、利用者は、以下の各号に定めるサービスの提供を受けることができます。',
        list2: {
          content: [
            {
              title: '本サービスの利用に必要な二次元バーコードの生成及び利用許諾',
            },
            {
              title:
                '当社と提携する旅客運送事業者（鉄道及びバスを含むがこれに限らない。以下、運送事業者）という）が指定した路線（以下、「指定路線」という。）における、旅客運送サービス利用時の運賃・料金について、前号に定める二次元バーコードを利用した決済の代行',
            },
          ],
        },
      },
      {
        title:
          '本サービスを利用できる範囲は、本アプリに表示した指定路線のみとします。なお、運送事業者が変更となる場合、又は単一の運送事業者によって複数の連続しない指定路線が設定されている場合、本サービスは各指定路線内における旅客運送サービスでのみ利用できるものとします。',
      },
    ],
  },
  {
    title: '（運送約款の適用）',
    list1: [
      {
        title:
          '旅客運送サービスについては、当社と提携する各運送事業者が提供し、当該事業者が定める旅客運送に係る規約等の定め（以下、「運送約款」といいます。）が適用されるものとします。',
      },
      {
        title:
          '本サービスによる利用者と運送事業者との個別の運送契約の成立時期は、利用する運送事業者において別段の定めをしない限り、当該運送事業者の駅又は車両において、乗車の際に二次元バーコード読取機による改札を受けたときとします。また、契約の成立した時以後における取り扱いは、ご利用になる旅客運送事業者において別段の定めをしない限り、全てその契約の成立したときに有効な運送約款の定めによるものとします。',
      },
    ],
  },
  {
    title: '（利用方法）',
    list1: [
      {
        title:
          '利用者は、本サービスの利用に先立ち、原規約第３条の定めに基づき、本アプリにクレジットカード情報を登録するものとします。',
      },
      {
        title:
          '利用者は、直接、入場時若しくは乗車時及び出場時若しくは降車時に、当該車両に設置されている二次元バーコード読取機に、自らの二次元バーコードを読み取らせることで改札を受けるものとします。',
      },
      {
        title:
          '前項において、二次元バーコード読取機にて取り扱いができない旨の指示があった場合、その理由の如何に関わらず、利用者は、本サービスを利用することができないものとします。',
      },
    ],
  },
  {
    title: '（支払時期）',
    list1: [
      {
        title:
          '本サービスにより支払うべき運賃・料金については、出場時及び降車時に前条第2項の改札を完了した日に、登録されたクレジットカードに請求するものとします。',
      },
      {
        title:
          '前項の規定に関わらず、前条第3項の定めに該当する場合、利用者は、運送事業者の指示に従って、出場時若しくは降車時に運賃・料金を支払うものとします。',
      },
    ],
  },
  {
    title: '（利用者の責務）',
    list1: [
      {
        title:
          '利用者は、運送サービスの提供を受ける際に、法令並びに運送事業者が定める運送約款及び運送事業者の職員の指示を遵守するものとします。',
      },
      {
        title:
          '利用者は、第三者に対し、当社から利用許諾された二次元バーコードを利用させてはならないものとします。万が一、利用者以外の第三者が、当該バーコードを利用した場合であっても、当社がそのことを知っていた場合を除き、当社及び運送事業者は、利用者が利用したものとみなすことができるものとします。',
      },
      {
        title:
          '利用者は、原規約及び本規約の定めに違反したことにより発生した全ての損害について、当社及び運送事業者に賠償を求めることはできないものとします。',
      },
    ],
  },
  {
    title: '（運送サービス中の事故の対応）',
    list1: [
      {
        title:
          '万が一、運送サービス利用中（駅舎等付属施設の利用時間も含む。）に発生した事故又はトラブル（以下、「事故等」といいます。）により利用者等が損害を被った場合、利用者等は、運送事業者が定める運送約款に基づき、運送事業者との間で解決するものとします。',
      },
      {
        title:
          '当社は、自ら又は運送事業者を代理して、前項に定める事故等の協議、解決に協力する一切の義務及び事故等により利用者に発生した損害を賠償する一切の責任を負いません。',
      },
      {
        title:
          '本条における紛争解決のため利用者等及び運送事業者等が訴訟を提起する場合、原規約第11条の定めは適用せず、日本法に基づき解釈され、日本国の裁判所において解決されるものとします。',
      },
    ],
  },
  {
    title: '（利用者等の情報）',
    list1: [
      {
        title:
          '利用者等は、QR決済サービスの申し込みにより、当社及び運送事業者がWILLERSから本アプリを通じて、運送サービスの提供を目的として、利用者等の氏名、性別、連絡先等の個人情報を必要な範囲において提供を受けること、及び当社及び運送事業者の定めるプライバシーポリシー又は個人情報取り扱い方針等を承諾したものとします。',
      },
      {
        title:
          '当社及び運送事業者は、前項に基づき又はQR決済サービス提供の過程で取得した利用者等の個人情報について、個人情報保護法と関連規定に基づき、適切に管理するものとします。',
      },
      {
        title:
          '前項に関して、当社及び運送事業者の定めるプライバシーポリシー又は個人情報取り扱い方針等並びに個人情報に関する問い合わせ先は、本アプリに掲載します。',
      },
    ],
  },
  {
    title: '（反社会的勢力の排除）',
    list1: [
      {
        title:
          '利用者等は、自らが以下の各号のいずれにも該当しないこと及び以下の各号の個人又は団体と密接な交流関係を有していないことを表明して、確約するものとします。',
        list2: {
          content: [
            {
              title: '暴力団',
            },
            {
              title: '暴力団の構成員（準構成員を含む。以下同じ。）',
            },
            {
              title:
                '暴力団関係企業又は暴力団若しくは暴力団の構成員が出資若しくは業務執行に関して重要な地位に就いている団体',
            },
            {
              title: '総会屋、社会運動標榜ゴロその他反社会的勢力に該当するもの',
            },
            {
              title: '特殊知能暴力集団その他反社会的勢力に該当する団体又はその構成員',
            },
            {
              title: 'その他前各号に準ずるもの',
            },
          ],
        },
      },
      {
        title:
          '利用者等は、自ら又は第三者を利用して、以下の各号に該当する行為を行わないことを表明して、確約するものとします。',
        list2: {
          content: [
            {
              title: '暴力的な又は法的な責任を超えた不当な要求行為',
            },
            {
              title: '取引に関して、脅迫的な言動をし、又は暴力を用いる行為',
            },
            {
              title: '風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為',
            },
            {
              title: 'その他前各号に準ずる行為',
            },
          ],
        },
      },
      {
        title:
          '当社及び運送事業者は、利用者等が前2項に定める表明事項に違反していた場合又は違反していると判断した場合、直ちに本サービスの提供を中止し、以後の提供を拒絶することができるとともに、これらの場合、当事者等は、自己の違反により、当社及び運送事業者がこれにより被った全ての損害（風評被害等による逸失利益、弁護士費用等を含むがこれに限らない。）を賠償しなければならないものとします。',
      },
    ],
  },
  {
    title: '（原規約の準用）',
    description:
      '原規約第4条から第10条の定めは、本規約に準用します。準用された原規約における「当社」は、本規約に定めるWILLER株式会社であり、「利用者」は、本規約に定める利用者に読み替えるものとします。',
  },
];
