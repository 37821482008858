import queryString from 'query-string';

/**
 * check required field
 * @param {String} input
 * @return {Boolean}
 */
export const validateInputEmpty = (input) => {
  if (input === null) {
    return true;
  }
  if (typeof input === 'string') {
    if (input === '' || !input.match(/\S/g)) {
      return true;
    }
    return false;
  }
};

/**
 * check valid input length
 * @param {String} input
 * @return {Boolean}
 */
export const validateInputLengthCheck = (input) => {
  return input.length > 255;
};

/**
 * check valid email
 * @param {String} email
 * @return {Boolean}
 */
export const isValidEmail = (email) => {
  /* eslint-disable */
  const regex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return regex.test(String(email).toLowerCase());
};

/**
 * check valid password length
 * @param {String} password
 */
export const passwordLengthCheck = (password) => {
  return password.length < 6;
};

/**
 * check valid phone number
 * @param {String} phoneNumber
 */
export const checkPhoneNumber = (phoneNumber) => {
  let regex = '^([0|+[0-9]{1,5})?([0-9]{9,16})$';
  return phoneNumber.match(regex);
};

/**
 * redirect route
 * @param {Object} props
 * @param {String} path
 */
export const redirectRouter = (props, path) => {
  props.history.push(path);
};

/**
 * verify initial data each shuttle bus screen
 * @param {Object} data
 * @return {Boolean}
 */
export const verifyInitialShuttleBusScreen = (data) => {
  return data.reservation?.length > 0 && data.shuttle_bus_info?.length > 0;
};

/**
 * handel onChange TextField's value in component
 * @param {component} component
 * @param {event} event
 */
export const onChangeTextField = (component, event) => {
  component.setState({
    ...component.state,
    [event.currentTarget.name]: event.target.value,
  });
};

/**
 * handle onChange SelectBox's value in component
 * @param {component} component
 * @param {event} event
 */
export const onChangeSelect = (component, event) => {
  component.setState({
    ...component.state,
    [event.target.name]: event.target.value,
  });
};

/**
 * handle onChange field's value in component state (array)
 * @param {component} component
 * @param {array} data
 * @param {string} fieldName
 * @param {int} index
 * @param {*} value
 */
export const onChangeListData = (component, data, fieldName, index, value) => {
  const copyData = data;
  fieldName ? (copyData[index][fieldName] = value) : (copyData[index] = value);
  component.setState({data: copyData});
};

/**
 * Check if device is iOS
 * @return {bool}
 */
export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

/**
 * Check if device is Android
 * @return {bool}
 */
export const isAndroid = () => {
  return /(android)/i.test(navigator.userAgent);
};

/**
 * convertToCurrency
 * @param {*} value
 */
export const convertToCurrency = (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * displayCurrency
 * @param {String} value
 * @param {String} currency
 * @return {String}
 */
export const displayCurrency = (value, currency) => {
  switch (currency) {
    case 'USD':
      return new Intl.NumberFormat('en-US').format(value ? value : 0) + '$';
    case 'VND':
      return new Intl.NumberFormat('vi-VN').format(value ? value : 0) + '₫';
    case 'JPY':
    default:
      return new Intl.NumberFormat('ja-JP').format(value ? value : 0) + '円';
  }
};

export const convertQueryStringToObject = (queryString) => {
  const params = new URLSearchParams(queryString);
  const obj = {};
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }
  return obj;
};

/**
 * changeUrlParams
 * @param {object} newParams
 */
export const changeUrlParams = (newParams) => {
  const newQueryString = getQueryStringFromObject(newParams);
  if (history.pushState) {
    const newUrl =
      window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + newQueryString;
    window.history.pushState({path: newUrl}, '', newUrl);
  }
};

/**
 * getQueryStringFromObject
 * @param {object} params
 * @return {string}
 */
export const getQueryStringFromObject = (params) => {
  return queryString.stringify(params, {arrayFormat: 'bracket', skipEmptyString: true, skipNull: true});
};

/**
 * customDisplayCurrency
 * @param {String} value
 * @param {String} currency
 * @return {String}
 */
export const customDisplayCurrency = (value, currency) => {
  switch (currency) {
    case 'USD':
      return new Intl.NumberFormat('en-US').format(value ? value : 0) + '$';
    case 'SG':
    case 'SGD':
      return 'S$ ' + new Intl.NumberFormat('en-SG').format(value ? value : 0) + '.00';
    case 'VND':
    case 'VN':
      return (
        new Intl.NumberFormat('vi-VN').format(value ? value : 0).split('.').join(',') +
        (localStorage.getItem('i18nextLng') === 'vi' ? ' ₫' : ' VND')
      );
    case 'MYR': 
      return 'RM ' + new Intl.NumberFormat('en-MY').format(value ? value : 0);
    case 'JPY':
    case 'JP':
    default:
      return localStorage.getItem('i18nextLng') === 'ja'
        ? (new Intl.NumberFormat('en-US').format(value ? value : 0) + '円')
        : ('￥' + new Intl.NumberFormat('en-US').format(value ? value : 0));
  }
};
