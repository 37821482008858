/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {asahikawaBusData} from '../../../../stores/asahikawa/actions';
import './style.css';

/**
 * Landing page Asahikawa
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * handle click button
   */
  handleOnClick() {
    this.props.handleOnClick();
  }

  /**
   * Render HTML
   * @return {component}
   */
  render() {
    return (
      <article className="snow_bus">
        <section id="hero">
          <div className="hero_container">
            <h1>
              <img src="/images/snow_bus/pc_mv.png" alt="" className="img-fluid d-none d-sm-block" />
              <img src="/images/snow_bus/sp_mv.png" alt="" className="img-fluid d-block d-sm-none" />
            </h1>
          </div>
        </section>
        <section id="limited">
          <div className="container">
            <div className="limited_inner">
              <p className="limited_text">
                <span>実施期間</span>
                <br className="d-block d-md-none" />
                2020年12月19日～2021年3月21日
              </p>
              <ul className="mt-3 kome">
                <li>
                  ※ スキー場への運行は
                  <br className="d-block d-sm-none" />
                  各スキー場営業状況により異なります。
                </li>
                <li>
                  ※ ウパシの森への運行は
                  <br className="d-block d-sm-none" />
                  2021年1月10日～2月28日の日曜・祝日のみとなります。
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id="about">
          <div className="container about_inner">
            <h2>
              <p className="sub_ttl">ABOUT</p>
              <span className="font-20">SNOW BUS by WILLER mobiとは</span>
            </h2>
            <p className="text mt-4 mt-xl-5">
              SNOW BUS by WILLER mobiは、<span className="point">アプリで簡単予約！</span>
              <br />
              事前予約で、大雪エリアのスキー場や
              <br className="d-block d-sm-none" />
              アフタースキーの観光スポット等へ手軽に
              <br className="d-block d-sm-none" />
              行くことが出来ます。
              <br />
              旭山動物園から旭川ラーメン村や男山などの
              <br className="d-block d-sm-none" />
              観光スポット巡り、高砂酒造から男山など
              <br className="d-block d-sm-none" />
              酒造巡りも楽しめます。
            </p>
            <a
              href="https://www.google.com/maps/d/u/1/viewer?mid=1OzEDxFy7Kfq2APyicLs_AFfH2vLkZ44T&ll=43.803344042152794%2C142.41887735&z=11"
              className="btn_block"
            >
              <i className="fas fa-window-restore"></i> 停車ポイントはこちら
            </a>
          </div>
        </section>
        <section id="area" className="bgcolor">
          <div className="container">
            <h2>
              <p className="sub_ttl">AREA MAP</p>エリアマップ
            </h2>
            <div className="mt-4">
              <img src="/images/snow_bus/pc_map.png" alt="" className="img-fluid d-none d-sm-block" />
              <img src="/images/snow_bus/sp_map.png" alt="" className="img-fluid d-block d-sm-none" />
            </div>
            <ul className="mt-3 kome">
              <li>
                ※ ホテル⇔旭山動物園、高砂酒造、ラーメン村、
                <br className="d-block d-sm-none" />
                旭川ラーメン村、男山酒造、旭川デザインセンター間の運行はありません。
              </li>
              <li>※ 旭山動物園、高砂酒造⇔スキー場、ウパシの森、鷹栖スノーパーク（助安農場）間の運行はありません。</li>
            </ul>
          </div>
        </section>
        <section id="price">
          <h2>
            <p className="sub_ttl">PRICE</p>料金表
          </h2>
          <p>価格は、片道・税込の表示となります。</p>
          <table className="table table-bordered justify-content-center price_table">
            <tbody>
              <tr>
                <th>
                  旭川市街 <i className="fas fa-arrows-alt-h"></i> ウパシの森
                </th>
                <td>
                  2,000<small>円</small>
                </td>
              </tr>
              <tr>
                <th>
                  旭川市街 <i className="fas fa-arrows-alt-h"></i> <br className="d-block d-sm-none" />
                  鷹栖スノーパーク<small>（助安農場）</small>
                </th>
                <td>
                  1,500<small>円</small>
                </td>
              </tr>
              <tr>
                <th>
                  旭川市街 <i className="fas fa-arrows-alt-h"></i> <br className="d-block d-sm-none" />
                  キャンモアスキービレッジ
                </th>
                <td>
                  1,500<small>円</small>
                </td>
              </tr>
              <tr>
                <th>
                  旭川市街 <i className="fas fa-arrows-alt-h"></i> <br className="d-block d-sm-none" />
                  ひっぷスキー場
                </th>
                <td>
                  2,000<small>円</small>
                </td>
              </tr>
              <tr>
                <th>
                  旭川市街 <i className="fas fa-arrows-alt-h"></i> <br className="d-block d-sm-none" />
                  サンタプレゼントパーク
                </th>
                <td>
                  1,000<small>円</small>
                </td>
              </tr>
              <tr>
                <th>
                  旭川動物園 <i className="fas fa-arrows-alt-h"></i> 永山エリア
                </th>
                <td>
                  1,200<small>円</small>
                </td>
              </tr>
              <tr>
                <th>
                  高砂酒造 <i className="fas fa-arrows-alt-h"></i> 永山エリア
                </th>
                <td>
                  1,000<small>円</small>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section id="flow">
          <div className="container flow_inner">
            <h2>
              <p className="sub_ttl">FLOW</p>予約の流れ
            </h2>
            <p className="text mt-4 mt-xl-5">
              本アプリにてご予約後、決済サイトにて
              <a href="https://www.taisetsu-kamui.jp/snowbus/" className="point">
                乗車チケット
              </a>
              をご購入ください。
            </p>
            <ul className="kome">
              <li>
                ※
                乗車チケット購入後に届くメール、またはマイページから支払証明書（領収書）画面を乗車時ドライバーにご提示ください
              </li>
            </ul>
            <div className="row">
              <div className="col-12 col-md-6 flow_block">
                <img src="/images/snow_bus/STEP01.png" alt="STEP01" className="img-fluid" />
                <div className="flow_text mt-4">
                  <h3>新規希望の乗降地を選んで検索</h3>
                  <p>下記「新規リクエスト予約はこちら」をクリックしお進みください。</p>
                </div>
                <a href="" className="btn_block" onClick={this.handleOnClick}>
                  新規リクエスト予約はこちら
                </a>
              </div>
              <div className="col-12 col-md-6 flow_block">
                <img src="/images/snow_bus/STEP02.png" alt="STEP02" className="img-fluid" />
                <div className="flow_text mt-4">
                  <h3>希望の時間帯を選んでリクエスト予約</h3>
                  <p>
                    リクエスト予約完了後、画面上に予約確定時間が表示されます。
                    <br />
                    予約確定時間に正確な発着時間をご連絡させていただきます。<small>※1</small>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 flow_block">
                <img src="/images/snow_bus/STEP03.png" alt="STEP03" className="img-fluid" />
                <div className="flow_text mt-4">
                  <h3>決済サイトにて乗車チケット購入</h3>
                  <p>
                    下記「決済はこちら」のリンクをクリックし、お進みください。
                    <br />
                    利用区間×人数分の乗車チケットをご購入ください。
                  </p>
                </div>
                <a href="https://www.taisetsu-kamui.jp/snowbus/" className="btn_block">
                  <i className="fas fa-window-restore"></i> 決済はこちら
                </a>
              </div>
              <div className="col-12 col-md-6 flow_block">
                <img src="/images/snow_bus/STEP04.png" alt="STEP04" className="img-fluid" />
                <div className="flow_text mt-4">
                  <h3>予約成立メールを受け取る</h3>
                  <p>予約確定時間に下記内容をメールにてお知らせします。</p>
                  <ul className="list">
                    <li>
                      予約の確定の有無 <small>※2</small>
                    </li>
                    <li>正確な発着時間</li>
                  </ul>
                </div>
                <a href={`${process.env.PUBLIC_URL}/reserve/asahikawa/login`} className="btn_block">
                  予約の確認はこちら
                </a>
              </div>
            </div>
            <ul className="mt-5 kome">
              <li>
                ※1 出発の1時間～3時間前までリクエスト予約が可能です。
                <br />
                （便により異なります。詳細はSNOW BUSサイトの 時刻表をご確認ください） <br />
                ▼SNOW BUSサイトURL&nbsp;
                <a href="https://www.taisetsu-kamui.jp/snowbus/">https://www.taisetsu-kamui.jp/snowbus/</a>
              </li>
              <li>
                ※2
                空席状況により、予約不成立となる場合がございますが、運行会社より代替車両の準備が整い次第ご連絡をさせて頂きます。
              </li>
            </ul>
          </div>
        </section>
        <section id="summary">
          <div className="container">
            <h2>
              <p className="sub_ttl">SUMMARY</p>概要
            </h2>
            <div className="mt-4 mt-md-5 summary_inner">
              <h3>商品情報</h3>
              <table className="mt-4 table justify-content-center summary_table">
                <tbody>
                  <tr>
                    <th>旅行日数</th>
                    <td>1日間</td>
                  </tr>
                  <tr>
                    <th>旅行形態</th>
                    <td>募集型企画旅行</td>
                  </tr>
                  <tr>
                    <th>最少催行人数</th>
                    <td>1名</td>
                  </tr>
                  <tr>
                    <th>予約販売期限</th>
                    <td>
                      出発の1時間～3時間前
                      <br />
                      <span className="kome">※商品により多少異なります</span>
                    </td>
                  </tr>
                  <tr>
                    <th>旅行形態</th>
                    <td>募集型企画旅行</td>
                  </tr>
                  <tr>
                    <th>添乗員</th>
                    <td>なし</td>
                  </tr>
                </tbody>
              </table>

              <h3 className="mt-5">旅行企画実施会社情報</h3>
              <table className="mt-4 table justify-content-center summary_table">
                <tbody>
                  <tr>
                    <th>会社名</th>
                    <td>一般社団法人大雪カムイミンタラDMO</td>
                  </tr>
                  <tr>
                    <th>所在地</th>
                    <td>
                      〒070-0033
                      <br className="d-block d-sm-none" /> 旭川市3条通7丁目418-1オクノ6Ｆ
                    </td>
                  </tr>
                  <tr>
                    <th>
                      旅行業
                      <br className="d-block d-sm-none" />
                      登録番号
                    </th>
                    <td>北海道知事登録旅行業第3-774号</td>
                  </tr>
                </tbody>
              </table>
              <h3 className="mt-5">お問い合わせ</h3>
              <table className="mt-4 table justify-content-center summary_table">
                <tbody>
                  <tr>
                    <th>
                      商品に関する
                      <br className="d-block d-sm-none" />
                      お問い合わせ先
                    </th>
                    <td>
                      一般社団法人大雪カムイミンタラDMO
                      <br />
                      ［電話番号］
                      <br className="d-block d-sm-none" />
                      0166-73-6968
                      <br />
                      ［営業時間］
                      <br className="d-block d-sm-none" />
                      8：45～17：15<small>（平日のみ）</small>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      運行に関する
                      <br className="d-block d-sm-none" />
                      お問い合わせ先
                    </th>
                    <td>
                      旭川中央交通株式会社
                      <br />
                      ［電話番号］
                      <br className="d-block d-sm-none" />
                      0166-33-3131
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <section id="section-breadcrumb">
          <div className="container">
            <ul className="breadcrumb-list-set clearfix" itemScope itemType="http://schema.org/BreadcrumbList">
              <li itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
                <a href="/" itemProp="item">
                  <span itemProp="name">HOME</span>
                </a>
                <meta itemProp="position" content="1" />
              </li>
              <li itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
                <a href="/maas/" itemProp="item">
                  <span itemProp="name">MaaSアプリ「WILLERS」</span>
                </a>
                <meta itemProp="position" content="2" />
              </li>
              <li itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
                <a href="/maas/willer-mobi/asahikawa/" itemProp="item">
                  <span itemProp="name">旭川SNOWBUS by WILLER mobi</span>
                </a>
                <meta itemProp="position" content="3" />
              </li>
            </ul>
          </div>
        </section>
      </article>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    asahikawa: state.asahikawa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    asahikawaBusData: (data) => dispatch(asahikawaBusData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
