export const ACTION_TYPES = {
  INITIAL_VERIFY_SCREEN: 'INITIAL_VERIFY_SCREEN',
  INITIAL_CONFIRM_SCREEN: 'INITIAL_CONFIRM_SCREEN',

  VERIFY_ORDER_START: 'VERIFY_ORDER_START',
  VERIFY_ORDER_SUCCESS: 'VERIFY_ORDER_SUCCESS',
  VERIFY_ORDER_FAIL: 'VERIFY_ORDER_FAIL',

  REGISTER_SHUTTLE_BUS_START: 'REGISTER_SHUTTLE_BUS_START',
  REGISTER_SHUTTLE_BUS_SUCCESS: 'REGISTER_SHUTTLE_BUS_SUCCESS',
  REGISTER_SHUTTLE_BUS_FAIL: 'REGISTER_SHUTTLE_BUS_FAIL',

  EDIT_SHUTTLE_BUS_START: 'EDIT_SHUTTLE_BUS_START',
  EDIT_SHUTTLE_BUS_SUCCESS: 'EDIT_SHUTTLE_BUS_SUCCESS',
  EDIT_SHUTTLE_BUS_FAIL: 'EDIT_SHUTTLE_BUS_FAIL',

  CANCEL_SHUTTLE_BUS_START: 'CANCEL_SHUTTLE_BUS_START',
  CANCEL_SHUTTLE_BUS_SUCCESS: 'CANCEL_SHUTTLE_BUS_SUCCESS',
  CANCEL_SHUTTLE_BUS_FAIL: 'CANCEL_SHUTTLE_BUS_FAIL',

  GET_LIST_JIT_TIME_SUCCESS: 'GET_LIST_JIT_TIME_SUCCESS',

  SHUTTLE_BUS_INFO: 'SHUTTLE_BUS_INFO',
};

const initialState = {
  isLoading: false,
  reservation: [],
  shuttle_bus_info: [],
  user_info: {},
  errorMessage: '',
  messageRegistry: {},
  messageDetail: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.INITIAL_VERIFY_SCREEN:
      return {
        ...state,
        isLoading: false,
        reservation: [],
        shuttle_bus_info: [],
        user_info: {},
        errorMessage: '',
        messageRegistry: {},
        messageDetail: {},
      };
    case ACTION_TYPES.INITIAL_CONFIRM_SCREEN:
      return {
        ...state,
        errorMessage: '',
      };
    case ACTION_TYPES.VERIFY_ORDER_START:
      return {
        ...state,
        isLoading: true,
        reservation: [],
        shuttle_bus_info: [],
        user_info: {},
        errorMessage: '',
      };
    case ACTION_TYPES.VERIFY_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reservation: action.reservation,
        shuttle_bus_info: action.bus_info,
        user_info: action.user_info,
        errorMessage: '',
        messageRegistry: {},
        messageDetail: {},
      };
    case ACTION_TYPES.VERIFY_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        reservation: [],
        shuttle_bus_info: [],
        user_info: {},
        errorMessage: action.errorMessage,
      };
    case ACTION_TYPES.REGISTER_SHUTTLE_BUS_START:
    case ACTION_TYPES.EDIT_SHUTTLE_BUS_START:
    case ACTION_TYPES.CANCEL_SHUTTLE_BUS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.REGISTER_SHUTTLE_BUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reservation: [],
        shuttle_bus_info: [],
        user_info: {},
        messageRegistry: action.messageRegistry,
        errorMessage: '',
      };
    case ACTION_TYPES.REGISTER_SHUTTLE_BUS_FAIL:
      return {
        ...state,
        isLoading: false,
        messageRegistry: {},
        errorMessage: action.errorMessage,
      };
    case ACTION_TYPES.EDIT_SHUTTLE_BUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reservation: [],
        shuttle_bus_info: [],
        user_info: {},
        messageDetail: action.messageDetail,
        errorMessage: '',
      };
    case ACTION_TYPES.EDIT_SHUTTLE_BUS_FAIL:
      return {
        ...state,
        isLoading: false,
        messageDetail: {},
        errorMessage: action.errorMessage,
      };
    case ACTION_TYPES.CANCEL_SHUTTLE_BUS_SUCCESS:
    case ACTION_TYPES.CANCEL_SHUTTLE_BUS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.SHUTTLE_BUS_INFO:
      const newState = Object.assign({}, state);
      newState.shuttle_bus_info = action.shuttle_bus_info;
      return newState;
    case ACTION_TYPES.GET_LIST_JIT_TIME_SUCCESS:
      return {
        ...state,
        shuttle_bus_info: state.shuttle_bus_info.map((info) => {
          if (
            info.reservation_id === action.result.reservation_id &&
            info.shuttle_bus_type === action.result.shuttle_bus_type
          ) {
            return {
              ...info,
              list_expect_time: action.result.data,
            };
          }
          return info;
        }),
      };
    default:
      return state;
  }
}
