import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';

/**
 * map component
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      pickup_name: '',
      pickup_position: {},
      dropoff_name: '',
      dropoff_position: {},
      simulation_id: null,

      activeMarker: {},
      selectedPlace: {},
      showingInfoWindow: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.loadGeoJson(this.props.polygon);
  }

  /**
   * registerCheckPolygonContainPoint
   * @param {object} google
   */
  registerCheckPolygonContainPoint(google) {
    if (!google.maps.Polygon.prototype.Contains) {
      google.maps.Polygon.prototype.Contains = function(point) {
        let crossings = 0;
        const path = this.getPath();

        // for each edge
        for (let i = 0; i < path.getLength(); i++) {
          const a = path.getAt(i);
          let j = i + 1;
          if (j >= path.getLength()) {
            j = 0;
          }
          const b = path.getAt(j);
          if (rayCrossesSegment(point, a, b)) {
            crossings++;
          }
        }

        // odd number of crossings?
        return crossings % 2 === 1;

        /**
         * rayCrossesSegment
         * @param {object} point
         * @param {*} a
         * @param {*} b
         * @return {*}
         */
        function rayCrossesSegment(point, a, b) {
          let px = point.lng();
          let py = point.lat();
          let ax = a.lng();
          let ay = a.lat();
          let bx = b.lng();
          let by = b.lat();
          if (ay > by) {
            ax = b.lng();
            ay = b.lat();
            bx = a.lng();
            by = a.lat();
          }
          // alter longitude to cater for 180 degree crossings
          if (px < 0) {
            px += 360;
          }
          if (ax < 0) {
            ax += 360;
          }
          if (bx < 0) {
            bx += 360;
          }

          if (py === ay || py === by) py += 0.00000001;
          if (py > by || py < ay || px > Math.max(ax, bx)) return false;
          if (px < Math.min(ax, bx)) return true;

          const red = ax !== bx ? (by - ay) / (bx - ax) : Infinity;
          const blue = ax !== px ? (py - ay) / (px - ax) : Infinity;
          return blue >= red;
        }
      };
    }
  }

  /**
   * load Geofences Json
   * @param {object} geojson
   */
  loadGeoJson(geojson) {
    const {google} = this.props;

    // add single polygon
    if (
      geojson &&
      typeof geojson === 'object' &&
      geojson.constructor === Object &&
      geojson.geometry.type === 'Polygon'
    ) {
      const jsonData = {
        type: 'Feature',
        geometry: {
          type: geojson.geometry.type,
          coordinates: geojson.geometry.coordinates,
        },
        properties: {
          Id: geojson.geojson,
        },
      };
      this.mapRef.map.data.addGeoJson(jsonData, {
        idPropertyName: 'Id',
      });
    }
    // add multiple polygon
    if (geojson && typeof geojson === 'object' && geojson.constructor === Array) {
      geojson.forEach((value) => {
        const jsonData = {
          type: 'Feature',
          geometry: {
            type: value.geometry.type,
            coordinates: value.geometry.coordinates,
          },
          properties: {
            Id: value.geofence_id,
          },
        };
        this.mapRef.map.data.addGeoJson(jsonData, {
          idPropertyName: 'Id',
        });
      });
    }
    // set style to geofence
    this.mapRef.map.data.setStyle({
      strokeColor: '#99cecf',
      fillColor: '#99cecf',
      fillOpacity: 0.5,
      strokeWeight: 0.1,
    });

    // focus to center geofence
    this.registerCheckPolygonContainPoint(google);
    const bounds = new google.maps.LatLngBounds();
    this.mapRef.map.data.forEach((feature) => {
      feature.getGeometry().forEachLatLng(function(latlng, i) {
        bounds.extend(latlng);
      });
    });
    this.setState({
      pickup_name: this.props.pickup_position?.place_name,
      pickup_position: new google.maps.LatLng(this.props.pickup_position?.lat, this.props.pickup_position?.lng),
      dropoff_name: this.props.dropoff_position?.place_name,
      dropoff_position: new google.maps.LatLng(this.props.dropoff_position?.lat, this.props.dropoff_position?.lng),
    });
    // this.mapRef.map.setCenter(new google.maps.LatLng(this.props.position.lat, this.props.position.lng));
    // this.mapRef.map.setZoom(15);
    this.mapRef.map.fitBounds(bounds);
  }

  /**
   * onClickMarker to show InfoWindow
   * @param {Object} props
   * @param {Object} marker
   */
  onMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    });
  };

  /**
   * onClick to close InfoWindow
   */
  onInfoWindowClose = () => {
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });
  };

  /**
   * onClick into Maps
   */
  onMapClicked = () => {
    if (this.state.showingInfoWindow) {
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      });
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    return (
      <div>
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          initialCenter={{
            lat: 36.2048,
            lng: 138.2529,
          }}
          zoom={15}
          centerAroundCurrentLocation={false}
          gestureHandling="greedy"
          disableDefaultUI
          containerStyle={{
            border: '1px solid #eeeded',
            width: '100% !important',
            height: 327,
            padding: '0px 24px !important',
            position: 'relative',
          }}
          className="width_100"
          onClick={this.onMapClicked}
        >
          <Marker
            name={this.state.pickup_name}
            position={this.state.pickup_position}
            draggable={false}
            icon={{
              url: `${process.env.PUBLIC_URL}/images/pick_up_position.svg`,
              scale: 10,
              strokeWeight: 2,
              strokeColor: this.props.markerColor,
              fillColor: this.props.markerColor,
              fillOpacity: 1,
            }}
            onClick={this.onMarkerClick}
          ></Marker>
          <Marker
            name={this.state.dropoff_name}
            position={this.state.dropoff_position}
            draggable={false}
            icon={{
              url: `${process.env.PUBLIC_URL}/images/drop_off_position.svg`,
              scale: 10,
              strokeWeight: 2,
              strokeColor: this.props.markerColor,
              fillColor: this.props.markerColor,
              fillOpacity: 1,
            }}
            onClick={this.onMarkerClick}
          ></Marker>
          <InfoWindow
            marker={this.state.activeMarker}
            onClose={this.onInfoWindowClose}
            visible={this.state.showingInfoWindow}
          >
            <div>
              <span>{this.state.selectedPlace.name}</span>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

// eslint-disable-next-line new-cap
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
})(withTranslation('translations')(Index));
