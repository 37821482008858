import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';
import {
  Container,
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Modal,
  Backdrop,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {ROUTE} from '../../../common/constant';
import MapDisplay from '../../../components/map_display';
import MobileModal from '../../../components/mobileModal';
import {shuttleBusInfo, editShuttleBus, initialConfirmScreen} from '../../../stores/shuttleBus/actions';
import {onChangeListData, verifyInitialShuttleBusScreen} from '../../../utils/common';
import {convertPayload, renderSelectPeople} from '../../../utils/shuttle_bus';
import {displayDateTime, convertDatetimeUTC} from '../../../utils/date_time';

/**
 * shuttle bus confirm
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      shuttle_bus_info: [],

      isExistEmail: false,
      isSubmitForm: false,
      isLoadingModal: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.props.initialConfirmScreen();
    if (!verifyInitialShuttleBusScreen(this.props.shuttle)) {
      this.props.history.push({pathname: ROUTE.VALIDATE_BUS});
    } else {
      this.setState({
        shuttle_bus_info: this.props.shuttle.shuttle_bus_info,
      });
      window.scrollTo(0, 0);
    }
  }

  /**
   * handleRegisterShuttle
   * @param {event} e
   */
  handleRegisterShuttle = (e) => {
    e.preventDefault();
    this.setState({
      isSubmitForm: true,
      isLoadingModal: true,
    });
  };

  /**
   * get loading status
   * @param {Boolean} progress
   */
  getLoadingStatus(progress) {
    if (progress === 100) {
      const payload = this.state.shuttle_bus_info.filter((e) => e.id === Number(this.props.match.params.id));
      if (
        payload[0].status === 'FAILED' ||
        payload[0].status === 'REGISTERED' ||
        payload[0].status === 'OFFER_FAILED'
      ) {
        payload[0].status = true;
      }
      this.props.editShuttleBus(convertPayload(payload), this.props.match.params.id, this.props);
    }
  }
  
  /**
   * handleClosedModal
   */
  handleClosedModal = () => {
    this.setState({
      isLoadingModal: false,
    });
  };

  /**
   * checkAllFieldValid
   * @param {Array} data
   * @return {Boolean}
   */
  checkAllFieldValid = (data) => {
    return data
      .map(
        (item) =>
          this.validator.check(item.passenger_name, 'required') &&
          this.validator.check(item.passenger_email, 'required|email') &&
          this.validator.check(item.phone, 'required|numeric|between:10,11,string') &&
          this.validator.check(item.number_in_use, 'required')
      )
      .every((e) => e);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {shuttle, maps, t} = this.props;

    return (
      <Container maxWidth="sm" className="p-0 body_content">
        <Grid container className="pt-12">
          <Grid item xs={12} justify="center" alignItems="center" container>
            <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
          </Grid>
        </Grid>
        {/* 往路 WILLER mobi 搭乗者情報 */}
        {this.state.shuttle_bus_info?.map(
          (item, index) =>
            Number(item.id) === Number(this.props.match.params.id) && (
              <Grid container key={index}>
                <Grid
                  item
                  xs={12}
                  justify="flex-start"
                  alignItems="center"
                  container
                  className="header_color_mobile mt-36"
                >
                  <span className="padding-item-6 font-weight-600">
                    {localStorage.getItem('way_down') &&
                    Number(item?.reservation_id) === Number(localStorage.getItem('way_down'))
                      ? '復路 WILLER mobi 搭乗者情報'
                      : '往路 WILLER mobi 搭乗者情報'}
                  </span>
                </Grid>
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <Box className="width_100">
                    <MapDisplay
                      pickup_position={{
                        lat:
                          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
                            ? item.drop_off_lat
                            : item.ex_bus_lat,
                        lng:
                          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
                            ? item.drop_off_lon
                            : item.ex_bus_lon,
                        place_name:
                          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
                            ? item.drop_off_name
                            : item.ex_bus_name,
                      }}
                      dropoff_position={{
                        lat:
                          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
                            ? item.ex_bus_lat
                            : item.drop_off_lat,
                        lng:
                          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
                            ? item.ex_bus_lon
                            : item.drop_off_lon,
                        place_name:
                          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
                            ? item.ex_bus_name
                            : item.drop_off_name,
                      }}
                      simulation_id={item.simulation_id}
                      type={item.shuttle_bus_type}
                      polygon={maps.geofences.filter((e) =>
                        item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
                          ? e.jit_home_to_work_sim_id === item.simulation_id
                          : e.jit_work_to_home_sim_id === item.simulation_id
                      )}
                      mapReadOnly
                    />
                  </Box>
                </Grid>
                {/* 乗車日時 */}
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">
                    {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
                      ? 'WILLER mobi 降車日時'
                      : 'WILLER mobi 乗車日時'}
                  </span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  <span className="padding-item-0 font-20">
                    {displayDateTime(convertDatetimeUTC(item.expected_time))}
                  </span>
                  <hr className="width_100 divider"></hr>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">WILLER mobi 乗車地</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  <span className="padding-item-0 font-20">
                    {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.drop_off_name : item.ex_bus_name}
                  </span>
                  <hr className="width_100 divider"></hr>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">WILLER mobi 降車地</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container>
                  <span className="padding-item-0 font-20">
                    {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.ex_bus_name : item.drop_off_name}
                  </span>
                  <hr className="width_100 divider"></hr>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">乗車代表者氏名</span>
                  <img src={`${process.env.PUBLIC_URL}/images/required.svg`} alt="Logo" className="logo_required" />
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
                  <FormControl className="width_100 padding-item-0">
                    <TextField
                      name="passenger_name"
                      variant="outlined"
                      className="width_100"
                      placeholder="氏名を入力してください"
                      value={item.passenger_name}
                      disabled={item.isExistEmail}
                      onChange={(event) =>
                        onChangeListData(
                          this,
                          this.state.shuttle_bus_info,
                          event.currentTarget.name,
                          this.state.shuttle_bus_info.indexOf(item),
                          event.target.value
                        )
                      }
                    />
                    {this.validator.message('passenger_name', item.passenger_name, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(item.passenger_name, 'required') && (
                      <FormHelperText id="passenger_name" error>
                        {t('validation.required', {field: '氏名'})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">連絡用Eメールアドレス</span>
                  <img src={`${process.env.PUBLIC_URL}/images/required.svg`} alt="Logo" className="logo_required" />
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
                  <FormControl className="width_100 padding-item-0">
                    <TextField
                      name="passenger_email"
                      variant="outlined"
                      className="width_100"
                      placeholder="Eメールアドレスを入力してください"
                      value={item.passenger_email}
                      onChange={(event) =>
                        onChangeListData(
                          this,
                          this.state.shuttle_bus_info,
                          event.currentTarget.name,
                          shuttle.shuttle_bus_info.indexOf(item),
                          event.target.value
                        )
                      }
                    />
                    {this.validator.message('passenger_email', item.passenger_email, 'required|email')}
                    {this.state.isSubmitForm && !this.validator.check(item.passenger_email, 'required') && (
                      <FormHelperText id="passenger_email" error>
                        {t('validation.required', {field: '連絡用Eメールアドレス'})}
                      </FormHelperText>
                    )}
                    {this.state.isSubmitForm &&
                      this.validator.check(item.passenger_email, 'required') &&
                      !this.validator.check(item.passenger_email, 'email') && (
                        <FormHelperText id="passenger_email" error>
                          {t('validation.email')}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">電話番号（ハイフンなし）</span>
                  <img src={`${process.env.PUBLIC_URL}/images/required.svg`} alt="Logo" className="logo_required" />
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
                  <FormControl className="width_100 padding-item-0">
                    <TextField
                      name="phone"
                      variant="outlined"
                      className="width_100"
                      placeholder="例：09012345678"
                      value={item.phone}
                      disabled={item.isExistEmail}
                      onChange={(event) =>
                        onChangeListData(
                          this,
                          this.state.shuttle_bus_info,
                          event.currentTarget.name,
                          this.state.shuttle_bus_info.indexOf(item),
                          event.target.value
                        )
                      }
                    />
                    {this.validator.message('phone', item.phone, 'required|numeric|between:10,11,string')}
                    {this.state.isSubmitForm &&
                      (!this.validator.check(item.phone, 'required') ? (
                        <FormHelperText id="phone" error>
                          {t('validation.required', {field: '電話番号'})}
                        </FormHelperText>
                      ) : !this.validator.check(item.phone, 'numeric') ? (
                        <FormHelperText id="phone" error>
                          {t('validation.numeric', {field: '電話番号'})}
                        </FormHelperText>
                      ) : (
                        !this.validator.check(item.phone, 'between:10,11,string') && (
                          <FormHelperText id="phone" error>
                            電話番号は10桁から11桁で入力してください
                          </FormHelperText>
                        )
                      ))}
                  </FormControl>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">利用人数</span>
                  <img src={`${process.env.PUBLIC_URL}/images/required.svg`} alt="Logo" className="logo_required" />
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12 mb-50">
                  <FormControl variant="outlined" className="width_100 padding-item-0">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'number_in_use',
                      }}
                      displayEmpty
                      value={item.number_in_use}
                      onChange={(event) =>
                        onChangeListData(
                          this,
                          this.state.shuttle_bus_info,
                          event.target.name,
                          this.state.shuttle_bus_info.indexOf(item),
                          event.target.value
                        )
                      }
                      renderValue={
                        item.number_in_use
                          ? undefined
                          : () => <div className="font-12 color-disabled">ご利用人数を選択してください</div>
                      }
                    >
                      {renderSelectPeople(item.cnt).map((p, index) => (
                        <MenuItem value={p.value} key={index}>
                          {p.display}
                        </MenuItem>
                      ))}
                    </Select>
                    {this.validator.message('number_in_use', item.number_in_use, 'required')}
                    {this.state.isSubmitForm && !this.validator.check(item.number_in_use, 'required') && (
                      <FormHelperText id="number_in_use" error>
                        {t('validation.required.choose', {field: '利用人数'})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )
        )}
        {/* Button submit */}
        <Grid container>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
            <div className="width_100 padding-item-0">
              <Button
                variant="contained"
                className="width_100 button_color_submit font-weight-600"
                onClick={this.handleRegisterShuttle}
              >
                予約完了
                <ArrowRightIcon style={{position: 'absolute', right: 5}} />
              </Button>
            </div>
          </Grid>
        </Grid>
        {shuttle.errorMessage &&
          (shuttle.errorMessage === 'error.closed.time.over' ? (
            <Grid container justify="center" alignItems="center" className="mt-36">
              <Grid item xs={12} justify="center" alignItems="center" container>
                <span className="padding-item-0 font-16 color-danger">
                  タイムアウトになりました。再ログインしてください。
                </span>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center" alignItems="center" className="mt-36">
              <Grid item xs={12} justify="center" alignItems="center" container>
                <span className="padding-item-0 font-16 color-danger">
                  指定した
                  {shuttle.errorMessage === 'error.expected.time.is.closed.home.to.work'
                    ? ' WILLER mobi 降車希望時間'
                    : ' WILLER mobi 乗車希望時間'}
                  の
                </span>
              </Grid>
              <Grid item xs={12} justify="center" alignItems="center" container>
                <span className="padding-item-0 font-16 color-danger">予約期限過ぎました。</span>
              </Grid>
              <Grid item xs={12} justify="center" alignItems="center" container>
                <span className="padding-item-0 font-12">前の画面に戻って再選択してください</span>
              </Grid>
            </Grid>
          ))}
        <Grid container className="footer">
          <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
            <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
          </Grid>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isLoadingModal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000',
          }}
        >
          {this.checkAllFieldValid(
            this.state.shuttle_bus_info.filter((e) => e.id === Number(this.props.match.params.id))
          ) ? (
            <MobileModal
              usage_type="LOADING"
              loadingComplete={(progress) => this.getLoadingStatus(progress)}
            ></MobileModal>
          ) : (
            <MobileModal onClickOk={this.handleClosedModal} usage_type="VALIDATOR"></MobileModal>
          )}
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shuttle: state.shuttle,
    maps: state.maps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initialConfirmScreen: () => dispatch(initialConfirmScreen()),
    shuttleBusInfo: (shuttle_bus_info) => dispatch(shuttleBusInfo(shuttle_bus_info)),
    editShuttleBus: (payload, id, props) => dispatch(editShuttleBus(payload, id, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
