/* eslint-disable max-len */
import React, {Component} from 'react';
import './styles.css';
import {withTranslation} from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

/**
 * Privacy
 * @return {HTMLElement}
 */
class Privacy extends Component {
  /**
   * @param {*} event
   * @param {*} tag
   */
  handleClick = (event, tag) => {
    const link = '#' + tag;
    const anchor = (event.target.ownerDocument || document).querySelector(link);
    if (anchor) {
      anchor.scrollIntoView({behavior: 'smooth'});
    }
  };
  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div style={{wordBreak: 'break-word'}}>
        <div className="anchor_link">
          <div className='anchor_link_item mb-8' onClick={(event) => this.handleClick(event, 'ja')}>
            <span className='mr-24'>日本でお使いの方 </span>
            <div className='anchor_link_icon'><ArrowDropDownIcon /></div>
          </div>
          <div className='anchor_link_item mb-8' onClick={(event) => this.handleClick(event, 'sg')}>
            <span className='mr-24'>For users in Singapore</span>
            <div className='anchor_link_icon'><ArrowDropDownIcon /></div>
          </div>
          <div className='anchor_link_item' onClick={(event) => this.handleClick(event, 'vi')}>
            <span className='mr-24'>For users in Vietnam</span>
            <div className='anchor_link_icon'><ArrowDropDownIcon /></div>
          </div>
          <div className='mb-8' style={{fontSize: '12px', color: 'darkgray'}}>Cho người dùng ở Việt Nam</div>
          <div className='anchor_link_item' onClick={(event) => this.handleClick(event, 'malay')}>
            <span className='mr-24'>For users in Malaysia</span>
            <div className='anchor_link_icon'><ArrowDropDownIcon /></div>
          </div>
        </div>
        <div className="card-body privacy-body">
          {/* Privacy Policy */}
          <h1 className="ttl-pagemain" id="ja">
            プライバシーポリシー
          </h1>
          <p>
            <u>
              この日本語版は、参考のための翻訳版です。日本語版と英語版の法的な解釈に差異がある場合、英語版に基づく解釈が優先して適用されます。
            </u>
          </p>
          <p>
            本ポリシーにおいて、「当社」とはWILLERS Pte.
            LTDのことをいい、「利用者」とは、当社のサービスを利用する又は業務を実施するために個人情報を当社に登録し、本ポリシーの適用を受ける全ての方をいいます。
          </p>
          <p>
            利用者の個人情報に関する本ポリシーは、当社にとって非常に重要であり、当社は、シンガポール法であるPersonal
            Data Protection
            Act（以下、「PDPA」といいます。）に従って、利用者のプライバシーと個人情報を適切に保護し、利用することをお約束するものです。本ポリシーは、当社が利用者から取得した又は利用者から提供された個人情報（法令等の定義に従います。）の管理方法、利用方法並びに利用者ご自身で当社が保有する個人情報にアクセスする若しくは削除する方法が定められています。本ポリシーをお読みいただき、当社の取り組みについて十分にご理解ください。
          </p>
          <p>
            なお、当社に個人情報を提供することにより、利用者は本ポリシー及び本ポリシーに基づく個人情報の取り扱い（保管、利用及び第三者提供等）に同意されたものとします。なお、本ポリシーに同意されない場合は、当社に個人情報を一切登録又は提供しないでください。
          </p>
          {/* Personal Data */}
          <h2>個人情報</h2>
          <ol className="lst-upper-alpha">
            <li>
              「個人情報」の内容
              <br />
              本ポリシーにおける「個人情報」とは、法令等に定められる、個人（法人は含みません。）を特定することができる情報のことをいいます。
              <br />
              個人情報の例には、名前、住所、連絡先の詳細、各種識別番号、財務情報、IPアドレス、当社のWebサイトおよびアプリケーションでのアクティビティに基づく取引情報、電話番号、電子メールアドレス、画像、およびその他の個人情報が含まれることがあります。
            </li>
            <li>
              個人情報提供の任意性
              <br />
              当社は、利用者が任意で提供した個人情報を取得いたします。当社が取得する個人情報の種類は、取得した際の目的及びサービスにより異なります。
              <br />
              利用者は、自らの個人情報を当社に提供しないことも選択することができます。後掲の「同意の撤回」に定める手順により、提供済みの利用者の個人情報について、当社による保管、利用及び第三者提供等に対する許諾を撤回することができます。この場合において、必要な個人情報に対する利用等ができなくなった場合、当社は、利用者に対する製品又はサービスの提供ができなくなることがあります。
            </li>
            <li>
              第三者の個人情報の提供
              <br />
              利用者は、第三者が承諾していること及び第三者が本ポリシーに同意していることを条件に、当該第三者の個人情報を当社に提供することができます。この第三者の個人情報の提供にあたり、利用者は、当社に対し、当該第三者から、提供する個人情報の内容、本ポリシーに定められている利用目的、利用方法、第三者への提供等の全てについて、承諾及び同意を得ていることを保証するものとします。
              <br />
              本項に基づき当社に提供された個人情報に関して、本ポリシーに従った取扱いをしていたにもかかわらず、当該第三者からの異議又は紛争等により当社が損害を被った場合、その請求の根拠を問わず、利用者はかかる損害を全て賠償又は補償するものとします。
            </li>
            <li>
              個人情報の完全性と正確性の確保
              <br />
              利用者は、当社に提供した個人情報の正確性、真実性及び完全性にについて責任を負うものとします。また、個人情報の変更についても同様とします。
            </li>
          </ol>
          {/* Collection of Personal Data */}
          <h2>個人情報の取得方法</h2>
          <ol className="lst-upper-alpha">
            <li>
              利用者からの提供
              <br />
              当社は、当社のサービス提供又は事業の実施のため、利用者から関連する個人情報を取得いたします。また、当社は、以下に示す方法を通じて、直接的または間接的に利用者の個人情報を取得することがあります。
              <ol className="lst-decimal">
                <li>利用者が当社に直接提供した場合</li>
                <li>
                  利用者が当社に対し、第三者から取得することを許諾した場合（第三者への提供を含む他者のプライバシーポリシーに同意された場合を含みます。）
                </li>
                <li>利用者が当社の運営するウェブサイト、アプリ又はサービスにユーザーアカウントを登録した場合</li>
                <li>利用者が当社のウェブサイト、アプリ又はサービスをダウンロードした場合</li>
                <li>
                  利用者が、ソーシャルメディアプラットフォーム、メッセンジャープラットフォーム、対面会議、電話、電子メール、ファックス、手紙など、さまざまなコミュニケーションチャネルを通じて、当社に対して、取引を申し込み、連絡し、又は連絡を要求した場合
                </li>
                <li>利用者が、当社に対して、電子メールやメーリングリストへの登録を要求した場合</li>
                <li>利用者が当社の施設内にいる間、当社が、CCTVカメラを介して画像又は映像を記録したとき</li>
                <li>利用者が、当社が催行するイベント等に参加した際に、当社によって画像又は映像を撮影した場合</li>
                <li>利用者が、当社が主催するイベントや行事に参加した又は参加を申し込んだ場合</li>
              </ol>
            </li>
            <li>
              第三者からの提供
              <br />
              当社との取引関係又は契約関係に応じて、第三者のソースからお客様の個人情報を取得する場合（以下に例示しますが、これに限りません。）があります。
              <ol className="lst-decimal">
                <li>
                  広告、マーケティング、プロモーションサービス、人材紹介を提供するサードパーティ等、当社の事業上必要な提携先、委託先等から提供された場合
                </li>
                <li>利用者を代理して、その家族又は友人から提供された場合</li>
                <li>行政機関その他の公的機関から提供された場合</li>
              </ol>
            </li>
          </ol>
          {/* Purposes for Collection, Use of Disclosure of Personal Data */}
          <h2>個人情報の利用目的</h2>
          <ol className="lst-upper-alpha">
            <li>
              利用者の個人情報
              <br />
              当社は、以下に定める目的で、お客様の個人情報の使用し、開示し、又は取り扱うことができるものとします。
              <ol className="lst-decimal">
                <li>会員登録情報の確認、登録及び手続の実施</li>
                <li>利用者に対し、適切なサービス提供に必要な利用者の特定及びサポートの提供</li>
                <li>利用者に対するサービスに必要な請求、連絡及び通知をするため</li>
                <li>
                  利用者と、外部事業者（当社のウェブサイト又はアプリにおいてサービス（以下、「個別サービス」といいます。）を提供する者に限ります。）との間における、個別サービス利用に必要な情報の提供するため
                </li>
                <li>当社の商品、サービス、ウェブサイト又はアプリの利用状況の分析ため</li>
                <li>
                  当社の商品又はサービスに関する広告、プロモーション等その他マーケティング活動及び利用満足度調査等を提供するため
                </li>
                <li>
                  第三者(6-Cに定める共同利用者を含む)の商品又はサービスに関する広告、プロモーションその他マーケティング活動等及び利用満足度等に関する調査を提供するため
                </li>
                <li>利用者からのお問い合わせ及びご要望に対応するため</li>
                <li>当社の採用情報への応募者に対する選考案内、選考手続及び通知のため</li>
                <li>
                  当社のサービス実施のため、利用者の個人情報を日本以外の国の会社（例えば、WILLERS PTE.
                  LTD.（シンガポール法人）等を含みますがこれに限りません。）との間で共有するため
                </li>
                <li>上記の各号に関連又は付随するその他の目的のため</li>
              </ol>
              当社は、本ポリシーに記載する目的及び方法並びに当社のサービスに関する利用条件に従って、利用者又は第三者から適法に提供された個人情報に限り利用します。
            </li>
            <li>
              法令等に基づく目的外利用
              <br />
              当社は、PDPAにより義務付けられた場合又は許可された場合、利用者から事前に同意を得ることなく、上記の利用目的以外の目的において利用者の個人情報を取得し、利用し及び開示することができるものとします。
            </li>
            <li>
              利用者への連絡
              <br />
              当社は、電子メール、郵便、SMS、電話及びその他の方法によって、利用者に対し、上記A)に定める目的に関する連絡や情報提供を行います。
              <br />
              これらの連絡や情報提供を不要とする場合、利用者は、後継の「問い合わせ方法」記載の連絡先に通知することにより、これらの連絡や情報提供の配信を停止することができます。
            </li>
          </ol>
          {/* Automated Data Collection Technologies */}
          <h2>自動データ取得技術の取り扱い</h2>
          <p>
            当社のウェブサイト及びアプリには、本ポリシーに記載されている方法でデータを取得する特定の技術（以下、「自動データ取得技術」といいます。）が含まれている場合があります。自動データ取得技術によるデータの取得に同意しない場合、利用者
            は、当社のWebサイトおよび/またはアプリを使用中止するか、または、可能な場合は自己の責任において自動データ取得技術を無効に設定してください。
          </p>
          <p>
            当社及び当社が採用するアプリのライセンサー（以下、「当社等」といいます。）は、利用者のIPアドレスを利用してウェブサイトサーバ及びアプリサーバーを管理することがあります。
          </p>
          <p>
            <ol className="lst-upper-alpha">
              <li>
                Cookie
                <br />
                当社は、利用者の端末上の記録媒体に保存されているCookieファイルを使用して、一般的なインターネットの使用状況に関する情報を取得する場合があります。Cookieには、この記録媒体に転送される情報が含まれています。当社は、取得された情報をアプリとウェブサイトの改善及びより適切なサービスを提供するために使用する場合があります
                <br />
                Cookieを拒否するように端末上で設定した場合を除き、一般的なブラウザは、アプリ又はウェブサイトにログオンした時点でシステムがCookieを発行します。利用者は自らの責任において設定することにより、Cookieの受け入れを拒否することができます。ただし、この場合、当社等が提供するアプリ又はウェブサイトにアクセスできなくなる場合や、当社等が提供するサービスの全部又は一部が利用できなくなる場合があります。
              </li>
              <li>
                Cookieその他の自動追跡技術の第三者による利用
                <br />
                広告を含むウェブサイト又はアプリの一部のコンテンツ又はアプリケーションは、広告主、広告ネットワーク及びサーバー、コンテンツプロバイダー、並びにアプリケーションプロバイダーを含む第三者によって提供されます。これらの第三者は、Cookieを単独で、またはWebビーコンやその他の追跡技術と組み合わせて使用して、利用者が当社のウェブサイトやアプリを使用する際に、利用者に関する情報を取得する場合があります。これらの第三者が取得する情報は、利用者の個人情報のみに関連付けられている場合もあれば、個人情報を含む、さまざまなウェブサイトやその他のオンラインサービスにおける活動履歴に関する情報を取得する場合もあります。
                これらの第三者はかかる情報を使用して、利用者の興味又は関心に基づく広告またはその他のターゲットを絞ったコンテンツを提供する場合があります。
                当社は、これらの第三者の追跡技術及びこれらの利用方法を管理していません。
                利用者は、広告やその他のターゲットコンテンツについて質問がある場合、該当する広告やその他のターゲットコンテンツの配信元に直接連絡する必要があります。
              </li>
            </ol>
          </p>
          {/* Voice Recording and Video Recording */}
          <h2>録音及び録画</h2>
          <ol className="lst-upper-alpha">
            <li>当社は、お問い合わせ内容を正確に把握し、対応するため、利用者との通話を録音することがあります。</li>
            <li>
              当社は、利用者が当社の施設を利用した場合、監視カメラでお客様の画像やビデオを録画し、これらの画像やビデオを防犯や安全の目的で使用することがあります。
            </li>
          </ol>
          {/* Sharing With and Disclosure to Third Parties */}
          <h2>第三者との共有及び開示</h2>
          <p>
            利用者は、当社が、本ポリシーに定める目的の実現のため、個人情報の保護に適用されるすべての法令等を遵守した上で、利用者の個人情報を第三者(以下に掲げる提供先を含む)に提供または開示するできるものとします。
          </p>
          <p>
            当社は、上記利用目的の範囲において、Community
            Mobility株式会社（日本法人）、WILLER株式会社（日本法人）、WILLER VIETNAM
            Co.,Ltd（ベトナム法人）を含む、すべての関係会社と利用者の個人情報を共有します。
          </p>
          <p>
            当社は、サービス（コールセンター、人材サービス、データセンターその他のITサービス）の提供若しくは決済代行会社、債権管理会社等事業運営上必要な業務に関して、又は法令等若しくは官公庁からの命令等により、アプリ等のライセンサー、委託先又は提携先、官公庁または公的機関にお客様の個人情報を第三者に開示することができるものとします。
          </p>
          <p>
            当社は、利用者の個人情報は、シンガポールから他の國、地域又は都市に移転させる場合があります。この場合、当社は、引き続き、少なくともPDPAにより要求される管理水準を維持するものとします。当社に情報を提供することにより、利用者は、これらの条件に基づいて、利用者の個人情報についてシンガポールから他の国、地域、および都市に転送することに同意するものとします。
          </p>
          {/* Protection of Personal Data */}
          <h2>個人情報の保護</h2>
          <p>
            不正アクセス、漏洩、目的外利用及び改変などから利用者の個人情報を保護するため、当社においては　以下で示す方法をはじめとして、適切な組織的、物理的及び技術的な保護対策を実施しています。当社の従業員、共同利用者の従業員及び委託先等による利用は、利用目的の実現のため必要がある場合に限られます。
          </p>
          <p>
            <ol className="lst-upper-alpha">
              <li>
                個人情報を保管するストレージサーバーそのものは、インターネットに接続しておらず、ジャンプサーバーを介してインターネットに接続されています。
              </li>
              <li>ジャンプサーバーへのアクセスは、暗号化キーを含むトークンを使用した端末のみアクセスできます。</li>
              <li>ファイアウォールや脅威検出サービスなどのサービスを適用しています。</li>
            </ol>
          </p>
          <p>
            ただし、インターネットを介した送信方法や電子ストレージの方法は、完全に安全ではないことに、十分にご注意ください。
            <br />
            当社は、お客様の個人情報を十分に保護するよう努めており、情報セキュリティ対策を常に見直し、強化していますが、完全なセキュリティを保証するものではありません。
            <br />
            当社の管理が及ばない、利用者のセキュリティ対策不足による不正または意図しないアクセスについて、当社は責任を負いかねます。
            <br />
          </p>
          {/* Other Websites */}
          <h2>第三者のホームページ、ウェブサイトについて</h2>
          <p>
            当社が提供するアプリとウェブサイトには、他者が提供するウェブサイトへのリンクや、他社が提供するウェブサイトからのリンクが含まれている場合があります。
            <br />
            これらリンク先のウェブサイトへ移動した場合、移動先のウェブサイトには独自のプライバシーポリシーが定められており、これらのウェブサイトにおけるプライバシーポリシーに関して、当社は何ら責任又は義務を負いません。
            <br />
            これらのウェブサイトに個人情報を送信する前には、利用者の責任において適用されるプライバシーポリシーをご確認ください。
            <br />
          </p>
          {/* Retention Limitation */}
          <h2>個人情報の保管期限</h2>
          <p>
            当社は、取得した目的を達成し、監査、会計、または報告の要件を含む当社のビジネス及び法的要求を充足する必要がある場合に限り、利用者の個人情報を保持します。
            お客様の個人情報を保持する期間は保持する目的によって異なります。例）
          </p>
          <p>
            <ol className="lst-upper-alpha">
              <li>
                法的手続（訴訟、仲裁及び調停を含みますが、これらに限りません。）において個人情報が必要となる可能性がある場合　当該法的手続が発生するおそれのある期間
              </li>
              <li>上記以外の場合　利用目的が終了し、又は完了した時から5年間</li>
            </ol>
          </p>
          <p>
            上記の他、たとえば法律で保持する必要がある場合や、訴訟の目的で保持する必要がある場合など、当社は利用者の個人情報をより長期間保持する場合があります。
          </p>
          {/* Withdrawing Your Consent */}
          <h2>同意の撤回</h2>
          <p>
            個人情報の取得、使用及び開示に対する利用者の同意は、利用者自身の書面（電子メール等で指摘法歩を含みます。）による撤回まで有効です。
            <br />
            下記「問い合わせ方法」に記載されている方法により、同意を撤回し、上記の目的のいずれかまたは全てを対象に個人情報の利用または開示を停止するように請求することができます。
            <br />
            当社は、利用者が同意を撤回する場合適切に対応するものとします。ただし利用者は、かかる同意の撤回の内容により、当社の商品やサービスの一部又は全部の提供を受けることができないことに同意されるものといたします。
            <br />
          </p>
          {/* Personal Data Breach */}
          <h2>個人情報漏洩時の対応</h2>
          <p>
            当社は、適用される法令において、利用者の個人情報に対する漏洩又は侵害等を確認した場合、遅滞なく利用者に対しその旨及びかかる侵害および取るべき内部手順に関する十分な情報を通知し、提供します。
          </p>
          {/* Access and Correction */}
          <h2>閲覧と訂正の権利</h2>
          <ol className="lst-upper-alpha">
            <li>
              利用者またはその代理人は、下記「問い合わせ方法」に記載の方法により、当社が保有する個人情報の利用目的の通知、開示、内容の訂正、追加・削除、利用停止、
              第三者へのデータの提供を削除または一時停止を請求することができます。この場合、当社は、利用者本人の意思に基づく請求内容であることを確認の上適切な措置をします。
            </li>
            <li>
              当社のサービスの会員である利用者は、サービスに提供している会員情報画面から個人情報を更新することができます。
            </li>
            <li>上記の定めに関わらず、当社は、適用される法令に基づき、請求を認めないことができるものとします。</li>
          </ol>
          {/* Changes to the Privacy Policy */}
          <h2>本ポリシーの変更</h2>
          <p>
            当社は、本ポリシーを任意の時点で修正し、アプリとウェブサイトに適用できるものとします。アプリおよびウェブサイトへのアクセスを継続すること、又は当社が提供するサービスの会員であり続けることにより、修正された本ポリシーの条件に同意したものとして取り扱います。
          </p>
          {/* Contact */}
          <h2>問い合わせ方法</h2>
          <p>
            本ポリシーに関するご質問、ご意見、苦情及び請求については。 カスタマーサービス（65）6245
            0491に連絡するか、pr-info@willers.com.sgに電子メールを送信するか、下記に郵送してください。
          </p>
          <p>情報保護管理者 6060 Paya Lebar Road #06-32 Paya Lebar Square Singapore 409051</p>
          {/* Governing Law and Submission to Jurisdiction */}
          <h2>準拠法及び専属的合意管轄の合意</h2>
          <p>
            このポリシーはシンガポールの法律に準拠し、利用者はシンガポール裁判所における専属管轄権に従うことに同意するものとします。
          </p>
          <p className="text-align-end">以上</p>
          <p>
            制定日：2021年10月19日
            <br />
            最終更新日：2022年4月1日
          </p>

          <h1 className="ttl-pagemain" id="sg">
            For Singapore Users
          </h1>

          {/* Privacy Policy */}
          <h1 className="ttl-pagemain">Privacy Policy</h1>
          <p>
            In this policy, “we”, “us”, “our” or “WILLERS” means WILLERS Pte Ltd, “you”, “your”, “yours”, “user”,
            “users”, “member” and “members” means the persons to whom this policy applies.
          </p>
          <p>
            The privacy of your Personal Data is important to us and WILLERS is committed to properly protecting and
            respecting your privacy and personal data to comply with the Personal Data Protection Act of Singapore
            (“PDPA”). This policy sets out the basis on which any personal data (as deﬁned in the PDPA) WILLERS collects
            from you, or that you provide to WILLERS, will be stored and used and your right to access and correct your
            information in accordance with this policy. Please read this policy carefully to understand WILLERSʼs views
            and practices regarding your personal data and how WILLERS will treat it.
          </p>
          <p>
            By providing us with personal data, you agree to the terms of this Privacy Policy and you consent to our
            collection, use, disclosure and processing of personal data on these terms. DO NOT provide any personal data
            to us if you do not accept this Privacy Policy.
          </p>
          {/* Personal Data */}
          <h2>Personal Data</h2>
          <ol className="lst-lower-alpha">
            <li>
              What is Personal Data
              <br />
              “Personal data” is data that can be used to identify a natural person. Examples of personal data include
              name, address, contact details, identification numbers, financial information, IP address, transactional
              information based on your activities on our website and applications, telephone numbers, email address,
              images, and any other information of a personal nature.
            </li>
            <li>
              Voluntary provision of personal data
              <br />
              We collect personal data that you voluntarily provide to us. What personal data we collect depends on the
              purposes for which the personal data is collected and what you have chosen to provide.
              <br />
              <br />
              You can choose not to provide us with personal data. You also have the right to withdraw your consent for
              us to continue collecting, using, disclosing and processing your personal data, by contacting in the way
              described in “Contact” section below. However, if you do so, it may not be possible for us to fulfil the
              purposes for which we require the personal data, including processing your transactions or providing you
              with the products and services that you require.
            </li>
            <li>
              Providing personal data belonging to others
              <br />
              In certain circumstances, you may also provide us with personal data of persons other than yourself. If
              you do so, you warrant that you have informed him/her of the purposes for which we are collecting his/her
              personal data and that he/she has consented to your disclosure of his/her personal data to us for those
              purposes. You agree to indemnify and hold us harmless from and against any and all claims by such
              individuals relating to our collection, use and disclosure of such personal data in accordance with the
              terms of this Privacy Policy.
            </li>
            <li>
              Accuracy and completeness of personal data
              <br />
              You are responsible for ensuring that all personal data that you provide to us is true, accurate and
              complete. You are responsible for informing us of any changes to your personal data.
            </li>
          </ol>
          <h2>Collection of Personal Data</h2>
          <ol className="lst-lower-alpha">
            <li>
              Personal data you provide
              <br />
              We collect personal data that is relevant to our relationship with you. We may collect your personal data
              directly or indirectly through various channels, such as when:
              <ol className="lst-lower-roman">
                <li>you provide us with your personal data for whatever reasons;</li>
                <li>you authorise us to obtain your personal data from a third party;</li>
                <li>you register for a user account on our Website and/or App;</li>
                <li>you download or use our Website and/or App and services;</li>
                <li>
                  you transact with us, contact us or request that we contact you through various communication
                  channels, for example, through social media platforms, messenger platforms, face-to-face meetings,
                  telephone calls, emails, fax and letters;
                </li>
                <li>you request to be included in an email or our mailing list;</li>
                <li>your images are captured via CCTV cameras while you are within our premises;</li>
                <li>
                  your images are captured via photographs or videos taken by us or our representatives when you attend
                  events organised by us;
                </li>
                <li>when you attend events or functions organised by us; and/or</li>
                <li>
                  we seek information about you and receive your personal data in connection with your relationship with
                  us, for example, if you are an investor or shareholder of WILLERS.
                </li>
              </ol>
            </li>
            <li>
              Personal data provided by others
              <br />
              Depending on your relationship with us, we may also collect your personal data from third party sources,
              for example:
              <ol className="lst-lower-roman">
                <li>
                  from our business partners such as third parties providing advertising, marketing and promotional
                  services to us;
                </li>
                <li>from your family members or friends who provide your personal data to us on your behalf; and/or</li>
                <li>from public agencies or other public sources.</li>
              </ol>
            </li>
          </ol>
          {/* Purposes for Collection, Use of Disclosure of Personal Data */}
          <h2>Purposes for Collection, Use of Disclosure of Personal Data</h2>
          <ol className="lst-lower-alpha">
            <li>
              Personal Data of Users or Members
              <br />
              WILLERS may collect use, disclose and process your personal data for purposes connected or relevant to our
              business, to make your relationship with us. Such purposes would include:
              <ol className="lst-lower-roman">
                <li>reviewing, processing and registration of membership application;</li>
                <li>
                  identifying the user for our service and providing appropriate assistance to users or members using
                  our service;
                </li>
                <li>
                  communication with users and members to implement proper membership management such as billing,
                  announcement and notiﬁcation;
                </li>
                <li>
                  communication with third party service providers providing individual services (“Individual Service”)
                  that our members and users have applied for through WILLERSʼs mobile applications and websites
                  (respectively “Apps” and “Websites”);
                </li>
                <li>analysis for facilitating and improving our services;</li>
                <li>
                  provision of promotional information about Individual Services and collection of information through
                  customer survey;
                </li>
                <li>
                  provision of promotional information for third partyʼs products and collection of information for
                  questionnaire surveys for third party;
                </li>
                <li>responding properly to enquiry and request;</li>
                <li>communicating with, consider and notice to applicant for WILLERSʼs staﬀ recruitment;</li>
                <li>
                  providing your Personal Data to organizations outside of Singapore, including, but not limited to,
                  Community Mobility Inc. (JAPAN), WILLER Inc. (JAPAN), KDDI CORPORATION (JAPAN), WILLER VIETNAM
                  (VIETNAM), TOKYO MK Co., Ltd. (JAPAN) and Mineyama TAXI Co., Ltd. (JAPAN).
                </li>
                <li>and other related and/or incidental to above purposes and reasons.</li>
              </ol>
              WILLERS will only use such information collected from users or members for the purposes and reasons set
              out in this policy and the terms and conditions that govern your membership with WILLERS.
            </li>
            <li>
              Use Permitted under Applicable Laws
              <br />
              We may also collect, use, disclose and process your personal data for other purposes, without your
              knowledge or consent, where this is required or permitted by law.
            </li>
            <li>
              Contacting You
              <br />
              With respect to sending marketing messages, such messages may be sent to you in various modes including
              but not limited to electronic mail, direct mailers, short message service, telephone calls, facsimile and
              other mobile messaging services. In doing so, we will comply with the PDPA.
              <br />
              <br />
              If you do not wish to receive any communication or information from us, or wish to restrict the manner by
              which we may contact or send you information, you may contact us in the way described in the “Contact”
              section below.
            </li>
          </ol>
          {/* Automated Data Collection Technologies */}
          <h2>Automated Data Collection Technologies</h2>
          <p>
            Our Website and/or App may contain certain technologies that collect data in the manner described in this
            Privacy Policy. You should not use our Website and/or App if you do not agree to having your data collected
            through such means. Alternatively, you should disable these technologies where it is possible to do so.
          </p>
          <p>
            WILLERS may use your IP address to help diagnose problems with our server, and to administer the Apps and
            Website.
          </p>
          <ol className="lst-lower-alpha">
            <li>
              Cookies (or browser cookies)
              <br />
              WILLERS may obtain information about your general internet usage by using a cookie ﬁle which is stored on
              the hard drive of your computer. Cookies contain information that is transferred to your computerʼs hard
              drive. The information collected may be used to help improve the Apps and Website to deliver a better and
              more personalized service.
              <br />
              <br />
              You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the
              setting of cookies. However, if you select this setting you may be unable to access certain parts of the
              Apps and Website. Unless you have adjusted your browser setting so that it will refuse cookies, the system
              will issue cookies when you log on to the Apps and Website.
            </li>
            <li>
              Third Party Use of Cookies and other Tracking Technologies
              <br />
              Some content or applications on the Website and/or App, including advertisements, are served by third
              parties, including advertisers, ad networks and servers, content providers, and application providers.
              These third parties may use cookies alone or in conjunction with web beacons or other tracking
              technologies to collect information about you when you use our Website and/or App. The information they
              collect may be associated with your personal information or they may collect information, including
              personal information, about your online activities over time and across different websites and other
              online services. They may use this information to provide you with interest-based (behavioral) advertising
              or other targeted content. We do not control these third parties' tracking technologies or how they may be
              used. If you have any questions about an advertisement or other targeted content, you should contact the
              responsible provider directly.
            </li>
          </ol>
          {/* Voice Recording and Video Recording */}
          <h2>Voice Recording and Video Recording</h2>
          <ol className="lst-lower-alpha">
            <li>
              We may record telephone calls with you in order to know the content of your enquiries accurately and deal
              with them accordingly.
            </li>
            <li>
              We may record your images and videos by surveillance camera when you visit our premises, and use these
              images and videos for crime prevention and safety purposes.
            </li>
          </ol>
          {/* Sharing With and Disclosure to Third Parties */}
          <h2>Sharing With and Disclosure to Third Parties</h2>
          <p>
            WILLERS may from time to time and in compliance with all applicable laws on personal data protection, share
            or disclose the personal data to third parties in order to carry out the purposes set out above.
          </p>
          <p>
            WILLERS may share your personal data with our parent company and any other aﬃliate companies{' '}
            <u>including, but not limited to,</u> Community Mobility Inc. (JAPAN), WILLER Inc. (JAPAN), KDDI CORPORATION
            (JAPAN), WILLER VIETNAM (VIETNAM), TOKYO MK Co., Ltd. (JAPAN) and Mineyama TAXI Co., Ltd. (JAPAN) for the
            purposes set out above.
          </p>
          <p>
            We reserve the right to disclose your personal data to our third-party service providers (including any
            third party service providers providing Individual Service in the country or region where you applied for
            the Individual Service, call centre, human resource, data centre or information technology services or which
            host or manage data from our Apps and Website; credit, debit and charge card companies, banks and other
            entities processing payment instructions given by you whether through our Website or otherwise; our lawyers,
            auditors and other professional advisers; debt collection agencies; and any other agents or subcontractors
            acting on our behalf), government or regulatory authorities, to the extent required in the normal course and
            scope of our business in the provision of our services, and where required or permitted by applicable law,
            statute, stock exchange regulation or by-law, regulatory or governmental order or court order.
          </p>
          <p>
            Your personal data may be transferred from Singapore to another country, state and city. When we transfer
            your personal data outside of Singapore, we will take steps to ensure that your personal data continues to
            receive a standard of protection that is at least comparable to that provided under the PDPA. By providing
            us with personal data, you consent that your personal data may be transferred from Singapore to another
            country, state and city under these terms. DO NOT provide any personal data to us if you do not consent to
            your personal data being transferred from Singapore to another country, state or city.
          </p>
          {/* Protection of Personal Data */}
          <h2>Protection of Personal Data</h2>
          <p>
            To safeguard your personal data from unauthorised access, collection, use, disclosure, copying, modiﬁcation,
            disposal or similar risks, we have introduced appropriate administrative, physical and technical measures,
            including but not limited to the specific measures below to secure the storage and transmission of personal
            data by us, and disclosing personal data both internally and to our authorised third party service providers
            and agents only on a need-to-know basis.
          </p>
          <ol className="lst-lower-alpha">
            <li>
              The storage server itself is not connected to the internet, and is only connected to the internet via a
              jump server. A two-step authentication is required to access the storage server from the jump server.
            </li>
            <li>
              Access to the jump server is only accessible from a terminal with a token including an encryption key.
            </li>
            <li>The jump server uses services on AWS such as firewall and threat detection services.</li>
          </ol>
          <p>
            You should be aware, however, that no method of transmission over the Internet or method of electronic
            storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your
            information and are constantly reviewing and enhancing our information security measures. We cannot be held
            responsible for unauthorised or unintended access that is beyond our control.
          </p>
          {/* Other Websites */}
          <h2>Other Websites</h2>
          <p>
            The Apps and Website may, from time to time, contain links to and from other websites. If you follow a link
            to any of these websites, please note that these websites have their own privacy policies and that we do not
            accept any responsibility or liability for these policies. Please check these policies before you submit any
            personal data to these websites.
          </p>
          {/* Retention Limitation */}
          <h2>Retention Limitation</h2>
          <p>
            We keep your personal data only for so long as we need the personal data to fulfil the purposes we collected
            it for, and to satisfy our business and/or legal purposes, including audit, accounting or reporting
            requirements. How long we keep your personal data depends on the nature of the data, for example:
          </p>
          <ol className="lst-lower-alpha">
            <li>
              we will keep personal data for at least the duration of the limitation period for bringing claims if the
              personal data may be required to commence or defend legal proceedings; and
            </li>
            <li>
              personal data collected for use for a limited purpose will generally not be retained for more than five
              (5) year after completion of the purpose.
            </li>
          </ol>
          <p>
            Some information may be retained for longer, for example where we are required to do so by law, or need to
            retain it for the purposes of litigation.
          </p>
          {/* Withdrawing Your Consent */}
          <h2>Withdrawing Your Consent</h2>
          <p>
            The consent that you provide for the collection, use and disclosure of your personal data will remain valid
            until such time it is being withdrawn by you in writing. You may withdraw consent and request us to stop
            using and/or disclosing your personal data for any or all of the purposes listed above by submitting your
            request in the way described in the “Contact” section below. Whilst we respect your decision to withdraw
            your consent, please note that depending on the nature and scope of your request, we may not be in a
            position to continue providing our goods or services to you.
          </p>
          {/* Personal Data Breach */}
          <h2>Personal Data Breach</h2>
          <p>
            We will notify you without undue delay upon becoming aware of any Personal Data breach under the PDPA
            affecting your Personal Data, providing you with sufficient information on such breach and the internal
            procedures to be taken.{' '}
          </p>
          {/* Access and Correction */}
          <h2>Access and Correction</h2>
          <ol className="lst-lower-alpha">
            <li>
              If we are requested by you or the person who has the right to do so, to notify the purposes for use of
              personal data which we retain, disclose such personal data, correct its content, add or delete, suspend
              the use, remove or suspend the provision of your data to third parties, we will deal with your request in
              writing after confirming the content of your request. You may request to access and correct your personal
              data kept by WILLERS in the way described in “Contact” section below.
            </li>
            <li>A member will also be able to update his personal data directly after login in the “Proﬁle”.</li>
            <li>We may be permitted under applicable laws to refuse a request.</li>
          </ol>
          {/* Changes to the Privacy Policy */}
          <h2>Changes to the Privacy Policy</h2>
          <p>
            WILLERS may amend this privacy policy from time to time and will make available the updated policy on the
            Apps and Website. By continuing to access the Apps and Website and/or by continuing to be a member of
            WILLERS, as applicable, you agree to be bound by the terms and conditions of this policy, as amended from
            time to time.
          </p>
          {/* Contact */}
          <h2>Contact</h2>
          <p>
            Questions, comments, complaints and requests regarding this privacy policy are welcome. You may contact our
            customer service at (65) 6245 0491 or email us at pr-info@willers.com.sg or write in to:
          </p>
          <p>Chief Data Oﬃcer 60 Paya Lebar Road #06-32 Paya Lebar Square Singapore 409051</p>
          {/* Governing Law and Submission to Jurisdiction */}
          <h2>Governing Law and Submission to Jurisdiction</h2>
          <p>
            This policy is governed by Singapore law and you agree to irrevocably submit to the exclusive jurisdiction
            of the Singapore courts.
          </p>
          <p>
            Eﬀective date : [19th Oct 2021]
            <br />
            Last updated : [1st Apr 2022]
          </p>

          {/* Chính sách bảo mật */}
          <h1 className="ttl-pagemain" id="vi">
            Chính sách bảo mật
          </h1>
          <p>
            Trong chính sách này, “chúng tôi” hay “WILLERS” là công ty WILLERS Pte Ltd; “người dùng” là bất kì người nào
            đăng ký thông tin cá nhân với chúng tôi để sử dụng dịch vụ của chúng tôi hoặc để thực hiện công việc đều là
            đối tượng điều chỉnh của chính sách này.
          </p>
          <p>
            Việc bảo mật Thông tin cá nhân của người dùng rất quan trọng đối với chúng tôi. WILLERS cam kết bảo vệ, tôn
            trọng quyền riêng tư và sử dụng thông tin cá nhân của người dùng một cách hợp lý tuân theo Đạo luật Bảo vệ
            Thông tin cá nhân của Singapore (Personal Data Protection Act, dưới đây gọi tắt là “PDPA”). Chính sách này
            hiển thị những quy định thiết yếu về cách quản lý và sử dụng thông tin cá nhân (được quy định trong PDPA) mà
            WILLERS thu thập từ người dùng hoặc do người dùng cung cấp cho WILLERS; và quyền của người dùng trong việc
            truy cập, sửa hoặc xóa bỏ thông tin mà chúng tôi lưu trữ. Vui lòng đọc kỹ chính sách này để hiểu đầy đủ các
            cam kết của chúng tôi.
          </p>
          <p>
            Bằng cách cung cấp cho chúng tôi thông tin cá nhân, người dùng đồng ý với các điều khoản của Chính sách bảo
            mậtnày và người dùng đồng ý với việc chúng tôi thu thập, sử dụng, cung cấp cho bên thứ ba và xử lý thông tin
            cá nhân theo các điều khoản này. KHÔNG cung cấp bất kỳ thông tin cá nhân nào cho chúng tôi nếu người dùng
            không chấp nhận Chính sách này.
          </p>
          {/* Thông tin cá nhân */}
          <h2>Thông tin cá nhân</h2>
          <ol className="lst-lower-alpha">
            <li>
              Thông tin cá nhân là gì?
              <br />
              “Thông tin cá nhân” trong chính sách bảo mật này là thông tin được dùng để xác định danh tính một cá nhân.
              Các ví dụ về thông tin cá nhân bao gồm họ tên, địa chỉ, thông tin liên lạc, mã số của các loại thẻ định
              danh cá nhân, thông tin tài chính, địa chỉ IP, thông tin giao dịch dựa trên các hoạt động của người dùng
              trên trang web và ứng dụng của chúng tôi, số điện thoại, địa chỉ email, hình ảnh và bất kỳ thông tin nào
              khác có tính chất cá nhân.
            </li>
            <li>
              Đồng ý cung cấp thông tin cá nhân
              <br />
              Chúng tôi thu thập thông tin cá nhân mà người dùng đồng ý cung cấp cho chúng tôi. Thông tin cá nhân mà
              chúng tôi thu thập phụ thuộc vào mục đích thu thập thông tin cá nhân và những gì người dùng đã chọn để
              cung cấp.
              <br />
              Người dùng có thể chọn không cung cấp thông tin cá nhân cho chúng tôi. Người dùng cũng có quyền rút lại sự
              đồng ý đối với những thông tin người dùng đã cung cấp cho chúng tôi và được chúng tôi thu thập, sử dụng,
              cung cấp cho bên thứ ba; bằng cách liên hệ theo cách được mô tả trong phần “Liên hệ” bên dưới. Tuy nhiên,
              trong trường hợp này, nếu chúng tôi không thể sử dụng các thông tin cá nhân cần thiết, chúng tôi có thể
              không thực hiện được các mục đích mà chúng tôi yêu cầu thông tin cá nhân, bao gồm cả việc xử lý các giao
              dịch của người dùng hoặc cung cấp cho người dùng các sản phẩm và dịch vụ mà người dùng yêu cầu.
            </li>
            <li>
              Cung cấp thông tin cá nhân của người thứ ba
              <br />
              Trong một số trường hợp nhất định, người dùng cũng có thể cung cấp cho chúng tôi thông tin cá nhân của
              người thứ ba, không phải là người dùng. Nếu người dùng cung cấp thông tin cá nhân của người không phải là
              người dùng, người dùng phải đảm bảo rằng người dùng đã thông báo cho người đó về mục đích mà chúng tôi thu
              thập thông tin cá nhân và người đó đã đồng ý với mục đích sử dụng, phương thức sử dụng được quy định trong
              chính sách này, để đảm bảo chúng tôi đã nhận được sự chấp thuận và đồng ý cho tất cả các điều khoản.
              <br />
              Đối với thông tin cá nhân được cung cấp cho chúng tôi theo điều khoản này, người dùng đồng ý không gây tổn
              hại cho chúng tôi và bồi thường mọi thiệt hại do bất kì khiếu nại, tranh chấp nào của người thứ ba liên
              quan đến việc chúng tôi thu thập, sử dụng và tiết lộ thông tin cá nhân theo các điều khoản của Chính sách
              quyển riêng tư này.
            </li>
            <li>
              Đảm bảo tính đầy đủ và chính xác của thông tin cá nhân
              <br />
              Người dùng có trách nhiệm đảm bảo rằng mọi thông tin cá nhân mà người dùng cung cấp cho chúng tôi là trung
              thực, chính xác và đầy đủ. Tương tự, người dùng có trách nhiệm đảm bảo rằng mọi thông cá nhân mà người
              dùng cung cấp cho chúng tôi là trung thực, chính xác và đầy đủ khi người dùng thay đổi thông tin cá nhân.
            </li>
          </ol>
          <h2>Phương thức thu thập thông tin cá nhân</h2>
          <ol className="lst-lower-alpha">
            <li>
              Thông tin cá nhân mà người dùng cung cấp
              <br />
              Chúng tôi thu thập thông tin cá nhân của người dùng liên quan đến việc cung cấp dịch vụ hoặc thực hiện
              nghiệp vụ của chúng tôi. Chúng tôi có thể thu thập thông tin trực tiếp hoặc gián tiếp thông qua nhiều
              phương thức khác nhau, như trong những trường hợp được liệt kê dưới đây:
              <ol className="lst-lower-roman">
                <li>Người dùng trực tiếp cung cấp thông tin cá nhân cho chúng tôi;</li>
                <li>
                  Người dùng cho phép chúng tôi lấy thông tin cá nhân của người dùng từ một bên thứ ba (bao gồm trường
                  hợp người dùng đồng ý với chính sách bảo mật của công ty khác, trong đó có điều khoản về việc cung cấp
                  cho bên thứ ba);
                </li>
                <li>Người dùng đăng ký tài khoản người dùng trên Website và/ hoặc App của chúng tôi;</li>
                <li>Người dùng tải về hoặc sử dụng Website và/ hoặc App và các dịch vụ của chúng tôi;</li>
                <li>
                  Người dùng giao dịch với chúng tôi, liên hệ với chúng tôi hoặc yêu cầu chúng tôi liên hệ với người
                  dùng thông qua các kênh liên lạc khác nhau, ví dụ: thông qua các nền tảng mạng xã hội, nền tảng nhắn
                  tin, gặp mặt trực tiếp, gọi điện thoại, email, fax và thư;
                </li>
                <li>Người dùng có yêu cầu được đăng ký email hoặc được thêm vào danh sách gửi thư của chúng tôi;</li>
                <li>
                  Hình ảnh của người dùng được ghi lại qua camera CCTV khi người dùng ở trong khuôn viên của chúng tôi;
                </li>
                <li>
                  Hình ảnh của người dùng được chúng tôi hoặc đại diện của chúng tôi chụp ảnh hoặc quay video khi người
                  dùng tham dự các sự kiện do chúng tôi tổ chức;
                </li>
                <li>Khi người dùng tham gia hoặc đăng ký tham gia các sự kiện do chúng tôi tổ chức; và/ hoặc</li>
                <li>
                  Chúng tôi tìm kiếm thông tin về người dùng và thấy thông tin cá nhân của người dùng trong mối liên hệ
                  giữa người dùng với chúng tôi, ví dụ: nếu người dùng là nhà đầu tư hoặc cổ đông của WILLERS.
                </li>
              </ol>
            </li>
            <li>
              Thông tin cá nhân được cung cấp bởi bên thứ ba
              <br />
              Tùy thuộc vào quan hệ giao dịch hoặc hợp đồng giữa người dùng và chúng tôi, chúng tôi có thể lấy thông tin
              cá nhân của người dùng từ các nguồn của bên thứ ba (bao gồm, nhưng không giới hạn ở những nguồn sau):
              <ol className="lst-lower-roman">
                <li>
                  Khi được các đối tác kinh doanh của chúng tôi, như các bên thứ ba cung cấp các dịch vụ quảng cáo,
                  marketing và khuyến mại cho chúng tôi cung cấp;
                </li>
                <li>
                  Khi được thành viên trong gia đình người dùng hoặc người dùng bè của người dùng – những người thay mặt
                  người dùng cung cấp thông tin cá nhân của người dùng cho chúng tôi; và/ hoặc
                </li>
                <li>Khi được cơ quan nhà nước hoặc các cơ quan công quyền khác cung cấp.</li>
              </ol>
            </li>
          </ol>
          {/* Mục đích thu thập, sử dụng thông tin cá nhân
           */}
          <h2>Mục đích thu thập, sử dụng thông tin cá nhân</h2>
          <ol className="lst-lower-alpha">
            <li>
              Thông tin cá nhân của người dùng hoặc thành viên
              <br />
              WILLERS có thể thu thập, sử dụng, tiết lộ và xử lý thông tin cá nhân của người dùng cho các mục đích dưới
              đây:
              <ol className="lst-lower-roman">
                <li>Xác nhận thông tin đăng ký thành viên, xử lý yêu cầu và đăng ký;</li>
                <li>
                  Xác định người dùng để cung cấp dịch vụ phù hợp và hỗ trợ thích hợp cho người dùng hoặc thành viên sử
                  dụng dịch vụ của chúng tôi;
                </li>
                <li>
                  Để thực hiện các yêu cầu thanh toán, liên hệ và thông báo cần thiết về các dịch vụ mà người dùng sử
                  dụng;
                </li>
                <li>
                  Để cung cấp thông tin cần thiết cho việc sử dụng các dịch vụ cá nhân (“Individual Service”) giữa người
                  dùng và các nhà cung cấp khác (là những nhà cung cấp dịch vụ trên website hoặc ứng dụng của chúng
                  tôi);
                </li>
                <li>
                  Để phân tích việc sử dụng các sản phẩm, dịch vụ, trang web hoặc ứng dụng của chúng tôi nhằm nâng cao
                  chất lượng dịch vụ;
                </li>
                <li>
                  Để cung cấp thông tin quảng cáo, khuyến mại và thu thập thông tin thông qua khảo sát mức độ hài lòng
                  của khách hàng liên quan đến sản phẩm, dịch vụ của chúng tôi;
                </li>
                <li>
                  Để cung cấp thông tin quảng cáo, khuyến mại và thu thập thông tin thông qua khảo sát mức độ hài lòng
                  của khách hàng liên quan đến sản phẩm, dịch vụ của bên thứ ba;
                </li>
                <li>Để phản hồi các thắc mắc và yêu cầu từ người dùng;</li>
                <li>Để thông báo về thông tin tuyển dụng cũng như cách thức ứng tuyển vào Willers đến các ứng viên;</li>
                <li>
                  Chia sẻ thông tin cá nhân của người dùng với các công ty ngoài Singapore (ví dụ: bao gồm, nhưng không
                  giới hạn công ty WILLERS PTE. LTD. của Singapore, Community Mobility Inc. (Nhật Bản), WILLER Inc.
                  (Nhật Bản), KDDI CORPORATION (Nhật Bản), WILLER VIETNAM (Việt Nam), TOKYO MK Co., Ltd. (Nhật Bản) and
                  Mineyama TAXI Co., Ltd. (Nhật Bản) để thực hiện các dịch vụ của chúng tôi;
                </li>
                <li>Cho các mục đích có liên quan và/ hoặc ngẫu nhiên khác.</li>
              </ol>
              <li>
                WILLERS sẽ chỉ sử dụng một cách hợp pháp thông tin được thu thập từ người dùng hoặc thành viên cho các
                mục đích được nêu trong chính sách này cùng với các điều khoản và điều kiện thành viên khi người dùng là
                thành viên của WILLERS.
              </li>
            </li>
            <li>
              Sử dụng cho các mục đích khác, theo quy định của pháp luật
              <br />
              Nếu được PDPA yêu cầu hoặc cho phép, chúng tôi có thể thu thập, sử dụng và tiết lộ thông tin cá nhân của
              người dùng cho các mục đích khác với những mục đích đã nêu ở trên mà không cần được sự đồng ý trước của
              người dùng.
            </li>
            <li>
              Liên hệ với người dùng
              <br />
              Chúng tôi có thể liên hệ với người dùng và cung cấp thông tin liên quan đến các mục đích nêu tại điều A ở
              trên bao gồm nhưng không giới hạn ở, e-mail, thư gửi theo đường bưu điện, SMS, cuộc gọi điện thoại và các
              phương thức khác. Chúng tôi sẽ tuân thủ PDPA khi thực hiện các liên hệ này.
              <br />
              Nếu người dùng không muốn nhận bất kỳ liên hệ hoặc thông tin nào từ chúng tôi hoặc muốn hạn chế cách thức
              chúng tôi có thể liên hệ hoặc gửi thông tin cho người dùng, người dùng có thể liên hệ với chúng tôi theo
              cách được mô tả trong phần “Liên hệ” bên dưới.
            </li>
          </ol>
          {/* Công nghệ thu thập dữ liệu tự động */}
          <h2>Công nghệ thu thập dữ liệu tự động</h2>
          <p>
            Trang web và/ hoặc ứng dụng của chúng tôi có thể chứa một số công nghệ nhất định thu thập dữ liệu theo cách
            được mô tả trong Chính sách bảo mật này (dưới đây gọi là “công nghệ thu thập dữ liệu tự động). Nếu người dùng
            không đồng ý cho chúng tôi thu thập thông của người dùng thông qua công nghệ thu thập dữ liệu tự động, người
            dùng có thể ngưng sử dụng trang web và/ hoặc ứng dụng của chúng tôi, hoặc cách khác là người dùng cài đặt
            tắt công nghệ này trong trường hợp có thể tắt.
          </p>
          <p>
            Chúng tôi và người cấp phép những ứng dụng mà chúng tôi sử dụng (dưới đây gọi là “chúng tôi”), có thể sử
            dụng địa chỉ IP của người dùng để giúp chẩn đoán sự cố với máy chủ của chúng tôi và để quản lý các ứng dụng
            và trang web.
          </p>
          <ol className="lst-lower-alpha">
            <li>
              Cookies (hay cookies của trình duyệt)
              <br />
              WILLERS có thể lấy thông tin về việc sử dụng internet chung của người dùng bằng cách sử dụng một tệp
              cookie được lưu trữ trong ổ cứng máy tính của người dùng. Cookie chứa thông tin được chuyển đến ổ cứng của
              người dùng. Chúng tôi có thể sử dụng thông tin thu thập được để cải thiện các ứng dụng và trang web của
              mình cũng như cung cấp cho người dùng các dịch vụ tốt hơn.
              <br />
              Các trình duyệt phổ biến sẽ tự động phát hành cookie khi người dùng đăng nhập vào một ứng dụng hoặc trang
              web, trừ trường hợp người dùng đã cài đặt từ chối chấp nhận cookie trên thiết bị của mình. (Người dùng có
              thể từ chối chấp nhận cookie bằng cách tự thiết lập cài đặt của mình). Tuy nhiên, trong trường hợp người
              dùng từ chối chấp nhận cookie, người dùng có thể không truy cập được vào một phần hoặc toàn bộ Ứng dụng và
              Trang web mà chúng tôi cung cấp.
            </li>
            <li>
              Sử dụng cookie và các công nghệ theo dõi tự động khác của bên thứ ba
              <br />
              Một số nội dung hoặc ứng dụng trên Trang web và/ hoặc Ứng dụng, bao gồm cả quảng cáo, được cung cấp bởi
              các bên thứ ba, bao gồm nhà quảng cáo, mạng quảng cáo (ad network) và máy chủ quảng cáo (ad server), nhà
              cung cấp nội dung và nhà cung cấp ứng dụng. Các bên thứ ba này có thể sử dụng cookie riêng hoặc kết hợp
              với web beacon hoặc các công nghệ theo dõi khác để thu thập thông tin về người dùng khi người dùng sử dụng
              Trang web và/ hoặc Ứng dụng của chúng tôi. Thông tin họ thu thập có thể được liên kết với thông tin cá
              nhân của người dùng hoặc họ có thể thu thập thông tin, bao gồm thông tin cá nhân, về các hoạt động trực
              tuyến của người dùng theo thời gian trên các trang web khác nhau và các dịch vụ trực tuyến khác. Họ có thể
              sử dụng thông tin này để cung cấp quảng cáo cho người dùng dựa trên sở thích (hành vi) hoặc nội dung mục
              tiêu khác. Chúng tôi không kiểm soát các công nghệ theo dõi của các bên thứ ba này hoặc cách các bên thứ
              ba này sử dụng chúng. Nếu người dùng có bất kỳ câu hỏi nào về quảng cáo hoặc nội dung mục tiêu khác, người
              dùng nên liên hệ trực tiếp với nhà cung cấp có trách nhiệm.
            </li>
          </ol>
          {/* Ghi âm giọng nói và ghi hình video */}
          <h2>Ghi âm giọng nói và ghi hình video</h2>
          <ol className="lst-lower-alpha">
            <li>
              Chúng tôi có thể ghi âm các cuộc điện thoại với người dùng để nắm bắt chính xác nội dung các thắc mắc của
              người dùng và giải quyết chúng một cách phù hợp.
            </li>
            <li>
              We may record your images and videos by surveillance camera when you visit our premises, and use these
              images and videos for crime prevention and safety purposes.
            </li>
          </ol>
          {/* Sharing With and Disclosure to Third Parties */}
          <h2>Chia sẻ với và tiết lộ với bên thứ ba</h2>
          <p>
            WILLERS trong một số trường hợp có thể chia sẻ hoặc tiết lộ thông tin cá nhân cho các bên thứ ba để thực
            hiện các mục đích đã nêu ở trên, tuân theo tất cả các luật hiện hành về bảo vệ thông tin cá nhân.
          </p>
          <p>
            Chúng tôi có thể chia sẻ thông tin cá nhân của người dùng với tất cả những công ty liên kết của chúng tôi,
            bao gồm, nhưng không giới hạn ở những công ty sau: Community Mobility Inc. (Nhật Bản), WILLER Inc. (Nhật
            Bản), WILLER VIETNAM (Việt Nam) để thực hiện các mục đích đã nêu ở trên.
          </p>
          <p>
            Chúng tôi có quyền tiết lộ thông tin cá nhân của người dùng cho các nhà cung cấp dịch vụ bên thứ ba của
            chúng tôi (bao gồm bất kỳ nhà cung cấp dịch vụ bên thứ ba nào cung cấp Dịch vụ Cá nhân ở quốc gia hoặc khu
            vực mà người dùng đã đăng ký Dịch vụ Cá nhân, trung tâm cuộc gọi, bộ phận nhân sự, trung tâm dữ liệu hoặc
            dịch vụ công nghệ thông tin hoặc lưu trữ hoặc quản lý dữ liệu từ Ứng dụng và Trang web của chúng tôi; các
            công ty thẻ tín dụng, thẻ ghi nợ và thẻ thanh toán tiêu dùng (charge card), ngân hàng và các tổ chức khác
            người dùng sử dụng để xử lý các thanh toán thông qua Trang web của chúng tôi hay qua cách khác; luật sư,
            kiểm toán viên và cố vấn chuyên nghiệp khác của chúng tôi; đại lý thu nợ; và bất kỳ đại lý hoặc nhà thầu phụ
            nào khác thay mặt cho chúng tôi), chính phủ hoặc cơ quan quản lý có thẩm quyền, trong phạm vi được yêu cầu,
            nằm trong quy trình và phạm vi kinh doanh cung cấp dịch vụ thông thường của chúng tôi và khi được yêu cầu
            hoặc cho phép bởi luật hiện hành, quy chế, quy định về sàn giao dịch chứng khoán hoặc theo luật của chính
            phủ hoặc lệnh của tòa án.
          </p>
          <p>
            Thông tin cá nhân của người dùng có thể được chuyển từ Singapore sang một quốc gia, khu vực và thành phố
            khác. Khi chúng tôi chuyển thông tin cá nhân của người dùng ra bên ngoài Singapore, chúng tôi sẽ thực hiện
            các biện pháp để đảm bảo rằng thông tin cá nhân của người dùng tiếp tục được bảo vệ với tiêu chuẩn tối thiểu
            là bằng tiêu chuẩn PDPA. Bằng cách cung cấp cho chúng tôi thông tin cá nhân, người dùng đồng ý rằng thông
            tin cá nhân của người dùng có thể được chuyển từ Singapore sang một quốc gia, khu vực và thành phố khác theo
            điều khoản này. KHÔNG cung cấp bất kỳ thông tin cá nhân nào cho chúng tôi nếu người dùng không đồng ý với
            việc thông tin cá nhân của người dùng sẽ được chuyển từ Singapore sang một quốc gia, khu vực hoặc thành phố
            khác.
          </p>
          {/* Bảo vệ thông tin cá nhân */}
          <h2>Bảo vệ thông tin cá nhân</h2>
          <p>
            Để bảo vệ thông tin cá nhân của người dùng khỏi bị truy cập, thu thập, sử dụng, tiết lộ, sao chép, sửa đổi,
            xử lý trái phép hoặc các rủi ro tương tự, chúng tôi đã sử dụng các biện pháp quản lý hành chính, vật lý và
            kỹ thuật thích hợp, bao gồm nhưng không giới hạn ở các biện pháp cụ thể dưới đây, để đảm bảo việc lưu trữ và
            truyền thông tin cá nhân của chúng tôi. Việc tiết lộ thông tin cá nhân cả trong nội bộ cũng cho các đại lý
            và nhà cung cấp dịch vụ bên thứ ba được ủy quyền của chúng tôi, chỉ được thực hiện khi cần thiết phục vụ cho
            những mục đích đã nêu ở trên.
          </p>
          <ol className="lst-lower-alpha">
            <li>
              Bản thân máy chủ lưu trữ không được kết nối với internet mà chỉ được kết nối với internet thông qua máy
              chủ trung gian jump server. Cần xác thực hai bước để truy cập máy chủ lưu trữ từ jump server.
            </li>
            <li>
              Chỉ có thể truy cập đến máy chủ trung gian jump server từ một thiết bị đầu cuối dùng token có chứa một
              khóa mã hóa.
            </li>
            <li>
              Máy chủ trung gian jump server sử dụng các dịch vụ trên AWS như tường lửa và dịch vụ phát hiện mối đe dọa.
            </li>
          </ol>
          <p>
            Tuy nhiên, người dùng nên biết rằng, không có phương thức truyền qua Internet hoặc phương thức lưu trữ điện
            tử nào là hoàn toàn an toàn. Mặc dù không thể đảm bảo an toàn hoàn toàn, chúng tôi luôn cố gắng bảo vệ chặt
            chẽ thông tin của người dùng và liên tục xem xét, nâng cao các biện pháp bảo mật thông tin của chúng tôi.
            <br />
            Chúng tôi không chịu trách nhiệm về các truy cập trái phép hoặc truy cập không chủ định do người dùng không
            có đủ các biện pháp bảo mật, nằm ngoài tầm kiểm soát của chúng tôi.
          </p>
          {/* Trang chủ và trang web của bên thứ ba */}
          <h2>Trang chủ và trang web của bên thứ ba</h2>
          <p>
            Đôi khi, Ứng dụng và Trang web của chúng tôi có thể chứa các liên kết dẫn đến và liên kết đi từ các trang
            web khác. Nếu người dùng đi theo liên kết đến bất kỳ trang web nào trong số này, xin lưu ý rằng các trang
            web này có chính sách bảo mật của riêng họ và chúng tôi không chịu bất kỳ trách nhiệm hoặc nghĩa vụ pháp lý
            nào đối với các chính sách này. Vui lòng kiểm tra các chính sách này trước khi người dùng gửi bất kỳ thông
            tin cá nhân nào đến các trang web này.
          </p>
          {/* Thời hạn lưu trữ thông tin cá nhân */}
          <h2>Thời hạn lưu trữ thông tin cá nhân</h2>
          <p>
            Chúng tôi chỉ lưu trữ thông tin cá nhân của người dùng khi cần thiết để thực hiện các mục đích mà thông tin
            đó được thu thập và để đáp ứng yêu cầu kinh doanh của chúng tôi cũng như các yêu cầu pháp lý, bao gồm yêu
            cầu về kiểm toán, kế toán hoặc báo cáo. Thời hạn chúng tôi lưu trữ thông tin cá nhân của người dùng tùy
            thuộc vào mục đích mà thông tin đó được lưu giữ. Ví dụ:
          </p>
          <ol className="lst-lower-alpha">
            <li>
              Nếu thông tin cá nhân có khả năng được yêu cầu trong thủ tục pháp lý (bao gồm, nhưng không giới hạn ở kiện
              tụng, trọng tài và hòa giải), thì thời hạn lưu trữ là thời gian thực hiện thủ tục pháp lý có thể phát
              sinh; và
            </li>
            <li>
              Ngoài các trường hợp trên, thời hạn là lưu trữ là cho đến khi hoàn thành mục đích sử dụng hoặc không quá 5
              năm kể từ khi hoàn thành mục đích sử dụng.
            </li>
          </ol>
          <p>
            Ngoài những điều đã nêu trên, chúng tôi có thể lưu trữ thông tin cá nhân của người dùng trong một thời gian
            dài hơn, ví dụ, trong trường hợp chúng tôi cần lưu trữ thông tin đó theo luật hoặc lưu trữ cho mục đích kiện
            tụng.
          </p>
          {/* Người dùng rút lại chấp thuận */}
          <h2>Người dùng rút lại chấp thuận</h2>
          <p>
            Sự đồng ý của người dùng đối với việc thu thập, sử dụng và tiết lộ dữ thông tin cá nhân có hiệu lực cho đến
            thời điểm người dùng rút lại bằng văn bản (bao gồm cả email). Người dùng có thể rút lại sự đồng ý và yêu cầu
            chúng tôi ngừng sử dụng và/ hoặc tiết lộ thông tin cá nhân của người dùng cho bất kỳ hoặc tất cả các mục
            đích được liệt kê ở trên bằng cách gửi yêu cầu cho chúng tôi theo cách được mô tả trong phần “Liên hệ” bên
            dưới. Chúng tôi tôn trọng quyết định rút lại sự đồng ý của người dùng. Tuy nhiên, xin vui lòng đồng ý rằng,
            tùy thuộc vào tính chất và phạm vi yêu cầu của người dùng, chúng tôi có thể không tiếp tục cung cấp hàng hóa
            hoặc dịch vụ của chúng tôi cho người dùng.
          </p>
          {/* Xử lý khi rò rỉ thông tin cá nhân */}
          <h2>Xử lý khi rò rỉ thông tin cá nhân</h2>
          <p>
            Chúng tôi sẽ thông báo cho người dùng ngay lập tức khi biết bất kỳ vi phạm thông tin cá nhân nào ảnh hưởng
            đến thông tin cá nhân của người dùng theo quy định của PDPA và cung cấp cho người dùng đầy đủ thông tin về
            vi phạm đó cũng như các thủ tục nội bộ mà chúng tôi thực hiện.
          </p>
          {/* Quyền truy cập và chỉnh sửa */}
          <h2>Quyền truy cập và chỉnh sửa</h2>
          <ol className="lst-lower-alpha">
            <li>
              Người dùng hoặc người đại diện của người dùng có thể yêu cầu chúng tôi thông báo mục đích sử dụng thông
              tin cá nhân mà chúng tôi lưu trữ, tiết lộ thông tin cá nhân đó, chỉnh sửa nội dung, thêm hoặc xóa, tạm
              ngừng sử dụng, xóa hoặc tạm ngừng cung cấp thông tin của người dùng cho bên thứ ba theo cách được mô tả
              trong phần “Liên hệ” bên dưới. Trong trường hợp này, sau khi xác nhận rằng nội dung yêu cầu dựa trên
              nguyện vọng của chính người dùng, chúng tôi sẽ giải quyết yêu cầu của người dùng bằng văn bản.
            </li>
            <li>
              Người dùng là thành viên của chúng tôi có thể trực tiếp cập nhật thông tin cá nhân của mình từ mục “thông
              tin thành viên” sau khi đăng nhập.
            </li>
            <li>Mặc dù vậy, chúng tôi có thể được luật hiện hành cho phép từ chối yêu cầu.</li>
          </ol>
          {/* Sửa đổi chính sách quyền riêng tư */}
          <h2>Sửa đổi chính sách quyền riêng tư</h2>
          <p>
            Chúng tôi có thể sửa đổi Chính sách này bất kỳ lúc nào và áp dụng cho các Ứng dụng và Trang web của chúng
            tôi. Bằng cách tiếp tục truy cập Ứng dụng và Trang web, hoặc tiếp tục là thành viên của các dịch vụ mà chúng
            tôi cung cấp, người dùng đồng ý chấp nhận các điều khoản và điều kiện của chính sách này khi được sửa đổi.
          </p>
          {/* Liên hệ */}
          <h2>Liên hệ</h2>
          <p>
            Nếu người dùng có bất kỳ câu hỏi, góp ý, khiếu nại và yêu cầu nào liên quan đến chính sách bảo mật này, xin
            vui lòng liên hệ bộ phận Dịch vụ Khách hàng của chúng tôi theo số (65) 6245 0491 hoặc gửi email đến địa chỉ
            pr-info@willers.com.sg hoặc gửi thư đến:
          </p>
          <p>Chief Data Officer 60 Paya Lebar Road #06-32 Paya Lebar Square Singapore 409051</p>
          {/* Luật áp dụng và thỏa thuận về quyền tài phán duy nhất */}
          <h2>Luật áp dụng và thỏa thuận về quyền tài phán duy nhất</h2>
          <p>
            Chính sách này chịu sự điều chỉnh của luật pháp Singapore và người dùng đồng ý tuân theo quyền tài phán duy
            nhất của tòa án Singapore.
          </p>
          <p>
            Ngày hiệu lực: 19/10/2021
            <br />
            Cập nhật mới nhất: 01/04/2022
          </p>

          <h1 className="ttl-pagemain" id="malay">
            For Malaysia Users
          </h1>
          {/* Privacy Policy */}
          <h1 className="ttl-pagemain">Privacy Policy</h1>
          <p>
            In this policy, “we”, “us”, “our” or “WILLERS” means WILLERS Pte Ltd, “you”, “your”, “yours”, “user”, “users”,
            “member” and “members” means the persons to whom this policy applies.
          </p>
          <p>
            The privacy of your Personal Data is important to us and WILLERS is committed to properly protecting and
            respecting your privacy and personal data to comply with the Personal Data Protection Act of Malaysia and the
            Personal Data Protection Act of Malaysia (“PDPA”). This policy sets out the basis on which any personal data (as
            deﬁned in the PDPA) WILLERS collects from you, or that you provide to WILLERS, will be stored and used and
            your right to access and correct your information in accordance with this policy. Please read this policy carefully to
            understand WILLERSʼs views and practices regarding your personal data and how WILLERS will treat it.
          </p>
          <p>
            By providing us with personal data, you agree to the terms of this Privacy Policy and you consent to our collection,
            use, disclosure and processing of personal data on these terms. DO NOT provide any personal data to us if you do
            not accept this Privacy Policy.
          </p>
          {/* Personal Data */}
          <h2>Personal Data</h2>
          <ol className="lst-lower-alpha">
            <li>
              What is Personal Data
              <br />
              “Personal data” is data that can be used to identify a natural person. Examples of personal data include
              name, address, contact details, identification numbers, financial information, IP address, transactional
              information based on your activities on our website and applications, telephone numbers, email address,
              images, and any other information of a personal nature.
            </li>
            <li>
              Voluntary provision of personal data
              <br />
              We collect personal data that you voluntarily provide to us. What personal data we collect depends on
              the purposes for which the personal data is collected and what you have chosen to provide.
              <br />
              <br />
              You can choose not to provide us with personal data. You also have the right to withdraw your consent for
              us to continue collecting, using, disclosing and processing your personal data, by contacting in the way
              described in the “Contact” section below. However, if you do so, it may not be possible for us to fulfil the
              purposes for which we require the personal data, including processing your transactions or providing you
              with the products and services that you require.
            </li>
            <li>
              Providing personal data belonging to others
              <br />
              In certain circumstances, you may also provide us with personal data of persons other than yourself. If
              you do so, you warrant that you have informed him/her of the purposes for which we are collecting his/her
              personal data and that he/she has consented to your disclosure of his/her personal data to us for those
              purposes. You agree to indemnify and hold us harmless from and against any and all claims by such
              individuals relating to our collection, use and disclosure of such personal data in accordance with the
              terms of this Privacy Policy.
            </li>
            <li>
              Accuracy and completeness of personal data
              <br />
              You are responsible for ensuring that all personal data that you provide to us is true, accurate and
              complete. You are responsible for informing us of any changes to your personal data.
            </li>
          </ol>
          <h2>Collection of Personal Data</h2>
          <ol className="lst-lower-alpha">
            <li>
              Personal data you provide
              <br />
              We collect personal data that is relevant to our relationship with you. We may collect your personal data
              directly or indirectly through various channels, such as when:
              <ol className="lst-lower-roman">
                <li>you provide us with your personal data for whatever reasons;</li>
                <li>you authorise us to obtain your personal data from a third party;</li>
                <li>you register for a user account on our Website and/or App;</li>
                <li>you download or use our Website and/or App and services;</li>
                <li>
                  you transact with us, contact us or request that we contact you through various communication
                  channels, for example, through social media platforms, messenger platforms, face-to-face meetings,
                  telephone calls, emails, fax and letters;
                </li>
                <li>you request to be included in an email or our mailing list;</li>
                <li>your images are captured via CCTV cameras while you are within our premises;</li>
                <li>
                  your images are captured via photographs or videos taken by us or our representatives when you attend
                  events organised by us;
                </li>
                <li>when you attend events or functions organised by us; and/or</li>
                <li>
                  we seek information about you and receive your personal data in connection with your relationship with
                  us, for example, if you are an investor or shareholder of WILLERS.
                </li>
              </ol>
            </li>
            <li>
              Personal data provided by others
              <br />
              Depending on your relationship with us, we may also collect your personal data from third party sources,
              for example:
              <ol className="lst-lower-roman">
                <li>
                  from our business partners such as third parties providing advertising, marketing and promotional
                  services to us;
                </li>
                <li>from your family members or friends who provide your personal data to us on your behalf; and/or</li>
                <li>from public agencies or other public sources.</li>
              </ol>
            </li>
          </ol>
          {/* Purposes for Collection, Use of Disclosure of Personal Data */}
          <h2>Purposes for Collection, Use of Disclosure of Personal Data</h2>
          <ol className="lst-lower-alpha">
            <li>
              Personal Data of Users or Members
              <br />
              WILLERS may collect, use, disclose and process your personal data for purposes connected or relevant to our
              business, to make your relationship with us. Such purposes would include:
              <ol className="lst-lower-roman">
                <li>reviewing, processing and registration of membership application;</li>
                <li>
                  identifying the user for our service and providing appropriate assistance to users or members using
                  our service;
                </li>
                <li>
                  communication with users and members to implement proper membership management such as billing,
                  announcement and notiﬁcation;
                </li>
                <li>
                  communication with third party service providers providing individual services (“Individual Service”)
                  that our members and users have applied for through WILLERSʼs mobile applications and websites
                  (respectively “Apps” and “Websites”);
                </li>
                <li>analysis for facilitating and improving our services;</li>
                <li>
                  provision of promotional information about Individual Services and collection of information through
                  customer survey;
                </li>
                <li>
                  provision of promotional information for third partyʼs products and collection of information for
                  questionnaire surveys for third party;
                </li>
                <li>responding properly to enquiry and request;</li>
                <li>communicating with, consider and notice to applicant for WILLERSʼs staﬀ recruitment;</li>
                <li>
                  providing your Personal Data to organizations outside of Malaysia, including, but not
                  limited to, Community Mobility Inc. (JAPAN), WILLER Inc. (JAPAN), KDDI
                  CORPORATION (JAPAN), WILLER VIETNAM (VIETNAM), TOKYO MK Co., Ltd.
                  (JAPAN), Mineyama TAXI Co., Ltd. (JAPAN), and Badanbas Coach Sdn Bhd
                  (MALAYSIA).
                </li>
                <li>and other related and/or incidental to above purposes and reasons.</li>
              </ol>
              WILLERS will only use such information collected from users or members for the purposes and reasons set
              out in this policy and the terms and conditions that govern your membership with WILLERS.
            </li>
            <li>
              Use Permitted under Applicable Laws
              <br />
              We may also collect, use, disclose and process your personal data for other purposes, without your
              knowledge or consent, where this is required or permitted by law.
            </li>
            <li>
              Contacting You
              <br />
              With respect to sending marketing messages, such messages may be sent to you in various modes including
              but not limited to electronic mail, direct mailers, short message service, telephone calls, facsimile and
              other mobile messaging services. In doing so, we will comply with the PDPA.
              <br />
              <br />
              If you do not wish to receive any communication or information from us, or wish to restrict the manner by
              which we may contact or send you information, you may contact us in the way described in the “Contact”
              section below.
            </li>
          </ol>
          {/* Automated Data Collection Technologies */}
          <h2>Automated Data Collection Technologies</h2>
          <p>
            Our Website and/or App may contain certain technologies that collect data in the manner described in this
            Privacy Policy. You should not use our Website and/or App if you do not agree to having your data collected
            through such means. Alternatively, you should disable these technologies where it is possible to do so.
          </p>
          <p>
            WILLERS may use your IP address to help diagnose problems with our server, and to administer the Apps and
            Website.
          </p>
          <ol className="lst-lower-alpha">
            <li>
              Cookies (or browser cookies)
              <br />
              WILLERS may obtain information about your general internet usage by using a cookie ﬁle which is stored on
              the hard drive of your computer. Cookies contain information that is transferred to your computerʼs hard
              drive. The information collected may be used to help improve the Apps and Website to deliver a better and
              more personalized service.
              <br />
              <br />
              You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the
              setting of cookies. However, if you select this setting you may be unable to access certain parts of the
              Apps and Website. Unless you have adjusted your browser setting so that it will refuse cookies, the system
              will issue cookies when you log on to the Apps and Website.
            </li>
            <li>
              Third Party Use of Cookies and other Tracking Technologies
              <br />
              Some content or applications on the Website and/or App, including advertisements, are served by third
              parties, including advertisers, ad networks and servers, content providers, and application providers.
              These third parties may use cookies alone or in conjunction with web beacons or other tracking
              technologies to collect information about you when you use our Website and/or App. The information they
              collect may be associated with your personal information or they may collect information, including
              personal information, about your online activities over time and across different websites and other
              online services. They may use this information to provide you with interest-based (behavioral) advertising
              or other targeted content. We do not control these third parties' tracking technologies or how they may be
              used. If you have any questions about an advertisement or other targeted content, you should contact the
              responsible provider directly.
            </li>
          </ol>
          {/* Voice Recording and Video Recording */}
          <h2>Voice Recording and Video Recording</h2>
          <ol className="lst-lower-alpha">
            <li>
              We may record telephone calls with you in order to know the content of your enquiries accurately and deal
              with them accordingly.
            </li>
            <li>
              We may record your images and videos by surveillance camera when you visit our premises, and use these
              images and videos for crime prevention and safety purposes.
            </li>
          </ol>
          {/* Sharing With and Disclosure to Third Parties */}
          <h2>Sharing With and Disclosure to Third Parties</h2>
          <p>
            WILLERS may from time to time and in compliance with all applicable laws on personal data protection, share
            or disclose the personal data to third parties in order to carry out the purposes set out above.
          </p>
          <p>
            WILLERS may share your personal data with our parent company and any other aﬃliate companies{' '}
            <u>including, but not limited to,</u> Community Mobility Inc. (JAPAN), WILLER Inc. (JAPAN), KDDI CORPORATION (JAPAN),
            WILLER VIETNAM (VIETNAM), TOKYO MK Co., Ltd. (JAPAN), Mineyama TAXI Co., Ltd. (JAPAN), and
            Badanbas Coach Sdn Bhd (MALAYSIA) for the purposes set out above.
          </p>
          <p>
            We reserve the right to disclose your personal data to our third-party service providers (including any
            third party service providers providing Individual Service in the country or region where you applied for
            the Individual Service, call centre, human resource, data centre or information technology services or which
            host or manage data from our Apps and Website; credit, debit and charge card companies, banks and other
            entities processing payment instructions given by you whether through our Website or otherwise; our lawyers,
            auditors and other professional advisers; debt collection agencies; and any other agents or subcontractors
            acting on our behalf), government or regulatory authorities, to the extent required in the normal course and
            scope of our business in the provision of our services, and where required or permitted by applicable law,
            statute, stock exchange regulation or by-law, regulatory or governmental order or court order.
          </p>
          <p>
            Your personal data may be transferred from Malaysia to another country, state and city. When we transfer
            your personal data outside of Malaysia, we will take steps to ensure that your personal data continues to
            receive a standard of protection that is at least comparable to that provided under the PDPA. By providing
            us with personal data, you consent that your personal data may be transferred from Malaysia to another
            country, state and city under these terms. DO NOT provide any personal data to us if you do not consent to
            your personal data being transferred from Malaysia to another country, state or city.
          </p>
          {/* Protection of Personal Data */}
          <h2>Protection of Personal Data</h2>
          <p>
            To safeguard your personal data from unauthorised access, collection, use, disclosure, copying, modiﬁcation,
            disposal or similar risks, we have introduced appropriate administrative, physical and technical measures,
            including but not limited to the specific measures below to secure the storage and transmission of personal
            data by us, and disclosing personal data both internally and to our authorised third party service providers
            and agents only on a need-to-know basis.
          </p>
          <ol className="lst-lower-alpha">
            <li>
              The storage server itself is not connected to the internet, and is only connected to the internet via a
              jump server. A two-step authentication is required to access the storage server from the jump server.
            </li>
            <li>
              Access to the jump server is only accessible from a terminal with a token including an encryption key.
            </li>
            <li>The jump server uses services on AWS such as firewall and threat detection services.</li>
          </ol>
          <p>
            You should be aware, however, that no method of transmission over the Internet or method of electronic
            storage is completely secure. While security cannot be guaranteed, we strive to protect the security of your
            information and are constantly reviewing and enhancing our information security measures. We cannot be held
            responsible for unauthorised or unintended access that is beyond our control.
          </p>
          {/* Other Websites */}
          <h2>Other Websites</h2>
          <p>
            The Apps and Website may, from time to time, contain links to and from other websites. If you follow a link
            to any of these websites, please note that these websites have their own privacy policies and that we do not
            accept any responsibility or liability for these policies. Please check these policies before you submit any
            personal data to these websites.
          </p>
          {/* Retention Limitation */}
          <h2>Retention Limitation</h2>
          <p>
            We keep your personal data only for so long as we need the personal data to fulfil the purposes we collected
            it for, and to satisfy our business and/or legal purposes, including audit, accounting or reporting
            requirements. How long we keep your personal data depends on the nature of the data, for example:
          </p>
          <ol className="lst-lower-alpha">
            <li>
              we will keep personal data for at least the duration of the limitation period for bringing claims if the
              personal data may be required to commence or defend legal proceedings; and
            </li>
            <li>
              personal data collected for use for a limited purpose will generally not be retained for more than five
              (5) years after completion of the purpose.
            </li>
          </ol>
          <p>
            Some information may be retained for longer, for example where we are required to do so by law, or need to
            retain it for the purposes of litigation.
          </p>
          {/* Withdrawing Your Consent */}
          <h2>Withdrawing Your Consent</h2>
          <p>
            The consent that you provide for the collection, use and disclosure of your personal data will remain valid
            until such time it is being withdrawn by you in writing. You may withdraw consent and request us to stop
            using and/or disclosing your personal data for any or all of the purposes listed above by submitting your
            request in the way described in the “Contact” section below. Whilst we respect your decision to withdraw
            your consent, please note that depending on the nature and scope of your request, we may not be in a
            position to continue providing our goods or services to you.
          </p>
          {/* Personal Data Breach */}
          <h2>Personal Data Breach</h2>
          <p>
            We will notify you without undue delay upon becoming aware of any Personal Data breach under the PDPA
            affecting your Personal Data, providing you with sufficient information on such breach and the internal
            procedures to be taken.{' '}
          </p>
          {/* Access and Correction */}
          <h2>Access and Correction</h2>
          <ol className="lst-lower-alpha">
            <li>
              If we are requested by you or the person who has the right to do so, to notify the purposes for use of
              personal data which we retain, disclose such personal data, correct its content, add or delete, suspend
              the use, remove or suspend the provision of your data to third parties, we will deal with your request in
              writing after confirming the content of your request. You may request to access and correct your personal
              data kept by WILLERS in the way described in “Contact” section below.
            </li>
            <li>A member will also be able to update his personal data directly after login in the “Proﬁle”.</li>
            <li>We may be permitted under applicable laws to refuse a request.</li>
          </ol>
          {/* Changes to the Privacy Policy */}
          <h2>Changes to the Privacy Policy</h2>
          <p>
            WILLERS may amend this privacy policy from time to time and will make available the updated policy on the
            Apps and Website. By continuing to access the Apps and Website and/or by continuing to be a member of
            WILLERS, as applicable, you agree to be bound by the terms and conditions of this policy, as amended from
            time to time.
          </p>
          {/* Contact */}
          <h2>Contact</h2>
          <p>
          Questions, comments, complaints and requests regarding this privacy policy are welcome.
          You may contact our customer service at (65) 6245 0491 or email us at <u style={{color: 'blue'}}>pr-info@willers.com.sg</u>
          </p>
          {/* Governing Law and Submission to Jurisdiction */}
          <h2>Governing Law and Submission to Jurisdiction</h2>
          <p>
          This policy is governed by Malaysia law and Malaysian law and you agree to irrevocably submit to the exclusive
          jurisdiction of the Malaysia courts and Malaysia’s courts whichever is applicable.
          </p>
          <p>
            Eﬀective date: [27th Jan 2023]
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation('translations')(Privacy);
