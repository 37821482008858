module.exports = {
  ROUTE: {
    VERIFY_MOBILE_ACCOUNT: '/account/verify-mail',
    VERIFY_SUBSCRIPTION: '/subscription/verify',
    MOBILE_REISSUE_PASSWORD: '/account/password-reset',
    MOBILE_RE_PASSWORD_COMPLETE: '/re-password-complete',
    QR_APPLE: '/lp/goodride/apple',
    QR_GOOGLE: '/lp/goodride/google',

    // Terms and Privacy
    USER_TERMS: '/account/user-term-of-service',
    USER_PRIVACY: '/account/user-privacy-policy',
    DRIVER_TERMS: '/account/driver-term-of-service',
    DRIVER_PRIVACY: '/account/driver-privacy-policy',
    HOW_TO_USE: '/account/how-to-use',
    HOW_TO_USE_QR: '/help/how-to-use-qr',
    MOBI_TERM: '/account/mobi-term-of-service',
    MOBILE_PRIVACY: '/account/mobile-privacy',
    QR_TERM: '/account/qr-term-of-service',

    // Shuttle bus
    VALIDATE_BUS: '/exbus',
    REGISTRY_INFORMATION: '/exbus/information',
    REGISTRY_POSITION: '/exbus/input',
    REGISTRY_CONFIRM: '/exbus/confirm',
    REGISTRY_COMPLETE: '/exbus/complete',
    DETAIL_ORDER: '/exbus/detail',
    DETAIL_POSITION: '/exbus/input/',
    DETAIL_CONFIRM: '/exbus/confirm/',
    DETAIL_COMPLETE: '/exbus/complete/',

    // Asahikawa
    ASAHIKAWA: '/reserve/asahikawa',
    ASAHIKAWA_INPUT: '/reserve/asahikawa/input',
    ASAHIKAWA_CONFIRM: '/reserve/asahikawa/confirm',
    ASAHIKAWA_COMPLETE: '/reserve/asahikawa/complete',
    ASAHIKAWA_DETAIL: '/reserve/asahikawa/detail',
    ASAHIKAWA_VERIFY_ORDER: '/reserve/asahikawa/login',

    // Subscription Page
    SUBSCRIPTION_LANDING_PAGE: '/plan',
    FAMILY_OPTION_LANDING_PAGE: '/family-option',
    TUTORIAL_APP_PAGE: '/tutorial',
    TICKET_LANDING_PAGE: '/ticket',

    // Jit Page
    JIT_LANDING_PAGE: '/jit',

    // Error Page UI
    MAINTENANCE: '/maintenance',
    UNAUTHORIZED: '/unauthorized',
  },

  ROUTER_KEY: {
    PAGE_VIEW: 1,
    PAGE_LAYOUT: 2,
  },

  APP_URL: {
    IOS: 'https://apps.apple.com/vn/app/willers/id1471754821',
    ANDROID: 'https://play.google.com/store/apps/details?id=jp.co.willer.maasapp',
  },

  LANGUAGE: [
    {language: 'JAPANESE', i18n: 'common.ja'},
    {language: 'ENGLISH', i18n: 'common.en'},
    {language: 'VIETNAMESE', i18n: 'common.vi'},
  ],
};
