import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {Grid, Container} from '@material-ui/core';
import {verifyAccountMobileApi} from '../../services/authenServices';
import SelectMultiLang from '../../components/SelectMultiLang';
import {convertQueryStringToObject} from '../../utils/common';

/**
 * verify mobile account
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      isLoading: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    const urlParamStr = convertQueryStringToObject(window.location.search);
    const token = urlParamStr?.token;

    this.setState({
      isLoading: true,
    });
    verifyAccountMobileApi(token)
        .then((response) => {
          this.setState({
            message: response,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            message: error,
            isLoading: false,
          });
        });
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <div>
        <LoadingOverlay
          active={this.state.isLoading}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          {this.state.isLoading ? (
            <Container maxWidth="sm" style={{height: '100vh'}}></Container>
          ) : (
            <Container maxWidth="sm" style={{height: '100vh'}}>
              <Grid container className="mt-10 mb-12" justify="flex-end" alignItems="center">
                <SelectMultiLang />
              </Grid>
              <br></br>
              {this.state.message.status === 10 ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-48">
                    <img src={`${process.env.PUBLIC_URL}/images/verify_email.png`} alt="Logo" style={{width: 72}} />
                  </Grid>
                  <Grid item xs={12} justify="center" alignItems="center" container style={{marginTop: 10}}>
                    <h4 className="success font-18">{t('user.verify.email.token.success')}</h4>
                  </Grid>
                  <Grid item xs={12} justify="center" alignItems="center" container>
                    <h4 className="success font-18">{t('user.verify.email.token.success2')}</h4>
                  </Grid>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
                    <span className="font-16">{t('verify_mobile_account.register_email')}</span>
                  </Grid>
                  {this.props.i18n.language === 'ja' && (
                    <Grid item xs={12} justify="center" alignItems="center" container className="mt-12 padding-item-0">
                      <span className="font-20">{t('user.verify.email.token.success')}</span>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-48">
                    <img src={`${process.env.PUBLIC_URL}/images/failure.png`} alt="Logo" style={{width: 40}} />
                  </Grid>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
                    <span className="error font-18">{t('user.email.token.invalid')}</span>
                  </Grid>
                </Grid>
              )}
              <br></br>
            </Container>
          )}
        </LoadingOverlay>
      </div>
    );
  }
}

export default withTranslation('translations')(Index);
