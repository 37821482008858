import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import SimpleReactValidator from 'simple-react-validator';
import {Container, Grid, TextField, Button, FormHelperText, FormControl} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {getGeofences} from '../../../stores/maps/actions';
import {onChangeTextField} from '../../../utils/common';
import {initialVerifyScreen, verifyOrder} from '../../../stores/shuttleBus/actions';

/**
 * validate express bus information
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      order_no: '',
      email: '',

      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.initialVerifyScreen();
    this.props.getGeofences();
    const urlParamStr = new URLSearchParams(window.location.search);
    this.setState({
      order_no: urlParamStr.get('order_no') ? urlParamStr.get('order_no') : '',
      email: urlParamStr.get('email') ? urlParamStr.get('email') : '',
    });
  }

  /**
   * handle submit form verify order
   * @param {event} e
   */
  handleValidateOrder = (e) => {
    e.preventDefault();
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.props.verifyOrder(this.state.order_no?.trim(), this.state.email?.trim().toLowerCase(), this.props);
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {shuttle, t} = this.props;

    return (
      <Container maxWidth="sm" style={{height: '100%'}} className="p-0">
        <LoadingOverlay active={shuttle.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <form onSubmit={this.handleValidateOrder}>
            <Grid container className="pt-12">
              <Grid item xs={12} justify="center" alignItems="center" container>
                <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
              </Grid>
            </Grid>
            <Grid container className="pt-12 mt-36">
              <Grid
                item
                xs={12}
                justify="flex-start"
                alignItems="center"
                container
                className="header_color_mobile mt-5"
              >
                <span className="padding-item-0 font-weight-600">予約情報を管理</span>
              </Grid>
            </Grid>
            <Grid container className="mt-36">
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <span className="padding-item-0 font-weight-600">高速予約バス番号(8桁)</span>
              </Grid>
            </Grid>
            <Grid container className="mt-12">
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <FormControl className="width_100 padding-item-0">
                  <TextField
                    name="order_no"
                    variant="outlined"
                    className="width_100 font-17"
                    placeholder="高速バス予約番号を入力してください"
                    value={this.state.order_no}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                  {this.validator.message('order_no', this.state.order_no?.trim(), 'numeric')}
                  {this.state.isSubmitForm && !this.validator.check(this.state.order_no?.trim(), 'numeric') && (
                    <FormHelperText id="order_no" error>
                      {t('validation.numeric', {field: '高速バス予約番号'})}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="mt-36">
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <span className="padding-item-0 font-weight-600">ログインID（メールアドレス）</span>
              </Grid>
            </Grid>
            <Grid container className="mt-12">
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <FormControl className="width_100 padding-item-0">
                  <TextField
                    name="email"
                    variant="outlined"
                    className="width_100 font-17"
                    placeholder="Eメールアドレスを入力してください"
                    value={this.state.email}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                  {this.validator.message('email', this.state.email?.trim(), 'email')}
                  {this.state.isSubmitForm && !this.validator.check(this.state.email?.trim(), 'email') && (
                    <FormHelperText id="email" error>
                      {t('validation.email')}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="mt-36">
              <Grid item xs={12} justify="center" alignItems="center" container>
                <div className="width_100 padding-item-0">
                  <Button
                    type="submit"
                    variant="contained"
                    className={
                      this.state.order_no && this.state.email ?
                        'width_100 button_color_submit button_height font-weight-600' :
                        'width_100 button_color_disabled button_height font-weight-600'
                    }
                    disabled={this.state.order_no && this.state.email ? false : true}
                  >
                    確認
                    <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                  </Button>
                </div>
              </Grid>
            </Grid>
            {shuttle.errorMessage && (
              <Grid container justify="center" alignItems="center" className="mt-36">
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <span className="padding-item-0 font-16 color-danger">該当する予約情報がありません</span>
                </Grid>
                <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
                  <span className="font-12">高速バス予約番号/連絡用Eメールアドレスを</span>
                  <span className="font-12">お確かめの上、再度ご入力ださい</span>
                </Grid>
              </Grid>
            )}
            <Grid container className="footer">
              <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
                <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
              </Grid>
            </Grid>
          </form>
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shuttle: state.shuttle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyOrder: (order_no, email, props) => dispatch(verifyOrder(order_no, email, props)),
    initialVerifyScreen: () => dispatch(initialVerifyScreen()),
    getGeofences: () => dispatch(getGeofences()),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
