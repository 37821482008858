import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Container, Grid} from '@material-ui/core';
import './style.css';

/**
 * Jit Landing Page
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t} = this.props;
    return (
      <Container
        style={{height: '100vh', maxWidth: '400px'}}
        className={this.props.i18n.language === 'vi' ? 'font-ios landing-page-jit' : 'landing-page-jit'}
      >
        <Grid container className="mt-36">
          <Grid item xs={12} justify="center" alignItems="center" container>
            <div className="frame-logo">S</div>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-15 list-content">
            <span className="font-25 item-conten font-weight-600">mobi shuttle</span>
            <span
              className="font-12 mt-10 item-content font-weight-600"
              style={{display: 'flex', justifyContent: 'center'}}
            >
              <span style={{background: 'black', color: 'white', padding: '2px 5px', borderRadius: '5px'}}>
                {t('jit.title')}
              </span>
            </span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-48 list-content">
            {this.props.i18n.language === 'ja' ? (
              <>
                <span className="font-23 pt-6 item-content">{t('jit.description1')}</span>
                <span className="font-23 pt-6 item-content">{t('jit.description2')}</span>
              </>
            ) : (
              <span className="font-23 pt-6 item-content">
                {t('jit.description1')} {t('jit.description2')}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid container className="mt-60">
          <Grid item xs={5} alignItems="center" container>
            <img src={`${process.env.PUBLIC_URL}/images/jit/img_01.png`} alt="img" />
          </Grid>
          <Grid item xs={7} justify="flex-start" alignItems="center" container>
            <div>
              <span className="font-23">{t('jit.item1.title')}</span>
              {this.props.i18n.language === 'ja' ? (
                <>
                  <span className="font-12 item-content mt-18">{t('jit.item1.description1')}</span>
                  <span className="font-12 item-content mt-5">{t('jit.item1.description2')}</span>
                  <span className="font-12 item-content mt-5">{t('jit.item1.description3')}</span>
                </>
              ) : (
                <span className="font-12 item-content mt-18">
                  {t('jit.item1.description1')} {t('jit.item1.description2')} {t('jit.item1.description3')}
                </span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container className="mt-60">
          <Grid item xs={5} alignItems="center" container>
            <img src={`${process.env.PUBLIC_URL}/images/jit/img_02.png`} alt="img" />
          </Grid>
          <Grid item xs={7} justify="flex-start" alignItems="center" container>
            <div>
              <span className="font-23">{t('jit.item2.title')}</span>
              {this.props.i18n.language === 'ja' ? (
                <>
                  <span className="font-12 item-content mt-18">{t('jit.item2.description1')}</span>
                  <span className="font-12 item-content mt-18">{t('jit.item2.description2')}</span>
                  <span className="font-12 item-content mt-18">{t('jit.item2.description3')}</span>
                </>
              ) : (
                <span className="font-12 item-content mt-18">
                  {t('jit.item2.description1')} {t('jit.item2.description2')} {t('jit.item2.description3')}
                </span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container className="mt-60">
          <Grid item xs={5} alignItems="center" container>
            <img src={`${process.env.PUBLIC_URL}/images/jit/img_03.png`} alt="img" />
          </Grid>
          <Grid item xs={7} justify="flex-start" alignItems="center" container>
            <div>
              <span className="font-23">{t('jit.item3.title1')}</span>
              <span className="font-24 item-content">{t('jit.item3.title2')}</span>
              {this.props.i18n.language === 'ja' ? (
                <>
                  <span className="font-12 item-content mt-18">{t('jit.item3.description1')}</span>
                  <span className="font-12 item-content mt-18">{t('jit.item3.description2')}</span>
                  <span className="font-12 item-content mt-18">{t('jit.item3.description3')}</span>
                </>
              ) : (
                <span className="font-12 item-content mt-18">
                  {t('jit.item3.description1')} {t('jit.item3.description2')} {t('jit.item3.description3')}
                </span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container className="mt-60">
          <Grid item xs={12} justify="center" alignItems="center" container>
            <div className="frame-button">{t('jit.btnMethod')}</div>
          </Grid>
        </Grid>
        <Grid container className="mt-60">
          <Grid item xs={12} justify="flex-start" alignItems="flex-end" container>
            <div className="frame">1</div>
            <span className={this.props.i18n.language === 'en' ? 'font-20 ml-2' : 'font-22 ml-2'}>
              {t('jit.category1.title')}
            </span>
          </Grid>
          <Grid item xs={12} justify="flex-start" alignItems="flex-end" container className="mt-20">
            <span>{t('jit.category1.description1')}</span>
            <span className="mt-5">{t('jit.category1.description2')}</span>
          </Grid>
          {[1, 2, 3].map((item) => (
            <Grid
              item
              xs={12}
              justify="center"
              alignItems="center"
              container
              key={item}
              className="mt-20"
              style={{border: '2px solid #E8E8E8', borderRadius: '5px'}}
            >
              <Grid container style={{borderBottom: '2px solid #E8E8E8'}} className="p-15">
                <Grid item xs={2} justify="center" alignItems="center" container className="font-18 font-weight-600">
                  11:00
                </Grid>
                <Grid item xs={2} justify="center" alignItems="center" container className="font-11">
                  <div>
                    <div>{t('jit.category1.departure')}</div>
                    <div>{t('jit.category1.indication')}</div>
                  </div>
                </Grid>
                <Grid item xs={3} justify="center" alignItems="center" container className="font-11">
                  <img src={`${process.env.PUBLIC_URL}/images/jit/back.png`} alt="img" />
                </Grid>
                <Grid item xs={2} justify="center" alignItems="center" container className="font-18 font-weight-600">
                  12:00
                </Grid>
                <Grid item xs={2} justify="center" alignItems="center" container className="font-11">
                  <div>
                    <div>{t('jit.category1.arrival')}</div>
                  </div>
                </Grid>
                <Grid item xs={1} justify="flex-end" alignItems="center" container className="font-12">
                  <img src={`${process.env.PUBLIC_URL}/images/jit/polygon.png`} alt="img" />
                </Grid>
              </Grid>
              <Grid container className="p-15 font-13">
                <Grid item xs={12} justify="space-between" alignItems="center" container style={{flexWrap: 'nowrap'}}>
                  <div style={{marginRight: '5px'}}>
                    {item !== 1 && (
                      <span>
                        {t('jit.category1.deadline')}
                        {this.props.i18n.language !== 'en' ? ' ' : <br />}
                        12/11 ({t('jit.category1.thursday')}) 23:00
                      </span>
                    )}
                  </div>
                  <div>{t('jit.category1.seats')}: 3</div>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container className="mt-60">
          <Grid item xs={12} justify="flex-start" alignItems="flex-end" container>
            <div className="frame">2</div>
            <span className={this.props.i18n.language === 'en' ? 'font-20 ml-2' : 'font-22 ml-2'}>
              {t('jit.category2.title')}
            </span>
          </Grid>
          <Grid item xs={12} justify="flex-start" alignItems="flex-end" container className="mt-20">
            <span>{t('jit.category2.description1')}</span>
            <span className="mt-5">{t('jit.category2.description2')}</span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-20">
            <img src={`${process.env.PUBLIC_URL}/images/jit/img_04.png`} alt="img" />
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-10" direction="column">
            <div style={{textAlign: 'center', position: 'relative', width: '100%'}}>
              <img src={`${process.env.PUBLIC_URL}/images/jit/down.png`} alt="img" />
              <div className="frame-button-clock">
                <div className="button-clock">{t('jit.btnRequest')}</div>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container className="mt-60" style={{paddingBottom: '100px'}}>
          <Grid item xs={12} justify="flex-start" alignItems="flex-end" container>
            <div className="frame">3</div>
            <span className={this.props.i18n.language === 'en' ? 'font-20 ml-2' : 'font-22 ml-2'}>
              {t('jit.category3.title')}
            </span>
          </Grid>
          <Grid item xs={12} justify="flex-start" alignItems="flex-end" container className="mt-20">
            <span>{t('jit.category3.description1')}</span>
            <span className="mt-5">
              {t('jit.category3.description2')} <b>{t('jit.notify')}</b> {t('jit.category3.description2.1')}
            </span>
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
            <img
              src={`${process.env.PUBLIC_URL}/images/jit/mobile_${localStorage.getItem('i18nextLng')}.png`}
              alt="img"
            />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation('translations')(Index);
