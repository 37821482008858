import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Route, Switch} from 'react-router-dom';
import {Container, Dialog} from '@material-ui/core';
import routes from '../router.js';
import MessageModal from '../components/messageModal';
import {convertQueryStringToObject} from '../utils/common';
import {ROUTE} from '../common/constant.js';

/**
 * Main Layout component
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.currentPathname = this.props.location.pathname;
    this.currentSearch = this.props.location.search;
  }

  /**
   * get routes
   * @param {routes} r
   * @return {Route} Route
   */
  getRoutes = (r) => {
    return r.map((prop, key) => {
      const currentRoute = this.getCurrentRoute();
      if (!prop.subMenu || !currentRoute) {
        return (
          <Route
            path={prop.path}
            render={(props) => <prop.component {...props} handleClick={this.handleNotificationClick} />}
            key={key}
          />
        );
      } else {
        return (
          <Route
            path={currentRoute.path}
            render={(props) => <currentRoute.component {...props} handleClick={this.handleNotificationClick} />}
            key={key}
          />
        );
      }
    });
  };

  /**
   * getCurrentRoute
   * @return {String}
   */
  getCurrentRoute = () => {
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      if (route?.subMenu) {
        for (let j = 0; j < route.subMenu.length; j++) {
          if (this.props.location.pathname.includes(route.subMenu[j].pathActive)) {
            return route.subMenu[j];
          }
        }
      } else {
        if (this.props.location.pathname.includes(route.pathActive)) {
          return route;
        }
      }
    }
    return [];
  };

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (window.location.pathname.includes('exbus') || window.location.pathname.includes('reserve/asahikawa')) {
      document.title = 'Maas Wex';
    } else {
      document.title = 'mobi Community Mobility';
    }
  }

  /**
   * componentWillMount
   */
  componentWillMount() {
    if (this.props.location.pathname === ROUTE.JIT_LANDING_PAGE) {
      this.props.i18n.changeLanguage(convertQueryStringToObject(this.props.location.search).lang || 'vi');
    } else {
      this.props.i18n.changeLanguage(convertQueryStringToObject(this.props.location.search).lang || 'ja');
    }
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const currentRoute = this.getCurrentRoute();

    return (
      <div>
        <Container maxWidth="xl" style={{height: '100vh'}} className={this.props.i18n.language === 'vi' ? 'p-0 font-ios' : 'p-0'}>
          <Switch currentRoute={currentRoute}>{this.getRoutes(routes)}</Switch>
        </Container>
        <Dialog
          open={this.props.modal.flgMessage}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <MessageModal></MessageModal>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
