/* eslint-disable max-len */
export default [
  {
    title: 'APPENDIX A',
    description: '<b><u>*PLEASE VISIT THE VENDOR’S WEBSITE TO CHECK THE PREVAILING APPLICABLE VENDOR TERMS AND CONFIRM THAT YOU ARE AGREEABLE TO THE VENDOR TERMS BEFORE BOOKING THE TRANSPORTATION SERVICES.</u></b>',
  },
  {
    title: '<u>LIST OF VENDORS</u>',
    highlight: true,
    center: true,
    table: {
      property: ['Name of Vendor', 'Address'],
      content: [
        [
          'GOPI TRAVEL TOURS SDN. BHD.',
          {
            sub_content: [
              'Pn. 9798, Lot 1435 Pekan Baru Simpang Petang',
              'Jelebu, Negeri Sembilan, Darual Khusus, Malaysia',
            ],
          },
        ],
        [
          'BADANBAS COACH SDN BHD',
          {
            sub_content: [
              'No. 38, Jalan Chow Kit, 50350 Kuala Lumpur, Malaysia',
            ],
          },
        ],
        [
          'Rapid Bus Sdn Bhd',
          {
            sub_content: [
              'B-20-1, Level 20, Menara UOA Bangsar,',
              'No. 5, Jalan Bangsar Utama 1, 59000 Kuala Lumpur, Malaysia',
            ],
          },
        ],
      ],
    },
    description: '<br/><br/>If you have any questions, feedback or concerns pertaining to the Transportation Services, please contact the respective Vendors',
  },
  {
    title: '<u>VENDOR TERMS</u>',
    noneDescription: true,
    highlight: true,
    center: true,
  },
  {
    title: 'Article 1. Detailed Transportation Conditions',
    center: true,
    afterNoneDescription: true,
    list1: [
      {
        title: 'These Transportation Conditions govern your relationship with our Vendors, and would apply in respect of the Transportation Services. By using the Transportation Services, you agree to the terms as set out in this Appendix A. ',
      },
      {
        title: 'Our Vendors have the right to suspend the provision of Transportation Services and request for you to leave the transportation vehicle if any of the following situation occurs, even if the ride is in progress, and you shall immediately comply with such a request:',
        list3: {
          content: [
            {
              title: 'If you had procured the Transportation Services through a method other than what was permitted or specified by the Application and/or the mobi Service Terms.',
            },
            {
              title: 'If the Vendor’s employees deem that there is a reasonable risk that you will inflict or have inflicted harm, or would pose a threat to the safety of the Vendor’s employees and other passengers aboard the transportation vehicle.',
            },
            {
              title: 'If you do not comply with the rules and regulations of our Vendors (including without limitation prohibition against eating or drinking aboard the transportation vehicle, or the prohibition of bringing on board any strong-smelling food or products) and/or any of the instructions given by the Vendors’ employees or personnel.',
            },
            {
              title: 'If you carry heavy or oversized baggage and/or dangerous or prohibited products on board the transportation vehicle.',
            },
            {
              title: 'If your physical condition does not allow you to board the transportation vehicle.',
            },
            {
              title: 'If you are suffering from any infectious diseases (eg. you have been diagnosed with being COVID-19 positive).',
            },
          ],
        },
      },
      {
        title: 'Travel distance, duration and estimate arrival and departure times displayed on the Application are dependent on the actual traffic situation and requests from other Registered Users, and are merely estimates and may be changed from time to time.',
      },
      {
        title: 'The Vendors shall be solely responsible for any injury, loss, claim, damage, costs and expenses or any direct, indirect, special exemplary, punitive, incidental or consequential damages of any kind or for any lost profits or lost savings, suffered by you during the provision of the Transportation Services. The Vendor shall be solely responsible for any injury, loss, claim, damage, costs and expenses or any direct, indirect, special, exemplary, punitive, incidental or consequential damage of any kind or for lost profits or lost savings, suffered by you during the provisions of the Transportation Services in the event the Vendor is found to be negligent or failed to exercise the required duty of care during the provision of the Transportation Services.',
      },
      {
        title: 'Without prejudice to the applicability and effect of the clauses above disclaiming or limiting liability, the Vendors will be excused from any delay or failure to perform any obligation arising under or in connection with these Terms, if such delay or failure results, directly or indirectly, from any act of nature or circumstance or cause beyond the Vendors’ reasonable control including, but not limited to, any Act of God, fire, flood, explosion, lightning, windstorm, earthquake, epidemic, disease outbreak, extraordinarily severe weather conditions, failure of machinery or equipment, shortage of materials, discontinuation of power supply, court order or governmental interference, radioactive, chemical or biological contamination, civil commotion, riot or armed conflict, war, civil war (whether declared or undeclared), acts of terrorism, strike, labour disturbances, transportation difficulties or labour shortages.',
      },
    ],
  },
  {
    title: 'Article 2. Roles and Responsibilities of the Vendors ',
    center: true,
    list1: [
      {
        title: 'The Vendors shall ensure that their transportation vehicles are maintained properly, are roadworthy and in good working condition and shall take all safety precautions to ensure safe passage for their passengers. In the event of any roadside emergency breakdown of the transportation vehicles in the course of providing the Transportation Services, the Vendors shall arrange for you to be transported by an alternative vehicle to your designated drop-off point in a safe manner. ',
      },
      {
        title: 'The Vendors shall at all times observe and comply with all prevailing laws and regulations in the course of providing the Transportation Services and shall bear all costs connected to the compliance of the laws and regulations. ',
      },
      {
        title: 'The Vendors warrants that they have obtained and continue to maintain all the appropriate licenses, approvals, permits, consents, authority and mandatory insurance policies related to or in connection with their provision of the Transportation Services.',
      },
    ],
  },
];
