export const ACTION_TYPES = {
  MESSAGE_MODAL: 'MESSAGE_MODAL',
};

const initialState = {
  flgMessage: false,
  message: '',
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action) {
  let newState;
  switch (action.type) {
    case ACTION_TYPES.MESSAGE_MODAL:
      newState = Object.assign({}, state);
      newState.flgMessage = action.payload.flg;
      newState.message = action.payload.message;
      return newState;
    default:
      return state;
  }
}
