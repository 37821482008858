/* eslint-disable max-len */
export default [
  {
    title: 'For Malaysia Users <br/> TERMS AND CONDITIONS FOR THE MOBI – COMMUNITY MOBILITY APPLCATION',
    noneDescription: true,
  },
  {
    title: 'GENERAL',
    afterNoneDescription: true,
    list6: [
      {
        title: 'In these terms and conditions (“<b>Terms</b>”), “we”, “us” or “our” refers to Willers Pte. Ltd. “You” and “your” refers to any individual and/or organisation using or accessing our mobile application, “mobi Community Mobility App”, or our website at [https://willers.com.sg/] (collectively referred to as the “<b>Application</b>”), or using our Services (as defined below at Clause 2.1) through the Application.',
      },
      {
        title: 'By accessing, browsing, using, downloading and/or installing the Application, you are deemed to have accepted these Terms, and that you agree to comply with them. <b>If you do not agree to these Terms, please exit the Application immediately and, where applicable, uninstall our Application, and refrain from any further use of or access to the Application.</b>',
      },
      {
        title: 'We may revise these Terms at any time by amending this page without prior notice to you. Please check this page from time to time and review the Terms to ensure that you understand all the terms and conditions that apply to your access and use of the Application, as they are binding on you. Your continued access to and/or use of the Application indicate your acceptance of the revised Terms.',
      },
      {
        title: 'We may modify or discontinue any information or feature that forms part of the Application at any time, without notice, and without liability. We may also, in our discretion, cease to operate the Application at any time.',
      },
      {
        title: 'These Terms will also apply to any update or upgrade provided by us that replaces and/or supplements the Application unless such update or upgrade is accompanied by separate terms, in which case those separate terms shall apply.',
      },
      {
        title: 'Any personal data or information which you provide to us is also subject to our <b>Privacy Policy</b> (made available through the Application and at [https://willers.com.sg/), which is incorporated by reference into these Terms. You agree that we may use information that you provide to us in accordance with the terms of our Privacy Policy (as amended from time to time). The Application may also utilize cookies to enable more convenient browsing by our users. For more information on how we use cookies, please refer to our Privacy Policy.',
      },
    ],
  },
  {
    title: 'SERVICES MADE AVAILABLE THROUGH THE APPLICATION',
    list6: [
      {
        title: 'The Application is an online platform which allows you to obtain or procure the following services (collectively defined as “<b>Services</b>”):',
        list7: [
          {
            title: 'booking transportation services (“<b>Transportation Services</b>”) from third party transportation providers (collectively “<b>Vendors</b>”) to bring you from your location to the selected desired destination through the Application; ',
          },
          {
            title: 'route search service which enables you to use the search function on the Application to search for the optimal route and form of transport for travelling from one destination to another (“<b>Route Search Services</b>”); and/or',
          },
          {
            title: 'service search which enables you to conduct a search for the available Transportation Services (“<b>Service Search</b>”).',
          },
        ],
      },
      {
        title: 'The terms and conditions governing the use of the Services are set out in the terms and conditions for the Services which may be accessed via the Application or through (“<b>Terms and Conditions for the Mobi Services</b>”). To the extent of any conflict or inconsistency between these Terms and the Terms and Conditions for the Mobi Services in respect of the provision of the Services, the Terms and Conditions for the Mobi Services shall prevail. If you do not agree to the Terms and Conditions for the Mobi Services, you should not register for a User Account and/or procure any Services.',
      },
      {
        title: 'For the avoidance of doubt, the Transportation Services are provided solely by our Vendors. We do not provide the Transportation Services, and are merely the intermediary between you and the Vendors. We are not responsible and disclaim all liability for any act or omission of the Vendors, or any dealings between you and the Vendors, whether or not such dealings have been facilitated by us or conducted on or through the Application. The Transportation Services may be subject to other terms and conditions as may be stipulated by our Vendors from time to time.',
      },
    ],
  },
  {
    title: 'YOUR USE OF THE APPLICATION',
    list6: [
      {
        title: 'The Application and its Contents are either owned by us or licensed to us by third parties. “Contents” refer to all content on the Application including but not limited to all text, graphics, audio and images, music, videos, HTML code, photographs, interactive features, software, scripts, advertisements, buttons, the arrangement and compilation of content, and any trademarks, names, logos, design, pages, information, reports, documents, and multimedia.',
      },
      {
        title: 'Subject to these Terms, we grant you a non-exclusive, revocable, non-transferable, limited licence to use the Application. This licence does not transfer any title in the Application to you. We retain all ownership rights in the Application and reserve all rights not expressly granted to you.',
      },
      {
        title: 'You acknowledge that you have no right, title, interest in and to the Application and its Contents and you agree not to challenge the validity of our ownership of or rights to them. ',
      },
      {
        title: 'The Contents are provided solely for your non-commercial use. You shall not reproduce, republish, copy, modify, adapt, translate, decompile, alter, disassemble, reverse engineer, or create derivative works from any Contents unless expressly authorized by us.',
      },
      {
        title: 'You acknowledge and agree that your use of the Application will not be done in a manner which:',
        list7: [
          {
            title: 'constitutes a breach of these Terms;',
          },
          {
            title: 'constitutes an infringement of our intellectual property rights and/or any third party’s intellectual property rights; and/or',
          },
          {
            title: 'we consider it to be an inappropriate and/or fraudulent use of the Application.',
          },
        ],
      },
      {
        title: 'You agree that you will not interrupt, impair, harm, or attempt to interrupt, impair or harm the Application in any way, and shall refrain from:',
        list7: [
          {
            title: 'transmitting material containing software viruses, worms, trojan horses or other harmful computer code, files, scripts, agents or programs; ',
          },
          {
            title: 'interfering with or disrupt the integrity or performance of the Application or the data contained therein; ',
          },
          {
            title: 'attempting to gain unauthorized access to the Application or its related software, systems or networks; ',
          },
          {
            title: 'create internet “links” to the Application or “frame” or “mirror” the Application on any other server or wireless or internet-based device;',
          },
          {
            title: 'circumventing the proper operation of the Application and network which the Service operates on; ',
          },
          {
            title: 'use any robot, spider, site search/retrieval Application, or other manual or automatic device or process to retrieve, index, “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Application or its contents; and/or ',
          },
          {
            title: 'using any manual or automated program or script, including but not limited to web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses or worms, or any program which may make multiple server requests per second, to unduly burden or hinder the operation and/or performance of the Application, or to circumvent the navigational structure or presentation of the Application or its Contents.',
          },
        ],
      },
      {
        title: 'Nothing in these Terms is deemed to grant to any person or entity any right or licence to use any Contents in any form of advertising, promotion, or marketing materials without our prior written consent. ',
      },
      {
        title: 'You are solely responsible for obtaining a suitable device and procuring and paying for mobile services or any equipment necessary to access and use the Application.',
      },
      {
        title: 'The Application is offered by us in Malaysia and we make no representation that it complies with the laws or is appropriate for use in any other jurisdiction.',
      },
    ],
  },
  {
    title: 'ACCOUNT REGISTRATION',
    list6: [
      {
        title: 'You are required to apply and register for a user account (“<b>User Account</b>”) with us before you are entitled to access and/or use certain features of the Application and/or use our Services. You acknowledge and agree that only one (1) User Account can be registered under your name. ',
      },
      {
        title: 'To register for a User Account, you will be required to complete the form as specified on the Application and agree to the Terms and Conditions for the Mobi Services. In signing up for a User Account with us, you represent and warrant that:',
        list7: [
          {
            title: 'all information provided by you is true, accurate, current, and complete;',
          },
          {
            title: 'you will promptly update us of any change to your registration details; and',
          },
          {
            title: 'you are of legal age and have the requisite mental and legal capacity in accordance with the applicable laws of Singapore to enter into these Terms and use the Application.',
          },
        ],
      },
      {
        title: 'We may require you to provide us with your proof of identity for verification purposes. ',
      },
      {
        title: 'We reserve the right, at our sole discretion without offering any reason, to reject your application for a User Account.',
      },
      {
        title: 'Upon successful registration of your User Account, you, as the account holder:',
        list7: [
          {
            title: 'agree to keep your account ID and password confidential and shall not allow other people to use your User Account; and',
          },
          {
            title: 'shall notify us immediately if you have any reason to believe that the security of your User Account has been compromised.',
          },
        ],
      },
      {
        title: 'You shall be solely responsible for any and all activities which occur under your User Account. We are entitled to treat all activities that occur under your User Account as having been conducted with your knowledge and authority. You may only maintain one User Account under your own name at any one time, and your User Account shall be non-transferable. For the avoidance of doubt, in cases where you have allowed any other person to use your User Account or have negligently or otherwise made your password and/or account ID publicly available, you agree that you are fully responsible for (i) the online conduct of such user; (ii) controlling the user’s access to and use of this Application; and (iii) the consequences of any use or misuse. We cannot and will not be liable for any loss or damage arising from your failure to comply with these requirements.',
      },
      {
        title: 'You acknowledge and agree that we may access your User Account and its contents as necessary for purposes including but not limited to identifying or resolving technical problems, verifying or ensuring your compliance with the Terms or responding to complaints without prior notice to you.',
      },
      {
        title: 'We reserve the right to limit, suspend, or terminate your access to your User Account without prior notice, without giving you a reason and/or without further reference to you if:',
        list7: [
          {
            title: 'you are in breach of any of these Terms; ',
          },
          {
            title: 'you have used or are using the Application for any illegal or impermissible purpose or activity including any activity which may expose or potentially expose us to civil or criminal liability or may otherwise affect our reputation;',
          },
          {
            title: 'your User Account is inactive;',
          },
          {
            title: 'we deem fit to do so, including for security reasons or to protect our interests or the interests of other users of the Application;',
          },
          {
            title: 'we discontinue the Application or the Services; and/or',
          },
          {
            title: 'we are ordered by enforcement or other governmental agencies to do so. ',
          },
        ],
        description: 'For the avoidance of doubt, we will not compensate or reimburse you for any costs or expenses incurred in the course of procuring the Services in the event that your access to your User Account is limited, suspended or terminated. ',
      },
      {
        title: 'You may delete your User Account by submitting a request through the Application. ',
      },
      {
        title: 'After your User Account is terminated or deleted, we may delete from the servers, any and all information contained in your User Account. ',
      },
    ],
  },
  {
    title: 'ACCESS TO THE APPLICATION',
    list6: [
      {
        title: 'The accessibility and operation of the Application rely on technologies outside our control. We do not guarantee that the Application will be error-free, continuously available or uninterrupted in operation, unhackable, uncompromisable, and/or free of any data breach (inadvertently or intentionally through interference or interception by third parties) and/or free of bugs or other harmful codes and components not specifically mentioned herein.',
      },
      {
        title: 'We have no obligation to provide any maintenance, support, or other services in relation to the Application, including providing any telephone assistance, documentation, error corrections, updates, upgrades, bug fixes, patches, and/or enhancements. However, if we do provide any updates, upgrades, bug fixes, and/or patches, and/or enhancements in respect of the Application, you shall install them and keep your installation of the Application updated to the most recent release or version made available by us.',
      },
      {
        title: 'Your access to and/or use of the Application may also be interrupted, suspended, or restricted when we provide any maintenance, support, or other services in relation to the Application.',
      },
      {
        title: 'You agree to us collecting and using your device’s technical data and all its associated information, including without limitation, technical information about your device, system, software, device functionality, and device specifications. The collected technical data may from time to time be used to facilitate the provision and improvement of the Application, software updates, product support, and other services associated with the Application (if any). You agree that we may use this technical data to improve our Application.',
      },
    ],
  },
  {
    title: 'ADVERTISEMENTS AND THIRD PARTY WEBSITES',
    list6: [
      {
        title: 'We may place advertisements such as banners, java applets and/or such other materials for the purposes of advertising product and/or services (“<b>Advertisements</b>”) in different locations on the Application and at different points during your use of the Application. These locations and points may change from time to time. ',
      },
      {
        title: 'The Application may also contain links to other websites (“<b>Third Party Website</b>”) which are not controlled or maintained by us. These links are provided only for your convenience and we make no representations whatsoever about any Third Party Website. ',
      },
      {
        title: 'We have no control over, and assume no responsibility for the content, privacy policies or practices of these Third Party Websites. In addition, we will not and cannot censor or edit the content of any Third Party Website.',
      },
      {
        title: 'A link to any Third Party Website does not mean that we endorse or accept any responsibility for the content, or the use of such a website. We make no representations, warranties or guarantees that the content contained therein is accurate, complete or up-to-date. We will not be liable for any loss or damage caused by your reliance on such third party content. By using or accessing such content, you acknowledge and agree that such use and access of any Third Party Website, and any dealings resulting from such use and/or access, is entirely at your own risk. ',
      },
    ],
  },
  {
    title: 'NOTIFICATION',
    list6: [
      {
        title: 'You acknowledge and agree that we, the Vendors and our third party service providers may contact you through the following means in order to provide you with information on the Application and/or Services:',
        list7: [
          {
            title: 'sending a notification to your User Account via the Application;',
          },
          {
            title: 'sending an email to your email address that was provided to us;',
          },
          {
            title: 'publishing a notice on our Application and/or website (available at [https://willers.com.sg/]); and/or',
          },
          {
            title: 'through any other appropriate communication methods.',
          },
        ],
      },
    ],
  },
  {
    title: 'GENERAL DISCLAIMERS',
    list6: [
      {
        title: 'THE APPLICATION AND ALL ITS CONTENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS ONLY, AND YOU USE THE APPLICATION AND ITS CONTENTS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE MAKE NO REPRESENTATIONS, WARRANTIES OR GUARANTEES, WHETHER EXPRESS OR IMPLIED, (I) AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE APPLICATION AND/OR CONTENTS, (II) THAT THE USE OF THE APPLICATION WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS, (III) AS TO THE SECURITY, AUTHENTICITY, INTEGRITY OR CONFIDENTIALITY OF ANY TRANSACTIONS AND OTHER COMMUNICATIONS MADE THROUGH THE APPLICATION; AND (IV) THAT THE USE OF THE APPLICATION WILL NOT HARM ANY DATA OR COMPUTING SYSTEM. YOUR RELIANCE ON THE APPLICATION AND/OR ANY CONTENT IS ENTIRELY AT YOUR OWN RISK. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR RELIANCE ON INFORMATION OBTAINED THROUGH THE APPLICATION.',
      },
      {
        title: 'WHILE WE TRY TO PROVIDE UP-TO-DATE INFORMATION ON MAPS AND GPS COORDINATES ON THE APPLICATION, WE DO NOT GUARANTEE THAT SUCH INFORMATION IS ACCURATE AT THE TIME OF USE. ',
      },
      {
        title: 'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE, OUR PARENTS, SUBSIDIARIES, AFFILIATES AND OUR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS, CONTRACTORS, SUCCESSORS AND ASSIGNS (COLLECTIVELY “<b>WILLERS</b>”), DISCLAIM ALL LIABILITIES FOR ANY INJURY, LOSS, CLAIM, DAMAGE, COSTS AND EXPENSES OR ANY DIRECT, INDIRECT, SPECIAL EXEMPLARY, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND OR FOR ANY LOST PROFITS OR LOST SAVINGS, WHETHER ARISING FROM BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), EQUITY, STRICT LIABILITY, BREACH OF STATUTORY DUTY OR OTHERWISE, AS A RESULT OF:',
        list7: [
          {
            title: 'YOUR ACCESS, USE OR INABILITY TO USE THE APPLICATION;',
          },
          {
            title: 'ANY SYSTEM, SERVER, COMMUNICATION OR CONNECTION FAILURE;',
          },
          {
            title: 'ANY DELAYS IN OPERATION OR TRANSMISSION;',
          },
          {
            title: 'ANY VIRUSES, TRACKERS, BUGS WORMS, TROJAN HORSES OR OTHER CODES DESIGNED TO PERMIT UNAUTHORISED ACCESS, TO DISABLE, MODIFY, ERASE, DAMAGE, STEAL OR USURP DATA OR OTHERWISE HARM ANY DATA OR COMPUTER SYSTEM; ',
          },
          {
            title: 'ANY ERROR, MISTAKE OMISSIONS, INACCURACIES, INTERRUPTIONS, DEFECTS OR OTHER PROBLEMS ASSOCIATED WITH THE APPLICATION OR OF ANY NATURE; AND/OR',
          },
          {
            title: 'ANY OTHER REASON ASSOCIATED WITH THE APPLICATION.',
          },
        ],
      },
      {
        title: 'OUR SOLE OBLIGATION, AND YOUR SOLE AND EXCLUSIVE REMEDY, IN THE EVENT OF INTERRUPTION TO THE USE OF AND/OR ACCESS TO APPLICATION SHALL BE TO USE REASONABLE ENDEAVOURS TO RESTORE THE USE OF AND ACCESS TO THE APPLICATION AS SOON AS REASONABLY PRACTICABLE.',
      },
      {
        title: 'YOU ACKNOWLEDGE AND AGREE THAT THERE ARE INHERENT RISKS IN INTERNET COMMUNICATIONS AND CONDUCTING TRANSACTIONS OVER THE INTERNET OR ELECTRONIC NETWORKS AND YOU HAVE VOLUNTARILY ASSUMED THOSE RISKS. WE EXPRESSLY DISCLAIM ANY LIABILITY FOR THE SECURITY, AUTHENTICITY, INTEGRITY OR CONFIDENTIALITY OF ANY TRANSACTIONS AND OTHER COMMUNICATIONS MADE THROUGH THE APPLICATION. ',
      },
      {
        title: 'YOU ACKNOWLEDGE AND AGREE THAT WHERE YOUR USE OF THE APPLICATION RESULTED IN ANY INJURY, LOSS, CLAIM, DAMAGE, COSTS AND EXPENSES OR ANY DIRECT, INDIRECT, SPECIAL EXEMPLARY, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND OR FOR ANY LOST PROFITS OR LOST SAVINGS, SUFFERED BY A THIRD PARTY (INCLUDING THE VENDORS), YOU SHALL BE SOLELY RESPONSIBLE FOR SUCH INJURY, LOSS, CLAIM, DAMAGE, COSTS AND EXPENSES. ',
      },
      {
        title: 'IF YOU ARE DISSATISFIED WITH THE APPLICATION AND/OR CONTENTS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO TERMINATE YOUR USER ACCOUNT. ',
      },
    ],
  },
  {
    title: 'LIMITATION OF LIABILITY',
    list6: [
      {
        title: 'Without prejudice to Clause 8, our total liability to you, whether based on contract, tort (including negligence), equity, strict liability, breach of statutory duty or otherwise, for any and all injury, loss, claim, damage, costs and expenses shall in no circumstances exceed the amount of paid by you to us in relation to the Services in the three (3) months preceding the date on which such injury, loss, claim, damage, costs and expenses was incurred. ',
      },
    ],
  },
  {
    title: 'INDEMNITY AND RELEASE',
    list6: [
      {
        title: 'You agree to indemnify and hold harmless Willers in respect of any and all claims, demands, actions, proceedings, liabilities (whether civil or criminal), damages, losses, costs and expenses (including legal costs on an indemnity basis) which may be suffered or incurred by Willers or asserted against Willers by any person in respect of:',
        list7: [
          {
            title: 'any unauthorised access and use of the Application by you;',
          },
          {
            title: 'any breach of these Terms by you;',
          },
          {
            title: 'the occurrence of any event due to your act, omission, or default which comprises the security or integrity of the Application; ',
          },
          {
            title: 'any dispute between you and any third party (including our Vendors); and/or',
          },
          {
            title: 'any violation of the laws of the Republic of Singapore.',
          },
        ],
      },
      {
        title: 'You acknowledge and agree that you use the Application at your own risk. We are not responsible for the actions or omissions of Vendors and you hereby release us, and our parent, subsidiaries, affiliates, officers, directors, shareholders, employees, agents, contractors, successors and assigns from any claims and damages, losses and expenses, known and unknown, arising out of or in connection any way connected with any claim you have against any Vendor or third party, or in relation to the Transportation Services provided by the Vendors.',
      },
    ],
  },
  {
    title: 'FORCE MAJEURE',
    list6: [
      {
        title: 'Without prejudice to the applicability and effect of the clauses above disclaiming or limiting liability, we will be excused from any delay or failure to perform any obligation arising under or in connection with these Terms, if such delay or failure results, directly or indirectly, from any act of nature or circumstance or cause beyond our reasonable control including, but not limited to, any Act of God, fire, flood, explosion, lightning, windstorm, earthquake, epidemic, disease outbreak, extraordinarily severe weather conditions, failure of machinery or equipment, shortage of materials, discontinuation of power supply, court order or governmental interference, radioactive, chemical or biological contamination, civil commotion, riot or armed conflict, war, civil war (whether declared or undeclared), acts of terrorism, strike, labour disturbances, transportation difficulties or labour shortages. ',
      },
    ],
  },
  {
    title: 'GOVERNING LAW AND DISPUTE RESOLUTION',
    list6: [
      {
        title: 'These Terms shall be governed by and construed in accordance with the laws of the Republic of Singapore. Any dispute arising out of or in connection with these Terms, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“<b>SIAC</b>”) of Singapore in accordance with the Arbitration Rules of the SIAC (“<b>SIAC Rules</b>”) for the time being in force, which rules are deemed to be incorporated by reference in this clause. The seat of arbitration shall be Singapore, and the Tribunal shall consist of 1 arbitrator. The language of the arbitration shall be in English. The arbitral award is final and binding upon the parties to the arbitration.',
      },
    ],
  },
  {
    title: 'MISCELLANEOUS',
    list6: [
      {
        title: 'These Terms set out the entire agreement between us and you on your use of the Application.',
      },
      {
        title: 'The headings used in these Terms are included for convenience only and will not limit or otherwise affect the provisions herein.',
      },
      {
        title: 'A person who is not a party to these Terms has no right under the Contracts (Rights of Third Parties) Act (Cap. 53B) to enforce any term provided in these Terms. ',
      },
      {
        title: 'If any provision in these Terms is invalid or unenforceable under applicable law, that provision shall be enforced to the maximum extent possible, and the remaining provisions will continue in full force and effect. ',
      },
      {
        title: 'Any failure by us to insist upon strict compliance with any term of these Terms shall not be construed as a waiver with regard to any subsequent failure to comply with such term. ',
      },
      {
        title: 'The rights and remedies under these Terms are cumulative and not exclusive of any other right or remedy provided by law or equity.',
      },
      {
        title: 'You may not transfer or assign any of your contractual rights or obligations without our prior written consent. We may, by notice in writing to you, transfer or assign or novate any of our contractual rights and obligations.',
      },
    ],
  },
];
