import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Map, GoogleApiWrapper} from 'google-maps-react';
import {getGeofencesBusStopApi} from '../../services/mapServices';
import {FormControl, Select, MenuItem} from '@material-ui/core';

/**
 * map component
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      position: '',
      userPickup: {},
      place_name: '',
      bus_stops: [],
      allMarkers: [],
      simulation_id: null,
      service_group_id: null,
      flag: false,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.loadGeoJson(this.props.polygon);
  }

  /**
   * registerCheckPolygonContainPoint
   * @param {object} google
   */
  registerCheckPolygonContainPoint(google) {
    if (!google.maps.Polygon.prototype.Contains) {
      google.maps.Polygon.prototype.Contains = function(point) {
        let crossings = 0;
        const path = this.getPath();

        // for each edge
        for (let i = 0; i < path.getLength(); i++) {
          const a = path.getAt(i);
          let j = i + 1;
          if (j >= path.getLength()) {
            j = 0;
          }
          const b = path.getAt(j);
          if (rayCrossesSegment(point, a, b)) {
            crossings++;
          }
        }

        // odd number of crossings?
        return crossings % 2 === 1;

        /**
         * rayCrossesSegment
         * @param {object} point
         * @param {*} a
         * @param {*} b
         * @return {*}
         */
        function rayCrossesSegment(point, a, b) {
          let px = point.lng();
          let py = point.lat();
          let ax = a.lng();
          let ay = a.lat();
          let bx = b.lng();
          let by = b.lat();
          if (ay > by) {
            ax = b.lng();
            ay = b.lat();
            bx = a.lng();
            by = a.lat();
          }
          // alter longitude to cater for 180 degree crossings
          if (px < 0) {
            px += 360;
          }
          if (ax < 0) {
            ax += 360;
          }
          if (bx < 0) {
            bx += 360;
          }

          if (py === ay || py === by) py += 0.00000001;
          if (py > by || py < ay || px > Math.max(ax, bx)) return false;
          if (px < Math.min(ax, bx)) return true;

          const red = ax !== bx ? (by - ay) / (bx - ax) : Infinity;
          const blue = ax !== px ? (py - ay) / (px - ax) : Infinity;
          return blue >= red;
        }
      };
    }
  }

  /**
   * getTransitStopNearest
   * @param {Number} simulation_id
   * @param {Number} service_group_id
   */
  async getTransitStopNearest(simulation_id, service_group_id) {
    const {google} = this.props;
    const payload = {
      simulation_id: simulation_id ? simulation_id : this.state.simulation_id,
      service_group_id: service_group_id,
      limit: 100,
      offset: 0,
    };
    const transitStop = await getGeofencesBusStopApi(payload);
    if (transitStop.status === 200) {
      this.setState({
        bus_stops: transitStop.result.transit_stops,
      });
      this.removeMarkers();
      await transitStop.result.transit_stops.forEach((item, index) => {
        const marker = new google.maps.Marker({
          position: {lat: item.point.coordinates[1], lng: item.point.coordinates[0]},
          icon: {
            url: `${process.env.PUBLIC_URL}/images/bus_stop.svg`,
            strokeWeight: 1,
            strokeColor: this.props.markerColor,
            fillColor: this.props.markerColor,
            fillOpacity: 1,
          },
          map: this.mapRef?.map,
        });
        marker.info = new google.maps.InfoWindow({
          content: item.name,
        });
        this.setState({
          allMarkers: [...this.state.allMarkers, marker],
        });
        google.maps.event.addListener(marker, 'click', () => {
          this.state.allMarkers.forEach((mark) => {
            if (Number(mark.id) !== Number(marker.id)) {
              mark.setIcon(`${process.env.PUBLIC_URL}/images/bus_stop.svg`);
              mark.info.close();
            }
          });
          marker.setIcon(
            this.props.type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
              `${process.env.PUBLIC_URL}/images/pick_up_position.svg` :
              `${process.env.PUBLIC_URL}/images/drop_off_position.svg`,
          );
          marker.info.open(this.mapRef.map, marker);
          this.setState({
            place_name: marker.info.content,
          });
          this.setPosition({
            latlng: marker.info.position,
            place_name: marker.info.content,
            simulation_id: this.state.simulation_id,
          });

          // focus selected position
          this.mapRef.map.setCenter(marker.info.position);
          this.mapRef.map.setZoom(16);
        });
      });

      // picked position
      if (this.props.pickedPosition.place_name) {
        const picked = this.state.allMarkers?.find((e) => e.info.content === this.props.pickedPosition?.place_name);
        if (picked) {
          picked.setIcon(
            this.props.type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
              `${process.env.PUBLIC_URL}/images/pick_up_position.svg` :
              `${process.env.PUBLIC_URL}/images/drop_off_position.svg`,
          );
          picked.info.open(this.mapRef.map, picked);
          this.setState({
            place_name: picked.info.content,
          });
          this.setPosition({
            latlng: picked.position,
            place_name: picked.info.content,
            simulation_id: this.props.pickedPosition.simulation_id,
          });
        }
      }
    }
  }

  /**
   * removeMarkers
   */
  removeMarkers() {
    const {google} = this.props;
    for (let i = 0; i < this.state.allMarkers.length; i++) {
      google.maps.event.clearInstanceListeners(this.state.allMarkers[i]);
      this.state.allMarkers[i].setMap(null);
    }
    this.setState({
      allMarkers: [],
    });
  }

  /**
   * setting position
   * @param {pos} pos
   */
  setPosition(pos) {
    this.props.position(pos);
  }

  /**
   * componentDidUpdate
   * @param {object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.polygon !== this.props.polygon) {
      this.loadGeoJson(this.props.polygon);
      this.mapRef.map.setCenter(this.props.position);
      this.mapRef.map.setZoom(16);
    }
  }

  /**
   * load Geofences Json
   * @param {object} geojson
   */
  async loadGeoJson(geojson) {
    const {google} = this.props;

    // add single polygon
    if (
      geojson &&
      typeof geojson === 'object' &&
      geojson.constructor === Object &&
      geojson.geometry.type === 'Polygon'
    ) {
      const jsonData = {
        type: 'Feature',
        geometry: {
          type: geojson.geometry.type,
          coordinates: geojson.geometry.coordinates,
        },
        properties: {
          Id: geojson.geojson,
        },
      };
      this.mapRef.map.data.addGeoJson(jsonData, {
        idPropertyName: 'Id',
      });
    }
    // add multiple polygon
    if (geojson && typeof geojson === 'object' && geojson.constructor === Array) {
      geojson.forEach((value) => {
        const jsonData = {
          type: 'Feature',
          geometry: {
            type: value.geometry.type,
            coordinates: value.geometry.coordinates,
          },
          properties: {
            Id: value.geofence_id,
          },
        };
        this.mapRef.map.data.addGeoJson(jsonData, {
          idPropertyName: 'Id',
        });
      });
    }
    // set style to geofence
    this.mapRef.map.data.setStyle({
      strokeColor: '#99cecf',
      fillColor: '#99cecf',
      fillOpacity: 0.5,
      strokeWeight: 0.1,
    });

    // focus to center geofence
    this.registerCheckPolygonContainPoint(google);
    const bounds = new google.maps.LatLngBounds();
    this.mapRef.map.data.forEach((feature) => {
      const polyPath = feature.getGeometry().getAt(0).getArray();
      const poly = new google.maps.Polygon({
        paths: polyPath,
      });
      // eslint-disable-next-line new-cap
      const check = poly.Contains(
          new google.maps.LatLng(this.props.expressBusLocal.lat, this.props.expressBusLocal.lng),
      );
      if (check) {
        feature.getGeometry().forEachLatLng(function(latlng, i) {
          bounds.extend(latlng);
        });
        const simulation_id = this.props.polygon.find((e) => e.geofence_id === feature.getId());
        this.setState({
          simulation_id:
            this.props.type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
              simulation_id.jit_home_to_work_sim_id :
              simulation_id.jit_work_to_home_sim_id,
        });
        this.getTransitStopNearest(
          this.props.type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
            simulation_id.jit_home_to_work_sim_id :
            simulation_id.jit_work_to_home_sim_id,
          simulation_id.service_group_id,
        );
      }
    });
    this.mapRef.map.fitBounds(bounds);
  }

  /**
   * handleChange
   * @param {event} event
   */
  handleChange(event) {
    this.state.allMarkers.forEach((mark) => {
      mark.setIcon(`${process.env.PUBLIC_URL}/images/bus_stop.svg`);
      mark.info.close();
    });
    const marker = this.state.allMarkers.find((e) => e.info.content === event.target.value);
    this.setState({
      place_name: event.target.value,
    });
    marker.setIcon(
      this.props.type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
        `${process.env.PUBLIC_URL}/images/pick_up_position.svg` :
        `${process.env.PUBLIC_URL}/images/drop_off_position.svg`,
    );
    marker.info.open(this.mapRef.map, marker);
    this.setPosition({
      latlng: marker.info.position,
      place_name: marker.info.content,
      simulation_id: this.state.simulation_id,
    });

    // focus selected position
    this.mapRef.map.setCenter(marker.info.position);
    this.mapRef.map.setZoom(16);
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    return (
      <div className="padding-item-0">
        {this.props.mapReadOnly && (
          <span className="font-20 font-weight-600">{this.props.pickedPosition.place_name}</span>
        )}
        <div className="mt-12" style={{display: 'flex', alignItems: 'center'}}>
          <img src={`${process.env.PUBLIC_URL}/images/bus_stop.svg`} alt="icon" />
          <span className="padding-item-0 font-12">
            {this.props.type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
              'アイコンをタップして乗車地を選択' :
              'アイコンをタップして降車地を選択'}
          </span>
        </div>
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          initialCenter={{
            lat: 36.2048,
            lng: 138.2529,
          }}
          zoom={5}
          centerAroundCurrentLocation={false}
          gestureHandling="greedy"
          disableDefaultUI
          containerStyle={{
            border: '1px solid #eeeded',
            width: '100% !important',
            height: 327,
            padding: '0px 24px !important',
            position: 'relative',
          }}
          className="mt-24 width_100"
        ></Map>
        <FormControl variant="outlined" margin="dense" className="width_100 mt-24 select-box">
          <Select
            margin="dense"
            inputProps={{
              name: 'place_name',
            }}
            displayEmpty
            value={this.state.place_name}
            onChange={(event) => this.handleChange(event)}
            renderValue={
              this.state.place_name ?
                undefined :
                () => (
                  <div className="font-12 color-disabled">
                    {this.props.type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                        '降車地を選択してください' :
                        '乗車地を選択してください'}
                  </div>
                )
            }
          >
            {this.state.bus_stops?.map((value, idx) => {
              return (
                <MenuItem value={value.name} key={idx}>
                  {value.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  }
}

// eslint-disable-next-line new-cap
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
})(withTranslation('translations')(Index));
