import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import {
  Container,
  Grid,
  FormControl,
  TextField,
  Modal,
  Backdrop,
  Paper,
  FormHelperText,
  MenuItem,
  Select,
  Button,
  Collapse,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Terms from '../../terms';
import Privacy from '../../privacy';
import {ROUTE} from '../../../common/constant';
import {onChangeSelect, onChangeTextField} from '../../../utils/common';
import {getPickUpPlaces, getDropOffPlaces, getExpectedTimes, asahikawaBusData} from '../../../stores/asahikawa/actions';
import {displayTimeOnly, convertDatetimeUTC, payloadDate, getClientTimezone} from '../../../utils/date_time';
import './styles.css';

const people = [
  {value: 1, display: '1名'},
  {value: 2, display: '2名'},
  {value: 3, display: '3名'},
  {value: 4, display: '4名'},
];

/**
 * Register shuttle bus Asahikawa
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      booking_id: '',
      pick_up_id: '',
      pick_up_name: '',
      pick_up_lat: null,
      pick_up_lon: null,
      drop_off_id: '',
      drop_off_name: '',
      drop_off_lat: null,
      drop_off_lon: null,
      shuttle_bus_type: '',
      expected_date: null,
      expected_time: '',
      passenger_name: '',
      passenger_email: '',
      phone: '',
      number_in_use: 1,
      simulation_id: null,
      geofence_id: '',
      status: '',
      isTermConditions: false,
      isPrivacyPolicy: false,

      open: false,
      openModal: false,
      contentModal: true,
      isSubmitForm: false,
      isPickUpFirst: false,
      isDropOffFirst: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (_.isEmpty(this.props.asahikawa?.asahikawa_bus)) {
      this.props.history.push(ROUTE.ASAHIKAWA);
    } else {
      this.props.getPickUpPlaces();
      this.props.getDropOffPlaces();
      const data = this.props.asahikawa?.asahikawa_bus;
      this.setState({
        id: data.id ? data.id : -1,
        booking_id: data?.booking_id,
        pick_up_id: data.pick_up_id ? data.pick_up_id : '',
        pick_up_name: data?.pick_up_name,
        pick_up_lat: data?.pick_up_lat,
        pick_up_lon: data?.pick_up_lon,
        drop_off_id: data.drop_off_id ? data.drop_off_id : '',
        drop_off_name: data?.drop_off_name,
        drop_off_lat: data?.drop_off_lat,
        drop_off_lon: data?.drop_off_lon,
        shuttle_bus_type: data?.shuttle_bus_type,
        expected_date: data.expected_time ? new Date(data.expected_time) : null,
        expected_time: data.expected_time ? data.expected_time : '',
        passenger_name: data.passenger_name ? data.passenger_name : '',
        passenger_email: data.passenger_email ? data.passenger_email : '',
        phone: data.phone ? data.phone : '',
        number_in_use: data.number_in_use ? data.number_in_use : '',
        simulation_id: data?.simulation_id,
        geofence_id: data?.geofence_id,
        status: data?.status,
        isTermConditions: data?.isTermConditions,
        isPrivacyPolicy: data?.isPrivacyPolicy,
      });

      if (data?.expected_time) {
        this.props.getExpectedTimes(
            payloadDate(data?.expected_time),
            getClientTimezone(),
          data?.geofence_id,
          data?.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? 1 : 2,
        );
      }
      window.scrollTo(0, 0);
    }
  }

  /**
   * onChangePickUpPlace
   * @param {event} event
   */
  onChangePickUp = (event) => {
    const pick_up = this.props.asahikawa.pick_up_places?.find((e) => e.transit_stops_id === event.target.value);
    this.setState({
      pick_up_id: event.target.value,
      pick_up_name: pick_up.name,
      pick_up_lat: pick_up.lat,
      pick_up_lon: pick_up.lon,
      expected_date: null,
      expected_time: '',
    });
    if (
      (!this.state.isPickUpFirst && !this.state.isDropOffFirst) ||
      (this.state.isPickUpFirst && !this.state.isDropOffFirst)
    ) {
      this.setState({
        isPickUpFirst: true,
        drop_off_id: '',
        drop_off_name: '',
        drop_off_lat: null,
        drop_off_lon: null,
        shuttle_bus_type:
          pick_up.place_type === 'SHUTTLE_BUS_JIT_HOME' ?
            'SHUTTLE_BUS_JIT_HOME_TO_WORK' :
            'SHUTTLE_BUS_JIT_WORK_TO_HOME',
      });
      this.props.getDropOffPlaces(
          pick_up.transit_stops_id,
        pick_up.place_type === 'SHUTTLE_BUS_JIT_HOME' ? 'SHUTTLE_BUS_JIT_WORK' : 'SHUTTLE_BUS_JIT_HOME',
      );
    } else {
      const geofences = this.props.maps?.geofences?.find((e) => e.geofence_id === pick_up.geofence_id);
      this.setState({
        simulation_id:
          pick_up.place_type === 'SHUTTLE_BUS_JIT_HOME' ?
            geofences?.jit_home_to_work_sim_id :
            geofences?.jit_work_to_home_sim_id,
        geofence_id: geofences.geofence_id,
      });
    }
  };

  /**
   * onChangeDropOffPlace
   * @param {event} event
   */
  onChangeDropOff = (event) => {
    const drop_off = this.props.asahikawa.drop_off_places?.find((e) => e.transit_stops_id === event.target.value);
    this.setState({
      drop_off_id: event.target.value,
      drop_off_name: drop_off.name,
      drop_off_lat: drop_off.lat,
      drop_off_lon: drop_off.lon,
      expected_date: null,
      expected_time: '',
    });
    if (
      (!this.state.isPickUpFirst && !this.state.isDropOffFirst) ||
      (!this.state.isPickUpFirst && this.state.isDropOffFirst)
    ) {
      this.setState({
        isDropOffFirst: true,
        pick_up_id: '',
        pick_up_name: '',
        pick_up_lat: null,
        pick_up_lon: null,
        shuttle_bus_type:
          drop_off.place_type === 'SHUTTLE_BUS_JIT_HOME' ?
            'SHUTTLE_BUS_JIT_WORK_TO_HOME' :
            'SHUTTLE_BUS_JIT_HOME_TO_WORK',
      });
      this.props.getPickUpPlaces(
          drop_off.transit_stops_id,
        drop_off.place_type === 'SHUTTLE_BUS_JIT_HOME' ? 'SHUTTLE_BUS_JIT_WORK' : 'SHUTTLE_BUS_JIT_HOME',
      );
    } else {
      const geofences = this.props.maps?.geofences?.find((e) => e.geofence_id === drop_off.geofence_id);
      this.setState({
        simulation_id:
          drop_off.place_type === 'SHUTTLE_BUS_JIT_HOME' ?
            geofences?.jit_work_to_home_sim_id :
            geofences?.jit_home_to_work_sim_id,
        geofence_id: geofences.geofence_id,
      });
    }
  };

  /**
   * handleSelectDate
   * @param {Date} date
   */
  handleSelectDate = (date) => {
    this.setState({expected_date: date, expected_time: ''});
    this.props.getExpectedTimes(
        payloadDate(date),
        getClientTimezone(),
        this.state.geofence_id,
      this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? 1 : 2,
    );
  };

  /**
   * onclick text field to open calendar
   * @param {Boolean} status
   */
  setPickerStatus = (status) => {
    this.setState({
      open: status,
    });
  };

  /**
   * handle open modal with term's content
   */
  handleOpenTerms = () => {
    this.setState({
      openModal: true,
      contentModal: true,
    });
  };

  /**
   * handle open modal with policy's content
   */
  handleOpenPolicy = () => {
    this.setState({
      openModal: true,
      contentModal: false,
    });
  };

  /**
   * handleSubmitData
   * @param {event} e
   */
  handleSubmitData = (e) => {
    e.preventDefault();
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.props.asahikawaBusData({
        id: this.state.id,
        booking_id: this.state.booking_id,
        pick_up_id: this.state.pick_up_id,
        pick_up_name: this.state.pick_up_name,
        pick_up_lat: this.state.pick_up_lat,
        pick_up_lon: this.state.pick_up_lon,
        drop_off_id: this.state.drop_off_id,
        drop_off_name: this.state.drop_off_name,
        drop_off_lat: this.state.drop_off_lat,
        drop_off_lon: this.state.drop_off_lon,
        expected_date: this.state.expected_date,
        expected_time: this.state.expected_time,
        shuttle_bus_type: this.state.shuttle_bus_type,
        passenger_name: this.state.passenger_name,
        passenger_email: this.state.passenger_email,
        phone: this.state.phone,
        number_in_use: this.state.number_in_use,
        simulation_id: this.state.simulation_id,
        geofence_id: this.state.geofence_id,
        status: this.state.status,
        isTermConditions: this.state.isTermConditions,
        isPrivacyPolicy: this.state.isPrivacyPolicy,
      });
      this.props.history.push(ROUTE.ASAHIKAWA_CONFIRM);
    }
  };

  /**
   * render HTML
   * @return {component}
   */
  render() {
    const {asahikawa, t} = this.props;

    return (
      <Container maxWidth="sm" className="p-0 body_content">
        <LoadingOverlay
          active={asahikawa.isLoading}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          <Grid container className="pt-12">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
            </Grid>
          </Grid>
          <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
            <span className="padding-item-0 font-weight-600">
              行きたい場所の乗車地/降車地を選んでから、 ご希望の時間帯を選択してください
            </span>
          </Grid>
          {/* 往路 WILLER mobi 搭乗者情報 */}
          <Grid container>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">WILLER mobi 乗車地</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
              <FormControl variant="outlined" className="width_100 padding-item-0">
                <Select
                  margin="dense"
                  inputProps={{
                    name: 'pick_up_id',
                  }}
                  displayEmpty
                  value={this.state.pick_up_id}
                  onChange={(event) => this.onChangePickUp(event)}
                  renderValue={
                    this.state.pick_up_id ?
                      undefined :
                      () => <div className="font-12 color-disabled">ご乗車地を選択してください</div>
                  }
                >
                  {asahikawa?.pick_up_places?.map((e, index) => (
                    <MenuItem value={e.transit_stops_id} key={index}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
                {this.validator.message('pick_up_id', this.state.pick_up_id, 'required')}
                {this.state.isSubmitForm && !this.validator.check(this.state.pick_up_id, 'required') && (
                  <FormHelperText id="phone" error>
                    {t('validation.required.choose', {field: 'WILLER mobi 乗車地'})}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-24">
              <MoreVertIcon />
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24">
              <span className="padding-item-0 font-weight-600">WILLER mobi 降車地</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
              <FormControl variant="outlined" className="width_100 padding-item-0">
                <Select
                  margin="dense"
                  inputProps={{
                    name: 'drop_off_id',
                  }}
                  displayEmpty
                  value={this.state.drop_off_id}
                  onChange={(event) => this.onChangeDropOff(event)}
                  renderValue={
                    this.state.drop_off_id ?
                      undefined :
                      () => <div className="font-12 color-disabled">ご降車地を選択してください</div>
                  }
                >
                  {asahikawa.drop_off_places?.map((e, index) => (
                    <MenuItem value={e.transit_stops_id} key={index}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
                {this.validator.message('drop_off_id', this.state.drop_off_id, 'required')}
                {this.state.isSubmitForm && !this.validator.check(this.state.drop_off_id, 'required') && (
                  <FormHelperText id="phone" error>
                    {t('validation.required.choose', {field: 'WILLER mobi 降車地'})}
                  </FormHelperText>
                )}
              </FormControl>
              <hr className="mt-24 width_100 divider"></hr>
            </Grid>
            <Collapse in={!!this.state.pick_up_id && !!this.state.drop_off_id} className="width_100">
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                <span className="padding-item-0">
                  <span className="font-weight-600">ご希望の</span>
                  <span className="font-weight-600 span_text_border">
                    {this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? '到着日時' : '出発日時'}
                  </span>
                  <span className="font-weight-600">選択してください</span>
                </span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
                <span className="padding-item-0 font-weight-600">
                  {this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                    'WILLER mobi 降車希望日時' :
                    'WILLER mobi 乗車希望日時'}
                </span>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
                <FormControl variant="outlined" className="width_100 padding-item-0">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="yyyy年MM月dd日"
                      margin="none"
                      placeholder={
                        this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                          '到着希望日付を選択してください' :
                          '出発希望日付を選択してください'
                      }
                      className="width_100"
                      value={this.state.expected_date}
                      onChange={this.handleSelectDate}
                      onClick={() => this.setPickerStatus(true)}
                      onClose={() => this.setPickerStatus(false)}
                      InputProps={{
                        readOnly: true,
                      }}
                      open={this.state.open}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {this.validator.message('expected_date', this.state.expected_date, 'required')}
                  {this.state.isSubmitForm && !this.validator.check(this.state.expected_date, 'required') && (
                    <FormHelperText id="expected_date" error>
                      {t('validation.required.choose', {
                        field:
                          this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                            'WILLER mobi 降車希望日付' :
                            'WILLER mobi 乗車希望日付',
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
                <FormControl variant="outlined" className="width_100 padding-item-0">
                  <Select
                    margin="dense"
                    inputProps={{
                      name: 'expected_time',
                    }}
                    displayEmpty
                    disabled={!this.state.expected_date}
                    value={this.state.expected_time}
                    onChange={(event) => onChangeSelect(this, event)}
                    renderValue={
                      this.state.expected_time &&
                      asahikawa.expected_times?.find((e) => e.expected_time === this.state.expected_time) ?
                        undefined :
                        () => (
                          <div className="font-12 color-disabled">
                            {this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                                '到着希望時間を選択してください' :
                                '出発希望時間を選択してください'}
                          </div>
                        )
                    }
                  >
                    {asahikawa?.expected_times?.length > 0 ? (
                      asahikawa.expected_times?.map((e, index) => (
                        <MenuItem value={e.expected_time} key={index}>
                          {displayTimeOnly(convertDatetimeUTC(e.expected_time))}&nbsp;
                          {this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? '到着' : '出発'}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <span className="no_item_list">本日デマンドサービスを提供できません</span>
                      </MenuItem>
                    )}
                  </Select>
                  {this.validator.message('expected_time', this.state.expected_time, 'required')}
                  {this.state.isSubmitForm && !this.validator.check(this.state.expected_time, 'required') && (
                    <FormHelperText id="expected_time" error>
                      {t('validation.required.choose', {
                        field:
                          this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                            'WILLER mobi 降車希望時間' :
                            'WILLER mobi 乗車希望時間',
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
                <hr className="mt-24 width_100 divider"></hr>
              </Grid>
            </Collapse>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">乗車代表者氏名</span>
              <img src={`${process.env.PUBLIC_URL}/images/required.svg`} alt="Logo" className="logo_required" />
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
              <FormControl className="width_100 padding-item-0">
                <TextField
                  name="passenger_name"
                  variant="outlined"
                  className="width_100"
                  placeholder="氏名を入力してください"
                  value={this.state.passenger_name}
                  onChange={(event) => onChangeTextField(this, event)}
                />
                {this.validator.message('passenger_name', this.state.passenger_name, 'required|max:255,string')}
                {this.state.isSubmitForm && !this.validator.check(this.state.passenger_name, 'required') && (
                  <FormHelperText id="passenger_name" error>
                    {t('validation.required', {field: '乗車代表者氏名'})}
                  </FormHelperText>
                )}
                {this.state.isSubmitForm &&
                  this.validator.check(this.state.passenger_name, 'required') &&
                  !this.validator.check(this.state.passenger_name, 'max:255,string') && (
                  <FormHelperText id="passenger_name" error>
                    {t('validation.length', {field: '乗車代表者氏名', number: 255})}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">連絡用Eメールアドレス</span>
              <img src={`${process.env.PUBLIC_URL}/images/required.svg`} alt="Logo" className="logo_required" />
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
              <FormControl className="width_100 padding-item-0">
                <TextField
                  name="passenger_email"
                  variant="outlined"
                  className="width_100"
                  placeholder="Eメールアドレスを入力してください"
                  value={this.state.passenger_email}
                  onChange={(event) => onChangeTextField(this, event)}
                />
                {this.validator.message('passenger_email', this.state.passenger_email, 'required|email|max:255,string')}
                {this.state.isSubmitForm && !this.validator.check(this.state.passenger_email, 'required') && (
                  <FormHelperText id="passenger_email" error>
                    {t('validation.required', {field: '連絡用Eメールアドレス'})}
                  </FormHelperText>
                )}
                {this.state.isSubmitForm &&
                  this.validator.check(this.state.passenger_email, 'required') &&
                  !this.validator.check(this.state.passenger_email, 'email') && (
                  <FormHelperText id="passenger_email" error>
                    {t('validation.email')}
                  </FormHelperText>
                )}
                {this.state.isSubmitForm &&
                  this.validator.check(this.state.passenger_email, 'required') &&
                  this.validator.check(this.state.passenger_email, 'email') &&
                  !this.validator.check(this.state.passenger_email, 'max:255,string') && (
                  <FormHelperText id="passenger_email" error>
                    {t('validation.length', {field: '連絡用Eメールアドレス', number: 255})}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">電話番号（ハイフンなし）</span>
              <img src={`${process.env.PUBLIC_URL}/images/required.svg`} alt="Logo" className="logo_required" />
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
              <FormControl className="width_100 padding-item-0">
                <TextField
                  name="phone"
                  variant="outlined"
                  className="width_100"
                  placeholder="例：09012345678"
                  value={this.state.phone}
                  onChange={(event) => onChangeTextField(this, event)}
                />
                {this.validator.message('phone', this.state.phone, 'required|numeric|between:10,11,string')}
                {this.state.isSubmitForm && !this.validator.check(this.state.phone, 'required') && (
                  <FormHelperText id="phone" error>
                    {t('validation.required', {field: '電話番号'})}
                  </FormHelperText>
                )}
                {this.state.isSubmitForm &&
                  this.validator.check(this.state.phone, 'required') &&
                  !this.validator.check(this.state.phone, 'numeric') && (
                  <FormHelperText id="phone" error>
                    {t('validation.numeric', {field: '電話番号'})}
                  </FormHelperText>
                )}
                {this.state.isSubmitForm &&
                  this.validator.check(this.state.phone, 'required') &&
                  this.validator.check(this.state.phone, 'numeric') &&
                  !this.validator.check(this.state.phone, 'between:10,11,string') && (
                  <FormHelperText id="phone" error>
                      電話番号は10桁から11桁で入力してください
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">利用人数</span>
              <img src={`${process.env.PUBLIC_URL}/images/required.svg`} alt="Logo" className="logo_required" />
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12 mb-50">
              <FormControl variant="outlined" className="width_100 padding-item-0">
                <Select
                  margin="dense"
                  inputProps={{
                    name: 'number_in_use',
                  }}
                  displayEmpty
                  value={this.state.number_in_use}
                  onChange={(event) => onChangeSelect(this, event)}
                  renderValue={
                    this.state.number_in_use ?
                      undefined :
                      () => <div className="font-12 color-disabled">ご利用人数を選択してください</div>
                  }
                >
                  {people.map((p, index) => (
                    <MenuItem value={p.value} key={index}>
                      {p.display}
                    </MenuItem>
                  ))}
                </Select>
                {this.validator.message('number_in_use', this.state.number_in_use, 'required')}
                {this.state.isSubmitForm && !this.validator.check(this.state.number_in_use, 'required') && (
                  <FormHelperText id="number_in_use" error>
                    {t('validation.required.choose', {field: '利用人数'})}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className="pt-12">
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="padding-item-0">
              <CheckCircle
                style={{fontSize: 36, marginRight: 12}}
                className={this.state.isTermConditions ? 'color-success' : 'color-secondary'}
                onClick={() => this.setState({isTermConditions: !this.state.isTermConditions})}
              />
              <span
                className="font-16 font-weight-600 color-info cursor_pointer"
                onClick={() => this.handleOpenTerms()}
              >
                利用規約
              </span>
              <span className="font-16 font-weight-600">に同意する</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24 padding-item-0">
              <CheckCircle
                style={{fontSize: 36, marginRight: 12}}
                className={this.state.isPrivacyPolicy ? 'color-success' : 'color-secondary'}
                onClick={() => this.setState({isPrivacyPolicy: !this.state.isPrivacyPolicy})}
              />
              <span
                className="font-16 font-weight-600 color-info cursor_pointer"
                onClick={() => this.handleOpenPolicy()}
              >
                プライバシーポリシー
              </span>
              <span className="font-16 font-weight-600">に同意する</span>
            </Grid>
          </Grid>
          {/* Button submit */}
          <Grid container>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <div className="width_100 padding-item-0">
                <Button
                  variant="contained"
                  className={
                    this.state.isTermConditions && this.state.isPrivacyPolicy ?
                      'width_100 button_color_submit font-weight-600' :
                      'width_100 button_color_disabled font-weight-600'
                  }
                  onClick={this.handleSubmitData}
                  disabled={!(this.state.isTermConditions && this.state.isPrivacyPolicy)}
                >
                  次へ
                  <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container className="footer">
            <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
              <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
            </Grid>
          </Grid>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <Paper className="terms_and_privacy">
              <Grid item xs={12} justify="flex-end" alignItems="center" container className="terms_and_privacy_header">
                <CloseIcon className="btn_close_modal" onClick={() => this.setState({openModal: false})} />
              </Grid>
              <Grid
                item
                xs={12}
                justify="center"
                alignItems="center"
                container
                style={{scrollbarWidth: 'thin'}}
                className="terms_and_privacy_content"
              >
                {this.state.contentModal ? <Terms /> : <Privacy />}
              </Grid>
            </Paper>
          </Modal>
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    asahikawa: state.asahikawa,
    maps: state.maps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPickUpPlaces: (geofences_id, place_type) => dispatch(getPickUpPlaces(geofences_id, place_type)),
    getDropOffPlaces: (geofences_id, place_type) => dispatch(getDropOffPlaces(geofences_id, place_type)),
    getExpectedTimes: (date_time, zone_id, geofence_id, time_type) =>
      dispatch(getExpectedTimes(date_time, zone_id, geofence_id, time_type)),
    asahikawaBusData: (data) => dispatch(asahikawaBusData(data)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
