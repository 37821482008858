import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Paper, Container, Button, Grid} from '@material-ui/core';
import LoadingCircle from '../../components/loading_circle';
import './style.css';

/**
 * Modal component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
    };
    this.handleClickOk = this.handleClickOk.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (this.props.usage_type === 'LOADING') {
      const interval = setInterval(() => {
        this.setState({progress: this.state.progress + 20});
        this.successLoading(this.state.progress);
        if (this.state.progress === 100) {
          clearInterval(interval);
        }
      }, 200);
    }
  }

  /**
   * success loading
   * @param {Number} progress
   */
  successLoading(progress) {
    this.props.loadingComplete(progress);
  }

  /**
   * handle click button
   */
  handleClickOk() {
    this.props.onClickOk(this.props.shuttleId);
  }

  /**
   * handle click button
   */
  handleClickCancel() {
    this.props.onClickCancel();
  }

  /**
   * render component by usage type
   * @param {String} usageType
   * @return {*}
   */
  renderByUsageType = (usageType) => {
    switch (usageType) {
      case 'DELETE':
        return (
          <Container maxWidth="xl" className="mobile_modal_container">
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-48">
              <img src={`${process.env.PUBLIC_URL}/images/failure.png`} alt="Logo" style={{width: 72}} />
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-20 color-danger">キャンセルしますか？</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-12">一度キャンセルすると</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12}>
              <span className="font-12">取り消しできません</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-36">
              <Grid item container alignContent="center" justify="center" xs={6}>
                <Button
                  variant="contained"
                  style={{backgroundColor: '#FF483C', borderRadius: '0px 0px 0px 12px'}}
                  className="width_100 button_color_secondary"
                  onClick={this.handleClickCancel}
                >
                  閉じる
                </Button>
              </Grid>
              <Grid item container alignContent="center" justify="center" xs={6}>
                <Button
                  variant="contained"
                  style={{backgroundColor: '#FF483C', borderRadius: '0px 0px 12px 0px'}}
                  className="width_100 button_color_danger"
                  onClick={this.handleClickOk}
                >
                  キャンセル
                </Button>
              </Grid>
            </Grid>
          </Container>
        );
      case 'NOTIFY':
        return (
          <Container maxWidth="xl" className="mobile_modal_container">
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-48">
              <img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Logo" style={{width: 72}} />
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-20 color-success">全ての予約が</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12}>
              <span className="font-20 color-success">キャンセルされました</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-12">現在、予約はありません。</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-36">
              <Grid item container alignContent="center" justify="center" xs={6}>
                <Button
                  variant="contained"
                  style={{backgroundColor: '#FF483C', borderRadius: '0px 0px 0px 12px'}}
                  className="width_100 button_color_secondary"
                  onClick={this.handleClickCancel}
                >
                  閉じる
                </Button>
              </Grid>
              <Grid item container alignContent="center" justify="center" xs={6}>
                <Button
                  variant="contained"
                  style={{backgroundColor: '#18C090', borderRadius: '0px 0px 12px 0px'}}
                  className="width_100 button_color_success"
                  onClick={this.handleClickOk}
                >
                  予約画面に戻る
                </Button>
              </Grid>
            </Grid>
          </Container>
        );
      case 'CANCEL':
        return (
          <Container maxWidth="xl" className="mobile_modal_container">
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-48">
              <img src={`${process.env.PUBLIC_URL}/images/failure.png`} alt="Logo" style={{width: 72}} />
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-20 color-danger">キャンセルしました</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-12">正常にキャンセル手続きが</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12}>
              <span className="font-12">完了しました</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-36">
              <Grid item container alignContent="center" justify="center" xs={12}>
                <Button
                  variant="contained"
                  style={{backgroundColor: '#18C090', borderRadius: '0px 0px 12px 12px'}}
                  className="width_100 button_color_success"
                  onClick={this.handleClickOk}
                >
                  トップに戻る
                </Button>
              </Grid>
            </Grid>
          </Container>
        );
      case 'LOADING':
        return (
          <Container maxWidth="xl" className="mobile_modal_container">
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-48">
              <LoadingCircle radius={36} stroke={4} progress={this.state.progress} />
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-20 color-success">予約リクエスト中です</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-12">ご入力いただいた内容で</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12}>
              <span className="font-12">予約リクエストをかけています</span>
            </Grid>
            <Grid
              item
              container
              alignContent="center"
              justify="center"
              xs={12}
              className="mt-48"
              style={{height: 48}}
            ></Grid>
          </Container>
        );
      case 'VALIDATOR':
        return (
          <Container maxWidth="xl" className="mobile_modal_container">
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-48">
              <img src={`${process.env.PUBLIC_URL}/images/failure.png`} alt="Logo" style={{width: 72}} />
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-20 color-danger">入力エラーがあります</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-24">
              <span className="font-12">ご入力いただいた内容を</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12}>
              <span className="font-12">ご確認ください</span>
            </Grid>
            <Grid item container alignContent="center" justify="center" xs={12} className="mt-48">
              <Grid item container alignContent="center" justify="center" xs={12}>
                <Button
                  variant="contained"
                  style={{backgroundColor: '#E8E8E8', borderRadius: '0px 0px 12px 12px'}}
                  className="width_100 button_color_secondary"
                  onClick={this.handleClickOk}
                >
                  閉じる
                </Button>
              </Grid>
            </Grid>
          </Container>
        );
      default:
        return;
    }
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    return <Paper className="mobile_modal">{this.renderByUsageType(this.props.usage_type)}</Paper>;
  }
}

export default withTranslation('translations')(Index);
