/* eslint-disable max-len */
export default [
  {
    title: 'Điều khoản sử dụng ứng dụng mobi Community Mobility',
    noneDescription: true,
  },
  {
    description:
      'Điều khoản sử dụng ứng dụng mobi Community Mobility (Dưới đây gọi tắt là "Điều khoản này") là văn bản quy định các điều kiện sử dụng ứng dụng mobi Community Mobility (Dưới đây gọi tắt là "Ứng dụng này") do Công ty WILLERS PTE PLD. (Dưới đây gọi tắt là "Công ty chúng tôi") vận hành và cung cấp.',
  },
  {
    title: 'Điều 1. Quy định chung',
    afterNoneDescription: true,
    list1: [
      {
        title:
          'Ứng dụng này có thể được sử dụng bởi người dùng đã đồng ý với các điều khoản sử dụng và đã cài đặt ứng dụng này (sau đây gọi tắt là "Người dùng") trên thiết bị thông tin di động như điện thoại thông minh do Người dùng sở hữu hoặc quản lý (sau đây gọi tắt là "thiết bị sử dụng").',
      },
      {
        title:
          'Bằng cách sử dụng ứng dụng này hoặc xem hoặc đăng ký dịch vụ riêng lẻ quy định tại Điều 2, người dùng được coi là đã xác nhận toàn bộ nội dung và đồng ý với điều khoản này, điều khoản quy định về dịch vụ riêng lẻ tại điều 2 (Dưới đây gọi tắt là “Điều khoản riêng”), và các quy định, quy tắc đính kèm điều khoản này và điều khoản riêng (Dưới đây gọi chung là “Các điều khoản này”).',
      },
      {
        title:
          'Nội dung của các điều khoản này có thể được thay đổi trong các trường hợp sau theo quyết định của Công ty hoặc người quản lý các điều khoản riêng. Trong trường hợp này, những nội dung thay đổi của điều khoản này sẽ được áp dụng cho mọi sử dụng từ sau ngày thay đổi. Ngoài ra, khi chúng tôi thay đổi nội dung của các điều khoản này, chúng tôi sẽ thông báo cho người dùng về những nội dung đã thay đổi trước một khoảng thời gian hợp lý.',
        list3: {
          content: [
            {
              title: 'Khi việc thay đổi nội dung các điều khoản này được thực hiện vì lợi ích chung của người dùng.',
            },
            {
              title:
                'Khi việc thay đổi nội dung các điều khoản này không vi phạm mục đích của các điều khoản này, và khi thấy sự cần thiết phải thay đổi, sự phù hợp của nội dung thay đổi, nội dung thay đổi và các trường hợp khác liên quan đến thay đổi đều hợp lý.',
            },
          ],
        },
      },
      {
        title:
          'Ngay cả khi một số quy định của các điều khoản này bị pháp luật hoặc phán quyết vô hiệu hoặc bị hủy bỏ thì tính hiệu lực, tính hợp pháp và hiệu lực ràng buộc pháp lý của các điều khoản còn lại vẫn sẽ còn hiệu lực...',
      },
    ],
  },
  {
    title: 'Điều 2. Dịch vụ cung cấp',
    list1: [
      {
        title:
          'Trong ứng dụng này, người dùng có thể được cung cấp miễn phí các dịch vụ chỉ định trong từng mục sau đây (Dưới đây gọi là "Dịch vụ này"). ',
        list3: {
          content: [
            {
              title:
                'Tìm kiếm tuyến đường: Có thể sử dụng các công cụ tìm kiếm được cung cấp bởi ứng dụng này để tìm kiếm và hiển thị một tuyến đường hoặc nhiều tuyến đường là tuyến đường và phương tiện di chuyển tối ưu nhất để đi từ điểm xuất phát đến điểm đến mong muốn trong một khu vực cụ thể. ',
            },
            {
              title:
                'Tìm kiếm dịch vụ riêng lẻ: Có thể hiển thị một hoặc nhiều kết quả tìm kiếm liên quan đến các tuyến đường ở trên hoặc kết quả tìm kiếm của người dùng về các dịch vụ liên quan đến vận tải do các công ty kinh doanh khác ngoài công ty chúng tôi (Dưới đây gọi là "dịch vụ riêng lẻ") cung cấp thông qua các chức năng của ứng dụng.',
            },
            {
              title:
                'Đại lý thanh toán: trường hợp thanh toán những chi phí phát sinh thông qua việc sử dụng ứng dụng này, công ty chúng tôi sẽ thanh toán những chi phí này cho những doanh nghiệp cung cấp dịch vụ riêng lẻ (dưới đây gọi là “nhà cung cấp”) thông qua bên thứ ba là công ty trung gian thanh toán. Tuy nhiên, phương thức thanh toán cho dịch vụ riêng lẻ sẽ được quy định trong điều khoản riêng lẻ.',
            },
          ],
        },
      },
      {
        title:
          'Khi người dùng đăng ký dịch vụ riêng lẻ, công ty chúng tôi sẽ giới thiệu các dịch vụ riêng lẻ thông qua ứng dụng hoặc trong một số trường hợp, chúng tôi sẽ hoạt động như một đại lý thanh toán, người dùng sẽ ký hợp đồng cho dịch vụ riêng lẻ chỉ với nhà cung cấp.',
      },
      {
        title:
          'Tùy thuộc vào vị trí của người dùng, thiết bị kỹ thuật số cầm tay hỗ trợ cá nhân được sử dụng, tình hình đường truyền thông tin và các môi trường sử dụng khác mà một phần hoặc toàn bộ các tính năng của dịch vụ này có thể không sử dụng được.',
      },
      {
        title:
          'Công ty sẽ có thể thay đổi, bổ sung hoặc hủy bỏ toàn bộ hoặc một phần dịch vụ này hoặc dịch vụ riêng lẻ (Dưới đây gọi là "Các thay đổi") theo quyết định riêng của mình sau khi thông báo một thời gian phù hợp. Công ty không chịu trách nhiệm về bất kỳ thiệt hại hoặc bất lợi nào gây ra cho người dùng do những thay đổi trong dịch vụ này hoặc các dịch vụ riêng lẻ.',
      },
      {
        title:
          'Trường hợp có nội dung mâu thuẫn giữa từng điều khoản chi tiết riêng và điều khoản này, thì các quy định của từng điều khoản chi tiết riêng sẽ được ưu tiên áp dụng trừ khi có quy định khác.',
      },
    ],
  },
  {
    title: 'Điều 3. Đăng ký thẻ tín dụng',
    list1: [
      {
        title:
          'Khi đăng ký thẻ tín dụng để thanh toán thay cho các dịch vụ chi tiết riêng, người dùng chỉ có thể đăng ký thẻ tín dụng dưới danh nghĩa của chính người dùng.',
      },
      {
        title:
          'Người dùng không được sử dụng thẻ tín dụng của người khác, nhập thông tin thẻ tín dụng giả hoặc thực hiện bất kỳ hành vi nào khác mà Công ty cho là không phù hợp. Công ty có thể yêu cầu người dùng bồi thường cho những thiệt hại do người dùng gây ra do đã tiến hành những vi phạm này.',
      },
      {
        title:
          'Công ty chúng tôi có thể thay đổi thẻ tín dụng có thể sử dụng cho dịch vụ này và các dịch vụ riêng lẻ theoquyết định của Công ty hoặc Công ty trung gian thanh toán liên kết.',
      },
      {
        title:
          'Nếu Công ty phát hành thẻ hoặc Công ty trung gian thanh toán vì một lý do nào đó đã từ chối thẻ tín dụng mà người dùng đã sử dụng để thanh toán, hoặc nếu xác định được rằng thẻ tín dụng được sử dụng không phù hợp theo các tiêu chuẩn do Công ty quy định, Công ty chúng tôi có thể yêu cầu người dùng thay đổi phương thức thanh toán hoặc thực hiện các biện pháp khác mà Công ty cho là cần thiết.',
      },
      {
        title:
          'Trường hợp sử dụng thẻ tín dụng làm phương thức thanh toán cho các dịch vụ riêng lẻ và có sự thay đổi nội dung hợp đồng do lý do từ phía người dùng, hoặc phí thay đổi phát sinh khi hủy dịch vụ, hoặc phí hủy dịch vụ được quy định trong điều khoản riêng lẻ, phí thay đổi hoặc phí hủy này cũng sẽ được thanh toán thông qua thẻ tín dụng mà chúng tôi sử dụng.',
      },
    ],
  },
  {
    title: 'Điều 4. Phương pháp thông báo tới người dùng',
    description:
      'Khi Công ty chúng tôi và Công ty cung cấp cần thông báo về việc sử dụng dịch vụ này và các dịch vụ chi tiết riêng theo quyết định riêng của mình, thì có thể tiến hành bằng cách thông báo trên ứng dụng này, thông báo bằng e-mail đến địa chỉ e-mail đã đăng ký trên ứng dụng này, hoặc đăng tải thông tin trên trang chủ do mình quản lý hoặc bằng bất kỳ một hoặc nhiều phương pháp thích hợp khác.',
  },
  {
    title: 'Điều 5. Thông tin cá nhân',
    description:
      'Công ty sẽ sử dụng thông tin cá nhân của người dùng có được thông qua việc cung cấp ứng dụng này hoặc dịch vụ này theo các quy định trong chính sách bảo mật của công ty và quy định hiện hành của Luật bảo vệ thông tin cá nhân được áp dụng và người dùng đồng ý với điều này.',
  },
  {
    title: 'Điều 6. Sử dụng dữ liệu',
    description:
      'Công ty chúng tôi sẽ không truy cập hoặc tiết lộ cho bên thứ ba thông tin cá nhân người dùng đã đăng ký hoặc dữ liệu chúng tôi thu thập được qua việc sử dụng ứng dụng này và các dịch vụ riêng lẻ. Tuy nhiên, điều này không áp dụng cho các trường hợp dựa trên các quy định của điều trên và các trường hợp được nêu trong các mục dưới đây và người dùng phải đồng ý cho việc truy cập hoặc tiết lộ cho bên thứ ba vì những lý do này.',
    list_one_item: [
      {
        title:
          'Trường hợp phải tiến hành thao tác sửa chữa khi dữ liệu người dùng bị hỏng do ứng dụng này bị lỗi, v.v.',
      },
      {
        title:
          'Trường hợp phải tiến hành thao tác chuyển đổi cần thiết để phản ánh tính năng đã thay đổi, mở rộng hoặc sửa đổi của hệ thống này vào dữ liệu người dùng theo những chỉnh sửa để đáp ứng sự thay đổi, mở rộng tính năng của ứng dụng này hoặc theo sửa đổi luật. ',
      },
      {
        title:
          'Trường hợp tiến hành phân tích dữ liệu cần thiết sau khi thực hiện xử lý ẩn danh để nâng cao chất lượng của ứng dụng này.',
      },
      {
        title:
          'Trường hợp được đánh giá là hợp lý và cần thiết để bảo vệ các lợi ích quan trọng như tính mạng, sức khỏe, tài sản, v.v. của công chúng.',
      },
      {
        title: 'Trường hợp có yêu cầu tiết lộ theo quy định pháp luật.',
      },
      {
        title:
          'Trường hợp đã được sự chấp thuận từ người dùng sau khi tiết lộ mục đích của việc tiết lộ và bên được tiết lộ thông tin.',
      },
    ],
  },
  {
    title: 'Điều 7. Hành vi nghiêm cấm',
    list1: [
      {
        title:
          'Khi sử dụng dịch vụ này, người dùng sẽ không được thực hiện bất kỳ hành vi nào thuộc hoặc bị Công ty chúng tôi cho là thuộc bất kỳ hạng mục nào dưới đây.',
        list3: {
          content: [
            {
              title: 'Vi phạm các điều khoản này. ',
            },
            {
              title: 'Sử dụng dịch vụ này cho các mục đích khác với mục đích do chính người dùng sử dụng.',
            },
            {
              title:
                'Sử dụng thông tin thu được từ việc sử dụng dịch vụ này và các dịch vụ riêng lẻ cho mục đích kinh doanh thu lợi.',
            },
            {
              title:
                'Chuyển giao, chuyển nhượng, cho vay hoặc cung cấp làm tài sản thế chấp cho người thứ ba (Trừ người được cung cấp dịch vụ riêng lẻ đồng thời cùng người dùng) mà không được sự chấp thuận trước từ Công ty chúng tôi hoặc Công ty cung cấp về địa vị trên hợp đồng, hoặc các quyền hay nghĩa vụ phát sinh từ hợp đồng này liên quan đến việc sử dụng dịch vụ này và các dịch vụ riêng lẻ.',
            },
            {
              title: 'Đăng ký thông tin giả khi sử dụng dịch vụ này.',
            },
            {
              title:
                'Vi phạm bản quyền tác giả, bản quyền thương hiệu, bản quyền thiết kế, quyền bằng sáng chế và các quyền sở hữu trí tuệ khác của Công ty chúng tôi và các công ty cung cấp của Công ty.',
            },
          ],
        },
      },
      {
        title:
          'Khi sử dụng dịch vụ này, người dùng sẽ không được thực hiện bất kỳ hành vi nào thuộc hoặc bị Công ty chúng tôi cho là thuộc bất kỳ hạng mục nào dưới đây.',
        list3: {
          content: [
            {
              title:
                'Gửi hoặc sử dụng bất kỳ chương trình hoặc dữ liệu có hại nào bao gồm cả vi rút máy tính tới ứng dụng này.',
            },
            {
              title: 'Sử dụng ứng dụng này theo cách cố ý xâm phạm tài sản trí tuệ của bên thứ ba.',
            },
            {
              title:
                'Đăng ký thông tin, thay đổi cài đặt hoặc cải tạo, chỉnh sửa, sao chép nhân bản, dịch ngược mã máy, dịch ngược mã nguồn và/ hoặc thiết kế đối chiếu hệ thống này vượt quá phạm vi cho phép của thỏa thuận này bởi chính người dùng hoặc qua bên thứ ba.',
            },
            {
              title: 'Các hành vi khác mà Công ty cho là không phù hợp.',
            },
          ],
        },
      },
      {
        title:
          'Khi Công ty chúng tôi xác nhận được rằng người dùng đã thực hiện các hành vi được quy định tại từng mục ở hai khoản trên, hoặc khi chúng tôi xác nhận một cách hợp lý rằng các hành vi đó đã được thực hiện, chúng tôi có thể đình chỉ quyền truy cập, hủy bỏ hoặc từ chối việc sử dụng các dịch vụ riêng lẻ của người dùng đó bất kỳ lúc nào.',
      },
    ],
  },
  {
    title: 'Điều 8. Tạm ngừng và dừng ứng dụng này',
    description:
      'Công ty chúng tôi có thể tạm ngừng hoặc dừng việc sử dụng toàn bộ hoặc một phần ứng dụng này sau khi đã thông báo trước cho người dùng mà không chịu bất kỳ trách nhiệm nào, nếu thuộc bất kỳ lý do nào được nêu trong các mục dưới đây. Tuy nhiên, trong trường hợp khẩn cấp, thông báo trước có thể được bỏ qua.',
    list_one_item: [
      {
        title: 'Khi tiến hành bảo trì định kỳ hoặc khẩn cấp thiết bị hoặc phần mềm.',
      },
      {
        title:
          'Khi việc cung cấp dịch vụ bị ngừng hoặc phát sinh lỗi do quyết định của bên cung cấp dịch vụ máy chủ đám mây (cloud server), đường truyền thông tin, điện lực hoặc các công ty cung cấp dịch vụ công cộng khác mà chúng tôi ký hợp đồng hoặc các trường hợp bất khả kháng.',
      },
      {
        title: 'Khi có lệnh, chỉ đạo hoặc yêu cầu tạm ngừng kinh doanh của cơ quan hành chính hoặc cơ quan tư pháp.',
      },
      {
        title:
          'Khi phát sinh sự cố (bao gồm cả lỗi do hoạt động của con người hoặc lỗi hệ thống) trên ứng dụng này hoặc thiết bị dùng cho hệ thống này.',
      },
      {
        title: 'Khi việc cung cấp hệ thống này bị cản trở bởi hoạt động trái phép hoặc thao tác sai của người dùng.',
      },
      {
        title: 'Khi có truy cập trái phép từ bên thứ ba vào ứng dụng này hoặc thiết bị cho ứng dụng này.',
      },
      {
        title: 'Khi cần khởi động lại ứng dụng này hoặc thiết bị cho ứng dụng này.',
      },
      {
        title:
          'Khi xét thấy cần cung cấp một môi trường sử dụng ổn định cho tất cả người dùng ứng dụng này khi số truy cập vào ứng dụng này tăng lên đáng kể và việc vận hành dịch vụ này bị quá tải.',
      },
      {
        title: 'Khi có hành vi thuộc "hạng mục bị cấm" quy định ở điều trên.',
      },
      {
        title:
          'Ngoài ra, khi Công ty xác định rằng cần thiết phải tạm dừng để duy trì quản lý vận hành cho dịch vụ này.',
      },
    ],
  },
  {
    title: 'Điều 9. Trách nhiệm của Công ty chúng tôi',
    list1: [
      {
        title:
          'Công ty chúng tôi sẽ cố gắng cung cấp thông tin chính xác và mới nhất về dữ liệu, thông tin bản đồ, thông tin tọa độ GPS, v.v. đăng tải trên ứng dụng này và các dịch vụ riêng lẻ, nhưng không đảm bảo rằng đây là thông tin chính xác và mới nhất tại thời điểm người dùng sử dụng.',
      },
      {
        title:
          'Ngay cả khi ứng dụng này liên kết với trang web của các công ty cung cấp, nhưng chúng tôi hoàn toàn không chịu trách nhiệm về các trang web đó. Thêm vào đó, Công ty chúng tôi sẽ không chịu trách nhiệm bồi thường bất kỳ thiệt hại nào phát sinh do sử dụng trang web đó.',
      },
      {
        title:
          'Nếu người dùng gây thiệt hại cho bên thứ ba bao gồm cả các công ty cung cấp thông qua việc sử dụng ứng dụng này, người dùng sẽ tự giải quyết bằng trách nhiệm và chi phí của mình, và Công ty chúng tôi hoàn toàn không chịu bất kỳ trách nhiệm nào.',
      },
      {
        title:
          ' Công ty chúng tôi sẽ không chịu bất kỳ trách nhiệm đối với toàn bộ thiệt hại phát sinh từ việc không thể sử dụng dịch vụ này do lỗi thiết bị kỹ thuật số cầm tay hỗ trợ cá nhân và đường truyền thông tin để sử dụng ứng dụng này.',
      },
      {
        title:
          ' Trường hợp người dùng bồi thường thiệt hại cho Công ty chúng tôi liên quan đến các điều khoản này, thì số tiền đó không được vượt quá giới hạn tổng phí sử dụng dịch vụ riêng lẻ đã dùng qua ứng dụng này trong 3 tháng gần nhất kể từ ngày phát sinh sự việc là nguyên nhân gây ra thiệt hại đó. Tuy nhiên, ngoại trừ trường hợp thiệt hại đó phát sinh do lỗi cố ý hoặc sơ suất nghiêm trọng của Công ty chúng tôi.',
      },
    ],
  },
  {
    title: 'Điều 10. Trách nhiệm của người dùng',
    list1: [
      {
        title:
          'Để sử dụng ứng dụng này, người dùng phải chuẩn bị thiết bị kỹ thuật số cầm tay hỗ trợ cá nhân, môi trường truyền tin và duy trì trạng thái có thể sử dụng với trách nhiệm và chi phí của chính người dùng.',
      },
      {
        title:
          'Người dùng phải tự chịu trách nhiệm quản lý địa chỉ e-mail và mật khẩu (Dưới đây gọi là "quyền truy cập") để đăng nhập vào ứng dụng này. Ngoài ra, nếu có trường hợp sử dụng dịch vụ này hoặc dịch vụ riêng lẻ bằng quyền truy cập của người dùng, ngay cả khi nó được sử dụng bởi người thứ ba không phải là người dùng, thì Công ty chúng tôi vẫn có thể coi đó là sử dụng của người dùng.',
      },
      {
        title:
          'Người dùng sẽ phải chịu trách nhiệm về toàn bộ thiệt hại gây ra cho Công ty chúng tôi hoặc Công ty cung cấp do vi phạm các Điều khoản và Điều kiện này.',
      },
    ],
  },
  {
    title: 'Điều 11. Cổng trao đổi thông tin, Luật điều chỉnh',
    list1: [
      {
        title: 'Điều khoản này và các quy tắc kèm theo được hiểu theo luật Singapore.',
      },
      {
        title:
          'Trường hợp có nghi vấn trong việc vận dụng và cách hiểu các điều khoản này khi sử dụng ứng dụng này, dịch vụ này và các dịch vụ riêng lẻ, người dùng có thể liên hệ đến địa chỉ sau để trao đổi thông tin chi tiết.',
      },
      {
        title:
          'Đối với các tranh chấp liên quan đến điều khoản này, Tòa án tại địa chỉ đã đăng ký của Công ty chúng tôi cho vụ kiện dân sự tại nước Cộng hòa Singapore sẽ là tòa án sơ thẩm có thẩm quyền quyết định cao nhất. Tuy nhiên, trường hợp được phép khởi kiện tại một tòa án khác theo quy định của Luật xử phạt cưỡng chế (Mandatory sentencing laws), thì sẽ áp dụng theo quy định đó.',
      },
    ],
  },
];
