/* eslint-disable max-len */
const ja = {
  translations: {
    // Route
    'route.home': 'ホームページ',
    'route.login': 'ログインする',
    'route.registration': '登録',
    'route.logout': 'ログアウト',
    'route.verify_mobile_account': 'モバイルアカウントを確認',
    'route.mobile_reissue_password': 'パスワードを再発行',

    // Button common
    'common.btnAgree': '同意する',
    'common.btnAdd': '追加',
    'common.btnUpdate': '更新',
    'common.btnDelete': '削除する',
    'common.btnSend': '送信',
    'common.btnChange': '変更',
    'common.btnLogin': 'ログイン',
    'common.btnLogout': 'ログアウト',
    'common.btnRegister': '登録',
    'common.btnCreate': '登録',
    'common.btnReturn': '戻る',
    'common.btnCancel': '閉じる',
    'common.btnResetSearch': '検索条件リセット',
    'common.btnSearch': '検索',
    'common.btnReopen.Stop': '停止／再開',
    'common.btnUploadCsv': 'CSV出カ',
    'common.btnDetail': '細部',
    'common.btnClose': '閉じる',
    'common.btnBulkRegistration': '一括登録',
    'common.ja': '日本語',
    'common.en': '英語',
    'common.vi': 'ベトナム語',

    // Common message code
    'messageCode.updateConfirm': 'この{{field}}を更新しますか？',
    'messageCode.deleteConfirm': 'この{{field}}を削除しますか？',
    'messageCode.changePasswordConfirm': 'パスワードを更新します。よろしいですか？',
    'messageCode.accountInviteConfirm': 'メールを送信します。よろしいですか？',
    'messageCode.deleteConfirmProduct': '製品を削除しますか？',
    'messageCode.deleteConfirmSupplier': 'サプライヤーを削除しますか?',
    'messageCode.deleteConfirmDriver': 'ドライバーを削除しますか?',
    'messageCode.deleteConfirmImageMaster': 'イメージマスターを削除しますか？',
    'messageCode.updateConfirmDriver': 'ドライバを更新しますか？',
    'messageCode.updateConfirmVehicle': '車両を更新しますか？',
    'messageCode.deleteConfirmVehicle': '車両を削除しますか？',
    'messageCode.verifyEmailToken': 'ご登録いただいたメールアドレスの認証に成功しました',

    // Common error field message
    'errorFields.invalidDateMessage': '無効な日付形式',
    'errorFields.maxDateMessage': '日付は最大日付の後にしないでください',
    'errorFields.minDateMessage': '日付は最小日付より前であってはなりません',
    'errorFields.passwordRequired': 'パスワードを入力してください。',
    'errorFields.emailRequired': 'メールを入力してください。',
    'errorFields.emailFormat': '入力されたメールの形式が正しくありません',
    'errorFields.textLength': '128文字以内で入力してください',
    'errorFields.text256Length': '256文字以内で入力してください',
    'errorFields.cannotChange': '変更不可',
    'errorFields.birthdayRequired': '誕生日を入力して下さい。',
    'errorFields.accountNameRequired': 'アカウント名を入力してください。',
    'errorFields.rePasswordRequired': '再入力パスワードを入力してください。',
    'errorFields.phoneNumberRequired': '電話番号を入力してください。',
    'errorFields.confirmPasswordMatch': '新パスワードと再入力パスワードが一致しません。もう一度入力してください。',
    'errorFields.oldPasswordRequired': '現在のパスワードを入力してください。',
    'errorFields.newPasswordRequired': '新パスワードを入力してください。',
    'errorFields.passwordMin': '小文字を含む6文字以上で入力してください。',
    'errorFields.confirmPasswordRequired': '再入力パスワードを入力してください。',
    'errorFields.personChargeRequired': '担当者名を入力してください。',
    'errorFields.firstNameRequired': '名を入力してください。',
    'errorFields.lastNameRequired': '姓を入力してください。',
    'errorFields.addressRequired': '住所を入力してください。',
    'errorFields.rePasswordMatch': '新パスワードと再入力パスワードが一致しません。もう一度入力してください。',
    'errorFields.businessRequired': '会社名を入力してください。',
    'errorFields.mobileRequired': '電話番号を入力してください',
    'errorFields.checkPhoneNumberMatch': '電話番号が一致しません。もう一度やり直してください。',
    'errorFields.sizeFile': '画像サイズは200KBより大きく、500KBを超えてはなりません。',
    'errorFields.statusRequired': 'ステータスを選択してください。',
    'errorFields.typeRequired': 'タイプを選択してください。',
    'errorFields.industryRequired': '業種をお選びください。',
    'errorFields.countryRequired': '国を選択してください。',
    'errorFields.supplierNameRequired': 'サプライヤー名を選択してください。',
    'errorFields.supplierCodeRequired': 'サプライヤーコードを選択してください。',
    'errorFields.supplierAddressRequired': '住所を選択してください。',
    'errorFields.facilityNameRequired': '施設名を入力してください。',
    'errorFields.areaRequired': 'エリアをお選びください。',
    'errorFields.languageRequired': '言語を選択してください。',
    'errorFields.holidayRequired': '休日をお選びください。',
    'errorFields.feeRequired': '料金を入力してください。',
    'errorFields.feeTypeRequired': 'タイプ料をお選びください',
    'errorFields.errorPrice': '料金を入力してください',

    // Message error call API
    'error.file.size.less.than.200KB': '200kb以上のファイルサイズ',
    'error.file.size.is.empty': 'ファイルを空白のままにすることはできません',
    'error.no.record': '記録なし',
    'error.already.exist.or.has.been.invited': 'メールはすでに存在するか、招待されています。',
    'error.account.user.not.found': 'アカウントユーザーが見つかりません。',
    'error.business.not.found': 'ビジネスが見つかりません。',
    'error.role.not.found': '役割が見つかりません。',
    'error.permission.not.found': '権限が見つかりません。',
    'error.api.role.permission.unknown': 'API役割の権限は不明です。',
    'error.api.token.not.match': 'APIトークンが一致しません。',
    'error.link.not.found': 'リンクが見つかりません。',
    'error.user.not.resumed': 'アカウントユーザーは再開されていません。',
    'error.link.expired': 'リンクの有効期限が切れています。',
    'error.email.not.found': 'メールが見つかりません。',
    'error.email.password.invalid': 'パスワードが無効です。',
    'error.change.password': 'このパスワードは間違っています。',
    'error.newPassword.must.not.equals.currentPassword': '現在のパスワードと新しいパスワードは一致してはなりません。',
    'error.not.found.user': 'ユーザーが見つかりません。',
    'error.user.not.exists': 'ユーザーが存在しません',
    'error.email.exists': 'メールが存在しません',
    'error.mobile.exists': '携帯は存在しません',
    'error.reissue.password.token_expired': 'トークンはすでに存在します',
    'error.reissue.password.token_not_match': 'トークンの形式が正しくありません',
    'error.member.not.exists': '',
    'invalid.email': '無効なメール',
    'invalid.timeTo': '無効な時間',
    'invalid.timeFrom': 'からの無効な時間',
    'error.time.from.not.after.time.to': 'からはあでもっと少なく',
    'user.email.token.invalid':
      'メールアドレスの認証に失敗しました。\n' +
      'URLが途中で改行され途切れていないか \n' +
      'ご確認の上で再度アクセスしてください。',
    'error.check.value.empty': '入力する値を確認してください',

    // Message success call API
    'api.invite.success': 'アカウントを招待しました。',
    'api.active.account.success': 'アクティブなアカウントが正常に作成されました。',
    'api.add.supplier.success': 'サプライヤーを追加しました。',
    'api.deleted.supplier.success': 'サプライヤーを削除しました',
    'api.delete.driver.success': 'ドライバーを削除しました',
    'api.delete.caution.success': 'アイテムーを削除しました',
    'api.update.caution.success': 'アイテムをーを更新しました',
    'api.create.driver.success': 'ドライバーを作成しました',
    'api.create.caution.success': 'アイテムーを作成しました',
    'api.updated.supplier.success': 'サプライヤーを更新しました。',
    'api.updated.account.success': 'アカウントを更新しました。',
    'api.execute.reissue.success': 'パスワードの再発行に成功しました。',
    'Api.success.change.password': 'パスワードを変更しました。',
    'api.updated.member.success': 'メンバーを更新しました。',
    'api.created.member.success': 'メンバーを作成しました',
    'api.reissue_password.request.success': 'パスワードの再発行をリクエストしました',
    'api.reissue_password.verify.success': '再発行パスワードを確認しました',
    'api.reissue_password.confirm.success': '再発行パスワードを確認しました',
    'api.canceled.member.success': 'メンバーはキャンセルされました',
    'api.deleted.facility.success': '施設を削除しました',
    'api.updated.facility.success': '施設を更新しました',
    'api.add.facility.success': '施設を追加しました',
    'api.facility.not.found': 'API機能が見つかりません',
    'api.delete.vehicle.success': '車両を削除しました',
    'api.updated.vehicle.success': 'サプライヤーの車両を更新しました',
    'api.created.vehicle.success': 'サプライヤーの車両を作成しました',
    'api.update.calendar.success': 'カレンダーを更新しました',
    'api.create.image.success': '画像を作成しました',
    'api.update.image.success': '画像を更新しました',
    'api.delete.image.success': '画像を削除しました',
    'user.verify.email.token.success': '認証に成功しました',
    'user.verify.email.token.success2': '',

    // Home page
    'homePage.title': 'ホーム',

    // Registration page
    'registration.title': 'アカウント作成',
    'registration.accountType': 'アカウント種別',
    'registration.authority': '権限',
    'registration.businessName': '事業者名',
    'registration.email': 'メール',
    'registration.contactName': '担当者名',
    'registration.password': 'パスワード',
    'registration.rePassword': '再入力パスワード',
    'registration.phoneNumber': '電話番号',

    // Error page UI
    'maintenance': 'ただいまメンテナンス中',
    'unauthorized': 'しまった !!! このリソースへのアクセスは拒否されました。',

    // validation
    'validation.required': '{{ field }}を入力してください',
    'validation.email': '入力されたメールの形式が正しくありません',
    'validation.required.choose': '{{ field }}を選択してください',
    'validation.numeric': '{{ field }}は数字でなければなりません',
    'validation.length': '{{ field }}は{{ number }}文字以下で入力してくださ',
    'validation.between': '{{field}} に{{min}}文字以上、{{max}}文字以下で入力してください',

    // common constant
    'currencyCode.VND': 'VND',
    'currencyCode.USD': 'USD',
    'currencyCode.YEN': '円',

    // Jit landing page
    'jit.title': 'モビ シャトル',
    'jit.description1': 'いつも行く場所に',
    'jit.description2': '便利な交通手段',

    'jit.item1.title': '通勤・通学に',
    'jit.item1.description1': '事前予約で定時刻運行なので',
    'jit.item1.description2': '移動のトラブルで',
    'jit.item1.description3': 'ヒヤヒヤすることはありません。',

    'jit.item2.title': 'いつもの移動に',
    'jit.item2.description1': '定時出発/到着運行のため',
    'jit.item2.description2': 'お買い物や通院・ジムなど',
    'jit.item2.description3': '定期的な移動に便利です。',

    'jit.item3.title1': 'ちょっと遠くの',
    'jit.item3.title2': 'お出かけに',
    'jit.item3.description1': 'いつもの活動エリアから',
    'jit.item3.description2': '離れた街への移動も、',
    'jit.item3.description3': '乗り換えることなく便利です。',

    'jit.btnMethod': '予約方法',
    'jit.btnRequest': '予約リクエスト',

    'jit.category1.title': 'ルートと時間を選択',
    'jit.category1.description1': '出発時間と到着時間の目安時間は、',
    'jit.category1.description2': 'お客様の予約状況により変動します。',
    'jit.category1.departure': '出発',
    'jit.category1.indication': '目安',
    'jit.category1.arrival': '到着',
    'jit.category1.deadline': '予約締切日時',
    'jit.category1.seats': '残席数',
    'jit.category1.thursday': '木',
    'jit.category1.month': '月',
    'jit.category1.day': '日',

    'jit.category2.title': '人数を確定',
    'jit.category2.description1': 'mobiシャトル月定額プランや',
    'jit.category2.description2': '一回払いで、ご乗車頂けます。',

    'jit.category3.title': '予約内容をお知らせ',
    'jit.category3.description1': '予約締切時間になったら、確定した',
    'jit.category3.description2': '乗車時間と降車時間をご連絡します。',
    'jit.category3.description2.1': '',
    'jit.notify': '',

    // User Privacy
    'user_privacy.title1': 'WILLERS アプリ WEBサイト利用における',
    'user_privacy.title2': 'プライバシーポリシー',
    'user_privacy.description1':
      'WILLERSPTE.LTD.では、主要事業である旅行事業およびそれらに付随する業務に関わるサービスを実施する上で、お客様の個人情報保護は非常に重要な社会的責務であると考え、これらを尊重し、適切にお取扱することを目的に、下記のプライバシーポリシーを定め、業務に当たっています。',
    'user_privacy.description2':
      'このプライバシーポリシーは、当社がお客様からお預かりするお客様の個人情報のお取扱いについて説明させていただくものです。当社のサービス・商品を利用いただくにあたっては、このプライバシーポリシーをよくお読みください。お客様が当社の提供するサービス・商品を利用いただいた場合、このプライバシーポリシーの内容を十分ご理解の上、同意いただいたものとみなします。',

    'user_privacy.item1.title': 'プライバシーポリシーの適用範囲について',
    'user_privacy.item1.description1':
      'プライバシーポリシーは、お客様が当社のサービス・商品をご利用されるときに入力する個人情報を含めた、当社の事業に利用するすべての個人情報に適用されます。',
    'user_privacy.item1.description2': '情報提供の任意性について',
    'user_privacy.item1.description3':
      '当社で取得し得る情報には、お客様の意思によりご提供を拒否できるものと、会員登録・予約時における氏名や住所など、利用目的によって提供が必須となるものがあります。当社に対し特定の情報の提供を希望されない場合には、当該情報を当社に提供しないで下さい。この場合、当社のサービスの全部又は一部を利用できない、当社のウェブサイトの機能の全部又は一部を利用できない等、お客様の受けるサービス等に影響が生じる場合があります。',
    'user_privacy.item1.description4':
      'お客様が当社に個人情報を提供される場合には、当社のウェブサイトにおける表示に従い、予め個人情報の取得及び利用について、同意されるか否かを明示して下さい。お客様は、いつでも同意を撤回することができます。ただし、同意を撤回された場合でも、撤回前に当社が行った処理の適法性には影響がありません。',

    'user_privacy.item2.title': '個人情報の取り扱いについて',
    'user_privacy.item2.description':
      '当社では、当サイト上でお客様から取得し得るいかなる個人情報も厳正な管理の下に保管し、後記利用目的のためにのみ利用し、以下のいずれかに該当する場合を除き、お客様の個人情報を第三者へ開示または提供いたしません。なお、目的に必要な範囲で、当社の関係会社、サービス提供者、業務提携先などに提供する場合は、第三者への開示または提供にあたりません。業務提携先などには、当社が定める基準と同様な個人情報管理を行うことを契約により義務化しています。',
    'user_privacy.item2.list1': 'お客様ご本人の同意がある場合',
    'user_privacy.item2.list2': 'シンガポール法に基づき開示、提供を求められた場合',
    'user_privacy.item2.list3':
      '人の生命、身体または財産の保護のために必要な場合であって、お客様の同意を得ることが困難である場合',
    'user_privacy.item2.list4':
      '国または地方公共団体等が公的な事務を実施する上で、協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障が出るおそれがある場合',
    'user_privacy.item2.list5': '統計的なデータなど本人を識別できない状態で開示、提供する場合',

    'user_privacy.item3.title': '当社で取得し得る情報について',
    'user_privacy.item3.list1.1': '会員登録・予約時に関するもの',
    'user_privacy.item3.list1.2': '氏名、住所、電話番号、メールアドレス、クレジットカード決済に関する情報など',
    'user_privacy.item3.list2': 'アンケートに関するもの',
    'user_privacy.item3.list3.1': '購買動機、趣向など',
    'user_privacy.item3.list3.2': '一般的なウェブ、アプリアクセス情報',
    'user_privacy.item3.list3.3': 'ドメイン名、アクセス時間、ブラウザの種類、アクセス機器の種類、利用履歴など',

    'user_privacy.item4.title': '利用目的について',
    'user_privacy.item4.description':
      '当社では、当サイト上でお客様からお預かりした個人情報を以下の目的に利用いたします。',
    'user_privacy.item4.list1': 'お申し込みいただいたサービスの提供及び手配、並びに管理業務',
    'user_privacy.item4.list2': '当社からの印刷物その他の通信⽂の郵送及びメールによるお知らせの発信',
    'user_privacy.item4.list3':
      '個人を識別できない情報に関して当社のサイト、販売商品及びサービスをより良くするための統計的な分析並びに検討',
    'user_privacy.item4.list4': '広告活動',

    'user_privacy.item5.title': '広告活動におけるクッキー（cookie）およびカスタムオーディエンス機能の利用について',
    'user_privacy.item5.list1.title': 'クッキー（cookie）の利用について',
    'user_privacy.item5.list1.description':
      '当社では、各種インターネット広告サービスを利用しており、第三者配信事業者から提供されるお客様のクッキー（Cookie）を利用する場合がございます。',
    'user_privacy.item5.list2.title': 'クッキー（cookie）とは?',
    'user_privacy.item5.list2.description':
      'クッキーとは、ウェブページを利用した時に、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、お客様のコンピュータにファイルとして保存しておく仕組みです。次回同じページにアクセスすると、クッキーの情報を使って、ページの運営者はお客様ごとに表示を変えることができます。お客様がブラウザの設定でクッキーの送受信を許可している場合、ウェブサイトはユーザーのブラウザからクッキーを取得できます。尚、お客様のブラウザはプライバシー保護の為、そのウェブサイトのサーバーが送受信したクッキーのみを送信します。',
    'user_privacy.item5.list3.title': 'クッキー（cookie）の利用目的',
    'user_privacy.item5.list3.description':
      '当社は以下の目的のため、クッキーを利用しています。なお当社は、第三者配信事業者への広告配信委託に基づき、第三者配信事業者を経由して、当社のクッキーを保存し、参照する場合があります。',
    'user_privacy.item5.list4.title':
      'お客様が認証サービスにログインされるとき、保存されているお客様の登録情報を参照して、お客様ごとにカスタマイズされたサービスを提供できるようにする為',
    'user_privacy.item5.list4.1':
      '1. お客様が興味を持っている内容や、当社のサイト上での利用状況を元に、最も適切な広告を他社サイト上で表示する為',
    'user_privacy.item5.list4.2': '2. 当社のサイトの利用者数やトラフィックを調査する為',
    'user_privacy.item5.list4.3': '3. 当社のサービスを改善する為',
    'user_privacy.item5.list4.4':
      '4. セキュリティー保持の観点より、ご利用から⼀定の時間が経過したお客様に対してパスワードの再入力（再認証）を促す為',
    'user_privacy.item5.list4.5': 'お客様によるクッキーの設定について',
    'user_privacy.item5.list4.6':
      'お客様は、クッキーの送受信に関する設定を「すべてのクッキーを許可する」、「すべてのクッキーを拒否する」、「クッキーを受信したらユーザーに通知する」などから選択できます。設定方法は、ブラウザにより異なります。クッキーに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。',
    'user_privacy.item5.list4.7':
      'Googleによるクッキーの設定については、次のページにアクセスしてオプトアウトが可能です。',
    'user_privacy.item5.list4.8':
      'すべてのクッキーを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。',
    'user_privacy.item5.list5.title': 'カスタムオーディエンスの利用について',
    'user_privacy.item5.list5.description':
      '当社ウェブサイトのご利用状況をもとに、お客様向けにカスタマイズした広告を表示するようFacebook及びTwitter（以下「Facebook等」といいます）に要請することおよび、そのように表示した広告のご利用状況を把握することを目的に、当社はカスタムオーディエンス機能を使用します。',

    'user_privacy.item5.list6.title': 'カスタムオーディエンス機能とは',
    'user_privacy.item5.list6.description':
      '当社に登録いただいたお客様の情報と、Facebook等に登録されている情報を照合し、一致したお客様について、Facebook等上のお客様のページにおいて当社の広告を配信させていただく機能です。この機能により、Facebook等側が、当社の保有するお客様の個人情報を取得することはありません。',

    'user_privacy.item5.list7.title': 'Twitterにおけるカスタムオーディエンス機能のオプトアウト',
    'user_privacy.item5.list7.description':
      'Twitterにおいてカスタムオーディエンス機能のオプトアウトを希望されるお客様は、こちらのURLよりお手続きください。',

    'user_privacy.item6.title': '同意撤回について',
    'user_privacy.item6.description1': 'お客様からご登録いただきました登録内容に訂正などがある場合、',
    'user_privacy.item6.description2':
      'お客様が個人情報の利用停止をご希望になる場合には、当社カスタマーサービスまでご連絡ください。ただし、退会手続きが完了すると当社サイトが提供しているサービスの一部または全部が利用できなくなりますので、お気を付け下さい。',
    'user_privacy.item6.description3': 'カスタマーサービス リンク',

    'user_privacy.item7.title': 'プライバシーポリシーの変更等',
    'user_privacy.item7.description':
      '当サイトでは諸事情により、上記のプライバシーポリシーを部分的に変更、修正、追加、削除させていただくことがございますが、その場合はすべてサイト上で通知させていただきます。この変更等につきましては、別途表示のないかぎり、本ウェブサイト上に掲載されると同時に有効となります。',

    'user_privacy.item8.title': '当社関係会社',
    'user_privacy.item8.description1': '関係会社とは、次の会社となります。',
    'user_privacy.item8.description2': 'WILLER株式会社',
    'user_privacy.item8.description3': 'WILLER EXPRESS株式会社',
    'user_privacy.item8.description4': 'WILLER TRAINS株式会社',
    'user_privacy.item8.description5': 'WILLER損保サービス株式会社',
    'user_privacy.item8.description6': '丹鉄ファンド株式会社',
    'user_privacy.item8.description7': '株式会社ホテルオペレーションシステムズ（ホテル白馬）',
    'user_privacy.item8.description8': 'WILLER VIETNAM Co., Ltd.',
    'user_privacy.item8.description9': 'WILLER VTI Company Limited',
    'user_privacy.item8.description10': 'その他、WILLER株式会社と資本的な連携を有している会社',
    'user_privacy.item8.description11': 'その他プライバシーポリシーに関する問い合わせ先',

    // mobi term of service
    'mobi_term.title': 'mobiサービス利用規約',
    'mobi_term.description':
      'mobiサービス利用規約（以下、「本規約」といいます。）は、Community Mobility株式会社（以下、「当社」といいます。）が、主として、「mobi Community Mobility アプリ」（以下、「本アプリ」といいます。）を通じて運営・提供するmobiサービスの利用条件等を定めるものです。',

    'mobi_term.item1.title': '第1条（総則）',
    'mobi_term.item1.content1.title':
      '本規約は、mobi Community Mobility アプリ利用規約（以下、「原規約」といいます。）における個別規約であり、mobiサービスに関して特段の定めがある場合を除き、本規約が優先して適用されます。',
    'mobi_term.item1.content2.title':
      '利用者は、mobiサービスの申込の時に、本規約及び付属する手順書、利用規則等（以下、「本規約等」といいます。）に同意したものとみなします。',
    'mobi_term.item1.content3.title': '原規約において、当社は提供事業者となり、mobiサービスは個別サービスとなります。',
    'mobi_term.item1.content4.title':
      '本規約等の内容は、当社の判断において、以下の場合に利用者に対して何らの事前の通知なしに変更することがあります。この場合、変更日以降の利用については、変更後の本規約等の内容が適用されます。なお、当社は、本規約等の内容を変更する場合、合理的な期間をもって事前に、利用者に変更内容を通知するものとします。',
    'mobi_term.item1.content4.list1': '本規約等の内容変更が、利用者の一般の利益のために実施されるとき',
    'mobi_term.item1.content4.list2':
      '本規約等の内容変更が、本規約等の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。',
    'mobi_term.item1.content5.title':
      '本規約等の定めの一部の効力が、法令又は判決により無効となった又は取り消された場合においても、残りの定めの有効性、適法性及び法的強制力はなお有効であるものとします。',

    'mobi_term.item2.title': '第2条（mobiサービスの内容）',
    'mobi_term.item2.content1.title':
      'mobiサービスにおいて、利用者は、以下の表に定める利用プランに応じて、当社が設定した仮想乗降場所の中から、本アプリ又は当社コールセンター等当社が別途指定する方法により設定した乗車地及び降車地の区間における、当社と提携した乗合旅客自動車運送事業者（以下、「運送事業者」といいます。）による一般乗合旅客自動車運送サービス（以下、「運送サービス」といいます。）の提供を受けることができます。',
    'mobi_term.item2.content1.list1': '１回利用プラン',
    'mobi_term.item2.content1.list1.description1': '運送サービスの利用お申込１回ごとに、所定の運賃料金を支払って頂くプランです。（初期設定）',
    'mobi_term.item2.content1.list1.content1':
      '内容：運送サービスの利用お申込１回ごとに、所定の運賃料金を支払って頂くプランです。（初期設定）',
    'mobi_term.item2.content1.list2': '定額利用プラン',
    'mobi_term.item2.content1.list2.description1': '本アプリ又は当社が定める方法により申し込んでいただくこと、及び、別に定める料金表に定める利用料金を事前にお支払いいただくことにより、利用料金に応じた一定期間内において、追加の運賃料金を支払うことなく、何回でも運送サービスを利用いただけるプランです。',
    'mobi_term.item2.content1.list2.description2': 'なお、付加サービスとして、利用者の親族の方とプランを共有いただける「家族利用オプション」も有料で６名まで追加いただくことができます。',
    'mobi_term.item2.content1.list2.content1':
      '内容：本アプリ又は当社が定める方法により申し込んでいただくこと、及び、別に定める料金表に定める利用料金を事前にお支払いいただくことにより、利用料金に応じた一定期間内において、追加の運賃料金を支払うことなく、何回でも運送サービスを利用いただけるプランです。',
    'mobi_term.item2.content1.list2.content2':
      'なお、付加サービスとして、利用者の親族の方とプランを共有いただける「家族利用オプション」も有料で６名まで追加いただくことができます。',
    'mobi_term.item2.content1.list3': '回数利用プラン',
    'mobi_term.item2.content1.list3.description1': '本アプリ又は当社が定める方法により申し込んでいただくこと、及び、別に定める料金表に定める利用料金を事前にお支払いいただくことにより、利用料金に応じた一定期間内において、追加の運賃料金を支払うことなく一定回数の運送サービスをご利用いただけるプランです。',
    'mobi_term.item2.content1.list3.description2': 'なお、本プランにおける回数は、別の定めがある場合を除き、当該一定期間内のみ有効とし、第4条の手続に従って更新した場合にのみ、更新後の次の利用期間に限り有効期間を延長するものとします。',
    'mobi_term.item2.content1.list3.content1':
      '内容：本アプリ又は当社が定める方法により申し込んでいただくこと、及び、別に定める料金表に定める利用料金を事前にお支払いいただくことにより、利用料金に応じた一定期間内において、追加の運賃料金を支払うことなく一定回数の運送サービスをご利用いただけるプランです。',
    'mobi_term.item2.content1.list3.content2':
      'なお、本プランにおける回数は、別の定めがある場合を除き、当該一定期間内のみ有効とし、第4条の手続に従って更新した場合にのみ、更新後の次の利用期間に限り有効期間を延長するものとします。',
    'mobi_term.item2.content1.list4': '前号のサービスに利用者と同居する親族 を含めるオプションサービス',
    'mobi_term.item2.content2.title':
      '運送サービスにおいて、一般乗合旅客自動車運送契約は、利用者と運送事業者との間において成立するものとし、当該運送契約は当該運送事業者が別途定める運送約款によるものとします。',
    'mobi_term.item3.title': '第3条（利用料金の支払い）',
    'mobi_term.item3.content1.title':
      '利用者は、１回利用プランによって運送サービスを利用する場合、別に定める運送サービスの運賃料金を以下の各号に定める方法で支払うものとします。',
    'mobi_term.item3.content1.list1':
      '本アプリを通じて申し込む場合：原規約に定める決済代行によるクレジットカード決済又は運送事業者へ乗車時に現金支払い、若しくは、無料利用券等のクーポンの交付',
    'mobi_term.item3.content1.list2':
      '当社コールセンターを通じて申し込む場合：運送事業者へ乗車時に現金支払い、又は、無料利用券等のクーポンの交付',
    'mobi_term.item3.content2.title':
      '利用者は、定額利用プラン又は回数利用プラン（以下、合わせて「サブスクリプションプラン」といいます。）を申し込んだ場合、利用料金表に定める利用料金を、以下の各号に定めるいずれかの方法により支払うものとします。',
    'mobi_term.item3.content2.list1': 'クレジットカード決済',
    'mobi_term.item3.content2.list2': '当社指定の銀行口座への振込（振込手数料は利用者負担）',
    'mobi_term.item3.content2.list3': '口座振替',

    'mobi_term.item3.content3.title': '前項各号における支払期日は以下の各号に定める通りとします。',
    'mobi_term.item3.content3.list1': '決済代行の場合　サブスクリプションプランお申し込み時点',
    'mobi_term.item3.content3.list2': '振込の場合　別途通知等により当社が指定する日',
    'mobi_term.item3.content3.list3': '口座振替の場合　口座振替実施金融機関が指定する日',

    'mobi_term.item3.content4.title':
      '利用者は、家族利用オプションを申し込んだ場合、自己の定額利用プランと一括で支払うものとします。この場合の支払方法は利用者自身の利用料の支払方法と同一の方法によるものとします。',
    'mobi_term.item3.content5.title':
      '本条にしたがって当社に支払われた利用料金は、法令または当社が特に認める場合を除き、利用者の利用の有無、回数その他のいかなる理由によっても返金することはできません。',

    'mobi_term.item4.title': '第4条（更新）',
    'mobi_term.item4.content1.title':
      'サブスクリプションプランのうち、支払い方法がクレジットカード決済及び口座振替であるものについて、利用期間の最終日までに利用者から申出がない場合、その翌日から自動的に同じ条件で新たなサブスクリプションプランが開始するものとします。この場合、クレジットカード決済については新たな定額利用の開始日に、口座振替については口座振替実施金融機関が指定する日に、自動的に引き落としします。',
    'mobi_term.item4.content2.title':
      'サブスクリプションプランのうち、支払い方法が銀行振込であるものについては、定額利用の最終日までに利用料をお支払いいただいた場合、その日の翌日から同じ条件で新たな定額利用が開始するものとします。',
    'mobi_term.item4.content3.title':
      '前2号に定める新たな定額利用の支払日の翌営業日において、利用料が支払われたことが確認できない場合、当社は、この新たな定額利用の開始日以後であったとしても、定額利用を直ちに取り消すことができるものとします。',

    'mobi_term.item5.title': '第5条（運送サービスの取消）',
    'mobi_term.item5.content1.title':
      '利用者及び家族利用オプションの適用された利用者の親族（以下、「利用者等」といいます。）は、mobiサービスによる運送サービスの申し込み後、運送サービス提供車両が利用者等の指定した出発地に到達するまでの間、いつでもこの運送サービスの申し込みを取り消すことができます。',
    'mobi_term.item5.content2.title':
      '当社は、１回利用の利用者による本条第1項の取消を確認した場合、速やかに支払い済みの利用料を返金します。',

    'mobi_term.item6.title': '第6条（利用者の責務）',
    'mobi_term.item6.content1.title':
      '利用者等は、運送サービスの提供を受ける際に、道路交通法並びに運送事業者が定める運送約款及び運送サービスの乗務員の指示を遵守するものとします。なお、利用者等が本条に違反する行為又は違反する恐れのある行為があったと合理的に運行サービス提供車両の乗務員が認めた場合、かかる乗務員は当該車両の管理者として、この利用者等に対し、直ちに乗車を拒み又は降車を命じることができるものとします。また、利用者等による本条の違反が反復された場合には、当社は、利用者等による運送サービスの申し込みの一部又は全部を拒絶し、中断し、又はサブスクリプションプランの更新を拒絶することができるものとします。',
    'mobi_term.item6.content2.title': '利用者等は、みだりに申し込みと取消を繰り返し行ってはならないものとします。',
    'mobi_term.item6.content3.title':
      'mobiサービス（ただし、定格利用及び家族利用に限ります。）は申し込んだ運送サービスは利用者等本人のみが利用できるものであり、利用者等の同行者は、この同行者自身が別途利用者等として申し込んだ場合を除き、同乗いただくことはできません。',
    'mobi_term.item6.content4.title': '未成年者も親権者・法定代理人の承諾を得ることで、利用者等本人として運送サービスを申し込んでご利用いただくことはできますが、当社は、必要に応じて、利用内容の照会、利用料金の回収、利用プランの制限その他任意の措置を講じることができるものとします。',
    'mobi_term.item7.title': '第7条（運送サービス中の事故の対応）',
    'mobi_term.item7.content1.title':
      '万が一、運送サービス利用中（乗降車中の時間も含む。）に発生した事故又はトラブル（以下、「事故等」といいます。）により利用者等が損害を被った場合、利用者等は、運送事業者が定める運送約款に基づき、運送事業者との間で協議解決するものとします。',
    'mobi_term.item7.content2.title':
      '当社は、自己の判断により、事故等の解決に協力することができるものとしますが、事故等により利用者に発生した損害について何ら賠償する責任を負うものではありません。',
    'mobi_term.item7.content3.title':
      '本条における紛争解決のため利用者等又は運送事業者等が訴訟を提起する場合、原規約第11条の定めは適用せず、日本法に基づき解釈され、日本国の裁判所において解決されるものとします。',

    'mobi_term.item8.title': '第8条（利用者等の情報）',
    'mobi_term.item8.content1.title':
      '利用者等は、mobiサービスの申し込みにより、当社及び運送事業者がWILLERSから本アプリを通じて、運送サービスの提供を目的として、利用者等の氏名、性別、連絡先等の個人情報を必要な範囲において提供を受けること、及び当社及び運送事業者の定めるプライバシーポリシー又は個人情報取扱方針等（以下、合わせて「プライバシーポリシー等」といいます。）を承諾したものとします。',
    'mobi_term.item8.content2.title':
      '当社及び運送事業者は、前項に基づき又はmobiサービス提供の過程で取得した利用者等の個人情報について、個人情報保護法等の関連規定及びプライバシーポリシー等に基づき、適切に管理するものとします。',
    'mobi_term.item8.content3.title':
      '前項に関して、当社及び運送事業者の定めるプライバシーポリシー等及び個人情報に関する問い合わせ先は、各社のホームページへの掲載等の方法により掲示します。',

    'mobi_term.item9.title': '第9条（反社会的勢力の排除）',
    'mobi_term.item9.content1.title':
      '利用者等は、自らが以下の各号のいずれにも該当しないこと及び以下の各号の個人又は団体と密接な交流関係を有していないことを表明して 、確約するものとします。',
    'mobi_term.item9.content1.list1': '暴力団',
    'mobi_term.item9.content1.list2': '暴力団の構成員（準構成員を含む。以下同じ。）',
    'mobi_term.item9.content1.list3':
      '暴力団関係企業又は暴力団若しくは暴力団の構成員が出資若しくは業務執行に関して重要な地位に就いている団体',
    'mobi_term.item9.content1.list4': '総会屋、社会運動標榜ゴロその他反社会的勢力に該当するもの',
    'mobi_term.item9.content1.list5': '特殊知能暴力集団その他反社会的勢力に該当する団体又はその構成員',
    'mobi_term.item9.content1.list6': 'その他前各号に準ずるもの',
    'mobi_term.item9.content2.title':
      '利用者等は、自ら又は第三者を利用して、以下の各号に該当する行為を行わないことを表明して、確約するものとします。',
    'mobi_term.item9.content2.list1': '暴力的な又は法的な責任を超えた不当な要求行為',
    'mobi_term.item9.content2.list2': '取引に関して、脅迫的な言動をし、又は暴力を用いる行為',
    'mobi_term.item9.content2.list3':
      '風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為',
    'mobi_term.item9.content2.list4': 'その他前各号に準ずる行為',
    'mobi_term.item9.content3.title':
      '当社及び運送事業者は、利用者等が前2項に定める表明事項に違反していた場合又は違反していると判断した場合、直ちにmobiサービスの提供を中止し、以後の提供を拒絶することができるとともに、これらの場合、当事者等は、自己の違反により、当社及び運送事業者がこれにより被った全ての損害（風評被害等による逸失利益、弁護士費用等を含むがこれに限らない。）を賠償しなければならないものとします。',

    'mobi_term.item10.title': '第10条（原契約の適用）',
    'mobi_term.item10.description':
      '原規約第4条から第10条の定めは、本規約に準用します。準用された原規約における「当社」は、本規約に定めるCommunity Mobility株式会社であり、「利用者」は、本規約に定める利用者等に読み替えるものとします。',

    // Subcription landing page
    'sub.title1': '昨日まで30日間',
    'sub.title2': '31日前から60日前',
    'sub.title3': '61日前から90日前',

    'sub.description1': 'いつもの移動を便利にお得に',
    'sub.description2': '定額 乗り放題プラン',

    'sub.mobi_define': 'mobi（モビ） は',
    'sub.mobi_define1': 'あなたの為に動く新感覚相乗り交通です。',
    'sub.mobi_define2': '定額 乗り放題プランに加入して',
    'sub.mobi_define3': 'お好きな時にお好きな場所へ移動してください。',

    'sub.feature': 'Feature',
    'sub.feature_description': '定額乗り放題プランの特徴',
    'sub.feature_description1': '定額{{amount}}',
    'sub.feature_description1.2': '',
    'sub.feature_description2': '日間　乗り放題',
    'sub.feature_description3': 'エリア内のご希望の乗車地までお迎えし、',
    'sub.feature_description4': '降車地までお送りします。',
    'sub.feature_description5': 'あなたのご利用状況',

    'sub.scope1_description1': '移動がラクラク',
    'sub.scope1_description2': '',
    'sub.scope1_description3': 'ご希望の乗車位置付近までお迎えして',
    'sub.scope1_description4': 'ご希望の降車位置付近までお届けします。',
    'sub.scope1_description5': '利用可能エリアを確認',

    'sub.scope2_description1': '車両が街を循環',
    'sub.scope2_description2': '',
    'sub.scope2_description3': '自分の街をmobiが循環。',
    'sub.scope2_description4': '最適な車両が、あなたの元に駆けつけます。',

    'sub.scope3_description1': '定額乗り放題プランに',
    'sub.scope3_description2': 'プラス{{fee}}/人で家族を追加',
    'sub.scope3_description3': 'ご家族を最大{{member}}名まで',
    'sub.scope3_description4': '定額乗り放題プランを自由に利用できます',
    'sub.scope3_description5': '家族オプションについて、もっと詳しく',
    'established_year': '2021年6月17日　制定 2022年6月21日　改正実施 2024年7月1日　改正実施',
    'established_year_1': '2021年6月17日　制定',
    'established_year_2': '2022年6月21日　改正実施',
    'established_year_3': '2024年7月1日　改正実施',

    // Ticket Landing Page
    'ticket.title1': '一回払いよりお得に乗車！',
    'ticket.title2': 'チケット定額プラン',
    'ticket.background_title1': 'お得に、無駄なく',
    'ticket.background_title2': 'チケット定額',
    'ticket.content1': 'mobi（モビ） は',
    'ticket.content2': 'あなたのために動く新感覚の相乗り交通です。',
    'ticket.content_ja1': 'チケット定額プランは、ライフスタイルに合わせて',
    'ticket.content_ja2': 'お得に無駄なく',
    'ticket.content3': 'mobiをご利用頂けるプランです。',
    'ticket.ticket_price': 'チケット定額プラン',
    'ticket.ticket_price.content': 'なんでおススメなの？',
    'ticket.save_money.content1': '便利な自動更新',
    'ticket.save_money.content2': 'ご利用プランの適用期間ごとに自動更新されるので毎回ご購入いただく必要がありません。また、解約はアプリから簡単にお手続きできます。',
    'ticket.save_money.content3': 'プラン適用期間ごとに自動購入されます。',
    'ticket.feature1.content1': '一回払いよりもお得',
    'ticket.feature1.content2': '複数回分のチケットを一度に購入するので',
    'ticket.feature1.content3': '1回あたりの乗車料金がお得になります。',
    'ticket.feature2.content1': '同乗者にも使える',
    'ticket.feature2.content2': 'ご家族やお友達など、',
    'ticket.feature2.content3': '同乗者の方にもお得なチケットを適用頂けます。',
    'ticket.feature3.content1': '未使用分は次回に繰り越し',
    'ticket.feature3.content2': '前回未使用分は',
    'ticket.feature3.content3': '次回に繰り越されるので、無駄なく使える。',
    'ticket.footer1': '※ 前々回分は繰り越されません。',

    // Family option
    'family_option.title1': '家族の移動も、便利でお得に',
    'family_option.description': '定額 乗り放題プラン',
    'family_option.title2': '家族オプション',
    'family_option.introduce': 'mobi（モビ） の',
    'family_option.introduce1': '定額 乗り放題プランを1人プラス{{value}}で',
    'family_option.introduce2': 'あなたのご家族にご利用いただけます',
    'family_option.feature': 'Feature',
    'family_option.feature_description': '家族オプションの特徴',
    'family_option.feature1_title': '定額乗り放題プランに',
    'family_option.feature1_title2': '',
    'family_option.feature1_description1': 'プラス{{value}}/人で家族を追加',
    'family_option.feature1_description2': 'ご家族を最大{{value}}名まで',
    'family_option.feature1_description3': '定額乗り放題プランを自由に利用できます',
    'family_option.feature2_title1': '家族オプションでも',
    'family_option.feature2_title2': 'できることは、変わりません',
    'family_option.feature2_description1': 'ご家族の方もアプリを',
    'family_option.feature2_description2': 'ダウンロードして利用',
    'family_option.feature2_description3': 'ご家族は簡単に登録可能です',
    'family_option.feature2_description4': '登録すると自由にmobiをご利用できます',
    'family_option.feature2_description5': '',
    'family_option.feature3_title1': 'スマホが無いご家族は',
    'family_option.feature3_title2': '電話番号でもmobiを呼べます',
    'family_option.feature3_description1': 'オペレーターが親切に',
    'family_option.feature3_description2': '予約を手配し、乗車場所をご案内します',
    'family_option.feature4_title1': 'エリア内であれば、どこでも',
    'family_option.feature4_title2': '自由に移動できます',
    'family_option.feature4_description1': '自分の街をmobiが循環。',
    'family_option.feature4_description2': 'このアプリで呼ぶと、すぐに駆けつけます。',
    'family_option.fast_feature': '利用可能エリアを確認',
    'family_option.feature5_title1': '呼んだら平均10-15分で',
    'family_option.feature5_title2': '指定の場所に駆けつけます',
    'family_option.feature5_description1': '自分の街をmobiが循環。',
    'family_option.feature5_description2': 'このアプリで呼ぶと、すぐに駆けつけます。',
    'family_option.japan_only': '日本のみ',

    // terms_mobi
    'terms.title': 'mobi Community Mobility<br>アプリ利用規約',
    'terms.description':
      'mobi Community Mobility アプリ利用規約（以下、「本規約」といいます。）は、シンガポール法人であるWILLERS PTE. LTD.（以下、「当社」といいます。）が運営・提供するアプリケーション「mobi Community Mobility アプリ」（以下、「本アプリ」といいます。）の利用条件を定めるものです。',

    'terms.item1.title': '第1条（総則）',
    'terms.item1.content1.title':
      '本アプリは、本規約に同意の上、自ら保有又は管理するスマートフォン等の携帯型情報端末（以下、対応端末といいます。）に本アプリをインストールした者（以下、「利用者」といいます。）に対して本アプリの利用が許諾されます。',
    'terms.item1.content2.title':
      '利用者は、本アプリを利用し又は第２条で定める個別サービスの閲覧若しくは申し込みにより、本規約の他、第２条で定める個別サービスに関して定められる規約（以下、「個別規約」といいます。）及び本規約又は個別規約に付随する約款、規則等（以下、合わせて「本規約等」といいます。）を全て確認の上、同意したものとみなします。',
    'terms.item1.content3.title':
      '本規約等の内容は、当社又は個別規約の管理者の判断において、以下の場合に利用者に対して何らかの事前の通知なしに変更することがあります。この場合、変更日以降の利用については、変更後の本規約等の内容が適用されます。なお、当社は、本規約等の内容を変更する場合、合理的な期間をもって事前に、利用者に変更内容を通知するものとします。',
    'terms.item1.content3.list1': '本規約等の内容変更が、利用者の一般の利益のために実施されるとき',
    'terms.item1.content3.list2':
      '本規約等の内容変更が、本規約等の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。',
    'terms.item1.content4.title':
      '本規約等の定めの一部の効力が、法令又は判決により無効となった又は取り消された場合においても、残りの定めの有効性、適法性及び法的強制力はなお有効であるものとします。',

    'terms.item2.title': '第2条（提供するサービス）',
    'terms.item2.content1.title':
      '本アプリにおいて、利用者は、以下の各号に定めるサービス（以下、「本サービス」といいます。）の提供を無料で受けることができます。',
    'terms.item2.content1.list1':
      'ルート検索：本アプリの提供する検索手段を利用して、特定エリアにおける出発地から目的地に至るまでの最適な経路及び交通手段を一ルート又は複数ルートを検索表示することができるものです。',
    'terms.item2.content1.list2':
      '個別サービス検索：当社以外の事業者が提供する運送サービス（以下、「個別サービス」といいます。）を、前号のルート検索に関連して又は利用者の検索した結果として、一つ又は複数表示することができるものです 。',
    'terms.item2.content1.list3':
      '決済代行：本アプリの利用を通じて発生した費用の決済をする場合に、第三者である決済代行会社を通じて、当社が個別サービスを提供する事業者（以下、「提供事業者」といいます。）に対して、この費用の支払いを代行するものです。なお、個別サービスの支払方法は、個別規約等により定めるものとします。',
    'terms.item2.content2.title':
      '利用者が個別サービスに申し込む場合、当社は、本アプリを通じて各個別サービスの紹介又は場合により決済代行を行うものであり、利用者は、提供事業者との間でのみ個別サービスに対する契約を締結するものとします。',
    'terms.item2.content3.title':
      '利用者の位置、使用する携帯型情報端末、通信状況その他の利用環境によっては、本サービスの一部又は全部の機能が利用できない場合があります。',
    'terms.item2.content4.title':
      '当社は、自己の判断により、利用者から事前の承諾を得ることなく本サービス又は個別サービスの全部又は一部を変更、追加又は廃止（以下、「変更等」といいます。）することができるものとします。なお、本サービス又は個別サービスの変更等により利用者に発生した損害又は不利益について、当社は何ら責任を負いません。',
    'terms.item2.content5.title':
      '各個別規約と本規約との間に矛盾する内容が定められている場合、別段の定めがない限り、各個別規約等の定めが優先して適用されるものとします。',

    'terms.item3.title': '第3条（クレジットカードの登録）',
    'terms.item3.content1.title':
      '利用者は、個別サービスに対する決済代行のためにクレジットカードを登録する場合、利用者本人名義のクレジットカードに限り登録できるものとします。',
    'terms.item3.content2.title':
      '利用者は、他人のクレジットカードの利用、虚偽のクレジットカード情報の入力、その他当社が不適切と認める行為を行わないものとします。当社は、利用者がこれらの違反行為を行ったことによって生じた損害等について、当該利用者に対して賠償を求めることができるものとします。',
    'terms.item3.content3.title':
      '当社は、当社又は提携する決済代行会社の判断により、本サービス及び個別サービスに利用可能なクレジットカードを変更することができるものとします。',
    'terms.item3.content4.title':
      '利用者が決済のために利用したクレジットカードについて、カード発行会社又は決済代行会社が、何らかの理由によりその利用を拒否した場合又は当社所定の基準により不適切な利用であると判断した場合、当社は、利用者の承諾なく、決済方法の変更その他の当社が必要と認める措置を実施することができるものとします。',
    'terms.item3.content5.title':
      '個別サービスの決済手段にクレジットカードを利用し、かつ、かかる個別サービスに対する利用者の都合による契約内容の変更又は取消に伴い発生する変更料又は取消料が個別規約に定められている場合、かかる変更料及び取消料についても当社が利用したクレジットカードを通じて清算するものとします。',

    'terms.item4.title': '第4条（利用者への周知方法）',
    'terms.item4.description':
      '当社及び提供事業者は、本サービス及び個別サービスの利用に関して周知する必要がある場合は、自己の判断に基づき、本アプリへの通知、本アプリへの登録メールアドレスへの電子メールによる通知又は自己の管理するホームページ上への掲載その他の適切な方法のいずれか一つ又は複数の方法により行うことができるものとします。',

    'terms.item5.title': '第5条（個人情報）',
    'terms.item5.description':
      '当社は、本アプリ又は本サービスの提供を通じて取得した利用者の個人情報について、当社のプライバシーポリシー及び適用される個人情報法令の定めに従い扱うものとし、利用者はこれに同意するものとします。',

    'terms.item6.title': '第6条（データの取扱）',
    'terms.item6.content1.title':
      '前条に定めるほか、当社は、利用者が登録したデータにアクセスし、又は第三者へ開示いたしません。ただし、以下の各号に定める場合はこの限りではなく、利用者はこれらの事情によるアクセス又は第三者への開示について、承諾したものとします。',
    'terms.item6.content1.list1': '本アプリの障害等により、利用者のデータが破損等した場合に修復作業を行う場合',
    'terms.item6.content1.list2':
      '本アプリの機能の変更、拡張又は法令の改正等に対応するための修正に伴い、変更、拡張又は修正した本システムの機能を利用者のデータに反映させるために必要な変換作業を行う場合',
    'terms.item6.content1.list3': '本アプリの品質向上のため匿名加工処理をした上で必要なデータ分析を行う場合',
    'terms.item6.content1.list4': '公衆の生命、健康、財産等の重大な利益を保護するために必要と合理的に判断できる場合',
    'terms.item6.content1.list5': '法令に基づく開示請求があった場合',
    'terms.item6.content1.list6': '開示先及び開示の目的を提示して利用者から個別の承諾を得た場合',

    'terms.item7.title': '第7条（禁止事項）',
    'terms.item7.content1.title':
      '利用者は、本サービスを利用するにあたり、次の各号に該当する又は該当すると当社が認める行為を行ってはならないものとします。',
    'terms.item7.content1.list1': '本規約等に違反すること',
    'terms.item7.content1.list2': '本サービスを利用者本人が利用する目的以外の目的で利用すること',
    'terms.item7.content1.list3': '本サービス及び個別サービスの利用を通じて得られた情報を、営利的な目的で利用すること',
    'terms.item7.content1.list4':
      '本サービス及び個別サービスの利用に関する契約上の地位又はこの契約から発生した権利若しくは義務を、当社又は提供事業者の事前の承諾なく、第三者（利用者と同時に個別サービスの提供を受ける者を除く。）に譲渡し、移転し、貸与し、又は担保に提供すること',
    'terms.item7.content1.list5': '本サービスの利用に際し、虚偽の情報を登録すること',
    'terms.item7.content1.list6': '当社及び提供事業者の著作権、商標権、意匠権、特許権その他の知的財産権を侵害すること',
    'terms.item7.content2.title':
      '利用者は、本アプリを利用するにあたり、次の各号に該当する又は該当すると当社が認める行為を行ってはならないものとします。',
    'terms.item7.content2.list1':
      '本アプリへのコンピューターウイルス等を含む一切の有害なプログラム又はデータの送信又は使用',
    'terms.item7.content2.list2': '故意に第三者の知的財産を侵害する方法での、本アプリの利用',
    'terms.item7.content2.list3':
      '自己又は第三者が、本契約により許諾される範囲を超えて、本アプリに対し情報を登録し、設定を変更し、又は本システムを改造し、改変し、複製し、逆アセンブルし、逆コンパイルし及び/若しくはリバースエンジニアリングをすること。',
    'terms.item7.content2.list4': 'その他、当社が不適切と認める行為',
    'terms.item7.content3.title':
      '当社は、利用者が前2項各号に定める行為を行ったこと確認し、又はかかる行為をしたと合理的に認めた場合、任意の時期に、当該利用者のアクセスを停止し、個別サービスの利用を取消し、又は拒絶することができるものとします。',

    'terms.item8.title': '第8条（本アプリの中断、停止等）',
    'terms.item8.description':
      '当社は、以下の各号に定める事由に該当する場合、何ら責任を負うことなく、利用者に対し事前に周知の上、本アプリの全部又は一部の利用を一時的に中断し又は停止することができるものとします。ただし、緊急の場合には、事前の周知を省略できるものとします。',
    'terms.item8.content1.list1': '設備又はソフトウェアの保守を定期的又は緊急に実施する場合',
    'terms.item8.content1.list2':
      '当社の契約するクラウドサーバー、通信回線、電力その他の公共的サービス提供事業者独自の判断又は不可抗力によるサービスの供給の停止もしくは障害が発生した場合',
    'terms.item8.content1.list3':
      '当社に帰することができない事由による行政機関又は司法機関による業務の停止命令又はその指導、要請があった場合',
    'terms.item8.content1.list4': '本アプリ上の不具合（エラー、バグを含む）又は本システム用設備の不具合が生じた場合',
    'terms.item8.content1.list5': '利用者による不正又は誤った操作により本システムの提供に支障が生じた場合',
    'terms.item8.content1.list6': '本アプリ又は本アプリ用設備に第三者からの不正アクセスがあった場合',
    'terms.item8.content1.list7': '本アプリ又は本アプリ用設備を再起動する必要が生じた場合',
    'terms.item8.content1.list8':
      '本アプリへのアクセスが著しく増加し、本サービスの運用に過度の負荷を与える等の場合で、本アプリの全ての利用者に対して安定した利用環境を提供するために必要だと当社が判断した場合',
    'terms.item8.content1.list9': '「禁止事項」に該当する行為があった場合',
    'terms.item8.content1.list10': 'その他、当社が本サービスの運営上維持的な中断が必要と判断した場合',

    'terms.item9.title': '第9条（当社の責任）',
    'terms.item9.content1.title':
      '当社は、本アプリ及び個別サービスにおいて掲載されているデータ、地図情報、GPS座標情報等について、最新かつ真正な情報となるよう努めるものとしますが、利用者の利用時点において最新かつ正確な情報であることについては保証いたしません。',
    'terms.item9.content2.title':
      '本アプリから提供事業者等のサイトへリンクしている場合であったとしても、当社はかかるサイトについては一切責任を負いません。また、当該サイトの利用を原因とし又は関連して発生した損害について賠償する責任を負わないものとします。',
    'terms.item9.content3.title':
      '利用者が、本アプリの利用を通じて提供事業者を含む第三者に対して損害を与えた場合、利用者は自身の責任と費用において解決するものとし、当社は何ら責任を負わないものとします。',
    'terms.item9.content4.title':
      '本アプリを利用するための携帯型情報端末及び通信回線を原因として、本サービスを利用できなかったことにより発生した一切の損害について、当社は何ら責任を負わないものとします。',
    'terms.item9.content5.title':
      '本規約に関して、当社が、利用者に損害を賠償する場合、その金額は、当該損害が発生した直近３ヶ月に本アプリの代行決済により決済した金額の総額又は直近１ヶ月に本アプリの代行決済により決済した金額に３を乗じた金額のうちいずれか高い金額を上限とします。ただし、係る損害が、当社の故意又は重大な過失により発生した場合はこの限りではありません。',

    'terms.item10.title': '第10条（利用者の責任）',
    'terms.item10.content1.title':
      '利用者は、本アプリの利用のため、利用者自身の責任と費用で、携帯型情報端末及び通信環境を準備し利用可能な状態を維持するものとします。',
    'terms.item10.content2.title':
      '利用者は、本アプリへのアクセス権を利用者自身の責任で管理しなければならないものとします。なお、利用者のアクセス権で本サービス及び個別サービスの利用があった場合、万が一利用者以外の第三者による利用であったとしても、当社は、利用者による利用であるとみなすことができるものとします。',
    'terms.item10.content3.title':
      '利用者は、本規約等に違反して、当社又は提供事業者に損害を与えた場合、これを賠償すべき責任を負うものとします。',

    'terms.item11.title': '第11条（相談窓口・準拠法）',
    'terms.item11.content1.title': '本規約及び付随する規則等は、シンガポール法によって解釈されるものとします。',
    'terms.item11.content2.title':
      '本アプリ、本サービス及び個別サービスの利用において、本規約等の運用又は解釈に疑義がある場合、利用者は、以下の連絡先に対し、問い合わせることができます。' +
      '<br>メールアドレス：maas@willer.co.jp',
    'terms.item11.content3.title':
      '本規約に関する紛争については、シンガポール共和国の民事訴訟により当社の登録住所を管轄する裁判所を第一審の独占的合意管轄裁判所とします。ただし、適用される強制法規により別の裁判所への提訴が許される場合は、その定めによります。',

    // Verify mobile account
    'verify_mobile_account.register_email': 'ご登録いただいたメールアドレスの',

    // Verify subcription
    'verify_subcription.family_option_app': '家族オプション申し込み',
    'verify_subcription.family_option_complete': '家族オプションの申し込みが完了しました。',
    'verify_subcription.failed_apply_family_option': '家族オプションの申し込みに失敗しました。',

    // Mobile reissue password
    'mobile_reissue_pass.enter_password': 'ご希望のパスワードを入力してください',
    'mobile_reissue_pass.enter_pass_confirm': '確認用に2度ご入力ください',
    'mobile_reissue_pass.re_enter_password': 'パスワードを再入力',
    'mobile_reissue_pass.enter_password_again': 'もう一度、パスワードを入力してください',
    'mobile_reissue_pass.enter_content': '入力された内容が異なります',
    'mobile_reissue_pass.token_failed': 'トークンの失敗を確認する',
    'validation.required.password': 'パスワードを入力してください',
    'validation.required.re_enter_password': 'もう一度、パスワードを入力してください',

    // Mobile repassword complete
    'mobile_repass_complete.new_password': '新しいパスワードが',
    'mobile_repass_complete.set': '設定されました',
    'mobile_repass_complete.launch_app': 'アプリを起動して',
    'mobile_repass_complete.login': 'ログインしてください',

    // tutorial app
    'tutorial_app.title': '毎日の移動が変わる',
    'tutorial_app.description': '定額プラン',
    'tutorial_app.content1': 'mobi（モビ） は',
    'tutorial_app.content2': 'あなたの為に動く新感覚相乗り交通です。',
    'tutorial_app.content3': 'このアプリでmobiをリクエストすると',
    'tutorial_app.content4': '最適なルートをお得に効率よく移動できます',
    'tutorial_app.feature.title': 'Feature',
    'tutorial_app.feature.description': 'mobiの特徴',
    'tutorial_app.location.title': '移動がラクラク',
    'tutorial_app.location.content1': 'ご希望の乗車位置付近までお迎えして',
    'tutorial_app.location.content2': 'ご希望の降車位置付近までお届けします。',
    'tutorial_app.car.title': '車両が街を循環',
    'tutorial_app.car.content1': '自分の街をmobiが循環。',
    'tutorial_app.car.content2': '最適な車両が、あなたの元に駆けつけます。',
    'tutorial_app.wallet.title': 'お財布に優しい',
    'tutorial_app.wallet.content1': '街のみんなで共有する相乗り交通なので',
    'tutorial_app.wallet.content2': '',
    'tutorial_app.wallet.content3': '料金が安く、お財布に優しい',
    'tutorial_app.request.title': 'Request',
    'tutorial_app.request.description': 'mobiの予約方法',
    'tutorial_app.step1.title': 'Step.1',
    'tutorial_app.step1.content': 'ご希望の出発地と目的地を入力',
    'tutorial_app.step2.title': 'Step.2',
    'tutorial_app.step2.content': '交通手段の中からmobiを選択',
    'tutorial_app.step3.title': 'Step.3',
    'tutorial_app.step3.content': '指定された乗車位置から乗車',
  },
};

export default ja;
