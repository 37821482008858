import {ROUTE, ROUTER_KEY} from './common/constant';
import VerifyMobileAccount from './views/verify_mobile_account';
import VerifySubscription from './views/subscription/verify';
import MobileReissuePassword from './views/mobile_forgot_password';
import MobileRePasswordComplete from './views/reset_password';
import QR from './views/qr';
import Terms from './views/terms';
import Privacy from './views/privacy';
import HowToUse from './views/how-to-use';
import MobiTerms from './views/mobi_term/index';

import MobilePrivacy from './views/privacy/mobile_privacy';
import QR_TERM from './views/qr_term/index';

import ValidateBus from './views/shuttle_bus/validate_bus';
import RegistryInformationBus from './views/shuttle_bus/registry_information';
import RegistryPositionBus from './views/shuttle_bus/registry_position';
import RegistryConfirmBus from './views/shuttle_bus/registry_confirm';
import RegistryCompleteBus from './views/shuttle_bus/registry_complete';
import DetailBus from './views/shuttle_bus/detail_order';
import DetailPosition from './views/shuttle_bus/detail_position';
import DetailConfirm from './views/shuttle_bus/detail_confirm';
import DetailComplete from './views/shuttle_bus/detail_complete';

import LandingPage from './views/shuttle_bus_asahikawa/landing_page';
import AsahikawaRegisterInput from './views/shuttle_bus_asahikawa/register_input';
import AsahikawaRegisterConfirm from './views/shuttle_bus_asahikawa/register_confirm';
import AsahikawaRegisterComplete from './views/shuttle_bus_asahikawa/register_complete';
import AsahikawaVerifyOrder from './views/shuttle_bus_asahikawa/verify_order';
import AsahikawaDetail from './views/shuttle_bus_asahikawa/detail';

import SubscriptionLandingPage from './views/subscription/landing-page';
import FamilyOptionLandingPage from './views/subscription/family-option';
import JitLandingPage from './views/jit/landing-page';
import TutorialApp from './views/subscription/tutorial-app';
import TicketLandingPage from './views/subscription/ticket';


const router = [
  // Page views
  {
    path: ROUTE.VERIFY_MOBILE_ACCOUNT,
    pathActive: ROUTE.VERIFY_MOBILE_ACCOUNT,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.verify_mobile_account',
    component: VerifyMobileAccount,
  },
  {
    path: ROUTE.VERIFY_SUBSCRIPTION,
    pathActive: ROUTE.VERIFY_SUBSCRIPTION,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.verify_mobile_account',
    component: VerifySubscription,
  },
  {
    path: ROUTE.MOBILE_REISSUE_PASSWORD,
    pathActive: ROUTE.MOBILE_REISSUE_PASSWORD,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.mobile_reissue_password',
    component: MobileReissuePassword,
  },
  {
    path: ROUTE.MOBILE_RE_PASSWORD_COMPLETE,
    pathActive: ROUTE.MOBILE_RE_PASSWORD_COMPLETE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.mobile_reissue_password',
    component: MobileRePasswordComplete,
  },
  {
    path: ROUTE.QR_APPLE,
    pathActive: ROUTE.QR_APPLE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.qrApple',
    component: QR,
  },
  {
    path: ROUTE.QR_GOOGLE,
    pathActive: ROUTE.QR_GOOGLE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.qrGoogle',
    component: QR,
  },
  {
    path: ROUTE.USER_TERMS,
    pathActive: ROUTE.USER_TERMS,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.terms',
    component: Terms,
  },
  {
    path: ROUTE.MOBI_TERM,
    pathActive: ROUTE.MOBI_TERM,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.mobi_terms',
    component: MobiTerms,
  },
  {
    path: ROUTE.USER_PRIVACY,
    pathActive: ROUTE.USER_PRIVACY,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.privacy',
    component: Privacy,
  },
  {
    path: ROUTE.DRIVER_TERMS,
    pathActive: ROUTE.DRIVER_TERMS,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.driverTerms',
    component: Terms,
  },
  {
    path: ROUTE.DRIVER_PRIVACY,
    pathActive: ROUTE.DRIVER_PRIVACY,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.driverPrivacy',
    component: Privacy,
  },
  {
    path: ROUTE.HOW_TO_USE,
    pathActive: ROUTE.HOW_TO_USE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.howToUse',
    component: HowToUse,
  },
  {
    path: ROUTE.HOW_TO_USE_QR,
    pathActive: ROUTE.HOW_TO_USE_QR,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.howToUse',
    component: HowToUse,
  },
  {
    path: ROUTE.MOBILE_PRIVACY,
    pathActive: ROUTE.MOBILE_PRIVACY,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.mobilePrivacy',
    component: MobilePrivacy,
  },
  {
    path: ROUTE.QR_TERM,
    pathActive: ROUTE.QR_TERM,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.qrTerm',
    component: QR_TERM,
  },

  // Page view inside layout
  {
    path: ROUTE.DETAIL_POSITION + ':id',
    pathActive: ROUTE.DETAIL_POSITION,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: DetailPosition,
  },
  {
    path: ROUTE.DETAIL_CONFIRM + ':id',
    pathActive: ROUTE.DETAIL_CONFIRM,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: DetailConfirm,
  },
  {
    path: ROUTE.DETAIL_COMPLETE + ':id',
    pathActive: ROUTE.DETAIL_COMPLETE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: DetailComplete,
  },
  {
    path: ROUTE.REGISTRY_INFORMATION,
    pathActive: ROUTE.REGISTRY_INFORMATION,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: RegistryInformationBus,
  },
  {
    path: ROUTE.REGISTRY_POSITION,
    pathActive: ROUTE.REGISTRY_POSITION,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: RegistryPositionBus,
  },
  {
    path: ROUTE.REGISTRY_CONFIRM,
    pathActive: ROUTE.REGISTRY_CONFIRM,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: RegistryConfirmBus,
  },
  {
    path: ROUTE.REGISTRY_COMPLETE,
    pathActive: ROUTE.REGISTRY_COMPLETE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: RegistryCompleteBus,
  },

  {
    path: ROUTE.DETAIL_ORDER,
    pathActive: ROUTE.DETAIL_ORDER,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: DetailBus,
  },
  {
    path: ROUTE.VALIDATE_BUS,
    pathActive: ROUTE.VALIDATE_BUS,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: ValidateBus,
  },

  // Shuttle Asahikawa
  {
    path: ROUTE.ASAHIKAWA_INPUT,
    pathActive: ROUTE.ASAHIKAWA_INPUT,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: AsahikawaRegisterInput,
  },
  {
    path: ROUTE.ASAHIKAWA_CONFIRM,
    pathActive: ROUTE.ASAHIKAWA_CONFIRM,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: AsahikawaRegisterConfirm,
  },
  {
    path: ROUTE.ASAHIKAWA_COMPLETE,
    pathActive: ROUTE.ASAHIKAWA_COMPLETE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: AsahikawaRegisterComplete,
  },
  {
    path: ROUTE.ASAHIKAWA_VERIFY_ORDER,
    pathActive: ROUTE.ASAHIKAWA_VERIFY_ORDER,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: AsahikawaVerifyOrder,
  },
  {
    path: ROUTE.ASAHIKAWA_DETAIL,
    pathActive: ROUTE.ASAHIKAWA_DETAIL,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: AsahikawaDetail,
  },
  {
    path: ROUTE.ASAHIKAWA,
    pathActive: ROUTE.ASAHIKAWA,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: LandingPage,
  },

  // Subscription Page
  {
    path: ROUTE.SUBSCRIPTION_LANDING_PAGE,
    pathActive: ROUTE.SUBSCRIPTION_LANDING_PAGE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: SubscriptionLandingPage,
  },
  {
    path: ROUTE.FAMILY_OPTION_LANDING_PAGE,
    pathActive: ROUTE.FAMILY_OPTION_LANDING_PAGE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: FamilyOptionLandingPage,
  },
  {
    path: ROUTE.TUTORIAL_APP_PAGE,
    pathActive: ROUTE.TUTORIAL_APP_PAGE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: TutorialApp,
  },
  {
    path: ROUTE.TICKET_LANDING_PAGE,
    pathActive: ROUTE.TICKET_LANDING_PAGE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: TicketLandingPage,
  },

  // Jit Page
  {
    path: ROUTE.JIT_LANDING_PAGE,
    pathActive: ROUTE.JIT_LANDING_PAGE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: '',
    component: JitLandingPage,
  },
];

export default router;
