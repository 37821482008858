/**
 * display Japanese date time (YYYY年MM月DD日 hh:mm)
 * @param {String} stringISO
 * @return {String}
 */
export const displayDateTime = (stringISO) => {
  if (!stringISO) return '記録なし';
  return (
    stringISO.substring(0, 4) +
    '年' +
    stringISO.substring(5, 7) +
    '月' +
    stringISO.substring(8, 10) +
    '日 ' +
    stringISO.substring(11, 13) +
    ':' +
    stringISO.substring(14, 16)
  );
};

/**
 * display Japanese date time (MM月DD日 hh:mm)
 * @param {String} stringISO
 * @return {String}
 */
export const displayTejimaiTime = (stringISO) => {
  if (!stringISO) return '記録なし';
  return (
    stringISO.substring(5, 7) +
    '月' +
    stringISO.substring(8, 10) +
    '日 ' +
    stringISO.substring(11, 13) +
    ':' +
    stringISO.substring(14, 16)
  );
};

/**
 * display time only (hh:mm)
 * @param {String} stringISO
 * @return {String}
 */
export const displayTimeOnly = (stringISO) => {
  if (!stringISO) return '記録なし';
  return stringISO.substring(11, 13) + ':' + stringISO.substring(14, 16);
};

/**
 * convert Date to format: yyyy-MM-dd hh:mm:ss
 * @param {Date} dateTime
 * @return {String}
 */
export const convertDateTimeToDate = (dateTime) => {
  if (!dateTime) return null;
  return dateTime.getFullYear() + '-' + ('0' + (dateTime.getMonth() + 1)).slice(-2) + '-' + ('0' + dateTime.getDate()).slice(-2);
};

/**
 * convert display payloadDate
 * @param {String} date
 * @return {String}
 */
export const payloadDate = (date) => {
  if (!date) return;
  const today = new Date(date);
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
};

/**
 * Convert display date time
 * @param {string} stringISO
 * @return {string}
 */
export const convertDatetimeUTC = (stringISO) => {
  if (!stringISO) return;
  const datetime = new Date(stringISO);
  // display timezone by client
  const result = datetime.setHours(datetime.getHours() + getGMTTime());
  return new Date(result).toISOString();
};

/**
 * get GMT Hour from client
 * @return {Date}
 */
export const getGMTTime = () => {
  return new Date().getTimezoneOffset() / -60;
};

/**
 * get client timezone
 * @return {String}
 */
export const getClientTimezone = () => {
  // eslint-disable-next-line new-cap
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * compare Time
 * @param {StringISO} start
 * @param {StringISO} end
 * @return {Boolean}
 */
export const compareTime = (start, end) => {
  return end === start;
};
