import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {Grid, Container} from '@material-ui/core';
import {verifySubscriptionApi} from '../../../services/authenServices';
import {convertQueryStringToObject} from '../../../utils/common';

/**
 * verify subscription
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      isLoading: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    const urlParamStr = convertQueryStringToObject(window.location.search);
    const token = urlParamStr?.token;
    this.setState({
      isLoading: true,
    });
    verifySubscriptionApi(token)
        .then((response) => {
          this.setState({
            message: response,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            message: error,
            isLoading: false,
          });
        });
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <div>
        <LoadingOverlay
          active={this.state.isLoading}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          {this.state.isLoading ? (
            <Container maxWidth="sm" style={{height: '100vh'}}></Container>
          ) : (
            <Container maxWidth="sm" style={{height: '100vh'}}>
              <br></br>
              {this.state.message.status === 200 ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-48">
                    <h4 className="font-18">{t('verify_subcription.family_option_app')}</h4>
                  </Grid>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/subscription/humanity_logo.png`}
                      alt="Logo"
                      style={{width: 64}}
                    />
                  </Grid>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
                    <span className="font-16">{t('verify_subcription.family_option_complete')}</span>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-48">
                    <h4 className="font-18">{t('verify_subcription.family_option_app')}</h4>
                  </Grid>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
                    <img src={`${process.env.PUBLIC_URL}/images/failure.png`} alt="Logo" style={{width: 64}} />
                  </Grid>
                  <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
                    <h4 className="font-16">{t('verify_subcription.failed_apply_family_option')}</h4>
                  </Grid>
                </Grid>
              )}
              <br></br>
            </Container>
          )}
        </LoadingOverlay>
      </div>
    );
  }
}

export default withTranslation('translations')(Index);
