import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import SimpleReactValidator from 'simple-react-validator';
import {Container, Grid, Button, TextField, FormControl, FormHelperText} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {convertQueryStringToObject, onChangeTextField} from '../../utils/common';
import {resetPassword, verifyPasswordToken} from '../../stores/authen/actions';
import SelectMultiLang from '../../components/SelectMultiLang';
import './style.css';

const useStyles = (theme) => ({
  header_title: {
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      marginTop: 150,
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 48,
      fontSize: 24,
    },
  },
  label: {
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
});

/**
 * Mobile forgot password
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      new_password: '',
      re_password: '',

      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    const urlParamStr = convertQueryStringToObject(window.location.search);
    const token = urlParamStr?.token;
    this.props.verifyPasswordToken(token);
  }

  /**
   * handle submit form
   * @param {event} e
   */
  handleSubmitForm = (e) => {
    e.preventDefault();
    const urlParamStr = convertQueryStringToObject(window.location.search);
    const token = urlParamStr?.token;
    const payload = {
      new_password: this.state.new_password.trim(),
      token: token,
    };
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.props.resetPassword(payload, this.props);
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {authen, classes, t} = this.props;

    return (
      <Container maxWidth="sm" style={{height: '100vh'}}>
        <LoadingOverlay active={authen.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Grid container className="mt-10 mb-12" justify="flex-end" alignItems="center">
            <SelectMultiLang />
          </Grid>
          <Grid container>
            <Grid item xs={12} justify="center" alignItems="center" container className={classes.header_logo}>
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
            </Grid>
          </Grid>
          {authen.message_code === 'Api.success' || authen.message_code === '' ? (
            <Grid container>
              <Grid container item xs={12} alignItems="center" justify="center" className={classes.header_title}>
                <span>{t('route.mobile_reissue_password')}</span>
              </Grid>
              <Grid container item xs={12} alignItems="center" justify="center" className="mt-24">
                <span className="font-12">{t('mobile_reissue_pass.enter_password')}</span>
              </Grid>
              <Grid container item xs={12} alignItems="center" justify="center">
                <span className="font-12">{t('mobile_reissue_pass.enter_pass_confirm')}</span>
              </Grid>
              <Grid container item xs={12} alignItems="center" justify="center" className="mt-48">
                <span className={classes.label}>{t('registration.password')}</span>
              </Grid>
              <Grid container item xs={12} alignItems="center" justify="center" className="mt-12">
                <FormControl className="width_100 padding-item-0">
                  <TextField
                    name="new_password"
                    variant="outlined"
                    type="password"
                    className="width_100"
                    inputProps={{style: {textAlign: 'center'}}}
                    placeholder={t('validation.required.password')}
                    value={this.state.new_password}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                  {this.validator.message('new_password', this.state.new_password, 'required|between:8,32,string')}
                  {this.state.isSubmitForm && !this.validator.check(this.state.new_password, 'required') && (
                    <FormHelperText id="new_password" error className="error_text">
                      <span>{t('validation.required.password')}</span>
                    </FormHelperText>
                  )}
                  {this.state.isSubmitForm &&
                    this.validator.check(this.state.new_password, 'required') &&
                    !this.validator.check(this.state.new_password, 'between:8,32,string') && (
                    <FormHelperText id="new_password" error className="error_text">
                      <span>{t('validation.between', {field: t('registration.password'), min: 8, max: 32})}</span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid container item xs={12} alignItems="center" justify="center" className="mt-36">
                <span className={classes.label}>{t('mobile_reissue_pass.re_enter_password')}</span>
              </Grid>
              <Grid container item xs={12} alignItems="center" justify="center" className="mt-12">
                <FormControl className="width_100 padding-item-0">
                  <TextField
                    name="re_password"
                    variant="outlined"
                    type="password"
                    className="width_100"
                    inputProps={{style: {textAlign: 'center'}}}
                    placeholder={t('validation.required.re_enter_password')}
                    value={this.state.re_password}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                  {this.validator.message(
                      're_password',
                      this.state.re_password,
                      `required|between:8,32,string|in:${this.state.new_password}`,
                  )}
                  {this.state.isSubmitForm && !this.validator.check(this.state.re_password, 'required') && (
                    <FormHelperText id="re_password" error className="error_text">
                      <span className="error_text">
                        {t('validation.required.re_enter_password')}
                      </span>
                    </FormHelperText>
                  )}
                  {this.state.isSubmitForm &&
                    this.validator.check(this.state.re_password, 'required') &&
                    !this.validator.check(this.state.re_password, 'between:8,32,string') && (
                    <FormHelperText id="re_password" error className="error_text">
                      <span>
                        {t('validation.between', {
                          field: t('registration.password'),
                          min: 8,
                          max: 32,
                        })}
                      </span>
                    </FormHelperText>
                  )}
                  {this.state.isSubmitForm &&
                    this.state.re_password &&
                    this.validator.check(this.state.re_password, 'between:8,32,string') &&
                    !this.validator.fieldValid('re_password') && (
                    <FormHelperText id="re_password" error className="error_text">
                      <span className="error_text">{t('mobile_reissue_pass.enter_content')}</span>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid container item xs={12} alignItems="center" justify="center" className="mt-48">
                <div className="submit_button padding-item-0">
                  <Button
                    variant="contained"
                    style={{borderRadius: 24}}
                    className="width_100 button_color_submit button_height"
                    onClick={this.handleSubmitForm}
                  >
                    {t('common.btnRegister')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid container item xs={12} alignItems="center" justify="center" className={classes.header_title}>
                <span>{t('route.mobile_reissue_password')}</span>
              </Grid>
              <Grid container item xs={12} alignItems="center" justify="center" className="mt-24">
                <span className="font-12 color-danger">{t('mobile_reissue_pass.token_failed')}</span>
              </Grid>
            </Grid>
          )}
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authen: state.authen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (payload, props) => dispatch(resetPassword(payload, props)),
    verifyPasswordToken: (token) => dispatch(verifyPasswordToken(token)),
  };
};

export default withTranslation('translations')(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(Index)),
);
