export const ACTION_TYPES = {
  GET_ASAHIKAWA_PICK_UP_PLACE_START: 'GET_ASAHIKAWA_PICK_UP_PLACE_START',
  GET_ASAHIKAWA_PICK_UP_PLACE_SUCCESS: 'GET_ASAHIKAWA_PICK_UP_PLACE_SUCCESS',
  GET_ASAHIKAWA_PICK_UP_PLACE_FAIL: 'GET_ASAHIKAWA_PICK_UP_PLACE_FAIL',

  GET_ASAHIKAWA_DROP_OFF_PLACE_START: 'GET_ASAHIKAWA_DROP_OFF_PLACE_START',
  GET_ASAHIKAWA_DROP_OFF_PLACE_SUCCESS: 'GET_ASAHIKAWA_DROP_OFF_PLACE_SUCCESS',
  GET_ASAHIKAWA_DROP_OFF_PLACE_FAIL: 'GET_ASAHIKAWA_DROP_OFF_PLACE_FAIL',

  GET_EXPECTED_TIME_START: 'GET_EXPECTED_TIME_START',
  GET_EXPECTED_TIME_SUCCESS: 'GET_EXPECTED_TIME_SUCCESS',
  GET_EXPECTED_TIME_FAIL: 'GET_EXPECTED_TIME_FAIL',

  REGISTER_ASAHIKAWA_BUS_START: 'REGISTER_ASAHIKAWA_BUS_START',
  REGISTER_ASAHIKAWA_BUS_SUCCESS: 'REGISTER_ASAHIKAWA_BUS_SUCCESS',
  REGISTER_ASAHIKAWA_BUS_FAIL: 'REGISTER_ASAHIKAWA_BUS_FAIL',

  UPDATE_ASAHIKAWA_BUS_START: 'UPDATE_ASAHIKAWA_BUS_START',
  UPDATE_ASAHIKAWA_BUS_SUCCESS: 'UPDATE_ASAHIKAWA_BUS_SUCCESS',
  UPDATE_ASAHIKAWA_BUS_FAIL: 'UPDATE_ASAHIKAWA_BUS_FAIL',

  VERIFY_ASAHIKAWA_ORDER_START: 'VERIFY_ASAHIKAWA_ORDER_START',
  VERIFY_ASAHIKAWA_ORDER_SUCCESS: 'VERIFY_ASAHIKAWA_ORDER_SUCCESS',
  VERIFY_ASAHIKAWA_ORDER_FAIL: 'VERIFY_ASAHIKAWA_ORDER_FAIL',

  CANCEL_ASAHIKAWA_BUS_START: 'CANCEL_ASAHIKAWA_BUS_START',
  CANCEL_ASAHIKAWA_BUS_SUCCESS: 'CANCEL_ASAHIKAWA_BUS_SUCCESS',
  CANCEL_ASAHIKAWA_BUS_FAIL: 'CANCEL_ASAHIKAWA_BUS_FAIL',

  ASAHIKAWA_BUS: 'ASAHIKAWA_BUS',

  INITIAL_SCREEN: 'INITIAL_SCREEN',
  INITIAL_LANDING_PAGE: 'INITIAL_LANDING_PAGE',
};

export const initialState = {
  isLoading: false,
  pick_up_places: [],
  drop_off_places: [],
  expected_times: [],
  asahikawa_bus: {},
  messageRegistry: {},
  errorMessage: '',
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.INITIAL_LANDING_PAGE:
      return {
        ...state,
        isLoading: false,
        pick_up_places: [],
        drop_off_places: [],
        expected_times: [],
        asahikawa_bus: {},
        messageRegistry: {},
        errorMessage: '',
      };
    case ACTION_TYPES.INITIAL_SCREEN:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
      };
    case ACTION_TYPES.GET_ASAHIKAWA_PICK_UP_PLACE_START:
    case ACTION_TYPES.GET_ASAHIKAWA_DROP_OFF_PLACE_START:
    case ACTION_TYPES.GET_EXPECTED_TIME_START:
    case ACTION_TYPES.REGISTER_ASAHIKAWA_BUS_START:
    case ACTION_TYPES.UPDATE_ASAHIKAWA_BUS_START:
    case ACTION_TYPES.CANCEL_ASAHIKAWA_BUS_START:
    case ACTION_TYPES.VERIFY_ASAHIKAWA_ORDER_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_ASAHIKAWA_PICK_UP_PLACE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pick_up_places: action.pick_up_places,
      };
    case ACTION_TYPES.GET_ASAHIKAWA_PICK_UP_PLACE_FAIL:
      return {
        ...state,
        isLoading: false,
        pick_up_places: [],
      };
    case ACTION_TYPES.GET_ASAHIKAWA_DROP_OFF_PLACE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        drop_off_places: action.drop_off_places,
      };
    case ACTION_TYPES.GET_ASAHIKAWA_DROP_OFF_PLACE_FAIL:
      return {
        ...state,
        isLoading: false,
        drop_off_places: [],
      };
    case ACTION_TYPES.GET_EXPECTED_TIME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        expected_times: action.expected_times,
      };
    case ACTION_TYPES.GET_EXPECTED_TIME_FAIL:
      return {
        ...state,
        isLoading: false,
        expected_times: [],
      };
    case ACTION_TYPES.REGISTER_ASAHIKAWA_BUS_SUCCESS:
    case ACTION_TYPES.UPDATE_ASAHIKAWA_BUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messageRegistry: action.messageRegistry,
        pick_up_places: [],
        drop_off_places: [],
        expected_times: [],
        asahikawa_bus: {},
        errorMessage: '',
      };
    case ACTION_TYPES.REGISTER_ASAHIKAWA_BUS_FAIL:
    case ACTION_TYPES.UPDATE_ASAHIKAWA_BUS_FAIL:
      return {
        ...state,
        isLoading: false,
        messageRegistry: {},
        errorMessage: action.errorMessage,
      };
    case ACTION_TYPES.VERIFY_ASAHIKAWA_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        asahikawa_bus: action.asahikawa_bus,
        errorMessage: '',
      };
    case ACTION_TYPES.VERIFY_ASAHIKAWA_ORDER_FAIL:
      return {
        ...state,
        isLoading: false,
        asahikawa_bus: {},
        errorMessage: action.errorMessage,
      };
    case ACTION_TYPES.CANCEL_ASAHIKAWA_BUS_SUCCESS:
    case ACTION_TYPES.CANCEL_ASAHIKAWA_BUS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.ASAHIKAWA_BUS:
      const newState = Object.assign({}, state);
      newState.asahikawa_bus = action.asahikawa_bus;
      return newState;
    default:
      return state;
  }
}
