/* eslint-disable max-len */
export default [
  {
    title: 'For Singapore Users <br/> TERMS AND CONDITIONS FOR THE MOBI SERVICE',
    noneDescription: true,
  },
  {
    title: 'GENERAL',
    center: true,
    afterNoneDescription: true,
    list6: [
      {
        title: 'These terms and conditions (“<b>mobi Service Terms</b>”) govern your use of the Services (as defined below at Clause <b>2.1</b>) made available through the mobile application, “mobi Community Mobility App”, and/or our website (available at [https://willers.com.sg/]) (collectively referred to as “<b>Application</b>”). In these mobi Service Terms, “we”, “us”, “our” or “Willers” refers to Willers Pte. Ltd. “You” and “your” refers to any individual and/or organisation (such as the Registered Users), using our Services.',
      },
      {
        title: 'By registering for an User Account, you agree to be bound by the mobi Service Terms and following which are incorporated by reference into these mobi Service Terms: ',
        list7: [
          {
            title: 'the Terms of Use of the Mobility Application (made available through the Application);',
          },
          {
            title: 'our Privacy Policy (made available through the Application and at [https://willers.com.sg/Privacy-Policy]);',
          },
          {
            title: 'any other rules and regulations we may issue for our registered users (“<b>Registered Users</b>”) from time to time. ',
          },
        ],
        description: 'Unless otherwise indicated, defined terms in our Terms of Use of the Mobility Application shall continue to apply in these mobi Service Terms. In the event of any conflict or inconsistency between the Terms and Conditions for the Mobility Application and these mobi Service Terms in respect of the provision of the Services, precedence shall be given to these mobi Service Terms.',
      },
      {
        title: 'We may revise the mobi Service Terms at any time by amending this page without prior notice to you. Please check this page from time to time and review the mobi Service Terms to ensure that you understand all the terms and conditions that apply to your access and use of the Services and Application, as they are binding on you. Your continued access to and/or use of the Application and/or Services indicate your acceptance of the revised mobi Service Terms.',
      },
      {
        title: 'We have no obligation to provide, or continue to provide any Service, feature, promotions, benefit or activity, now or in the future. We reserve the right, at any time, temporarily or permanently, in whole or in part, without prior notification and without incurring any liability to you, to: modify, suspend or discontinue any Service, feature, promotions, benefit or activity, and to discontinue, restrict or modify access to your User Account. You are deemed to agree to such modifications if you continue to access and use the Application.',
      },
    ],
  },
  {
    title: 'SERVICES MADE AVAILABLE THROUGH THE APPLICATION',
    center: true,
    list6: [
      {
        title: 'The Application is an online platform which allows you to obtain or procure the following services (collectively defined as “<b>Services</b>”):',
        list7: [
          {
            title: 'booking transportation services (“<b>Transportation Services</b>”) from third party transportation providers (collectively “<b>Vendors</b>”), to bring you from your location to the selected desired destination through the Application. These Transportation Services are offered on a pay-per-use basis or on a subscription basis. By procuring the Transportation Services, you agree to be picked up and dropped off at designated pick-up and drop-off points; ',
          },
          {
            title: 'route search service which enables you to use the search function on the Application to search for the optimal route and form of transport for travelling from one destination to another (“<b>Route Search Services</b>”);  and/or',
          },
          {
            title: 'service search which enables you to conduct a search for the available Transportation Services (“<b>Service Search</b>”). ',
          },
        ],
      },
      {
        title: 'The Services can only be used by Registered Users. Registered Users would refer to the registered holder of the User Account used to access the Application for the purposes of procuring the Services.',
      },
      {
        title: 'You acknowledge and agree that <b> WE ARE A COMPANY THAT PROVIDES THE MOBILE APPLICATION AND </b> PLATFORM TO FACILITATE THE SERVICES AND DO NOT PROVIDE TRANSPORTATION SERVICES. THE TRANSPORTATION SERVICES ARE PROVIDED SOLELY BY OUR VENDORS. WE ARE MERELY THE INTERMEDIARY BETWEEN YOU AND THE VENDORS. WE ARE NOT RESPONSIBLE AND DISCLAIM ALL LIABILITY FOR ANY ACT OR OMISSION OF THE VENDORS, OR ANY DEALINGS BETWEEN YOU AND THE VENDORS, WHETHER OR NOT SUCH DEALINGS HAVE BEEN FACILITATED BY US OR CONDUCTED ON OR THROUGH THE APPLICATION AND OUR SERVICES.',
      },
      {
        title: 'Your procurement of Transportation Services shall be subject to your acceptance of the Vendor’s terms and conditions for the provision of Transportation Services as may be amended from time to time (“<b>Vendor Terms</b>”). A copy of the current Vendor Terms are set out at <b>Appendix A</b> and you are responsible for ensuring that you have read and understood the Vendor Terms. You are deemed to have accepted then current Vendor Terms when you purchase the Transportation Services through the Application. <b>If you are not agreeable to the Vendor Terms, do not use the Transportation Services.</b>',
      },
      {
        title: 'You acknowledge and accept that in the event of (i) any dispute arising between you and a Vendor; (ii) any act or omission of a Vendor; and/or (iii) any accident occurring during the provision of the Transportation Services, your only recourse is against the Vendor. We shall not be responsible or liable for any act or omission of a Vendor and/or any Transportation Service.',
      },
      {
        title: 'You acknowledge and accept that the information displayed on the Application (such as travel distance, duration, estimated arrival and departure times) are dependent on the prevailing traffic conditions, and may be changed from time to time. We do not warrant the accuracy of the information displayed as such information are merely estimates. Please refer to the Vendor Terms for further information on the Transportation Services provided. ',
      },
    ],
  },
  {
    title: 'PAYMENT',
    center: true,
    list6: [
      {
        title: 'All amounts payable by you for the use of the Services are final and non-refundable, and must be paid using the payment methods offered via the Application from time to time, including (where applicable) by credit or debit card, electronic funds transfer (such as PayNow) or by such other methods made available in the Application (“<b>Designated Payment Method</b>”). Different payment plans may have different Designated Payment Methods.',
      },
      {
        title: 'All payments must be made in Singapore dollars and shall include all applicable prevailing taxes.',
      },
      {
        title: 'There are two types of payment plans:',
        list7: [
          {
            title: '<b>Pay-Per-Use</b>: We will impose a one-time fee for each reservation of Transportation Services (“<b>One-Time Fee</b>”). The One-Time Fee must be paid through the Designated Payment Method on the Application.',
          },
          {
            title: '<b>Monthly Subscription plan</b>: We offer an individual monthly subscription plan (“<b>Monthly Subscription Plans</b>”). <br/>The Monthly Subscription Plans are strictly non-transferable.',
          },
          {
            title: 'The monthly subscription fee for the Monthly Subscription Plans must be paid in advance before the start of the relevant month using the Designated Payment Method on the Application.',
          },
          {
            title: 'The Monthly Subscription Plan will be automatically renewed unless you cancel your Monthly Subscription Plan before the expiry of the relevant month. There will be no refund of any payment made for a Monthly Subscription Plan.',
          },
        ],
      },
      {
        title: 'The fees to be charged under the respective payment plans (including taxes) (“Fees”) will be notified to you through the Application. We reserve the right to amend the Fees payable from time to time, and will notify you of the changes in the Fees payable prior to implementing such changes. ',
      },
      {
        title: 'We may use third party services to verify, secure, facilitate and/or process payment of the Fees. The third party service provider may charge a fee for processing payments on the Application. Any such charges and/or fees shall be borne by you. Where you are making payment using a credit or debit card, the relevant credit card details will be required. We will only use the credit card details in order to process any payment made by you in respect of the Application and/or Services. You:',
        list7: [
          {
            title: 'represent and warrant that the details provided are of a credit card registered in your name;',
          },
          {
            title: 'shall not provide the details of a credit card registered in the name of another person, provide false credit card details or otherwise perform any act considered to be illegal under the laws of the Republic of Singapore; ',
          },
          {
            title: 'acknowledge that we may work with specific third party service providers, and not all credit cards or debit cards will be accepted; and',
          },
          {
            title: 'agree that if there are issues with the credit or debit card used to make payment of the Fees, we reserve the right to take all steps necessary, including changing the method of payment, to process the payment of such Fees. ',
          },
        ],
      },
      {
        title: 'All applicable Fees must be paid in advance before the Services are provided. ',
      },
      {
        title: 'If you believe that you have been incorrectly charged a fee, you may contact us via the Application or by email [pr-info@willers.com.sg] for assistance. ',
      },
    ],
  },
  {
    title: 'CANCELLATION OF TRANSPORTATION SERVICES',
    center: true,
    list6: [
      {
        title: 'You may cancel your request for Transportation Services at any time between making a request for Transportation Services and before the arrival of the transportation vehicle at the designated pick-up point . Should you decide to cancel your request for Transportation Services upon or after the arrival of the transportation vehicle at the designated pick-up point, or do not show up at the designated pick-up point at the designated time, we reserve the rights to impose penalties for such cancellation. No refund will be made for fees paid under a Monthly Subscription Plan should there be any cancellation of the Transportation Services.',
      },
    ],
  },
  {
    title: 'PROMOTIONS',
    center: true,
    list6: [
      {
        title: 'In order to promote the usage of the Services, we may from time to time, in our sole discretion, offer promotions which shall be subject to such criteria, as well as terms and conditions as we may specify (“<b>Promotions</b>”). By signing up for a User Account, you agree to receive electronic communications relating to the Services and Promotions that we and/or our selected third party partners (including our Vendors) may offer.',
      },
      {
        title: 'If you participate in a Promotion, you warrant that you meet the applicable criteria and agree to the relevant terms and conditions. You shall use a Promotion for its intended purpose and in accordance with the relevant terms and conditions, and will not abuse, duplicate, sell or transfer the Promotion in any manner. Promotions cannot be exchanged for cash and may expire on a certain date, even before you use them.',
      },
      {
        title: 'From time to time, third party partners (including our Vendors) may offer promotions or advertise their services through our Application (“<b>Third Party Promotions</b>”). Such information are provided only for your convenience and we make no representations whatsoever about such Third Party Promotions. Allowing the publication of the Third Party Promotions on our Application does not mean that we endorse or accept any responsibility for your reliance on the Third Party Promotions and/or any transactions between you and the relevant third party. ',
      },
      {
        title: 'If you decide to participate in any Third Party Promotion, you acknowledge and agree that such promotion may be subject terms and conditions imposed by the relevant third party. We shall not be liable for any loss or damage caused or alleged to be caused by or in connection with your acceptance of or participation in any Third Party Promotion. ',
      },
    ],
  },
  {
    title: 'REPAIR OR CLEANING FEES',
    center: true,
    list6: [
      {
        title: 'Where applicable, you shall be responsible for the cost of repairing any damage caused to and/or the necessary cleaning of our Vendors’ property as a result of your misuse of the Transportation Services or your breach of these mobi Service Terms. We may facilitate payment for the cost of such repair or cleaning on behalf of our Vendors via your Designated Payment Method, in the event our Vendors’ repair or cleaning request has been verified by us. You agree that you shall make full payment of the applicable repair or cleaning fees as may be imposed.',
      },
    ],
  },
  {
    title: 'YOUR RESPONSIBILITIES',
    center: true,
    list6: [
      {
        title: 'You shall abide strictly with your obligations under these mobi Service Terms and agree that you shall not:',
        list7: [
          {
            title: 'perform any acts that would breach the terms as set out under the mobi Service Terms;',
          },
          {
            title: 'use the Services for any unlawful purposes or any other purposes than the purpose for which it is intended to be used;',
          },
          {
            title: 'make reservations of Transportation Services through your account for another individual who is not a Registered User of the Application; ',
          },
          {
            title: 'make frivolous, fraudulent and/or malicious bookings and/or cancellations of the Transportation Services;',
          },
          {
            title: 'use the Services to harm or injure another person, or infringe another person’s rights;',
          },
          {
            title: 'employ any means to defraud us or enrich yourself through any means, whether fraudulent or otherwise;',
          },
          {
            title: 'breach any of the Vendor Terms or any guideline, rule or regulation for the Transportation Services, or otherwise:',
            list8: [
              {
                title: 'cause a nuisance or behave in an inappropriate or disrespectful manner, or disobey the instructions of the driver of the transportation vehicle and/or any of the Vendor’s employees onboard such vehicles;',
              },
              {
                title: 'eat, drink or smoke while onboard the transportation vehicle;',
              },
              {
                title: 'bring or consume durians while onboard the transportation vehicle;',
              },
              {
                title: 'whether intentionally or unintentionally, cause or attempt to cause damage to the Vendors and/or their properties, or any other passengers aboard the Vendors’ transportation vehicles; and/or',
              },
            ],
          },
          {
            title: 'contact our Vendors for any reason apart from their provision of the Transportation Services.',
          },
        ],
      },
      {
        title: 'If you are in breach of Clause <b>7.1</b>, we shall be entitled to suspend or terminate your User Account with immediate effect and decline to provide the Services to you. You further acknowledge and agree that the Vendors may also decline to provide the Transportation Services to you, notwithstanding the payment of any Fees.  ',
      },
    ],
  },
  {
    title: 'SUSPENSION AND TERMINATION OF USER ACCOUNT',
    center: true,
    list6: [
      {
        title: 'We have the right and absolute discretion to suspend or terminate a Registered User’s access to the Application, User Account, Services, Promotion and any other service, feature, benefit, promotion or activity, without offering any reason.',
      },
      {
        title: 'We reserve the right to suspend or terminate your User Account immediately, without prior notice, without giving you a reason if:',
        list7: [
          {
            title: 'you are, or we suspect that you are, in breach of these mobi Service Terms; ',
          },
          {
            title: 'you have failed to make payment for any monies due; ',
          },
          {
            title: 'you have used or are using the Services for any illegal or impermissible purpose or activity including any activity which may expose or potentially expose us to civil or criminal liability or may otherwise affect our reputation;',
          },
          {
            title: 'we deem fit to do so, including for security reasons or to protect our interests or the interests of other Registered Users;',
          },
          {
            title: 'we discontinue the Application or the Services; and/or',
          },
          {
            title: 'we are ordered by enforcement or other governmental agencies to do so. ',
          },
        ],
      },
      {
        title: 'You may terminate your User Account with us at any time via the Application or by written notification to pr-info@willers.com.sg.',
      },
      {
        title: 'Any Promotions, rebates, rewards and/or offers cannot be redeemed, exchanged for cash and/or transferred, and shall be forfeited if your User Account is suspended or terminated.',
      },
      {
        title: 'We will not compensate or reimburse you for any costs or expenses you may incur in the event that you terminate your User Account, we suspend or terminate your User Account, or we otherwise limit, change or cease the provision of the Services. ',
      },
    ],
  },
  {
    title: 'GENERAL DISCLAIMERS',
    center: true,
    list6: [
      {
        title: 'THE SERVICES, INCLUDING ANY PROMOTIONS, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS ONLY. YOU USE THE SERVICES AT YOUR OWN RISK.',
      },
      {
        title: 'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE MAKE NO REPRESENTATIONS, WARRANTIES OR GUARANTEES, WHETHER EXPRESS OR IMPLIED, (I) AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES; (II) THAT THE USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; (III) THAT ANY GOODS OR SERVICES YOU PROCURE THROUGH THE APPLICATION WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS.',
      },
      {
        title: 'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE, OUR PARENTS, SUBSIDIARIES, AFFILIATES, AND OUR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, AGENTS, CONTRACTORS, SUCCESSORS AND ASSIGNS (COLLECTIVELY, “WILLERS”) SHALL NOT BE LIABLE TO ANY PERSON OR ENTITY FOR ANY DIRECT OR INDIRECT LOSS, DAMAGE, INJURY, CLAIM OR LIABILITY OF ANY KIND (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE, SPECIAL OR OTHERWISE) HOWEVER ARISING AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, ARISING OUT OF, RESULTING FROM OR OTHERWISE IN CONNECTION WITH THE APPLICATION, SERVICES, PROMOTIONS, VENDORS, TRANSPORTATION SERVICES, THIRD PARTY PROMOTIONS, OR ANY INFORMATION OR CONTENT PROVIDED ON THE APPLICATION, OR ANY PRODUCT OR SERVICE OBTAINED FROM OR THROUGH THE APPLICATION OR OUR SERVICES.',
      },
      {
        title: 'IF YOU ARE DISSATISFIED WITH THE APPLICATION, SERVICES, PROMOTIONS, VENDORS, TRANSPORTATION SERVICES, THIRD PARTY PROMOTIONS, OR ANY INFORMATION OR CONTENT PROVIDED ON THE APPLICATION OR ANY PRODUCT OR SERVICE OBTAINED FROM OR THROUGH THE APPLICATION OR OUR SERVICES, YOUR SOLE AND EXCLUSIVE REMEDY IS TO TERMINATE YOUR USER ACCOUNT. ',
      },
      {
        title: 'YOU AGREE THAT YOU SHALL BEAR ALL RISK ARISING OUT OF YOUR USE OF THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT WHERE YOUR USE OF THE SERVICES RESULTED IN ANY INJURY, LOSS, CLAIM, DAMAGE, COSTS AND EXPENSES OR ANY DIRECT, INDIRECT, SPECIAL EXEMPLARY, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND OR FOR ANY LOST PROFITS OR LOST SAVINGS, SUFFERED BY A THIRD PARTY (INCLUDING THE VENDORS), YOU SHALL BE SOLELY RESPONSIBLE FOR SUCH INJURY, LOSS, CLAIM, DAMAGE, COSTS AND EXPENSES, AND SHALL HAVE NO RECOURSE TO US IN RESPECT OF THE SAME. ',
      },
    ],
  },
  {
    title: 'LIMITATION OF LIABILITY',
    center: true,
    list6: [
      {
        title: 'Without prejudice to Clause <b>9</b>, Willers’ total liability to you, whether based on contract, tort (including negligence), equity, strict liability, breach of statutory duty or otherwise, for any and all injury, loss, claim, damage, costs and expenses shall in no circumstances exceed the amount paid by you to us for the Services in the three (3) months preceding the date on which such injury, loss, claim, damage, costs and expenses was incurred. ',
      },
    ],
  },
  {
    title: 'INDEMNITY AND RELEASE',
    center: true,
    list6: [
      {
        title: 'You agree to indemnify and hold harmless Willers in respect of any and all claims, demands, actions, proceedings, liabilities (whether civil or criminal), damages, losses, costs and expenses (including legal costs on an indemnity basis) which may be suffered or incurred by Willers or asserted Willers by any person in respect of:',
        list7: [
          {
            title: 'any unauthorised access and use of the Services by you;',
          },
          {
            title: 'any breach of these mobi Service Terms by you;',
          },
          {
            title: 'your dealings with the Vendors; ',
          },
          {
            title: 'any dispute between you and any third party (including our Vendors); and/or',
          },
          {
            title: 'any violation of the laws of the Republic of Singapore.',
          },
        ],
      },
      {
        title: 'You acknowledge and agree that you use the Services at your own risk. We are not responsible for the actions or omissions of Vendors and you hereby release Willers from any claims and damages, losses and expenses, known and unknown, arising out of or in connection any way connected with any claim you have against any Vendor or third party, or in relation to the Transportation Services provided by the Vendors.',
      },
    ],
  },
  {
    title: 'FORCE MAJEURE',
    center: true,
    list6: [
      {
        title: 'Without prejudice to the applicability and effect of the clauses above disclaiming or limiting liability, we will be excused from any delay or failure to perform any obligation arising under or in connection with these mobi Service Terms, if such delay or failure results, directly or indirectly, from any act of nature or circumstance or cause beyond our reasonable control including, but not limited to, any Act of God, fire, flood, explosion, lightning, windstorm, earthquake, epidemic, disease outbreak, extraordinarily severe weather conditions, failure of machinery or equipment, shortage of materials, discontinuation of power supply, court order or governmental interference, radioactive, chemical or biological contamination, civil commotion, riot or armed conflict, war, civil war (whether declared or undeclared), acts of terrorism, strike, labour disturbances, transportation difficulties or labour shortages. ',
      },
    ],
  },
  {
    title: 'GOVERNING LAW AND DISPUTE RESOLUTION',
    center: true,
    list6: [
      {
        title: 'These Terms shall be governed by and construed in accordance with the laws of the Republic of Singapore. Any dispute arising out of or in connection with these Terms, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“<b>SIAC</b>”) of Singapore in accordance with the Arbitration Rules of the SIAC (“<b>SIAC Rules</b>”) for the time being in force, which rules are deemed to be incorporated by reference in this clause. The seat of arbitration shall be Singapore, and the Tribunal shall consist of 1 arbitrator. The language of the arbitration shall be in English. The arbitral award is final and binding upon the parties to the arbitration. ',
      },
    ],
  },
];
