import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Container, Grid, Button} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircleOutlineOutlined';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {ROUTE} from '../../../common/constant';
import {shuttleBusInfo, getListJitTime} from '../../../stores/shuttleBus/actions';
import {onChangeListData, verifyInitialShuttleBusScreen} from '../../../utils/common';
import {displayDateTime, convertDatetimeUTC} from '../../../utils/date_time';

/**
 * express bus information∂
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      shuttle_bus_info: [],
      isSelect: false,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.shuttle.shuttle_bus_info.filter((item) => item.is_shuttle).forEach(async (element) => {
      await this.props.getListJitTime(element.date_time, element.shuttle_bus_type, element.reservation_id);
    });
    await this.setState({
      shuttle_bus_info: this.props.shuttle.shuttle_bus_info,
    });
    await this.checkSelectAtLeast();
    !verifyInitialShuttleBusScreen(this.props.shuttle) && this.props.history.replace({pathname: ROUTE.VALIDATE_BUS});
    this.forceUpdate();
  }

  /**
   * componentWillReceiveProps
   * @param {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this.setState({
      shuttle_bus_info: nextProps.shuttle.shuttle_bus_info,
    });
    this.forceUpdate();
  }

  checkSelectAtLeast = () => {
    if (this.state.shuttle_bus_info?.some((e) => e.status === true)) {
      this.setState({
        isSelect: true,
      });
    } else {
      this.setState({
        isSelect: false,
      });
    }
  };

  /**
   * handleNextScreen
   */
  handleNextScreen = () => {
    this.props.shuttleBusInfo(this.props.shuttle.shuttle_bus_info);
    this.props.history.push(ROUTE.REGISTRY_POSITION);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {shuttle} = this.props;
    const data = this.state.shuttle_bus_info;

    return (
      <Container maxWidth="sm" className="p-0 body_content">
        <Grid container className="pt-12">
          <Grid item xs={12} justify="center" alignItems="center" container>
            <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
          </Grid>
        </Grid>
        <Grid container className="mt-36">
          <Grid item xs={12} justify="flex-start" alignItems="center" container>
            <span className="padding-item-0 font-weight-600">高速バス予約番号</span>
          </Grid>
          <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-12">
            <span className="padding-item-0 font-25">{localStorage.getItem('order_no')}</span>
          </Grid>
        </Grid>
        {data?.map((item, index) => (
          <Grid container key={index}>
            {Number(item?.reservation_id) === Number(localStorage.getItem('way_up')) &&
              item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' && (
              <Grid
                item
                xs={12}
                justify="flex-start"
                alignItems="center"
                container
                className="header_color_mobile mt-36"
              >
                <span className="padding-item-6 font-weight-600">往路 WILLER mobi </span>
              </Grid>
            )}
            {localStorage.getItem('way_down') &&
              Number(item?.reservation_id) === Number(localStorage.getItem('way_down')) &&
              item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' && (
              <Grid
                item
                xs={12}
                justify="flex-start"
                alignItems="center"
                container
                className="header_color_mobile mt-36"
              >
                <span className="padding-item-6 font-weight-600">復路 WILLER mobi </span>
              </Grid>
            )}
            {/* 高速バス乗車情報 */}
            <Grid item xs={10} justify="flex-start" alignItems="center" container className="mt-24 title_direction">
              <span className="padding-item-6 font-weight-600">
                {index % 2 === 0 ? '高速バス乗車情報' : '高速バス降車情報'}
              </span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">
                {index % 2 === 0 ? '高速バス乗車日時' : '高速バス降車日時'}
              </span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              <span className="padding-item-0 font-20">{displayDateTime(convertDatetimeUTC(item.date_time))}</span>
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">
                {index % 2 === 0 ? '高速バス乗車地' : '高速バス降車地'}
              </span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              <span className="padding-item-0 font-20">{item.ex_bus_name}</span>
              <hr className="width_100 divider"></hr>
            </Grid>
            {item.is_shuttle ? (
              item.list_expect_time?.length > 0 ? (
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36 mb-30">
                  <div className="width_100 padding-item-0">
                    <Button
                      variant="contained"
                      style={{height: 72}}
                      className={item.status ? 'width_100 button_color_success' : 'width_100 button_color_disabled'}
                      onClick={() => {
                        onChangeListData(this, shuttle.shuttle_bus_info, 'status', index, !item.status);
                        this.checkSelectAtLeast();
                      }}
                    >
                      <div style={{display: 'grid', justifyItems: 'flex-start'}}>
                        <span className="font-weight-600 title_button">WILLER mobi</span>
                        <span className="font-20 font-weight-600">利用する</span>
                      </div>
                      <CheckCircle style={{position: 'absolute', left: 5, fontSize: 36}} />
                    </Button>
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                    <span className="padding-item-0 font-weight-600">WILLER mobi</span>
                  </Grid>
                  <Grid item xs={12} justify="flex-start" alignItems="center" container className="mb-30">
                    <span className="padding-item-6 disabled_title">現時点デマンドサービスを提供できません</span>
                  </Grid>
                </>
              )
            ) : (
              <>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                  <span className="padding-item-0 font-weight-600">WILLER mobi</span>
                </Grid>
                <Grid item xs={12} justify="flex-start" alignItems="center" container className="mb-30">
                  <span className="padding-item-6 disabled_title">この停留所はデマンド対象外の停留所です</span>
                </Grid>
              </>
            )}
          </Grid>
        ))}
        {/* Button submit */}
        <Grid container>
          <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
            <div className="width_100 padding-item-0">
              <Button
                variant="contained"
                className={
                  this.state.isSelect ?
                    'width_100 button_color_submit font-weight-600' :
                    'width_100 button_color_disabled font-weight-600'
                }
                onClick={this.handleNextScreen}
                disabled={!this.state.isSelect}
              >
                次へ
                <ArrowRightIcon style={{position: 'absolute', right: 5}} />
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container className="footer">
          <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
            <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shuttle: state.shuttle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    shuttleBusInfo: (shuttle_bus_info) => dispatch(shuttleBusInfo(shuttle_bus_info)),
    getListJitTime: (date_time, shuttle_bus_type, reservation_id) =>
      dispatch(getListJitTime(date_time, shuttle_bus_type, reservation_id)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
