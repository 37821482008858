import {combineReducers} from 'redux';
import authen from '../stores/authen/reducer';
import modal from '../stores/modal/reducer';
import shuttle from '../stores/shuttleBus/reducer';
import maps from '../stores/maps/reducer';
import asahikawa from '../stores/asahikawa/reducer';

const reducer = combineReducers(
    Object.assign({
      authen,
      modal,
      shuttle,
      maps,
      asahikawa,
    }),
);

export default reducer;
