/* eslint-disable max-len */
export default [
  {
    title: 'mobi_term.title',
    description: 'mobi_term.description',
  },
  {
    title: 'mobi_term.item1.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item1.content1.title',
      },
      {
        title: 'mobi_term.item1.content2.title',
      },
      {
        title: 'mobi_term.item1.content3.title',
      },
      {
        title: 'mobi_term.item1.content4.title',
        list2: {
          content: [
            {
              title: 'mobi_term.item1.content4.list1',
            },
            {
              title: 'mobi_term.item1.content4.list2',
            },
          ],
        },
      },
      {
        title: 'mobi_term.item1.content5.title',
      },
    ],
  },
  {
    title: 'mobi_term.item2.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item2.content1.title',
        list2: {
          content: [
            {
              title: 'mobi_term.item2.content1.list1',
              descriptions: ['mobi_term.item2.content1.list1.description1'],
            },
            {
              title: 'mobi_term.item2.content1.list2',
              descriptions:
                [
                  'mobi_term.item2.content1.list2.description1',
                  'mobi_term.item2.content1.list2.description2',
                ],
            },
            {
              title: 'mobi_term.item2.content1.list3',
              descriptions:
                [
                  'mobi_term.item2.content1.list3.description1',
                  'mobi_term.item2.content1.list3.description2',
                ],
            },
          ],
        },
      },
      {
        title: 'mobi_term.item2.content2.title',
      },
    ],
  },
  {
    title: 'mobi_term.item3.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item3.content1.title',
        list2: {
          content: [
            {
              title: 'mobi_term.item3.content1.list1',
            },
            {
              title: 'mobi_term.item3.content1.list2',
            },
          ],
        },
      },
      {
        title: 'mobi_term.item3.content2.title',
        list2: {
          content: [
            {
              title: 'mobi_term.item3.content2.list1',
            },
            {
              title: 'mobi_term.item3.content2.list2',
            },
            {
              title: 'mobi_term.item3.content2.list3',
            },
          ],
        },
      },
      {
        title: 'mobi_term.item3.content3.title',
        list2: {
          content: [
            {
              title: 'mobi_term.item3.content3.list1',
            },
            {
              title: 'mobi_term.item3.content3.list2',
            },
            {
              title: 'mobi_term.item3.content3.list3',
            },
          ],
        },
      },
      {
        title: 'mobi_term.item3.content4.title',
      },
      {
        title: 'mobi_term.item3.content5.title',
      },
    ],
  },
  {
    title: 'mobi_term.item4.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item4.content1.title',
      },
      {
        title: 'mobi_term.item4.content2.title',
      },
      {
        title: 'mobi_term.item4.content3.title',
      },
    ],
  },
  {
    title: 'mobi_term.item5.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item5.content1.title',
      },
      {
        title: 'mobi_term.item5.content2.title',
      },
    ],
  },
  {
    title: 'mobi_term.item6.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item6.content1.title',
      },
      {
        title: 'mobi_term.item6.content2.title',
      },
      {
        title: 'mobi_term.item6.content3.title',
      },
      {
        title: 'mobi_term.item6.content4.title',
      },
    ],
  },
  {
    title: 'mobi_term.item7.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item7.content1.title',
      },
      {
        title: 'mobi_term.item7.content2.title',
      },
      {
        title: 'mobi_term.item7.content3.title',
      },
    ],
  },

  {
    title: 'mobi_term.item8.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item8.content1.title',
      },
      {
        title: 'mobi_term.item8.content2.title',
      },
      {
        title: 'mobi_term.item8.content3.title',
      },
    ],
  },

  {
    title: 'mobi_term.item9.title',
    center: true,
    list1: [
      {
        title: 'mobi_term.item9.content1.title',
        list2: {
          content: [
            {
              title: 'mobi_term.item9.content1.list1',
            },
            {
              title: 'mobi_term.item9.content1.list2',
            },
            {
              title: 'mobi_term.item9.content1.list3',
            },
            {
              title: 'mobi_term.item9.content1.list4',
            },
            {
              title: 'mobi_term.item9.content1.list5',
            },
            {
              title: 'mobi_term.item9.content1.list6',
            },
          ],
        },
      },
      {
        title: 'mobi_term.item9.content2.title',
        list2: {
          content: [
            {
              title: 'mobi_term.item9.content2.list1',
            },
            {
              title: 'mobi_term.item9.content2.list2',
            },
            {
              title: 'mobi_term.item9.content2.list3',
            },
            {
              title: 'mobi_term.item9.content2.list4',
            },
          ],
        },
      },
      {
        title: 'mobi_term.item9.content3.title',
      },
    ],
  },
  {
    title: 'mobi_term.item10.title',
    center: true,
    description: 'mobi_term.item10.description',
  },
];
