import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';

/**
 * Mobile privacy landing page
 */
class MobilePrivacy extends Component {
  /**
   * render component
   * @return {HTML}
   */
  render() {
    return (
      <Container maxWidth="xs" style={{height: '100vh'}}>
        <Grid container>
          <Grid item xs={12} justify="center" alignItems="center" container>
            <h2>Dieu khoan Mobile</h2>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default MobilePrivacy;
