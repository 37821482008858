import React, {Component} from 'react';
import './styles.css';
import {withTranslation} from 'react-i18next';

import {TableContainer, Table, TableCell, TableBody, TableHead, TableRow} from '@material-ui/core';

/**
 * TermAndPrivacySection
 */
class TermAndPrivacySection extends Component {
  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    const {section, i, t} = this.props;

    return (
      <div className="section" key={i}>
        <h1 className="title-header">{t(section?.title_header)}</h1>
        {section.title &&
          (i === 0 || section.highlight ? (
            <h2
              className="section-title"
              style={{
                marginTop: section.afterNoneDescription ? 0 : 40,
                marginBottom: section.noneDescription ? 0 : 20,
                textAlign: section.center ? 'center' : '',
              }}
              dangerouslySetInnerHTML={{__html: t(section.title)}}
            ></h2>
          ) : (
            <h2
              className="section-title"
              style={{
                marginTop: section.afterNoneDescription ? 0 : 40,
                marginBottom: section.noneDescription ? 0 : 20,
                textAlign: section.center ? 'center' : '',
              }}
            >
              {t(section.title)}
            </h2>
          ))}
        {section.table && (
          <TableContainer className="padding-x-15">
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell className="index-cell table-padding"></TableCell>
                  {section.table?.property?.map((item, index) => (
                    <TableCell key={index} className="table-padding">
                      <p class="header-cell">{item}</p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {section.table?.content?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="table-padding" align="center">
                      <p className="body-cell">{index + 1}</p>
                    </TableCell>
                    {row?.map((row1, idx) =>
                      row1?.sub_content ? (
                        <TableCell key={idx} className="table-padding">
                          {row1?.sub_content.map((item, index) => (
                            <p className="body-cell">{item}</p>
                          ))}
                        </TableCell>
                      ) : (
                        <TableCell key={idx} className="table-padding">
                          <p className="body-cell">{row1}</p>
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {section.description && (
          <p className="section-description">
            <div dangerouslySetInnerHTML={{__html: t(section.description)}}></div>
          </p>
        )}
        {section.description_for_malay && (
          <p style={{paddingLeft: '55px'}}>
            <div dangerouslySetInnerHTML={{__html: t(section.description_for_malay)}}></div>
          </p>
        )}
        {section.list_one_item && (
          <ul className={'num-kakko'}>
            {section.list_one_item.map((listOneItem, k) => (
              <li key={k}>
                <div dangerouslySetInnerHTML={{__html: t(listOneItem.title)}}></div>
              </li>
            ))}
          </ul>
        )}
        {section.list1 && (
          <ol className={'ol ' + section.type ? section.type : ''}>
            {section.list1.map((list1Item, j) => (
              <li key={j}>
                <div dangerouslySetInnerHTML={{__html: t(list1Item.title)}}></div>
                {list1Item.list10 && localStorage.getItem('i18nextLng') === 'ja' ? (
                  <ul className="num-kakko">
                    {list1Item.list10?.content?.ja?.map((list3Item, l) => (
                      <li key={l}>
                        <div dangerouslySetInnerHTML={{__html: t(list3Item.title)}}></div>
                        <div>{t(list3Item.content1)}</div>
                        <div>{t(list3Item.content2)}</div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <ul className={list1Item.list2?.isUl ? '' : 'num-kakko'}>
                    {list1Item.list2?.content?.map((list2Item, k) => (
                      <li key={k}>
                        <div dangerouslySetInnerHTML={{__html: t(list2Item.title)}}></div>
                        {list2Item.note && (
                          <ul>
                            {list2Item.note?.map((note, k) => {
                              return <li key={k} dangerouslySetInnerHTML={{__html: t(note.title)}}></li>;
                            })}
                          </ul>
                        )}
                        {list2Item.descriptions && (
                          <ul type='disc'>
                            {list2Item.descriptions?.map((description) => (
                              <li>{t(description)}</li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
                {list1Item.list10 && localStorage.getItem('i18nextLng') !== 'ja' && (
                  <ul className={list1Item.list10?.isUl ? '' : 'num-kakko'}>
                    {list1Item.list10?.content?.en?.map((list3Item, k) => (
                      <li key={k}>
                        <div dangerouslySetInnerHTML={{__html: t(list3Item.title)}}></div>
                      </li>
                    ))}
                  </ul>
                )}
                {list1Item.list3 && (
                  <ul className={list1Item.list3?.isUl ? '' : 'num-kakko'}>
                    {list1Item.list3?.content?.map((list3Item, k) => (
                      <li key={k}>
                        <div dangerouslySetInnerHTML={{__html: t(list3Item.title)}}></div>
                      </li>
                    ))}
                  </ul>
                )}
                {list1Item.list4 && (
                  <ul className="num-roman">
                    {list1Item.list4?.map((list4Item, k) => (
                      <li key={k}>
                        <div dangerouslySetInnerHTML={{__html: t(list4Item.title)}}></div>
                        {list4Item.list5 && (
                          <ul className="num-alpha">
                            {list4Item.list5?.map((list5Item, k2) => {
                              return <li key={k2} dangerouslySetInnerHTML={{__html: t(list5Item.title)}}></li>;
                            })}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ol>
        )}
        {section.list3 && (
          <ul className="num-kakko2">
            {section.list3.map((list3Item, j) => (
              <li key={j}>
                <div dangerouslySetInnerHTML={{__html: t(list3Item.title)}}></div>
              </li>
            ))}
          </ul>
        )}
        {section.list6 && (
          <ul className="num-index-nested1">
            {section.list6.map((list6Item, j) => (
              <li key={j}>
                <div dangerouslySetInnerHTML={{__html: t(list6Item.title)}}></div>
                {list6Item.list7 && (
                  <ul className="num-index-nested2" style={{listStyleType: 'none'}}>
                    {list6Item.list7?.map((list7Item, k2) => (
                      <li key={k2}>
                        <span dangerouslySetInnerHTML={{__html: t(list7Item.title)}}></span>
                        {list7Item.list8 && (
                          <ul className="num-index-nested3" style={{listStyleType: 'none'}}>
                            {list7Item.list8?.map((list8Item, k3) => (
                              <li key={k3} dangerouslySetInnerHTML={{__html: t(list8Item.title)}}></li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
                {list6Item.description && (
                  <p className="section-description">
                    <div dangerouslySetInnerHTML={{__html: t(list6Item.description)}}></div>
                  </p>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation('translations')(TermAndPrivacySection);
