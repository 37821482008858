import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Container, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import SelectMultiLang from '../../components/SelectMultiLang';

const useStyles = (theme) => ({
  header_title: {
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      marginTop: 150,
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 48,
      fontSize: 24,
    },
  },
  label: {
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
});

/**
 * Mobile forgot password
 */
class Index extends Component {
  /**
   * render component
   * @return {component}
   */
  render() {
    const {classes, t} = this.props;
    const width = window.innerWidth;

    return (
      <Container maxWidth="sm" style={{height: '100vh'}}>
        <Grid container className="mt-10 mb-12" justify="flex-end" alignItems="center">
          <SelectMultiLang />
        </Grid>
        <Grid container>
          <Grid item xs={12} justify="center" alignItems="center" container className={classes.header_logo}>
            {width < 600 ? (
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10" />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/logo-title-2.png`}
                alt="Logo"
                style={{width: 108, height: 150}}
                className="p-10"
              />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={12} alignItems="center" justify="center" className={classes.header_title}>
            <span>{t('route.mobile_reissue_password')}</span>
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" className="mt-24">
            <span className="font-12">{t('mobile_reissue_pass.enter_password')}</span>
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" className="mb-50">
            <span className="font-12">{t('mobile_reissue_pass.enter_pass_confirm')}</span>
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" className="mt-48">
            <img src={`${process.env.PUBLIC_URL}/images/success.png`} alt="Logo" className="logo_success p-10"></img>
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center" className="mt-24">
            <span className="font-18 font-weight-600">{t('mobile_repass_complete.new_password')}</span>
          </Grid>
          <Grid container item xs={12} alignItems="center" justify="center">
            <span className="font-18 font-weight-600">{t('mobile_repass_complete.set')}</span>
          </Grid>
        </Grid>
        <Grid container className="mt-48" alignItems="center" justify="center">
          <Grid container item xs={12} alignItems="center" justify="center" className="notification_title">
            <Grid container item xs={12} alignItems="center" justify="center">
              <span className="font-20 font-weight-600 color-danger">{t('mobile_repass_complete.launch_app')}</span>
            </Grid>
            <Grid container item xs={12} alignItems="center" justify="center">
              <span className="font-20 font-weight-600 color-danger">{t('mobile_repass_complete.login')}</span>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(useStyles)(withTranslation('translations')(Index));
