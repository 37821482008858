export const ACTION_TYPES = {
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',

  VERIFY_PASSWORD_TOKEN_START: 'VERIFY_PASSWORD_TOKEN_START',
  VERIFY_PASSWORD_TOKEN_SUCCESS: 'VERIFY_PASSWORD_TOKEN_SUCCESS',
  VERIFY_PASSWORD_TOKEN_FAIL: 'VERIFY_PASSWORD_TOKEN_FAIL',
};

const initialState = {
  isLoading: false,
  message_code: '',
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.VERIFY_PASSWORD_TOKEN_START:
    case ACTION_TYPES.RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.VERIFY_PASSWORD_TOKEN_SUCCESS:
    case ACTION_TYPES.VERIFY_PASSWORD_TOKEN_FAIL:
      return {
        ...state,
        isLoading: false,
        message_code: action.message_code,
      };
    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
    case ACTION_TYPES.RESET_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
