import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import SimpleReactValidator from 'simple-react-validator';
import {Container, Grid, Button, FormControl, Select, MenuItem, FormHelperText, Box} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {ROUTE} from '../../../common/constant';
import Maps from '../../../components/maps';
import {checkValidTimeApi} from '../../../services/shuttleBusServices';
import {shuttleBusInfo, getListJitTime} from '../../../stores/shuttleBus/actions';
import {onChangeListData, verifyInitialShuttleBusScreen} from '../../../utils/common';
import {displayDateTime, convertDatetimeUTC} from '../../../utils/date_time';

/**
 * shuttle bus position
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      shuttle_bus_info: [],
      position: null,

      isValidTime: true,
      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleNextScreen
   * @param {event} e
   */
  handleNextScreen = (e) => {
    e.preventDefault();
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && this.state.isValidTime) {
      this.props.shuttleBusInfo(this.state.shuttle_bus_info);
      this.props.history.push(ROUTE.DETAIL_CONFIRM + this.props.match.params.id);
    }
  };

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (!verifyInitialShuttleBusScreen(this.props.shuttle)) {
      this.props.history.push({pathname: ROUTE.VALIDATE_BUS});
    } else {
      this.props.shuttle.shuttle_bus_info
          .filter((item) => item.is_shuttle)
          .forEach(async (element) => {
            await this.props.getListJitTime(element.date_time, element.shuttle_bus_type, element.reservation_id);
          });
      const shuttle = this.props.shuttle.shuttle_bus_info;
      const index = shuttle.findIndex((item) => Number(item.id) === Number(this.props.match.params.id));
      const targetObject = shuttle.find((e) => Number(e.id) === Number(this.props.match.params.id));
      if (!targetObject.list_expect_time.find((item) => item.expected_time === targetObject.expected_time)) {
        shuttle[index].expected_time = null;
      }

      this.setState({
        shuttle_bus_info: shuttle,
      });
      window.scrollTo(0, 0);
    }
  }

  /**
   * componentWillReceiveProps
   * @param {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this.setState({
      shuttle_bus_info: nextProps.shuttle.shuttle_bus_info,
    });
    const data = nextProps.shuttle.shuttle_bus_info.find((e) => Number(e.id) === Number(this.props.match.params.id));
    if (!data.list_expect_time.length > 0) {
      this.props.history.push(ROUTE.DETAIL_ORDER);
    }
  }

  /**
   * get shuttle bus position
   * @param {object} position
   * @param {int} index
   */
  getPosition(position, index) {
    onChangeListData(this, this.state.shuttle_bus_info, 'drop_off_lat', index, position.latlng?.lat());
    onChangeListData(this, this.state.shuttle_bus_info, 'drop_off_lon', index, position.latlng?.lng());
    onChangeListData(
        this,
        this.state.shuttle_bus_info,
        'drop_off_name',
        index,
      position.place_name ? position.place_name : '',
    );
    onChangeListData(this, this.state.shuttle_bus_info, 'simulation_id', index, position.simulation_id);
  }

  /**
   * handleSelectJitTime
   * @param {value} value
   * @param {int} index
   */
  handleSelectJitTime = async (value, index) => {
    onChangeListData(this, this.state.shuttle_bus_info, 'expected_time', index, value);
    const result = await this.checkValidTime(
        value,
      this.state.shuttle_bus_info[index].shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? 1 : 2,
    );
    this.setState({isValidTime: result});
  };

  /**
   * check expected time
   * @param {String} expected_time
   * @param {Number} time_type
   */
  async checkValidTime(expected_time, time_type) {
    const response = await checkValidTimeApi(expected_time, time_type);
    return response.result;
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {maps, t} = this.props;

    return (
      <Container maxWidth="sm" className="p-0 body_content">
        <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Grid container className="pt-12">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
            </Grid>
          </Grid>
          {/* 往路 WILLER mobi */}
          {this.state.shuttle_bus_info?.map(
              (item, index) =>
                Number(item.id) === Number(this.props.match.params.id) && (
                  <Grid container key={index}>
                    <Grid
                      item
                      xs={12}
                      justify="flex-start"
                      alignItems="center"
                      container
                      className="header_color_mobile mt-36"
                    >
                      <span className="padding-item-6 font-weight-600">
                        {localStorage.getItem('way_down') &&
                      Number(item?.reservation_id) === Number(localStorage.getItem('way_down')) ?
                        '復路 WILLER mobi' :
                        '往路 WILLER mobi'}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      justify="flex-start"
                      alignItems="center"
                      container
                      className="mt-24 title_direction"
                    >
                      <span className="padding-item-6 font-weight-600">
                        {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                        '高速バス乗車情報' :
                        '高速バス降車情報'}
                      </span>
                    </Grid>
                    <Grid item xs={12} justify="flex-start" alignItems="center" container>
                      <span className="padding-item-0 font-20">
                        {displayDateTime(convertDatetimeUTC(item.date_time))}
                      </span>
                    </Grid>
                    <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                      <span className="padding-item-0 font-weight-600">
                        {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? '高速バス乗車地' : '高速バス降車地'}
                      </span>
                    </Grid>
                    <Grid item xs={12} justify="flex-start" alignItems="center" container>
                      <span className="padding-item-0 font-20">{item.ex_bus_name}</span>
                    </Grid>
                    <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                      <span className="padding-item-0 font-weight-600">
                        {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                        'WILLER mobi 降車希望時間' :
                        'WILLER mobi 乗車希望時間'}
                      </span>
                    </Grid>
                    <Grid item xs={12} justify="flex-start" alignItems="center" container>
                      <FormControl variant="outlined" margin="dense" className="width_100 padding-item-6 select-box">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'expected_time',
                          }}
                          displayEmpty
                          value={item.expected_time}
                          onChange={(event) => this.handleSelectJitTime(event.target.value, index)}
                          renderValue={
                          item.expected_time &&
                          item.list_expect_time?.find((e) => e.expected_time === item.expected_time) ?
                            undefined :
                            () => (
                              <div className="font-12 color-disabled">
                                {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                                    '到着希望日時を選択してください' :
                                    '乗車希望日時を選択してください'}
                              </div>
                            )
                          }
                        >
                          {item.list_expect_time?.map((item, idx) => {
                            return (
                              <MenuItem value={item.expected_time} key={idx}>
                                {displayDateTime(convertDatetimeUTC(item.expected_time))}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('expected_time', item.expected_time, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(item.expected_time, 'required') && (
                          <FormHelperText id="expected_time" error>
                            {t('validation.required.choose', {
                              field:
                              item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                                ' WILLER mobi 降車希望時間' :
                                ' WILLER mobi 乗車希望時間',
                            })}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                        this.validator.check(item.expected_time, 'required') &&
                        !this.state.isValidTime && (
                          <FormHelperText id="expected_time" error>
                            指定した
                            {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                              ' WILLER mobi 降車希望時間' :
                              ' WILLER mobi 乗車希望時間'}
                            を再選択してください
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
                      <span className="padding-item-0 font-weight-600">
                        {item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                        'WILLER mobi の乗車地' :
                        'WILLER mobi の降車地'}
                      </span>
                    </Grid>
                    <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-24 mb-50">
                      <Box className="width_100">
                        {maps?.geofences?.length > 0 && (
                          <Maps
                            pickedPosition={{
                              lat: item.drop_off_lat,
                              lng: item.drop_off_lon,
                              place_name: item.drop_off_name,
                              simulation_id: item.simulation_id,
                            }}
                            type={item.shuttle_bus_type}
                            position={(pos) => this.getPosition(pos, index)}
                            polygon={maps.geofences}
                            expressBusLocal={{lat: item.ex_bus_lat, lng: item.ex_bus_lon}}
                            placeholder={
                            item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                              '乗車地の住所やキーワードを入力' :
                              '降車地の住所やキーワードを入力'
                            }
                          />
                        )}
                        {this.validator.message('drop_off_name', item.drop_off_name, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(item.drop_off_name, 'required') && (
                          <FormHelperText
                            id="drop_off_name" error className="padding-item-0 MuiFormHelperText-contained">
                            {t('validation.required.choose', {
                              field:
                              item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                                'WILLER mobi の乗車地' :
                                'WILLER mobi の降車地',
                            })}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ),
          )}
          {/* Button submit */}
          <Grid container>
            <Grid item xs={12} justify="center" alignItems="center" container className="mt-36">
              <div className="width_100 padding-item-6">
                <Button
                  variant="contained"
                  className="width_100 button_color_submit font-weight-600"
                  onClick={this.handleNextScreen}
                >
                  次へ
                  <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container className="footer">
            <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
              <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    maps: state.maps,
    shuttle: state.shuttle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    shuttleBusInfo: (shuttle_bus_info) => dispatch(shuttleBusInfo(shuttle_bus_info)),
    getListJitTime: (date_time, shuttle_bus_type, reservation_id) =>
      dispatch(getListJitTime(date_time, shuttle_bus_type, reservation_id)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
