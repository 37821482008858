import {ACTION_TYPES} from './reducer';
import {
  verifyOrderApi,
  registerShuttleBusApi,
  cancelShuttleBusApi,
  getJitTimeSettingApi,
} from '../../services/shuttleBusServices';
import {convertShuttleBusData} from '../../utils/shuttle_bus';
import {ROUTE} from '../../common/constant';
import {getClientTimezone} from '../../utils/date_time';

// initial verify exbus order screen
export const initialVerifyScreen = () => {
  return {
    type: ACTION_TYPES.INITIAL_VERIFY_SCREEN,
  };
};

// initial confirm shuttle bus order screen
export const initialConfirmScreen = () => {
  return {
    type: ACTION_TYPES.INITIAL_CONFIRM_SCREEN,
  };
};

// verify order number
const requestVerifyOrderAction = {
  type: ACTION_TYPES.VERIFY_ORDER_START,
};

export const successVerifyOrderAction = (data) => {
  return {
    type: ACTION_TYPES.VERIFY_ORDER_SUCCESS,
    reservation: data.reservation_wex,
    shuttle_bus_info: data.shuttle_bus_info,
    user_info: data.user_info,
  };
};

export const failVerifyOrderAction = (data) => {
  return {
    type: ACTION_TYPES.VERIFY_ORDER_FAIL,
    errorMessage: data,
  };
};

// register shuttle bus
const requestRegisterShuttleBusAction = {
  type: ACTION_TYPES.REGISTER_SHUTTLE_BUS_START,
};

export const successRegisterShuttleBusAction = (data) => {
  return {
    type: ACTION_TYPES.REGISTER_SHUTTLE_BUS_SUCCESS,
    messageRegistry: data.result,
  };
};

export const failRegisterShuttleBusAction = (data) => {
  return {
    type: ACTION_TYPES.REGISTER_SHUTTLE_BUS_FAIL,
    errorMessage: data,
  };
};

// edit shuttle bus
const requestEditShuttleBusAction = {
  type: ACTION_TYPES.EDIT_SHUTTLE_BUS_START,
};

export const successEditShuttleBusAction = (data) => {
  return {
    type: ACTION_TYPES.EDIT_SHUTTLE_BUS_SUCCESS,
    messageDetail: data.result,
  };
};

export const failEditShuttleBusAction = (data) => {
  return {
    type: ACTION_TYPES.EDIT_SHUTTLE_BUS_FAIL,
    errorMessage: data,
  };
};

// cancel shuttle bus
const requestCancelShuttleBusAction = {
  type: ACTION_TYPES.CANCEL_SHUTTLE_BUS_START,
};

export const successCancelShuttleBusAction = (data) => {
  return {
    type: ACTION_TYPES.CANCEL_SHUTTLE_BUS_SUCCESS,
  };
};

export const failCancelShuttleBusAction = (data) => {
  return {
    type: ACTION_TYPES.CANCEL_SHUTTLE_BUS_FAIL,
  };
};

// get list jit setting time
export const successGetListJitTime = (result) => {
  return {
    type: ACTION_TYPES.GET_LIST_JIT_TIME_SUCCESS,
    result,
  };
};

// update shuttle bus data
export const shuttleBusInfo = (data) => {
  return {
    type: ACTION_TYPES.SHUTTLE_BUS_INFO,
    shuttle_bus_info: data,
  };
};

/**
 * Verify Order Number
 * @param {string} order_no
 * @param {string} email
 * @param {string} props
 * @return {object}
 */
export const verifyOrder = (order_no, email, props) => {
  return (dispatch) => {
    dispatch(requestVerifyOrderAction);
    localStorage.removeItem('token');
    return verifyOrderApi(order_no, email).then(
        async (response) => {
          if (response && response.status === 200) {
            localStorage.setItem('way_up', response.result.reservation_wex[0]?.reservation_id);
            localStorage.setItem('way_down', response.result.reservation_wex[1]?.reservation_id);
            localStorage.setItem('token', response.result.token);
            localStorage.setItem('order_no', response.result.order_no);
            localStorage.setItem('email', response.result.email);
            dispatch(successVerifyOrderAction(response.result));
            const shuttle_data = convertShuttleBusData(
                response.result.reservation_wex,
                response.result.shuttle_bus_info,
            );
            await dispatch(shuttleBusInfo(shuttle_data));
          response.result.shuttle_bus_info.length > 0 &&
          response.result.shuttle_bus_info.some(
              (e) => e.status === 'REGISTERED' || e.status === 'FAILED' || e.status === 'OFFER_FAILED',
          ) ?
            props.history.push(ROUTE.DETAIL_ORDER) :
            props.history.push(ROUTE.REGISTRY_INFORMATION);
          } else {
            dispatch(failVerifyOrderAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failVerifyOrderAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Reload Detail Order
 * @param {string} order_no
 * @param {string} email
 * @param {string} props
 * @return {object}
 */
export const reloadDetailOrder = (order_no, email, props) => {
  return (dispatch) => {
    dispatch(requestVerifyOrderAction);
    localStorage.removeItem('token');
    return verifyOrderApi(order_no, email).then(
        async (response) => {
          if (response && response.status === 200) {
            dispatch(successVerifyOrderAction(response.result));
            const shuttle_data = convertShuttleBusData(
                response.result.reservation_wex,
                response.result.shuttle_bus_info,
            );
            await dispatch(shuttleBusInfo(shuttle_data));
            localStorage.setItem('way_up', response.result.reservation_wex[0]?.reservation_id);
            localStorage.setItem('way_down', response.result.reservation_wex[1]?.reservation_id);
            localStorage.setItem('token', response.result.token);
            localStorage.setItem('order_no', response.result.order_no);
            localStorage.setItem('email', response.result.email);
            await getJitSettingTime(dispatch, shuttle_data?.filter((item) => item.is_shuttle));
          } else {
            dispatch(failVerifyOrderAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failVerifyOrderAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Register Shuttle Bus
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const registerShuttleBus = (payload, props) => {
  return (dispatch) => {
    dispatch(requestRegisterShuttleBusAction);
    return registerShuttleBusApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successRegisterShuttleBusAction(response));
            props.history.push(ROUTE.REGISTRY_COMPLETE);
          } else {
            dispatch(failRegisterShuttleBusAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failRegisterShuttleBusAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Edit Shuttle Bus
 * @param {object} payload
 * @param {number} id
 * @param {object} props
 * @return {object}
 */
export const editShuttleBus = (payload, id, props) => {
  return (dispatch) => {
    dispatch(requestEditShuttleBusAction);
    return registerShuttleBusApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successEditShuttleBusAction(response));
            props.history.push(ROUTE.DETAIL_COMPLETE + id);
          } else {
            dispatch(failEditShuttleBusAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failEditShuttleBusAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Cancel Shuttle Bus
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const cancelShuttleBus = (payload, props) => {
  return (dispatch) => {
    dispatch(requestCancelShuttleBusAction);
    return cancelShuttleBusApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(successCancelShuttleBusAction(response.message_code));
            window.location.reload();
          } else {
            dispatch(failCancelShuttleBusAction(response.message_code));
          }
        },
        (error) => {
          dispatch(failCancelShuttleBusAction('Internal Server Error'));
        },
    );
  };
};

/**
 * Get Jit setting time
 * @param {string} dispatch
 * @param {object} payload
 */
export const getJitSettingTime = (dispatch, payload) => {
  payload.forEach((element) =>
    dispatch(getListJitTime(element.date_time, element.shuttle_bus_type, element.reservation_id)),
  );
};

export const getListJitTime = (date_time, shuttle_bus_type, reservation_id) => {
  return (dispatch) => {
    return getJitTimeSettingApi(
        date_time,
      shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? 1 : 2,
      getClientTimezone(),
    ).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(
                successGetListJitTime({
                  reservation_id: reservation_id,
                  shuttle_bus_type: shuttle_bus_type,
                  data: response.result,
                }),
            );
          } else {
            dispatch(
                successGetListJitTime({reservation_id: reservation_id, shuttle_bus_type: shuttle_bus_type, data: []}),
            );
          }
        },
        (error) => {
          dispatch(
              successGetListJitTime({reservation_id: reservation_id, shuttle_bus_type: shuttle_bus_type, data: []}));
        },
    );
  };
};
