/* eslint-disable max-len */
import {getClientTimezone} from './date_time';

/**
 * Convert Express-bus's data to Raw's data
 * @param {Array} reservation
 * @return {Array} raw_data
 */
export const convertReservationToRawData = (reservation) => {
  const shuttle_bus_info = [];
  if (reservation.length <= 0) {
    return [];
  }
  reservation.forEach((rev) => {
    shuttle_bus_info.push({
      status: false,
      ex_bus_lat: rev.dept_stop_lat,
      ex_bus_lon: rev.dept_stop_lon,
      ex_bus_name: rev.dept_stop_name,
      date_time: rev.dept_date_time,
      is_shuttle: rev.is_dept_shuttle_bus,
      id: -1,
      reservation_id: rev.reservation_id,
      drop_off_lat: 0,
      drop_off_lon: 0,
      drop_off_name: '',
      simulation_id: null,
      passenger_email: '',
      passenger_name: '',
      phone: '',
      shuttle_bus_type: 'SHUTTLE_BUS_JIT_HOME_TO_WORK',
      estimate_swat_time: null,
      expected_time: '',
      number_in_use: '',
      min_closed_time: null,
      max_closed_time: null,
      shuttle_bus_stop_name: null,
      cnt: rev.cnt,
      list_expect_time: [],
    });
    shuttle_bus_info.push({
      status: false,
      ex_bus_lat: rev.arrive_stop_lat,
      ex_bus_lon: rev.arrive_stop_lon,
      ex_bus_name: rev.arrive_stop_name,
      date_time: rev.arrive_date_time,
      is_shuttle: rev.is_arrive_shuttle_bus,
      id: -1,
      reservation_id: rev.reservation_id,
      drop_off_lat: 0,
      drop_off_lon: 0,
      drop_off_name: '',
      simulation_id: null,
      passenger_email: '',
      passenger_name: '',
      phone: '',
      shuttle_bus_type: 'SHUTTLE_BUS_JIT_WORK_TO_HOME',
      estimate_swat_time: null,
      expected_time: '',
      number_in_use: '',
      min_closed_time: null,
      max_closed_time: null,
      shuttle_bus_stop_name: null,
      cnt: rev.cnt,
      list_expect_time: [],
    });
  });
  return shuttle_bus_info;
};

/**
 * convert raw_data to Shuttle-bus's data
 * @param {Array} reservation
 * @param {Array} bus_info
 * @return {Array}
 */
export const convertShuttleBusData = (reservation, bus_info) => {
  const api_data = [];
  const shuttle_bus_info = convertReservationToRawData(reservation);
  const bus_data = bus_info.filter(
      (e) => e.status === 'REGISTERED' || e.status === 'FAILED' || e.status === 'OFFER_FAILED',
  );

  if (bus_data.length > 0) {
    bus_data.forEach((bus) => {
      const rev = shuttle_bus_info?.find(
          (rev) => Number(rev.reservation_id) === Number(bus.reservation_id) && rev.shuttle_bus_type === bus.shuttle_bus_type,
      );

      api_data.push({
        status: bus.status,
        ex_bus_lat: rev.ex_bus_lat,
        ex_bus_lon: rev.ex_bus_lon,
        ex_bus_name: rev.ex_bus_name,
        date_time: rev.date_time,
        is_shuttle: rev.is_shuttle,
        id: bus.id,
        reservation_id: bus.reservation_id,
        drop_off_lat: bus.drop_off_lat,
        drop_off_lon: bus.drop_off_lon,
        drop_off_name: bus.drop_off_name,
        simulation_id: bus.simulation_id,
        passenger_email: bus.passenger_email,
        passenger_name: bus.passenger_name,
        phone: bus.phone,
        shuttle_bus_type: bus.shuttle_bus_type,
        estimate_swat_time:
          bus.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
            bus.estimated_pickup_swat_time :
            bus.estimated_drop_off_swat_time,
        expected_time: bus.expected_time,
        number_in_use: bus.number_in_use,
        min_closed_time: bus.min_closed_time,
        max_closed_time: bus.max_closed_time,
        shuttle_bus_stop_name: bus.shuttle_bus_stop_name,
        cnt: rev.cnt,
        list_expect_time: [],
      });
    });
  }
  return shuttle_bus_info.map(
      (item) =>
        api_data.find((e) => e.reservation_id === item.reservation_id && e.shuttle_bus_type === item.shuttle_bus_type) ||
      item,
  );
};

/**
 * convert data (registry and edit shuttle bus's information)
 * @param {Object} data
 * @return {Object}
 */
export const convertPayload = (data) => {
  const wex_infos = data
      .filter((e) => e.status === true || e.status === 'FAILED' || e.status === 'OFFER_FAILED')
      .map((item) => ({
        id: item.id,
        reservation_id: item.reservation_id,
        drop_off_lat: item.drop_off_lat,
        drop_off_lon: item.drop_off_lon,
        drop_off_name: item.drop_off_name,
        passenger_email: item.passenger_email.trim().toLowerCase(),
        passenger_name: item.passenger_name.trim(),
        phone: item.phone.trim(),
        shuttle_bus_type: item.shuttle_bus_type,
        expected_time: item.expected_time,
        number_in_use: item.number_in_use,
        status: item.status ? 'REGISTERED' : 'NONE',
        booking_id: item.booking_id,
        trip_booking: {
          transportation_type: 'SHUTTLE_BUS',
          pickup_location_lat:
          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.drop_off_lat : item.ex_bus_lat,
          pickup_location_lon:
          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.drop_off_lon : item.ex_bus_lon,
          pickup_location_name:
          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.drop_off_name : item.ex_bus_name,
          dropoff_location_lat:
          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.ex_bus_lat : item.drop_off_lat,
          dropoff_location_lon:
          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.ex_bus_lon : item.drop_off_lon,
          dropoff_location_name:
          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.ex_bus_name : item.drop_off_name,
          demand: {
            normal_passengers: 0,
            passenger: item.number_in_use,
            special_category: 0,
          },
          requested_departure_time: item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? null : item.expected_time,
          requested_destination_time:
          item.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.expected_time : null,
          trip_info: [{}],
          simulation_id: item.simulation_id,
          service_type: 'SHUTTLE_BUS_JIT',
        },
      }));
  return {wex_infos: wex_infos, zone_id: getClientTimezone()};
};

/**
 * convert data for cancel shuttle bus
 * @param {Object} data
 * @param {Number} id
 * @return {Object}
 */
export const convertDeletePayload = (data, id) => {
  const object = data.find((e) => e.id === id);
  return {
    pickup_location_name:
      object.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? object.drop_off_name : object.ex_bus_name,
    dropoff_location_name:
      object.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? object.ex_bus_name : object.drop_off_name,
    requested_departure_time: object.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? null : object.expected_time,
    requested_destination_time:
      object.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? object.expected_time : null,
    id: object.id,
    zone_id: getClientTimezone(),
  };
};

/**
 * render select people
 * @param {Number} number
 * @return {Array}
 */
export const renderSelectPeople = (number) => {
  const people = [
    {value: 1, display: '1名'},
    {value: 2, display: '2名'},
    {value: 3, display: '3名'},
    {value: 4, display: '4名'},
    {value: 5, display: '5名'},
    {value: 6, display: '6名'},
    {value: 7, display: '7名'},
    {value: 8, display: '8名'},
    {value: 9, display: '9名'},
  ];

  const array = [];
  for (let index = 0; index < number; index++) {
    array.push({value: index + 1, display: `${index + 1}名`});
  }
  return number > 9 ? people : array;
};
