import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import _ from 'lodash';
import {Container, Grid, Button, Box} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MapAsahikawa from '../../../components/map_display';
import {ROUTE} from '../../../common/constant';
import {displayDateTime, convertDatetimeUTC} from '../../../utils/date_time';
import {convertAsahikawaPayload} from '../../../utils/asahikawa';
import {asahikawaBusData, registerAsahikawaBus, updateAsahikawaBus} from '../../../stores/asahikawa/actions';

/**
 * Confirm Asahikawa shuttle bus
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      booking_id: '',
      pick_up_id: null,
      pick_up_name: '',
      pick_up_lat: null,
      pick_up_lon: null,
      drop_off_id: null,
      drop_off_name: '',
      drop_off_lat: null,
      drop_off_lon: null,
      shuttle_bus_type: '',
      expected_date: null,
      expected_time: '',
      passenger_name: '',
      passenger_email: '',
      phone: '',
      number_in_use: 1,
      simulation_id: null,
      geofence_id: '',
      status: '',
      isTermConditions: false,
      isPrivacyPolicy: false,

      isSubmitForm: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (_.isEmpty(this.props.asahikawa?.asahikawa_bus)) {
      this.props.history.push(ROUTE.ASAHIKAWA);
    } else {
      const data = this.props.asahikawa?.asahikawa_bus;
      this.setState({
        id: data.id ? data.id : -1,
        booking_id: data?.booking_id,
        pick_up_id: data.pick_up_id ? data.pick_up_id : '',
        pick_up_name: data?.pick_up_name,
        pick_up_lat: data?.pick_up_lat,
        pick_up_lon: data?.pick_up_lon,
        drop_off_id: data.drop_off_id ? data.drop_off_id : '',
        drop_off_name: data?.drop_off_name,
        drop_off_lat: data?.drop_off_lat,
        drop_off_lon: data?.drop_off_lon,
        shuttle_bus_type: data?.shuttle_bus_type,
        expected_date: data.expected_time ? new Date(data.expected_time) : null,
        expected_time: data.expected_time ? data.expected_time : '',
        passenger_name: data.passenger_name ? data.passenger_name : '',
        passenger_email: data.passenger_email ? data.passenger_email : '',
        phone: data.phone ? data.phone : '',
        number_in_use: data.number_in_use ? data.number_in_use : '',
        simulation_id: data?.simulation_id,
        geofence_id: data?.geofence_id,
        status: data?.status,
        isTermConditions: data?.isTermConditions,
        isPrivacyPolicy: data?.isPrivacyPolicy,
      });
      window.scrollTo(0, 0);
    }
  }

  /**
   * handleSubmitData
   * @param {event} e
   */
  handleSubmitData = (e) => {
    e.preventDefault();
    const payload = {
      id: this.state.id,
      booking_id: this.state.booking_id,
      pick_up_id: this.state.pick_up_id,
      pick_up_name: this.state.pick_up_name,
      pick_up_lat: this.state.pick_up_lat,
      pick_up_lon: this.state.pick_up_lon,
      drop_off_id: this.state.drop_off_id,
      drop_off_name: this.state.drop_off_name,
      drop_off_lat: this.state.drop_off_lat,
      drop_off_lon: this.state.drop_off_lon,
      expected_date: this.state.expected_date,
      expected_time: this.state.expected_time,
      shuttle_bus_type: this.state.shuttle_bus_type,
      passenger_name: this.state.passenger_name,
      passenger_email: this.state.passenger_email,
      phone: this.state.phone,
      number_in_use: this.state.number_in_use,
      simulation_id: this.state.simulation_id,
      geofence_id: this.state.geofence_id,
      isTermConditions: this.state.isTermConditions,
      isPrivacyPolicy: this.state.isPrivacyPolicy,
    };
    this.props.asahikawaBusData(payload);
    this.state.booking_id ?
      this.props.updateAsahikawaBus(convertAsahikawaPayload(payload), this.props) :
      this.props.registerAsahikawaBus(convertAsahikawaPayload(payload), this.props);
  };

  /**
   * Render HTML
   * @return {component}
   */
  render() {
    const {asahikawa, maps} = this.props;

    return (
      <Container maxWidth="sm" style={{height: '100%'}} className="p-0 body_content">
        <LoadingOverlay
          active={asahikawa.isLoading}
          style={{height: '100%'}}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          <Grid container className="pt-12">
            <Grid item xs={12} justify="center" alignItems="center" container>
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
            </Grid>
          </Grid>
          {/* 往路 WILLER mobi 搭乗者情報 */}
          <Grid container>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="header_color_mobile mt-36">
              <span className="padding-item-6 font-weight-600">WILLER mobi 搭乗者情報</span>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container>
              <Box className="width_100">
                <MapAsahikawa
                  pickup_position={{
                    lat: this.state.pick_up_lat,
                    lng: this.state.pick_up_lon,
                    place_name: this.state.pick_up_name,
                  }}
                  dropoff_position={{
                    lat: this.state.drop_off_lat,
                    lng: this.state.drop_off_lon,
                    place_name: this.state.drop_off_name,
                  }}
                  simulation_id={this.state.simulation_id}
                  geofence_id={this.state.geofence_id}
                  type={this.state.shuttle_bus_type}
                  polygon={maps.geofences.filter((e) => e.geofence_id === this.state.geofence_id)}
                  mapReadOnly
                />
              </Box>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">WILLER mobi 乗車地</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              <span className="padding-item-0 font-20">{this.state.pick_up_name}</span>
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">WILLER mobi 降車地</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              <span className="padding-item-0 font-20">{this.state.drop_off_name}</span>
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">WILLER mobi 乗車日時</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              {this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? (
                <span className="padding-item-0 font-16 color-secondary">予約確定後、メールにてご連絡</span>
              ) : (
                <span className="padding-item-0 font-20">
                  {displayDateTime(convertDatetimeUTC(this.state.expected_time))}
                </span>
              )}
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">WILLER mobi 降車希望日時</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              {this.state.shuttle_bus_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? (
                <span className="padding-item-0 font-20">
                  {displayDateTime(convertDatetimeUTC(this.state.expected_time))}
                </span>
              ) : (
                <span className="padding-item-0 font-16 color-secondary">予約確定後、メールにてご連絡</span>
              )}
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">乗車代表者氏名</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              <span className="padding-item-0 font-20">{this.state.passenger_name}</span>
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">連絡用Eメールアドレス</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              <span className="padding-item-0 font-20">
                {this.state.passenger_email ? this.state.passenger_email : localStorage.getItem('email')}
              </span>
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">電話番号</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              <span className="padding-item-0 font-20">{this.state.phone}</span>
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <span className="padding-item-0 font-weight-600">利用人数</span>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container>
              <span className="padding-item-0 font-20">{this.state.number_in_use}名</span>
              <hr className="width_100 divider"></hr>
            </Grid>
            <Grid item xs={12} justify="flex-start" alignItems="center" container className="mt-36">
              <div className="width_100 padding-item-0">
                <Button
                  variant="contained"
                  className="width_100 button_color_success font-weight-600"
                  onClick={this.handleSubmitData}
                >
                  予約リクエスト内容を送信する
                  <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                </Button>
              </div>
            </Grid>
          </Grid>
          {asahikawa.errorMessage &&
            (asahikawa.errorMessage === 'error.closed.time.over' ? (
              <Grid container justify="center" alignItems="center" className="mt-36">
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <span className="padding-item-0 font-16 color-danger">
                    タイムアウトになりました。再ログインしてください。
                  </span>
                </Grid>
              </Grid>
            ) : (
              <Grid container justify="center" alignItems="center" className="mt-36">
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <span className="padding-item-0 font-16 color-danger">
                    指定した
                    {asahikawa.errorMessage === 'error.expected.time.is.closed.home.to.work' ?
                      ' WILLER mobi 降車希望時間' :
                      ' WILLER mobi 乗車希望時間'}
                    の
                  </span>
                </Grid>
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <span className="padding-item-0 font-16 color-danger">予約期限過ぎました。</span>
                </Grid>
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <span className="padding-item-0 font-12">前の画面に戻って再選択してください</span>
                </Grid>
              </Grid>
            ))}
          <Grid container className="footer">
            <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
              <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    asahikawa: state.asahikawa,
    maps: state.maps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    asahikawaBusData: (data) => dispatch(asahikawaBusData(data)),
    registerAsahikawaBus: (payload, props) => dispatch(registerAsahikawaBus(payload, props)),
    updateAsahikawaBus: (payload, props) => dispatch(updateAsahikawaBus(payload, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
