import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import SimpleReactValidator from 'simple-react-validator';
import {Container, Grid, TextField, Button, FormHelperText, FormControl} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {ROUTE} from '../../../common/constant';
import {getGeofences} from '../../../stores/maps/actions';
import {defaultData} from '../../../utils/asahikawa';
import {onChangeTextField} from '../../../utils/common';
import {initialLandingPageState, verifyAsahikawaOrder, asahikawaBusData} from '../../../stores/asahikawa/actions';

/**
 * validate express bus information
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      reservation_number: '',
      email: '',

      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.initialLandingPageState();
    this.props.getGeofences();
    const urlParamStr = new URLSearchParams(window.location.search);
    this.setState({
      reservation_number: urlParamStr.get('reservation_number') ? urlParamStr.get('reservation_number') : '',
      email: urlParamStr.get('email') ? urlParamStr.get('email') : '',
    });
    window.scrollTo(0, 0);
  }

  /**
   * handleToNewOrder
   */
  handleToNewOrder = () => {
    this.props.getGeofences();
    this.props.asahikawaBusData(defaultData());
    this.props.history.push(ROUTE.ASAHIKAWA_INPUT);
  }

  /**
   * handle submit form verify order
   * @param {event} e
   */
  handleValidateOrder = (e) => {
    e.preventDefault();
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.props.verifyAsahikawaOrder(
        this.state.reservation_number?.trim().toUpperCase(),
        this.state.email?.trim().toLowerCase(),
        this.props,
      );
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {asahikawa, t} = this.props;

    return (
      <Container maxWidth="sm" style={{height: '100%'}} className="p-0">
        <LoadingOverlay
          active={asahikawa.isLoading}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          <form onSubmit={this.handleValidateOrder}>
            <Grid container className="pt-12">
              <Grid item xs={12} justify="center" alignItems="center" container>
                <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header p-10"></img>
              </Grid>
            </Grid>
            <Grid container className="pt-12 mt-36">
              <Grid
                item
                xs={12}
                justify="flex-start"
                alignItems="center"
                container
                className="header_color_mobile mt-5"
              >
                <span className="padding-item-0 font-weight-600">予約情報を管理</span>
              </Grid>
            </Grid>
            <Grid container className="mt-36">
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <span className="padding-item-0 font-weight-600">WILLER mobi 予約番号</span>
              </Grid>
            </Grid>
            <Grid container className="mt-12">
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <FormControl className="width_100 padding-item-0">
                  <TextField
                    name="reservation_number"
                    variant="outlined"
                    className="width_100 font-17"
                    placeholder="高速バス予約番号を入力してください"
                    value={this.state.reservation_number}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="mt-36">
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <span className="padding-item-0 font-weight-600">連絡用Eメールアドレス</span>
              </Grid>
            </Grid>
            <Grid container className="mt-12">
              <Grid item xs={12} justify="flex-start" alignItems="center" container>
                <FormControl className="width_100 padding-item-0">
                  <TextField
                    name="email"
                    variant="outlined"
                    className="width_100 font-17"
                    placeholder="Eメールアドレスを入力してください"
                    value={this.state.email}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                  {this.validator.message('email', this.state.email?.trim(), 'email')}
                  {this.state.isSubmitForm && !this.validator.check(this.state.email?.trim(), 'email') && (
                    <FormHelperText id="email" error>
                      {t('validation.email')}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="mt-36">
              <Grid item xs={12} justify="center" alignItems="center" container>
                <div className="width_100 padding-item-0">
                  <Button
                    type="submit"
                    variant="contained"
                    className={
                      this.state.reservation_number && this.state.email ?
                        'width_100 button_color_submit button_height font-weight-600' :
                        'width_100 button_color_disabled button_height font-weight-600'
                    }
                    disabled={this.state.reservation_number && this.state.email ? false : true}
                  >
                    確認
                    <ArrowRightIcon style={{position: 'absolute', right: 5}} />
                  </Button>
                </div>
              </Grid>
            </Grid>
            {asahikawa.errorMessage && (
              <Grid container justify="center" alignItems="center" className="mt-36">
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <span className="padding-item-0 font-16 color-danger">ご入力された情報が存在しません。</span>
                </Grid>
                <Grid item xs={12} justify="center" alignItems="center" container className="mt-12">
                  <span className="padding-item-0 font-16 color-danger">入力内容を確認し、再度ご入力ください。</span>
                </Grid>
              </Grid>
            )}
            <Grid container className="pt-12 mt-36">
              <Grid item xs={12} justify="center" alignItems="center" container className="mt-5">
                <span
                  className="padding-item-0 font-weight-600 color-info cursor_pointer"
                  onClick={this.handleToNewOrder}
                >
                  新規予約の方はこちら
                </span>
              </Grid>
            </Grid>
            <Grid container className="footer">
              <Grid item xs={12} justify="center" alignItems="flex-start" container className="mt-5">
                <span className="font-10">Copyright © WILLER, Inc. All Rights Reserved.</span>
              </Grid>
            </Grid>
          </form>
        </LoadingOverlay>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    asahikawa: state.asahikawa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyAsahikawaOrder: (reservation_number, email, props) =>
      dispatch(verifyAsahikawaOrder(reservation_number, email, props)),
    initialLandingPageState: () => dispatch(initialLandingPageState()),
    getGeofences: () => dispatch(getGeofences()),
    asahikawaBusData: (data) => dispatch(asahikawaBusData(data)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
